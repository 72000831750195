import {
  Check,
  HelpOutline,
  HistoryToggleOff,
  Update,
  VerifiedOutlined,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import { SyndicationStatus } from "domain/syndication";
import { FC, useMemo } from "react";

interface SyndicationStatusBadgeProps {
  status?: SyndicationStatus;
}

interface StatusValues {
  color: string;
  icon: JSX.Element;
  text: string;
}

export const SyndicationStatusBadge: FC<SyndicationStatusBadgeProps> = ({
  status,
}) => {
  const { color, icon, text } = useMemo<StatusValues>(() => {
    switch (status) {
      case SyndicationStatus.Participating:
        return {
          color: "primary",
          icon: <VerifiedOutlined />,
          text: "Participating",
        };
      case SyndicationStatus.Registered:
        return {
          color: "info",
          icon: <Check />,
          text: "Registered",
        };
      case SyndicationStatus.Pending:
        return {
          color: "warning",
          icon: <Update />,
          text: "Pending",
        };
      case SyndicationStatus.Expired:
        return {
          color: "error",
          icon: <HistoryToggleOff />,
          text: "Expired",
        };
      default:
        return {
          color: "default",
          icon: <HelpOutline />,
          text: "Invalid",
        };
    }
  }, [status]);

  return (
    <Chip
      clickable
      variant="outlined"
      color={color as any}
      icon={icon}
      label={text}
    />
  );
};
