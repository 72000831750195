import DealService from "services/deal.service";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import useSWR from "swr";

export default function useDealHistoricalPerformance() {
  const alias = useDealAlias() as string;
  const { dealAccess: isAllowed } = useDealPermissions();

  const { data, ...rest } = useSWR(
    () => isAllowed && `fund/${alias}/historical-performance`,
    async () => {
      const responseData =
        await DealService.getDealHistoricalPerformanceAllEntries(alias);

      if (Array.isArray(responseData) && responseData.length) {
        return responseData;
      }

      return null;
    }
  );

  return { data, loading: typeof data === "undefined" && !rest.error, ...rest };
}
