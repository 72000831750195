import { clsxm } from "application/utils";
import { FC } from "react";
import {
  SegmentControlButton,
  SegmentControlButtonProps,
} from "./SegmentedControl.components";

type SegmentedControlItem = SegmentControlButtonProps;

type SegmentedControlProps = {
  items: SegmentedControlItem[];
  className?: string;
  truncate?: boolean;
};

export const SegmentedControl: FC<SegmentedControlProps> = ({
  className,
  items,
  truncate,
}) => {
  return (
    <div
      className={clsxm(
        "rounded-full bg-gray-150 relative flex space-x-1 p-1 w-max-full",
        className
      )}
    >
      {items.map((item, index) => (
        <SegmentControlButton
          truncate={truncate}
          key={`${item.title}-${index}`}
          {...item}
        />
      ))}
    </div>
  );
};
