import { Bookmark, Diamond, StyleOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Outlet, generatePath, useLocation, useParams } from "react-router-dom";
import * as paths from "ui/Router/paths";
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Loading,
  NavigationDrawer,
} from "ui/components";
import Aside, { IAsideItem } from "ui/components/Aside/Aside";
import { useSyndicationLayout, useSyndicationPartner } from "ui/hooks";
import { ModalComponent } from "../CommonLayout/Modals/ModalComponent";
import UseManagePartnerContext from "ui/pages/SyndicationManagement/reducer/context";

interface SubPageItem extends IAsideItem {
  breadcrumbsItems: BreadcrumbsProps["items"];
}

interface IRouteMap {
  detailsRoute: string;
  brandingRoute?: string;
  legalRoute?: string;
  dealsRoute?: string;
}

export const SyndicationManagementPartnerCreateWrapper = () => {
  const location = useLocation();
  const [isBlockModalOpen, setBlockModalOpen] = useState(false);
  const completePathname = [location.pathname, location.hash].join("");
  const { partnerId } = useParams();
  const { isSyndicate } = useSyndicationLayout();
  const { data, loading } = useSyndicationPartner(partnerId || "", {
    isPaused: () => !partnerId,
  });

  const { brandingRoute, detailsRoute, legalRoute } = useMemo<IRouteMap>(() => {
    if (partnerId) {
      return {
        brandingRoute: generatePath(paths.managePartnerBrandingUpdate, {
          partnerId,
        }),
        legalRoute: generatePath(paths.managePartnerLegalUpdate, {
          partnerId,
        }),
        detailsRoute: generatePath(paths.managePartnerUpdate, {
          partnerId,
        }),
        dealsRoute: generatePath(paths.managePartnerDealsUpdate, {
          partnerId,
        }),
      };
    }

    return {
      detailsRoute: paths.managePartnerCreate,
    };
  }, [partnerId]);

  const onClickAction = useMemo(() => {
    if (partnerId) {
      return undefined;
    }

    return () => {
      setBlockModalOpen(true);
    };
  }, [partnerId]);

  const syndicationAsideMenuItems = useMemo<SubPageItem[]>(() => {
    const commonBreadcrumbItems = isSyndicate
      ? [
          {
            label: "Admin",
            to:
              "/" +
              (!isSyndicate
                ? paths.manageUsersList
                : paths.manageSyndicationUsers),
          },
        ]
      : [
          {
            label: "Admin",
            to:
              "/" +
              (!isSyndicate
                ? paths.manageUsersList
                : paths.manageSyndicationUsers),
          },
          {
            label: "Partners",
            to: "/" + paths.managePartners,
            isLast: true,
          },
        ];

    return [
      {
        label: "Company details",
        Icon: Bookmark,
        to: detailsRoute,
        breadcrumbsItems: [
          ...commonBreadcrumbItems,
          {
            label: isSyndicate
              ? "Platform management"
              : `${partnerId ? "Edit" : "Add"} partner`,
            to: "/" + detailsRoute,
            isLast: true,
          },
        ],
      },
      {
        label: "Legal",
        Icon: Diamond,
        to: legalRoute,
        onClick: onClickAction,
        breadcrumbsItems: [
          ...commonBreadcrumbItems,
          {
            label: isSyndicate
              ? "Platform management - Legal details"
              : "Edit partner legal details",
            to: "/" + legalRoute,
            isLast: true,
          },
        ],
      },
      {
        label: "Branding",
        Icon: StyleOutlined,
        to: brandingRoute,
        onClick: onClickAction,
        breadcrumbsItems: [
          ...commonBreadcrumbItems,
          {
            label: isSyndicate
              ? "Platform management - Branding details"
              : "Edit partner branding details",
            to: "/" + brandingRoute,
            isLast: true,
          },
        ],
      },
    ] as SubPageItem[];
  }, [
    brandingRoute,
    detailsRoute,
    isSyndicate,
    legalRoute,
    onClickAction,
    partnerId,
  ]);

  const activeSubItem = useMemo(
    () =>
      syndicationAsideMenuItems.find(
        (item) => "/" + item.to === completePathname
      ),
    [syndicationAsideMenuItems, completePathname]
  );

  if (partnerId && loading) {
    return <Loading full />;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col grow">
        <div className="w-full shrink-0">
          <NavigationDrawer
            className="block md:hidden"
            title="Campaign"
            items={syndicationAsideMenuItems.map((item) => ({
              ...item,
              to: "/" + item.to,
            }))}
          />
        </div>
        <div className="m9-container">
          <div className="hidden md:block">
            <Breadcrumbs items={activeSubItem?.breadcrumbsItems || []} />
          </div>
          <div className="flex justify-between items-center my-7">
            <Typography variant="h2" className="text-primary-600 font-serif">
              {isSyndicate
                ? "Platform management"
                : `${
                    partnerId ? "Edit" : "Add a new"
                  } partner - Overview & Settings`}
            </Typography>
            {data && (
              <img
                className="w-[50px] h-[50px] object-contain object-center"
                src={data.logo.logoSquare}
                alt={`${data.legalName}'s logo`}
                title={data.legalName}
              />
            )}
          </div>

          <Grid container className="w-full" spacing={{ md: 3 }}>
            <Grid item xs={0} md={3}>
              <Aside
                className="hidden md:block"
                asideItems={syndicationAsideMenuItems}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <UseManagePartnerContext.Provider
                value={{ syndicationPartnerData: data }}
              >
                <Outlet />
              </UseManagePartnerContext.Provider>
            </Grid>
          </Grid>
        </div>
      </div>
      <ModalComponent
        title="Create partner first"
        onClose={() => setBlockModalOpen(false)}
        open={isBlockModalOpen}
      >
        <div className="flex flex-col h-full">
          <div>
            <Typography variant="body2" className="text-gray-500">
              To access this page, please create the <b>Partner</b> by filling
              out the <i>necessary information</i>. This will allow you to
              continue filling out the partner details and take full advantage
              of our site's features.
            </Typography>
          </div>
          <div className="pt-8 mt-auto md:mt-0 w-full">
            <Button
              onClick={() => setBlockModalOpen(false)}
              variant="contained"
              className="w-full"
            >
              Close
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};
