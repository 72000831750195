import {
  AutoAwesome,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { AssistantThreadMessageRole } from "domain/assistant";
import { FC, ReactElement, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IAssistantMessage } from "types/assistant.service";
import {
  AssistantMessageHeader,
  AssistantMessageWrapper,
} from "../AssistantChat/AssistantChat.components";
import { BetaTag } from "../BetaTag";
import { CopyButton, EnterButton } from "../Button";
import { SearchInput } from "../SearchInput";
import { useUserProfile } from "ui/hooks";
import { clsxm } from "application/utils";
import { noop } from "lodash";

interface AssistantFloatingChatProps {
  onClose: () => void;
  onInit?: () => Promise<void>;
  onMessageAdd: (message: string) => Promise<void>;
  allowMinimize?: boolean;
  isInitialized: boolean;
  isLoading: boolean;
  messages: IAssistantChatMessage[];
  title: string;
  disabled?: boolean;
  disclaimer?: string;
}

export interface IAssistantChatMessage
  extends Omit<IAssistantMessage, "content"> {
  content: string | ReactElement;
  textToCopy?: string;
  error?: boolean;
}

export const AssistantFloatingChat: FC<AssistantFloatingChatProps> = ({
  onClose,
  isLoading,
  messages,
  title,
  disabled,
  onInit = noop,
  isInitialized,
  onMessageAdd,
  allowMinimize = false,
  disclaimer = "Deal assistant won't always get it right.",
}) => {
  const { data: profile } = useUserProfile();
  const [isMinimized, setMinimized] = useState(false);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: { message: "" },
  });

  const hasError = useMemo(() => {
    return messages?.[0]?.error;
  }, [messages]);

  const options = useMemo(() => {
    if (hasError) {
      return ["Retry"];
    }

    return ["Elaborate", "Shorten", "Formalize"];
  }, [hasError]);

  return (
    <div className="flex flex-col h-full">
      <div
        onClick={() => {
          if (!allowMinimize) return;

          setMinimized(true);
        }}
        className={clsxm(
          "p-4 flex flex-nowrap overflow-hidden w-full items-center shrink-0",
          allowMinimize && "cursor-pointer"
        )}
      >
        <div className="rounded-full border border-solid border-primary flex items-center text-primary bg-white py-1 px-2">
          <AutoAwesome className="mr-1 text-[1rem] text-inherit" />
          <Typography variant="caption2" className="text-primary">
            AI
          </Typography>
        </div>
        <Typography
          variant="caption"
          className="text-primary-600 ml-1 truncate font-bold"
        >
          - {title}
        </Typography>
        <div className="ml-auto">
          <BetaTag />
        </div>
        <div className="flex space-x-4 items-center shrink-0 ml-2">
          {allowMinimize && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setMinimized(!isMinimized);
              }}
              className="text-primary h-8 w-8"
            >
              {isMinimized ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
          {!isMinimized && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              className="text-primary h-8 w-8"
            >
              <Close />
            </IconButton>
          )}
        </div>
      </div>

      {!isMinimized && [
        <div key={1} className="grow flex flex-col-reverse px-4 overflow-auto">
          {!isInitialized && (
            <div className="mt-auto mx-auto mb-10 ">
              <LoadingButton
                startIcon={<AutoAwesome />}
                onClick={onInit}
                loading={isLoading}
                className="h-8 px-3"
                variant="contained"
              >
                <Typography
                  variant="caption"
                  className="text-inherit font-bold"
                >
                  Create field content
                </Typography>
              </LoadingButton>
            </div>
          )}
          <div className="h-10 shrink-0 mb-auto w-full">&nbsp;</div>
          {messages.length > 0
            ? messages.map((message) => {
                const isUserMessage =
                  message.role === AssistantThreadMessageRole.User;

                const content = (
                  <div className="flex">
                    <Typography
                      variant="body2"
                      className={clsx("rounded-lg grow w-fit")}
                    >
                      {message.content}
                    </Typography>
                    {message.textToCopy && (
                      <div className="h-full pl-2 grow-0 shrink-0">
                        <CopyButton
                          textToCopy={message.textToCopy}
                          className="sticky top-1"
                        />
                      </div>
                    )}
                  </div>
                );

                if (isUserMessage) {
                  return (
                    <div className="my-4 w-full">
                      <AssistantMessageHeader
                        isUserMessage
                        userInformation={{
                          fullName: profile?.fullName || "",
                          imageUrl: profile?.imageUrl || "",
                        }}
                      />
                      <AssistantMessageWrapper>
                        {content}
                      </AssistantMessageWrapper>
                    </div>
                  );
                }

                return (
                  <div className="my-4 w-full">
                    <AssistantMessageHeader isUserMessage={false} />
                    {content}
                  </div>
                );
              })
            : null}
        </div>,
        <div key={2} className="relative items-center bg-white py-4 px-4">
          {isInitialized && (
            <div className="left-2 flex space-x-1">
              {options.map((item) => (
                <Button
                  onClick={() => onMessageAdd(item)}
                  disabled={disabled || isLoading}
                  variant="outlined"
                  className="h-8 px-3 bg-white shadow-sm"
                  size="small"
                >
                  <Typography
                    variant="caption"
                    className="text-inherit font-bold"
                  >
                    {item}
                  </Typography>
                </Button>
              ))}
            </div>
          )}
          <div
            className={clsx(
              "w-full flex flex-nowrap space-x-2 border-0 mt-4",
              !isInitialized && "opacity-40"
            )}
          >
            <form
              onSubmit={handleSubmit(async ({ message }) => {
                await onMessageAdd(message);
                reset();
              })}
              className="w-full flex"
            >
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <SearchInput
                    disabled={disabled || !isInitialized || isLoading}
                    inputProps={field}
                    placeholder="Add new message"
                    buttonType="submit"
                    className="py-1 w-full"
                    customButton={
                      <EnterButton
                        className="h-4 px-5"
                        type="submit"
                        disabled={disabled || !isInitialized || isLoading}
                        loading={isLoading}
                      >
                        <Typography variant="button2" className="text-inherit">
                          Send
                        </Typography>
                      </EnterButton>
                    }
                  />
                )}
              />
            </form>
          </div>
          <div className="mt-4 flex items-center text-center justify-center w-full">
            <Typography
              variant="caption"
              className="flex items-center text-center justify-center text-gray-400"
            >
              {disclaimer}
            </Typography>
          </div>
        </div>,
      ]}
    </div>
  );
};
