import { vestResolver } from "@hookform/resolvers/vest";
import { getTypedTest, getVestValidateEmail } from "application/utils";
import { parsePhoneNumber } from "libphonenumber-js";
import sanitizeHtml from "sanitize-html";
import { create, enforce, skipWhen } from "vest";
import { FormFields } from "./SyndicateModal";

const test = getTypedTest<FormFields>();

const schema = create<(data: FormFields) => void>(
  ({ countryCode, emailAddress, emailMessage, phoneNumber }) => {
    const sanitizedMessageValue = sanitizeHtml(emailMessage);

    skipWhen(!emailAddress, () => {
      test("emailMessage", "Email Message is required", () => {
        enforce(emailMessage).isNotBlank();
      });

      test("emailMessage", "Email Message is required", () => {
        enforce(emailMessage).isNotBlank();
      });

      test("emailMessage", "Min.: 15 characters", () => {
        enforce(sanitizedMessageValue).longerThanOrEquals(15);
      });

      test(
        `emailAddress`,
        "Email is not valid",
        getVestValidateEmail(emailAddress)
      );
    });

    skipWhen(!phoneNumber, () => {
      test(
        `countryCode`,
        "The country code is required when setting a phone number",
        () => {
          enforce(countryCode?.value).isNotBlank();
        }
      );
    });

    skipWhen(!(countryCode && phoneNumber), () => {
      test(`phoneNumber`, "Phone number doesn't match required format", () => {
        const _parsePhoneNumber = parsePhoneNumber(
          `${countryCode?.value} ${phoneNumber}`
        );
        enforce(_parsePhoneNumber?.isPossible()).isTruthy();
      });
    });
  }
);

export default vestResolver(schema);
