import {
  CreateOrUpdateDealPersonPayload,
  GetDealPersonDataResponse,
} from "types/deal";
import {
  useDealId,
  useDealPaths,
  useIsMounted,
  useOrganization,
} from "ui/hooks";
import { useErrorHandler } from "ui/hooks/useErrorHandler";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { defaultsDeep, isString, isUndefined } from "lodash";
import {
  DealCreateOrEditPage,
  DealFormLayout,
  DealEditPageType,
} from "ui/pages/Form";
import {
  FormInputs,
  BasicInfoSection,
  BasicInfoSectionProps,
  defaultValues,
  OtherDealsSection,
  OtherDealsSectionProps,
} from "./DealEditPerson.form";
import resolver from "./DealEditPerson.validation";
import { useDealEditPostUpdate } from "ui/pages/Form/DealForm.hooks";
import { useNavigate } from "react-router-dom";
import { SWRResponse } from "swr";
import { AssistantAutoComplete, ImageUploadWithCropper } from "ui/components";
import { v4 as uuid } from "uuid";
import useDealAlias from "ui/hooks/Deal/useDealAlias";
import { useSnackbar } from "notistack";
import { includeKeys } from "application/utils";
import { AssistantAttachableField } from "domain/assistant";

export type UpdateDealPersonImageCallback = (
  fundId: string,
  investorId: string,
  formData: FormData
) => Promise<any>;

export type UpdateDealPersonCallback = (
  fundId: string,
  investorId: string,
  payload: CreateOrUpdateDealPersonPayload
) => Promise<any>;

export type CreateDealPersonCallback = (
  fundId: string,
  payload: CreateOrUpdateDealPersonPayload
) => Promise<string>;

export interface DealEditPersonProps {
  BasicInfoSectionProps: Omit<
    BasicInfoSectionProps,
    "isOrganization" | "inputNames"
  >;
  OtherDealsSectionProps: Omit<OtherDealsSectionProps, "name">;
  imageInputTitle: string;
  title: string;
  fieldId: AssistantAttachableField;
  stepName: "leadInvestor" | "sponsor" | "advisor";
  fetchPersonDataHook: () => SWRResponse<GetDealPersonDataResponse> & {
    loading: boolean;
    hasAccess?: boolean;
  };
  updateDealPersonImageCallback: UpdateDealPersonImageCallback;
  updateDealPersonCallback: UpdateDealPersonCallback;
  createDealPersonCallback: CreateDealPersonCallback;
}

interface IAssistantValue {
  items: Array<{ name: string; bio: string }>;
}

export const DealEditPerson: FC<DealCreateOrEditPage & DealEditPersonProps> = ({
  routeAfterSubmit,
  type,
  BasicInfoSectionProps,
  OtherDealsSectionProps,
  imageInputTitle,
  title,
  stepName,
  fieldId,
  fetchPersonDataHook,
  updateDealPersonImageCallback,
  updateDealPersonCallback,
  createDealPersonCallback,
}) => {
  const dealId = useDealId();
  const dealAlias = useDealAlias();
  const navigate = useNavigate();
  const { dealPath } = useDealPaths();
  const isMounted = useIsMounted();
  const [isLoading, setLoading] = useState(true);
  const { handleError } = useErrorHandler();
  const { goToTheNextRouteIfExists, updateCreateProgress } =
    useDealEditPostUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>();
  const {
    data: personData,
    loading,
    error: fetchError,
  } = fetchPersonDataHook();
  React.useEffect(() => {
    setError(fetchError);
  }, [fetchError]);
  const { reset, control, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues,
    resolver,
  });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "otherDeals",
  });
  const imageUrl = watch("imageUrl");
  const nameValue = watch("name");
  const organizationId = (nameValue as Option)?.value;
  const { data: organization } = useOrganization(organizationId);
  const isOrganization = useMemo(
    () => Boolean(organizationId),
    [organizationId]
  );

  useEffect(() => {
    if (!organization) return;
    setValue("url", organization?.website);
    setValue("imageUrl", organization?.imageUrl);
    setValue("description", organization.longDescription);
  }, [organization, setValue]);

  useEffect(() => {
    if (isUndefined(personData) || formState.isDirty) return;

    reset(
      defaultsDeep(
        {
          ...personData,
          name: personData?.organizationId
            ? { label: personData.name, value: personData.organizationId }
            : personData?.name,
          otherDeals: personData?.otherDeals?.map((id) => ({ id })),
        },
        defaultValues
      )
    );

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData]);

  const updateImageIfExists = useCallback(
    async (newInvestorId: string, imageFile?: Blob | null) => {
      if (!imageFile) return;

      const formData = new FormData();
      formData.append("file", imageFile, `${uuid()}.png`);

      try {
        await updateDealPersonImageCallback(dealId, newInvestorId, formData);
      } catch (e) {
        throw new Error("It was not possible to update Lead image.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealId]
  );

  const createOrUpdatePerson = useCallback(
    async (payload: CreateOrUpdateDealPersonPayload) => {
      if (personData?.id) {
        return updateDealPersonCallback(dealId, personData?.id, payload);
      }

      return createDealPersonCallback(dealId, payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealId, personData]
  );

  const onSubmit: SubmitHandler<FormInputs> = async ({
    imageFile,
    ...data
  }) => {
    setSubmitting(true);

    const isNameString = isString(data.name);

    const payload = {
      description: data.description,
      name: isNameString ? data.name : (data.name as Option).label,
      organizationId: isNameString ? null : (data.name as Option).value,
      url: data.url || "",
      otherDeals: data?.otherDeals?.map(({ id }) => id).filter(Boolean) || [],
    } as CreateOrUpdateDealPersonPayload;

    try {
      const newLeadId = await createOrUpdatePerson(payload);
      await updateImageIfExists(newLeadId, imageFile);
      await updateCreateProgress({
        type,
        dealId,
        stepName,
      });
      goToTheNextRouteIfExists({ routeAfterSubmit, dealAlias });

      enqueueSnackbar("Data has been updated", {
        variant: "success",
        title: "Saved successfully.",
      });

      if (type === DealEditPageType.Edit) {
        navigate(dealPath);
      }
    } catch (error) {
      handleError(error, "It was not possible to update the data");
    } finally {
      if (isMounted()) {
        setSubmitting(false);
      }
    }
  };

  return (
    <DealFormLayout
      title={title}
      type={type}
      formState={formState}
      onReset={() => reset(defaultValues)}
      isLoading={isLoading || loading}
      onSkip={() => goToTheNextRouteIfExists({ routeAfterSubmit, dealAlias })}
      isSubmitting={isSubmitting}
      error={error}
      onSubmit={handleSubmit(onSubmit)}
      uploadSection={
        <ImageUploadWithCropper
          caption="We recommend an image of at least 400x400."
          title={imageInputTitle}
          disabled={isOrganization}
          valueSetter={(val: Blob | null) => {
            if (setValue) {
              setValue("imageFile", val);
              if (!val) {
                setValue("imageUrl", "");
              }
            }
          }}
          imgUrlSrc={imageUrl}
        />
      }
    >
      <BasicInfoSection
        {...BasicInfoSectionProps}
        titleAction={
          <AssistantAutoComplete
            fieldId={fieldId}
            dealId={dealId}
            onData={(value: IAssistantValue) => {
              const item = value?.items?.[0] || {};
              if (!item) return;
              reset({
                description: item.bio,
                name: item.name,
                otherDeals: [],
                url: "",
              });
            }}
            validate={(value) =>
              Boolean(
                value &&
                  value.items &&
                  Array.isArray(value?.items) &&
                  value.items.length > 0 &&
                  value.items.every((item) =>
                    includeKeys(item, ["bio", "name"])
                  )
              )
            }
          ></AssistantAutoComplete>
        }
        isOrganization={isOrganization}
        control={control}
        inputNames={{
          description: "description",
          name: "name",
          url: "url",
        }}
      />
      {!organizationId && (
        <OtherDealsSection
          {...OtherDealsSectionProps}
          control={control}
          name="otherDeals"
          fields={fields}
          onItemIncrement={() => append(defaultValues?.otherDeals[0])}
          onItemRemove={(index?: number) => remove(index)}
        />
      )}
    </DealFormLayout>
  );
};

export default DealEditPerson;
