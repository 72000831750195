import React from "react";
import { PermissionError } from "application/errors";
import { useGroupValidation } from "ui/hooks";
import { Outlet } from "react-router-dom";
import { isBoolean } from "lodash";

interface Props {
  investor?: boolean;
  admin?: boolean;
  fundManager?: boolean;
  syndicationAdmin?: boolean;
  developer?: boolean;
}

export const RestrictedByGroupOutlet = ({
  admin,
  investor,
  fundManager,
  syndicationAdmin,
  developer,
}: Props) => {
  const { loading, ...groupValidation } = useGroupValidation();

  if (loading) {
    return null;
  }

  const {
    isAdmin,
    isFundManager,
    isInvestor,
    isSyndicationAdmin,
    isDeveloper,
  } = groupValidation;

  const validations = [
    developer ? isDeveloper : null,
    admin ? isAdmin : null,
    syndicationAdmin ? isSyndicationAdmin : null,
    investor ? isInvestor : null,
    fundManager ? isFundManager : null,
  ].filter((condition) => isBoolean(condition));

  if (validations.length === 0 || validations.some(Boolean)) {
    return <Outlet />;
  }

  if (investor && !isInvestor) {
    throw new PermissionError(
      "You need to be an Investor to access the current content. Please get in touch with admin support to know more."
    );
  }

  if (fundManager && !isFundManager) {
    throw new PermissionError(
      "You need to be a Fund Manager to access the current content. Please get in touch with admin support to know more."
    );
  }

  throw new PermissionError(
    "You do not have the permissions to access the current content."
  );
};
