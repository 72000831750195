import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

interface ButtonSelectorProps {
  options: Option[];
  value: string;
  onChange: (newValue: string) => void;
}

export const ButtonSelector: FC<ButtonSelectorProps> = ({
  options,
  value,
  onChange,
}) => (
  <div className="flex justify-around items-center">
    {options.map(({ label, value: currentValue }, index) => {
      const isActive = value === currentValue;

      return (
        <button
          key={currentValue}
          tabIndex={index + 1}
          onClick={() => onChange(currentValue)}
          className={clsx(
            "h-5 cursor-pointer rounded-md px-2 w-auto border-0 transition-colors ease-linear flex items-center justify-center",
            {
              "bg-primary hover:bg-primary-600": isActive,
              "bg-gray-150 hover:bg-gray-200": !isActive,
            }
          )}
        >
          <Typography
            variant="caption2"
            className={clsx("font-sans", {
              "text-white": isActive,
              "text-gray-300": !isActive,
            })}
          >
            {label}
          </Typography>
        </button>
      );
    })}
  </div>
);
