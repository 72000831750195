import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import clsx from "clsx";
import { FC } from "react";
import styles from "./Button.styles.module.scss";

export const AIButton: FC<LoadingButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <LoadingButton {...props} className={clsx(styles.AIButton, className)}>
      {children}
    </LoadingButton>
  );
};
