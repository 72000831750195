import DealService from "services/deal.service";
import { KeyHighlightsData } from "types/deal";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import { AxiosError } from "axios";
import { isUndefined } from "lodash";
import useSWR from "swr";

export default function useDealKeyHighlights() {
  const alias = useDealAlias();
  const { dealAccess: hasAccess } = useDealPermissions();

  const { data, error, ...rest } = useSWR(
    () => hasAccess && `fund/${alias}/key-highlights`,
    async () => {
      try {
        const data = await DealService.getDealKeyHighlights(alias);

        if (data) {
          let items = [];
          const _items = data.keyHighlights.find(({ Key }) => Key === "items");
          if (_items) {
            items = JSON.parse(_items.Value);
          }

          return {
            description: data.description,
            items,
          } as KeyHighlightsData;
        }

        return null;
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          return null;
        }

        throw error;
      }
    }
  );

  const loading = isUndefined(data) && isUndefined(error);

  return { data, loading, error, hasAccess, ...rest };
}
