import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useErrorHandler } from "../useErrorHandler";

export const useDealParticipationRequestErrorHandler = () => {
  const { handleError: genericHandleError, getSummarizedError } =
    useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useCallback(
    (error: unknown) => {
      const summarizedError = getSummarizedError(error);

      if (
        summarizedError.responseAPIMessage?.includes(
          "There is already a request for participation"
        )
      ) {
        enqueueSnackbar(
          "You've already submitted the request.  We will notify you when it is processed.",
          {
            title: "Pending request",
            variant: "warning",
            persist: true,
          }
        );
        return;
      }

      genericHandleError(error, "It was not possible to request the access.");
    },
    [getSummarizedError, genericHandleError, enqueueSnackbar]
  );

  return {
    handleError,
  };
};
