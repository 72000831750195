import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISyndicationPartnerLayout } from "types/syndication.service";

export interface SyndicateState {
  isModalOpen: boolean;
  dealId?: string;
  syndicateLayout?: ISyndicationPartnerLayout;
}

const initialState: SyndicateState = {
  isModalOpen: false,
};

const syndicateSlice = createSlice({
  name: "syndicate",
  initialState,
  reducers: {
    openSyndicateModal(
      state: SyndicateState,
      { payload }: PayloadAction<{ dealId: string }>
    ) {
      state.isModalOpen = true;
      state.dealId = payload.dealId;
    },
    closeSyndicateModal(state: SyndicateState) {
      state.isModalOpen = false;
      state.dealId = initialState.dealId;
    },
    resetSyndicateState(state: SyndicateState) {
      state.isModalOpen = initialState.isModalOpen;
    },
    initSyndicateLayout(
      state: SyndicateState,
      { payload }: PayloadAction<ISyndicationPartnerLayout | undefined>
    ) {
      state.syndicateLayout = payload;
    },
  },
});

export const {
  resetSyndicateState,
  openSyndicateModal,
  closeSyndicateModal,
  initSyndicateLayout,
} = syndicateSlice.actions;

export default syndicateSlice.reducer;
