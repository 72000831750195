import React, { FC, useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import {
  useDealAlias,
  useDealGeneralData,
  useParticipationRequestsForCurrentUser,
} from "ui/hooks";
import DealDialog, { DialogProps, DialogTypes } from "./DealDialog/DealDialog";
import GetFundAccess from "./GetFundAccess/GetFundAccess";
import {
  DealStatusEnum,
  ParticipationRequestStatus,
  ParticipationTypeEnum,
} from "domain/deal";
import {
  DealCardPayload,
  DealGeneralData,
  DealPermissions,
  ParticipationPayload,
} from "types/deal";
import DealService from "services/deal.service";
import { get, isBoolean } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDealParticipationRequestErrorHandler } from "ui/hooks/Deal/useDealParticipationRequestErrorHandler";

export const DialogContext = React.createContext<{
  dialogProps: DialogProps;
  setDialogProps: React.Dispatch<React.SetStateAction<DialogProps>>;
} | null>(null);

const defaultDialogProps = {
  open: false,
  type: DialogTypes.ParticipationRequestOpen,
};

interface DealCardBodyProps {
  deal?: DealGeneralData | DealCardPayload;
  permissions?: DealPermissions;
}

const DealCardBody: FC<DealCardBodyProps> = ({ deal, permissions }) => {
  const alias = useDealAlias();
  const { mutate } = useDealGeneralData();
  const { handleError } = useDealParticipationRequestErrorHandler();
  const {
    data: participationRequests,
    loading,
    mutate: mutateParticipationRequests,
  } = useParticipationRequestsForCurrentUser(alias);
  const [isSubmittingDataRoomAccess, setSubmittingDataRoomAccess] =
    useState(false);

  const [dialogProps, setDialogProps] =
    React.useState<DialogProps>(defaultDialogProps);

  const handleDialogClose = () => {
    setDialogProps((state) => ({ ...state, open: false }));
  };

  const openParticipationModal = () => {
    setDialogProps({ open: true, type: DialogTypes.ParticipationRequestOpen });
  };

  const showDataRoomAccessRequestSent = () => {
    setDialogProps({ open: true, type: DialogTypes.DataRoomAccessRequestSent });
  };

  const showGetAccessRequestSent = useCallback(async () => {
    await mutate();
    await mutateParticipationRequests();
    setDialogProps({ open: true, type: DialogTypes.AccessRequestSent });
  }, [mutate, mutateParticipationRequests]);

  const handleAccessDataRoom = useCallback(async () => {
    setSubmittingDataRoomAccess(true);

    const payload = {
      participationType: ParticipationTypeEnum.DataRoom,
    } as ParticipationPayload;

    try {
      await DealService.sendParticipationRequest(alias, payload);
      await mutate();

      showDataRoomAccessRequestSent();
    } catch (e) {
      handleError(e);
    } finally {
      setSubmittingDataRoomAccess(false);
    }
  }, [alias, handleError, mutate]);

  const isClosed =
    get(deal, "status", DealStatusEnum.Closed) === DealStatusEnum.Closed;

  const participateButton = useMemo(() => {
    if (permissions?.isParticipant || isClosed) {
      return null;
    }

    const isAccessParticipationRequestPending = participationRequests?.some(
      (participation) =>
        participation.participationType ===
          ParticipationTypeEnum.FundParticipation &&
        [
          ParticipationRequestStatus.Pending,
          ParticipationRequestStatus.AdminReview,
        ].includes(participation.status)
    );

    if (isAccessParticipationRequestPending) {
      return (
        <Button variant="contained" color="primary" disabled>
          Waiting access approval
        </Button>
      );
    }

    return (
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={openParticipationModal}
        loading={loading}
      >
        Participate
      </LoadingButton>
    );
  }, [isClosed, loading, participationRequests, permissions?.isParticipant]);

  const dataRoomButton = useMemo(() => {
    const hasDataRoomAccess = isBoolean(permissions?.dataRoomAccess)
      ? permissions?.dataRoomAccess
      : true;

    if (hasDataRoomAccess || isClosed) {
      return null;
    }

    const isDataRoomAccessPending = participationRequests?.some(
      (participation) =>
        participation.participationType === ParticipationTypeEnum.DataRoom &&
        [
          ParticipationRequestStatus.Pending,
          ParticipationRequestStatus.AdminReview,
        ].includes(participation.status)
    );

    if (isDataRoomAccessPending) {
      return (
        <Button variant="contained" color="primary" disabled>
          Data Room Approval Pending
        </Button>
      );
    }

    return (
      <LoadingButton
        variant="outlined"
        color="primary"
        onClick={handleAccessDataRoom}
        loading={isSubmittingDataRoomAccess || loading}
      >
        Request Data Room Access
      </LoadingButton>
    );
  }, [
    handleAccessDataRoom,
    isClosed,
    isSubmittingDataRoomAccess,
    loading,
    participationRequests,
    permissions?.dataRoomAccess,
  ]);

  const accessButton = useMemo(() => {
    if (isClosed) {
      return null;
    }

    const isDataRoomAccessPending = participationRequests?.some(
      (participation) =>
        participation.participationType === ParticipationTypeEnum.FundAccess &&
        [
          ParticipationRequestStatus.Pending,
          ParticipationRequestStatus.AdminReview,
        ].includes(participation.status)
    );

    if (isDataRoomAccessPending) {
      return (
        <Button variant="contained" color="primary" disabled>
          Access Approval Pending
        </Button>
      );
    }

    if (loading) {
      return <LoadingButton loading>Loading...</LoadingButton>;
    }

    return <GetFundAccess onSubmitSuccess={showGetAccessRequestSent} />;
  }, [isClosed, loading, participationRequests, showGetAccessRequestSent]);

  if (!permissions) return null;

  return (
    <DialogContext.Provider value={{ dialogProps, setDialogProps }}>
      <div className="flex flex-col space-y-3 mx-auto max-w-[320px]">
        {permissions.fundAccess && (
          <>
            {participateButton}
            {dataRoomButton}
          </>
        )}
        {!permissions.fundAccess && !isClosed && accessButton}
        <DealDialog {...dialogProps} onClose={handleDialogClose} />
      </div>
    </DialogContext.Provider>
  );
};

export default DealCardBody;
