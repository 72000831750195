import { useDispatch } from "react-redux";
import { closeInviteModal } from "redux/Invite/reducer";
import { closeSyndicateModal } from "redux/Syndicate/reducer";
import { SyndicateModal } from "ui/components";
import { useApplicationVersion, useTypedSelector } from "ui/hooks";
import ReferralModal from "../ReferralModal";
import DisclosureModal from "./DisclosureModal";
import { PodcastAccessModal } from "./PodcastAccessModal";
import { useEffect, useState } from "react";
import { OutdatedModal } from "./OutdatedModal";

const APP_VERSION = process.env.REACT_APP_VERSION;

export const Modals = () => {
  const dispatch = useDispatch();
  const isInviteModalOpen = useTypedSelector(
    (state) => state.invite.isModalOpen
  );
  const isSyndicateModalOpen = useTypedSelector(
    (state) => state.syndicate.isModalOpen
  );
  const [isAppOutdated, setAppOutdated] = useState(false);
  const [isOutdatedModalOpen, setOutdatedModalOpen] = useState(false);
  const { data: latestApplicationVersion } = useApplicationVersion({
    isPaused: () => isAppOutdated,
    refreshInterval: 30000,
  });

  useEffect(() => {
    if (!(latestApplicationVersion && APP_VERSION)) return;

    if (latestApplicationVersion !== APP_VERSION) {
      setAppOutdated(true);
      setOutdatedModalOpen(true);
    }
  }, [latestApplicationVersion]);

  return (
    <>
      <DisclosureModal />
      <PodcastAccessModal />
      <ReferralModal
        open={isInviteModalOpen}
        onClose={() => dispatch(closeInviteModal())}
      />
      <SyndicateModal
        isLoading={false}
        isOpen={isSyndicateModalOpen}
        onClose={() => dispatch(closeSyndicateModal())}
        onSendEmail={async (x) => {
          console.log("Send email to: " + x);
        }}
        onSendSMS={async (x) => {
          console.log("Send SMS to " + x);
        }}
      />
      <OutdatedModal
        isOpen={isOutdatedModalOpen}
        onClose={() => setOutdatedModalOpen(false)}
      />
    </>
  );
};

export default Modals;
