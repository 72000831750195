import { Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getCurrencySymbol, getNameCombined } from "application/utils";
import clsx from "clsx";
import { DealStatusEnum } from "domain/deal";
// import { noop } from "lodash";
import numeral from "numeral";
import React, { FC, useMemo } from "react";
import { DealCardPayload, DealGeneralData } from "types/deal";
import DealAvailable from "./components/DealAvailable";
import { DealCardTitle } from "./components/DealCardTitle";
import DealLogo from "./components/DealLogo";
import DealSummary from "./components/DealSummary";
import "./styles.scss";
import { AutoAwesome } from "@mui/icons-material";
// import { ReactComponent as IconShareSVG } from "assets/svg/icon-share.svg";

export interface DealCardProps {
  deal?: DealGeneralData | DealCardPayload;
  size?: "medium" | "large";
  minimal?: boolean;
  className?: string;
  dealCardClassName?: string;
  onShare?: (dealId: string) => void;
  isShareable?: boolean;
  children?: React.ReactNode;
}

const DealCard: FC<DealCardProps> = ({
  dealCardClassName,
  deal,
  size = "medium",
  children,
  minimal,
  className,
  // isShareable,
  // onShare = noop,
}) => {
  const { imageUrl, shortDescription, defaultCurrency = "usd" } = deal || {};
  const isMediumSize = size === "medium";
  const isLargeSize = size === "large";

  const summaryProps = useMemo(() => {
    if (deal) {
      const { fundSize, minContribution, sector, dealType } = deal;

      return {
        fundSize,
        minContribution,
        sector,
        dealType,
      };
    }

    return null;
  }, [deal]);

  const availableProps = useMemo(() => {
    if (deal) {
      const { availablePart, availablePercent, defaultCurrency } = deal;

      return {
        amount: availablePart
          ? `${getCurrencySymbol(defaultCurrency)}${numeral(
              availablePart
            ).format("0a")}`
          : "TBD",
        percentage: availablePercent,
      };
    }

    return null;
  }, [deal]);

  const postedBy = React.useMemo(() => {
    if (deal) {
      const { fundOwner } = deal;

      return getNameCombined(fundOwner?.name, fundOwner?.familyName);
    }

    return "";
  }, [deal]);

  return (
    <Paper className={clsx("rounded-xl shadow-depth-2", className)}>
      <div
        className={clsx(
          dealCardClassName,
          "m9-deal-card relative flex flex-col h-full",
          {
            "p-5": isMediumSize,
            "p-8": isLargeSize,
          }
        )}
      >
        <div className="flex justify-between">
          <DealLogo size={size} imageUrl={imageUrl} />
          {deal?.isAssistantEnabled && isMediumSize && (
            <Tooltip title="AI Assistant Beta" placement="top">
              <div className="h-10 w-10 rounded-[4px] bg-secondary-0 text-semantic-yellow500 flex items-center justify-center cursor-pointer shrink-0">
                <AutoAwesome />
              </div>
            </Tooltip>
          )}
          {/* {isShareable && (
            <div>
              <IconButton
                className="text-gray-300 hover:text-gray-400 focus:text-gray-400"
                onClick={onShare}
              >
                <IconShareSVG />
              </IconButton>
            </div>
          )} */}
        </div>
        {deal && (
          <div
            className={clsx({
              "mt-3": isMediumSize,
              "mt-2": isLargeSize,
            })}
          >
            <DealCardTitle
              size={size}
              dealName={deal.name}
              dealOwnerName={postedBy}
            />
          </div>
        )}
        {!minimal && (
          <>
            <div
              className={clsx({
                "mt-3": isMediumSize,
                "mt-5": isLargeSize,
              })}
            >
              <Tooltip placement="bottom-start" title={<>{shortDescription}</>}>
                <Typography
                  variant={isMediumSize ? "caption2" : "body2"}
                  className={clsx("deal-card-short-description", {
                    "text-gray-300 h-[3.5em]": isMediumSize,
                    "font-normal text-gray-400": isLargeSize,
                  })}
                >
                  {shortDescription}
                </Typography>
              </Tooltip>
            </div>
            {summaryProps && (
              <div className="mt-4">
                <DealSummary
                  defaultCurrency={defaultCurrency}
                  {...summaryProps}
                />
              </div>
            )}
            {availableProps && (
              <div className="mt-5">
                <DealAvailable
                  closeDate={deal?.endDate}
                  {...availableProps}
                  status={deal?.status || DealStatusEnum.Invalid}
                  size={size}
                />
              </div>
            )}
          </>
        )}
        {children && <div className="mt-auto pt-6">{children}</div>}
      </div>
    </Paper>
  );
};

export default DealCard;
