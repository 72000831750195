import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as InfoCircleSVG } from "assets/svg/info-circle-filled.svg";

interface Props {
  children: string | React.ReactNode;
  tooltip?: string;
}

const DialogTextSection = ({ children, tooltip }: Props) => {
  return (
    <div className="flex items-center">
      <Typography variant="body2" className="text-gray-400 grow">
        {children}
      </Typography>
      {tooltip && (
        <Tooltip
          title={tooltip}
          arrow
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -11],
                  },
                },
              ],
            },
          }}
        >
          <div className="px-5">
            <InfoCircleSVG />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default DialogTextSection;
