import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { HTMLEditorField, HTMLEditorFieldHandle } from "ui/components";
import DealService from "services/deal.service";
import { useDealId, useErrorHandler, useDealQuestions } from "ui/hooks";
import { DealQuestionID } from "types/deal";

interface Props {
  questionId: DealQuestionID;
}

interface FormValues {
  text: string;
}

const defaultValues: FormValues = {
  text: "",
};

export const AnswerInput = ({
  questionId,
  ...props
}: Props & React.ComponentPropsWithoutRef<"form">) => {
  const dealId = useDealId() as string;
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const { handleError } = useErrorHandler();
  const { mutate } = useDealQuestions();
  const answerRef = React.useRef<HTMLEditorFieldHandle>(null);

  const clearAll = () => {
    reset(defaultValues);
    answerRef.current?.reset(defaultValues.text);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);

      if (data.text) {
        await DealService.putDealQuestionAnswer(dealId, questionId, data);
      }

      clearAll();
      mutate();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props}>
      <Controller
        render={(props) => (
          <HTMLEditorField
            ref={answerRef}
            {...props}
            placeholder="Answer"
            toolbarProps={{ options: ["image"] }}
            showError
          />
        )}
        name="text"
        control={control}
      />
      <div className="space-x-6">
        <Button
          disabled={loading}
          type="submit"
          color="primary"
          variant="contained"
        >
          Answer
        </Button>
        <Button startIcon={<HighlightOffIcon />} onClick={clearAll}>
          Clear
        </Button>
      </div>
    </form>
  );
};

export default AnswerInput;
