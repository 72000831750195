import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { HTMLEditorField, HTMLEditorFieldHandle } from "ui/components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DealService from "services/deal.service";
import { useDealId, useErrorHandler, useDealQuestions } from "ui/hooks";

interface FormValues {
  question: string;
  answer: string;
}

const defaultValues: FormValues = {
  question: "",
  answer: "",
};

export const PostQuestion = () => {
  const dealId = useDealId() as string;
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues,
  });
  const questionRef = React.useRef<HTMLEditorFieldHandle>(null);
  const { handleError } = useErrorHandler();
  const { mutate } = useDealQuestions();

  const clearAll = () => {
    reset(defaultValues);
    questionRef.current?.reset(defaultValues.question);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      if (data?.question) {
        await DealService.postDealQuestion(dealId, {
          anonymous: false,
          text: data.question,
        });
        mutate();
        clearAll();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="p-8 pt-5 bg-white rounded-2xl relative shadow-depth-2 border border-solid border-[#F4F5F6] space-y-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center">
        <Typography className="text-gray-400 font-semibold" variant="caption">
          Ask new questions
        </Typography>
      </div>
      <Divider />
      <div className="space-y-5">
        <Controller
          render={(props) => (
            <HTMLEditorField
              ref={questionRef}
              {...props}
              placeholder="Ask a question..."
              toolbarProps={{ options: ["image"] }}
              showError
            />
          )}
          name="question"
          control={control}
        />
      </div>
      <Divider />
      <div className="space-x-6">
        <Button
          disabled={loading}
          type="submit"
          color="primary"
          variant="contained"
        >
          Publish
        </Button>
        <Button startIcon={<HighlightOffIcon />} onClick={clearAll}>
          Clear
        </Button>
      </div>
    </form>
  );
};

export default PostQuestion;
