import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
// import { LinkTabs } from "ui/components/LinkTabs";

// interface TabItemDef {
//   label: string;
//   to: string;
// }

// const items: TabItemDef[] = [
//   { label: "Profile", to: "" },
//   { label: "Posts", to: "posts" },
//   { label: "Contacts", to: "contacts" },
//   { label: "Stats", to: "stats" },
// ];

export const UserProfileWrapper = () => {
  // const { pathname } = useLocation();
  const { id } = useParams();
  // const pathnames = useMemo(
  //   () => pathname.split("/").filter(Boolean),
  //   [pathname]
  // );

  // const currentTabValue =
  //   items.find(({ to }) => pathnames.includes(to))?.to || items[0]?.to;

  return (
    <Box sx={{ width: "100%" }}>
      {/* <LinkTabs value={currentTabValue}>
        {items.map(({ label, to }, index) => {
          return (
            <Tab
              key={index}
              value={to}
              className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
              component={Link}
              to={"./" + to}
              label={label}
            />
          );
        })}
      </LinkTabs> */}
      <Outlet context={{ id }} />
    </Box>
  );
};
