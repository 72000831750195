export const landingInvest = "invest";
export const landingInvestCalculator = "invest/calculator";
export const landingISyndicate = "syndicate";
export const landingAi = "ai";
export const landingAIWaitList = "beta";
export const landingSyndicateCalculator = "syndicate/calculator";

export const signInPath = "sign-in";
export const oauthFederatedSignin = "oauth/federated-signin";

export const signUpInvite = "sign-up/invite";
export const signUpPath = "sign-up";
export const signUpPhoneConfirmation = "sign-up/phone-confirmation";
export const signUpEmailConfirmation = "sign-up/email-confirmation";
export const signUpInterests = "sign-up/interests";
export const signUpAccreditation = "sign-up/accreditation";
export const signUpPosition = "sign-up/position";
export const signUpNda = "sign-up/nda";
export const signUpFollow = "sign-up/follow";
export const signUpWelcome = "sign-up/welcome";
export const qualifyAsInvestor = "sign-up/qualify-as-investor";

export const firstSteps = "first-steps";
export const helpCenter = "help-center";
export const confirmSignUpPath = "confirm-sign-up";
export const userProfile = "profile/:id";
export const userProfileEdit = "profile/:id/edit";
export const deals = "deals";
export const dealsAll = "deals/all";
export const dealsYour = "deals/your";
export const dealCreate = "deal/create";
export const dealCreateAI = "deal/create/ai";
export const dealCreateAIGenerating = "deal/:alias/generating/ai";
export const notificationsPath = "notifications";
export const preferencesPath = "preferences";
export const portfolioPath = "portfolio";
export const inviteContacts = "invite-contacts";
export const dealRolePermissionManagement =
  "deal/:alias/manage/role-permission";
export const privacyPolicy = "privacy-policy";
export const termsOfService = "terms-of-service";
export const disclosure = "disclosure";
export const podcasts = "podcasts";
export const dealPodcasts = "podcasts/deal";
export const podcastDetail = "podcast/:mediaId";
export const podcastCreate = "podcast/create";
export const podcastEdit = "podcast/:mediaId/edit";
export const podcastEditTranscription = "podcast/:mediaId/edit/transcription";
export const podcastEditReview = "podcast/:mediaId/edit/review";

export const campaigns = "campaign/regular";
export const campaignCreate = "campaign/create";
export const campaignEdit = "campaign/:campaignId/edit";
export const campaignEdiSummary = "campaign/:campaignId/edit/summary";

export const dealDetails = "deal/:alias";
export const dealAssistant = "deal/:alias/assistant";
export const dealAsk = "deal/:alias/ask";
export const dealRedirectById = "deal/redirect/:id";
export const dealDashboard = "deal/:alias/dashboard";
export const dealReports = "deal/:alias/reports";
export const dealReportPerformance = "deal/:alias/report/deal-performance";
export const dealReportDataRoomPerformance =
  "deal/:alias/report/data-room-performance";
export const dealQuestionsAndAnswers = "deal/:alias/questions-and-answers";
export const dealManage = "deal/:alias/manage";
export const dealEdit = "deal/:alias/edit";
export const dealRolePermissions = "deal/:alias/role-permissions";
export const dealUserPermissions = "deal/:alias/user-permissions";
export const dealDataroom = "deal/:alias/dataroom";
export const dealDataroomVdr = "deal/:alias/dataroom/vdr";
export const dealDataroomDirectories = "deal/:alias/dataroom/vdr/*";
export const dealDataroomDirectoriesFolder = "deal/:alias/dataroom/vdr/:folder";
export const dealDataroomUserPermissions =
  "deal/:alias/dataroom/user-permissions";
export const dealCampaigns = "deal/:alias/campaign/regular";
export const dealCampaignContactLists = "deal/:alias/campaign/contact-lists";
export const dealCampaignExclusionLists = "deal/:alias/exclusion-lists";

export const createDeal = "deal/create";
export const createDealLeadInvestor = "deal/:alias/create/lead-investor";
export const createDealSponsor = "deal/:alias/create/sponsor";
export const createDealAdvisor = "deal/:alias/create/advisor";
export const createDealHistoricalPerformance =
  "deal/:alias/create/historical-performance";
export const createDealKeyBiographies = "deal/:alias/create/key-biographies";
export const createDealKeyHighlights = "deal/:alias/create/key-highlights";
export const createDealSummary = "deal/:alias/create/summary";

export const editDeal = "deal/:alias/edit";
export const editDealPodcast = "deal/:alias/edit/podcast";
export const editDealPodcastTranscription =
  "deal/:alias/edit/podcast/:mediaId/transcription";
export const editDealPodcastReview = "deal/:alias/edit/podcast/:mediaId/review";
export const editDealLeadInvestor = "deal/:alias/edit/lead-investor";
export const editDealSponsor = "deal/:alias/edit/sponsor";
export const editDealAdvisor = "deal/:alias/edit/advisor";
export const editDealHistoricalPerformance =
  "deal/:alias/edit/historical-performance";
export const editDealKeyBiographies = "deal/:alias/edit/key-biographies";
export const editDealKeyHighlights = "deal/:alias/edit/key-highlights";
export const editDealSummary = "deal/:alias/edit/summary";

export const editDealCampaign = "deal/:alias/edit/campaign";
export const editDealCampaignWithCampaignId =
  "deal/:alias/edit/campaign/:campaignId";
export const editDealCampaignSummary =
  "deal/:alias/edit/campaign/:campaignId/summary";

export const manageUsersList = "manage/users";
export const manageFlags = "manage/flags";
export const manageDeveloperActions = "manage/dev-actions";
export const manageSyndicationUsers = "manage/syndication-users";
export const manageSyndicationCampaigns = "manage/syndication-campaigns";
export const manageUser = "manage/user/:id";
export const manageDeals = "manage/deals";
export const manageProjects = "manage/projects";
export const managePartners = "manage/partners";
export const manageInviteList = "manage/invites";
export const campaignContactLists = "campaign/contact-lists";
export const campaignContactList = "campaign/contact-list/:id";

export const managePartnerCreate = "manage/partner/create";
export const managePartner = "manage/partner/:partnerId";
export const managePartnerPreview = "manage/partner/:partnerId/preview";
export const managePartnerUpdate = "manage/partner/:partnerId/update";
export const managePartnerDealsUpdate =
  "manage/partner/:partnerId/update/deals";
export const managePartnerLegalUpdate =
  "manage/partner/:partnerId/update/legal";
export const managePartnerBrandingUpdate =
  "manage/partner/:partnerId/update/branding";

export const manageInviteConversions = "manage/invite/:inviteCode/conversions";
export const manageInviteCreate = "manage/invite/create";
export const manageInviteEdit = "manage/invite/edit/:inviteCode";

export const auditActivities = "audit";

export const companyWildcard = "company/:id/*";
export const companyAbout = "company/:id/about";
export const companyPeople = "company/:id/people";

export const assistantManagement = "assistant";
export const assistantTemplates = "assistant/templates";
export const assistantConversations = "assistant/conversations";
export const assistantEmailConversations = "assistant/email-conversations";
export const assistantEmailRecipients =
  "assistant/email-conversations/recipients";
export const assistantCache = "assistant/cache";
export const assistantSettings = "assistant/settings";

export const projectList = "projects";
export const projectCreate = "project/create";
export const projectDataRoomVDR = "project/:alias/dataroom/vdr";
export const projectDataRoomVDRDirectories = "project/:alias/dataroom/vdr/*";
export const projectDataroomVDRDirectoriesFolder =
  "project/:alias/dataroom/vdr/:folder";
export const projectAssistant = "project/:alias/assistant";
export const projectReports = "project/:alias/reports";
export const projectSettings = "project/:alias/settings";
export const projectDataroomPermissions = "project/:alias/dataroom/permissions";
