export const returnAndFees = [
  {
    label: "Annual management fee",
    value: "annual-management-fee",
  },
  {
    label: "Target annual net return",
    value: "target-annual-net-return",
  },
  {
    label: "Share of excess profits",
    value: "share-of-excess-profits",
  },
  {
    label: "Incentive fee",
    value: "incentive-fee",
  },
];

export const schedule = [
  {
    label: "Payment schedule",
    value: "payment-schedule",
  },
  {
    label: "Term type",
    value: "term-type",
  },
  {
    label: "Term",
    value: "term",
  },
  {
    label: "Target liquidity",
    value: "target-liquidity",
  },
];

export const structure = [
  {
    label: "Tax document",
    value: "tax-document",
  },
  {
    label: "Offering structure",
    value: "Offering-structure",
  },
  {
    label: "Annual Flat expense",
    value: "annual-flat-expense",
  },
];

export const LABEL_TO_VALUE_MAP: Record<string, string> = [
  ...structure,
  ...returnAndFees,
  ...schedule,
].reduce(
  (out, item) => ({
    ...out,
    [item.value]: item.label,
  }),
  {}
);
