import DealService from "services/deal.service";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import { isUndefined } from "lodash";
import useSWR from "swr";

export function useDealAdvisor() {
  const alias = useDealAlias();
  const { dealAccess: hasAccess } = useDealPermissions();

  const { data, error, ...rest } = useSWR(
    () => hasAccess && `fund/${alias}/advisor`,
    async () => {
      const [data] = await DealService.getAdvisor(alias);

      return data || null;
    }
  );

  const loading = isUndefined(data) && isUndefined(error);

  return { data, loading, error, hasAccess, ...rest };
}

export default useDealAdvisor;
