import * as React from "react";
import type { SVGProps } from "react";
const SvgIcg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={47}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M0 .386v4.465h3.862L0 8.691v10.982h4.49V.386zm38.079 11.995h4.13v3.482c-.538.149-1.346.208-2.065.208-4.28 0-6.855-2.292-6.855-6.13 0-3.632 2.395-6.012 6.047-6.012 2.156 0 3.563.714 4.64 1.726L47 2.619C45.113.804 42.51 0 39.366 0c-6.287 0-10.628 4.107-10.628 10.03 0 6.012 4.401 9.97 11.197 9.97.958 0 2.364-.12 3.263-.298l3.293-3.274v-7.56h-4.88zm-18.92-8.363c1.947 0 3.263.595 4.43 1.725l3.084-3.065C24.667.833 22.153 0 19.13 0 12.962 0 8.65 4.136 8.65 10.03c0 5.863 4.311 9.97 10.538 9.97 2.815 0 5.15-.804 6.198-1.517v-4.316c-1.438 1.041-3.623 1.815-5.899 1.815-3.801 0-6.286-2.262-6.286-5.952 0-3.631 2.335-6.012 5.957-6.012"
    />
  </svg>
);
export default SvgIcg;
