import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import mediaService from "services/media.service";
import { IMediaItem } from "../../types/media.service";

export interface AudioPlayerState {
  currentMediaItem: IMediaItem | null;
}

const initialState: AudioPlayerState = {
  currentMediaItem: null,
};

export const fetchMediaForPlayer = createAsyncThunk(
  "MediaPlayer/fetchMediaForPlayer",
  async (audioId: string) => {
    //  TODO Remove after BE implement /Media get items with video type
    return audioId === "1"
      ? {
          id: "1",
          title: "Introducing GP-led secondary market and Emerge9 team",
          description:
            "Meet the Emerge9 team. We're focused on bringing liquidity to the private assets market by joining institutional-level transactions and a broader base of investors. On this episode we will give an introduction to the Emerge9 platform and explore the growing opportunities in the GP-led secondary market, via continuation vehicles.\n",
          duration: "00:38:06",
          status: "published",
          thumbnailImageUrl:
            "https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_episode400/29816825/29816825-1661380685767-bc6301c876746.jpg",
          createdAt: "2022-08-30T13:42:09.0000000-03:00",

          type: "video",
          hasPublicInfo: true,
          hasPrivateInfo: true,
          hasPrivateContentAccess: true,
          mediaUrl: "https://www.youtube.com/watch?v=kSoO2KjVVG4",
        }
      : await mediaService.getMediaItem(audioId);
  }
);

const MediaPlayerSlice = createSlice({
  name: "AudioPlayer",
  initialState,
  reducers: {
    setCurrentMediaItem: (
      state: AudioPlayerState,
      { payload }: PayloadAction<IMediaItem | null>
    ) => {
      state.currentMediaItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaForPlayer.fulfilled, (state, { payload }) => {
      // @ts-ignore-next-line
      state.currentMediaItem = payload;
    });
  },
});

export const { setCurrentMediaItem } = MediaPlayerSlice.actions;
export default MediaPlayerSlice.reducer;
