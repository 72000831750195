import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { ReactComponent as AddCircleIconSVG } from "assets/svg/addcircle.svg";
import { ReactComponent as AddCircleGrayIconSVG } from "assets/svg/addcircle-gray.svg";
import { DataRoomPermissionRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";
import { Close } from "@mui/icons-material";
import { useRemoveRoleMutation } from "redux/Api/DataroomPermissionsApi";
import { Loading } from "ui/components";
import CreateNewRole from "../CreateNewRole/CreateNewRole";
import { clsxm } from "application/utils";
import { useErrorHandler } from "ui/hooks";
import { ResourceType } from "types/resource.service";

const ITEM_HEIGHT = 48;

interface AddRoleProps {
  roles?: DataRoomPermissionRoles;
  className?: string;
  resourceId: string;
  type: ResourceType;
  disabled?: boolean;
  roleNames?: string[];
  addRoleHandler?: (roleId: string) => () => void;
  isLoadingAdd?: boolean;
  unassignable?: string[];
  disableRemove?: boolean;
}

const AddRole = ({
  roles,
  className,
  resourceId,
  type,
  disabled = false,
  roleNames,
  addRoleHandler,
  isLoadingAdd,
  unassignable = [],
  disableRemove = false,
}: AddRoleProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [removeRoleMutation, { isLoading: isLoadingRemoveRole }] =
    useRemoveRoleMutation();
  const { handleError } = useErrorHandler();
  const { customColors } = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeRoleHandler = async (roleId: string) => {
    try {
      if (resourceId) {
        await removeRoleMutation({
          resourceId,
          type,
          roleId,
        }).unwrap();
      }
    } catch (error) {
      handleError(error, "Failed to remove role");
    }
  };

  const onRemoveRole =
    (roleId: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      removeRoleHandler(roleId);
    };

  const onAdd = (roleId: string) => () => {
    handleClose();
    addRoleHandler?.(roleId)();
  };

  if (isLoadingAdd) return <Loading size={20} className="my-0" />;

  return (
    <div className={clsxm(className)}>
      <IconButton onClick={handleClick} className="p-0" disabled={disabled}>
        {disabled ? <AddCircleGrayIconSVG /> : <AddCircleIconSVG />}
      </IconButton>
      <Menu
        MenuListProps={{
          sx: { paddingTop: 0 },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
            borderRadius: "8px",
            color: customColors.gray[500],
          },
        }}
      >
        <div className="sticky top-0 z-10 bg-white pt-2">
          <CreateNewRole
            roleNames={roleNames}
            resourceId={resourceId}
            type={type}
          />
          {roles && roles?.length > 0 && (
            <div className="pb-2">
              <hr style={{ border: `1px solid ${customColors.gray[200]}` }} />
              <Typography
                className="text-gray-300 text-xs px-4"
                variant="hairline2"
              >
                Existing roles
              </Typography>
            </div>
          )}
        </div>
        {roles?.map(({ roleId, roleName, isRemovable }) => {
          const isUnassignable = unassignable.includes(roleId);
          return (
            <MenuItem
              onClick={isUnassignable ? undefined : onAdd?.(roleId)}
              key={roleId}
              sx={{
                "& .close-btn": {
                  display: "none",
                },
                "&:hover .close-btn": {
                  display: "block",
                },
              }}
            >
              <Box
                className={clsxm(
                  "h-5 w-full flex items-center justify-between",
                  isUnassignable && "text-gray-300"
                )}
              >
                {roleName}
                {isRemovable && !isUnassignable && !disableRemove && (
                  <div
                    onClick={onRemoveRole(roleId)}
                    className="close-btn mt-1"
                  >
                    {isLoadingRemoveRole ? (
                      <Loading size={16} />
                    ) : (
                      <Close
                        sx={{ fontSize: 16 }}
                        className="text-gray-300 hover:text-gray-500"
                      />
                    )}
                  </div>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default AddRole;
