import { lazy, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { fetchUserProfile } from "redux/User/actions";
import authService from "services/auth.service";
import { AuthorizedOutlet } from "ui/Router/AuthorizedOutlet";
import { NotLoggedOutlet } from "ui/Router/NotLoggedOutlet";
import RememberMeCheck from "ui/Router/RememberMeCheck/RememberMeCheck";
import * as paths from "ui/Router/paths";
import ScrollToTop from "ui/components/ScrollToTop/ScrollToTop";
import { useNotifier, useSyndicationLayout } from "ui/hooks";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import {
  CommonLayout,
  DealManageNavs,
  DealPageLayout,
  DealQuestionsAndAnswers,
  DealRoot,
  DealsWrapper,
  NotFound,
} from "ui/pages";
import AdminManageWrapper from "ui/pages/AdminManage/AdminManage.wrapper";
import AssistantManagementWrapper from "ui/pages/AssistantManagement/AssistantManagement.wrapper";
import { CampaignsWrapper } from "ui/pages/Campaigns/CampaignsWrapper";
import { DealDetailsWrapper } from "ui/pages/DealDetailsWrapper";
import { DealsType } from "ui/pages/DealsList/constants";
import { PodcastListType } from "ui/pages/Podcasts/Podcasts";
import RegistrationWrapper from "ui/pages/Registration/RegistrationWrapper";
import { SyndicationManagementPartnerCreateWrapper } from "ui/pages/SyndicationManagement";
import { UserProfileWrapper } from "ui/pages/UserProfile/UserProfileWrapper/UserProfileWrapper";
import { CampaignsDealBreadcrumbsLayout } from "../pages/Campaigns/CampaignsDealBreadcrumbsLayout";
import { CompanyPageWrapper } from "../pages/CompanyPage";
import { CompanyPageLayout } from "../pages/CompanyPage/CompanyPageLayout";
import DealCreateCampaign from "../pages/DealCreateCampaign/DealCreateCampaign";
import { DealManagePageLayout } from "../pages/DealPage/DealManagePageLayout";
import { DealEditPageType } from "../pages/Form";
import { RestrictedByGroupOutlet } from "./RestrictedByGroupOutlet";
import { ProjectPageWrapper } from "ui/pages/ProjectPage/ProjectPage.wrapper";
import { RestrictedByFeatureFlagOutlet } from "./RestrictedByFeatureFlagOutlet";
import { FeatureFlagId } from "domain/featureFlag";
import { CognitoUser } from "redux/Auth/types";
import { ProjectGuard } from "./ProjectGuard";
import { config } from "config";
import { isBullpen } from "application/utils";

const FirstSteps = lazy(() => import("../pages/FirstSteps"));
const HelpCenter = lazy(() => import("../pages/HelpCenter"));

const CompanyPeople = lazy(() => import("../pages/CompanyPage/CompanyPeople"));
const CompanyAbout = lazy(() => import("../pages/CompanyPage/CompanyAbout"));

const AuditActivities = lazy(() => import("ui/pages/AuditActivities"));
const DealReports = lazy(() => import("ui/pages/DealReports"));
const AdminManageUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageUsers")
);
const AdminManageDeals = lazy(
  () => import("ui/pages/AdminManage/AdminManageDeals")
);
const AdminManageProjects = lazy(
  () => import("ui/pages/AdminManage/AdminManageProjects")
);
const AdminManageDevActions = lazy(
  () => import("ui/pages/AdminManage/AdminDevActions")
);
const SyndicationManagementInviteList = lazy(
  () => import("ui/pages/SyndicationManagement/SyndicationManagementInviteList")
);
const SyndicationManagementInviteConversions = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementInviteConversions"
    )
);
const SyndicationManagementCreateInvite = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementCreateInvite")
);
const SyndicationManagementPartnerList = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementPartnerList")
);
const SyndicationManagementPartnerDetailsCreate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate"
    )
);
const SyndicationManagementPartnerDetailsUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerDetailsCreate/SyndicationManagementPartnerDetailsCreate.update"
    )
);
const SyndicationManagementPartnerLegalUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerLegalUpdate"
    )
);
const SyndicationManagementPartnerBrandingUpdate = lazy(
  () =>
    import(
      "ui/pages/SyndicationManagement/SyndicationManagementPartnerBrandingUpdate"
    )
);

const AdminManageFlags = lazy(
  () => import("ui/pages/AdminManage/AdminManageFlags")
);
const AdminManageUser = lazy(
  () => import("ui/pages/AdminManage/AdminManageUser")
);
const AdminManageSyndicationUsers = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationUsers")
);
const AdminManageSyndicationCampaigns = lazy(
  () => import("ui/pages/AdminManage/AdminManageSyndicationCampaigns")
);
const CampaignsContactList = lazy(
  () => import("ui/pages/CampaignsContactList")
);
const CampaignsContactLists = lazy(
  () => import("ui/pages/CampaignsContactLists")
);

const DealExclusionList = lazy(() => import("ui/pages/DealExclusionList"));
const DealsList = lazy(() => import("ui/pages/DealsList/DealsList"));
const DealDashboard = lazy(() => import("ui/pages/DealDashboard"));
const DealDataPerformance = lazy(
  () => import("ui/pages/DealReports/DealReports.deal")
);
const DealDataRoomPerformance = lazy(
  () => import("ui/pages/DealReports/DealReports.dataRoom")
);
const DealCreate = lazy(() => import("ui/pages/DealCreate/DealCreate"));
const DealAssistantCreate = lazy(() => import("ui/pages/DealAssistantCreate"));
const DealAssistantGenerating = lazy(
  () => import("ui/pages/DealAssistantCreate/DealAssistantCreate.generating")
);
const DealUpdate = lazy(() => import("ui/pages/DealCreate/DealCreate.update"));
const DealEditHistoricalPerformance = lazy(
  () => import("ui/pages/DealEditHistoricalPerformance")
);
const DealEditPersonLeadInvestor = lazy(
  () => import("ui/pages/DealEditPersonLeadInvestor")
);
const DealEditPersonSponsor = lazy(
  () => import("ui/pages/DealEditPersonSponsor")
);
const DealEditPersonAdvisor = lazy(
  () => import("ui/pages/DealEditPersonAdvisor")
);
const DealEditKeyBiographies = lazy(
  () => import("ui/pages/DealEditKeyBiographies")
);
const DealEditKeyHighlights = lazy(
  () => import("ui/pages/DealEditKeyHighlights")
);
const DealRedirectById = lazy(() => import("ui/pages/DealRedirectById"));
const DealDetail = lazy(
  () => import("ui/pages/DealPage/DealDetail/DealDetail")
);
const DealEditSummary = lazy(() => import("ui/pages/DealEditSummary"));

const DealEditPodcast = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast")
);
const DealEditPodcastTranscription = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast.transcription")
);
const DealEditPodcastReview = lazy(
  () => import("ui/pages/DealEditPodcast/DealEditPodcast.review")
);

const DealCampaigns = lazy(
  () => import("ui/pages/DealCampaigns/DealCampaigns")
);
const DealEditCampaign = lazy(
  () => import("ui/pages/DealEditCampaign/DealEditCampaign")
);

const DealEditCampaignReview = lazy(
  () => import("ui/pages/DealEditCampaign/DealEditCampaign.review")
);

// const DealAsk = lazy(() => import("ui/pages/DealAsk"));
const PodcastCreate = lazy(() => import("ui/pages/PodcastCreate"));
const PodcastReview = lazy(() => import("ui/pages/PodcastReview"));
const PodcastDetail = lazy(() => import("ui/pages/PodcastDetail"));
const PodcastEdit = lazy(() => import("ui/pages/PodcastEdit"));
const PodcastEditTranscription = lazy(
  () => import("ui/pages/PodcastEditTranscription")
);

const CampaignCreate = lazy(() => import("ui/pages/CampaignCreate"));
const CampaignReview = lazy(() => import("ui/pages/CampaignReview"));
const CampaignEdit = lazy(() => import("ui/pages/CampaignEdit"));

const DealRolePermission = lazy(
  () => import("ui/pages/DealRolePermissions/DealRolePermissions")
);

const DealUserPermissions = lazy(
  () => import("ui/pages/DealUserPermissions/DealUserPermissions")
);
const Campaigns = lazy(() => import("ui/pages/Campaigns/Campaigns"));
const DealDataRoom = lazy(() => import("ui/pages/DealDataRoom"));
const DealDataRoomUserPermissions = lazy(
  () => import("ui/pages/DealDataRoomPermissions")
);

const AssistantManagementTemplates = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagement.templates")
);
const AssistantManagementConversations = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagement.conversations")
);
const AssistantManagementEmailConversations = lazy(
  () =>
    import(
      "ui/pages/AssistantManagement/AssistantManagement.email-conversations"
    )
);
const AssistantManagementEmailRecipients = lazy(
  () =>
    import("ui/pages/AssistantManagement/AssistantManagement.email-recipients")
);
const AssistantManagementSettings = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagementSettings")
);

const AssistantManagementCache = lazy(
  () => import("ui/pages/AssistantManagement/AssistantManagement.cache")
);
const DealAssistant = lazy(() => import("ui/pages/DealAssistant"));

const Preferences = lazy(() => import("ui/pages/Preferences/Preferences"));
const Portfolio = lazy(() => import("ui/pages/Portfolio/Portfolio"));

const InviteContacts = lazy(
  () => import("ui/pages/InviteContacts/InviteContacts")
);

const AsyncConfirmSignUp = lazy(
  () => import("ui/pages/ConfirmSignUp/ConfirmSignUp")
);

const AsyncLandingV2 = lazy(() => import("ui/pages/Landing/LandingV2"));
const AsyncLandingAI = lazy(() => import("ui/pages/Landing/LandingAI"));
const AsyncLandingAIWaitList = lazy(
  () => import("ui/pages/Landing/LandingAIWaitList")
);

const AsyncLandingInvest = lazy(
  () => import("ui/pages/LandingInvest/LandingInvest")
);
const AsyncLandingInvestAdvancedCalculator = lazy(
  () => import("ui/pages/LandingInvestAdvancedCalculator")
);
const AsyncLandingSyndicateAdvancedCalculator = lazy(
  () => import("ui/pages/LandingSyndicateAdvancedCalculator")
);
const AsyncLandingSyndicate = lazy(
  () => import("ui/pages/LandingSyndicate/LandingSyndicate")
);
const AsyncSignIn = lazy(() => import("ui/pages/SignIn/SignIn"));
const AsyncSyndicateLogin = lazy(() => import("ui/pages/SyndicationLogin"));
const FederatedSignIn = lazy(
  () => import("ui/pages/FederatedSignIn/FederatedSignIn")
);
const SyndicationManagementLoginPreview = lazy(
  () =>
    import("ui/pages/SyndicationManagement/SyndicationManagementLoginPreview")
);
const AsyncRegistrationInvite = lazy(
  () => import("ui/pages/Registration/RegistrationInvite")
);
const AsyncRegistrationBasicInformation = lazy(
  () => import("ui/pages/Registration/RegistrationBasicInformation")
);
const AsyncRegistrationPhoneConfirmation = lazy(
  () => import("ui/pages/Registration/RegistrationPhoneConfirmation")
);
const AsyncRegistrationEmailConfirmation = lazy(
  () => import("ui/pages/Registration/RegistrationEmailConfirmation")
);
const AsyncRegistrationPosition = lazy(
  () => import("ui/pages/Registration/RegistrationPosition")
);
const AsyncRegistrationAccreditation = lazy(
  () => import("ui/pages/Registration/RegistrationAccreditation")
);
const AsyncRegistrationInterests = lazy(
  () => import("ui/pages/Registration/RegistrationInterests")
);
const AsyncRegistrationFollow = lazy(
  () => import("ui/pages/Registration/RegistrationFollow")
);
const AsyncRegistrationNDA = lazy(
  () => import("ui/pages/Registration/RegistrationNDA")
);
const AsyncRegistrationWelcome = lazy(
  () => import("ui/pages/Registration/RegistrationWelcome")
);

const AsyncUserNotifications = lazy(
  () => import("ui/pages/UserNotifications/UserNotifications")
);
const AsyncUserProfile = lazy(() => import("ui/pages/UserProfile/UserProfile"));
const AsyncUserProfileEdit = lazy(
  () => import("ui/pages/UserProfileEdit/UserProfileEdit")
);

const AsyncPrivacyPolicy = lazy(
  () => import("ui/pages/PrivacyPolicy/PrivacyPolicy")
);
const AsyncTermsAndService = lazy(
  () => import("ui/pages/TermsAndService/TermsAndService")
);
const AsyncPodcasts = lazy(() => import("ui/pages/Podcasts/Podcasts"));

const ProjectList = lazy(() => import("ui/pages/ProjectList"));
const ProjectCreate = lazy(() => import("ui/pages/ProjectCreate"));
const ProjectDataRoom = lazy(
  () => import("ui/pages/ProjectPage/ProjectDataRoom")
);
const ProjectAssistant = lazy(
  () => import("ui/pages/ProjectPage/ProjectAssistant")
);
const ProjectDataRoomPermissions = lazy(
  () => import("ui/pages/ProjectDataRoomPermissions")
);
const ProjectSettings = lazy(
  () => import("ui/pages/ProjectPage/ProjectSettings")
);
const ProjectReports = lazy(
  () => import("ui/pages/ProjectPage/ProjectReports")
);

const BullpenHomePage = lazy(() => import("ui/pages/Bullpen"));

interface AppRouterProps {
  hasLayoutError: boolean;
}

const AppRouter = ({ hasLayoutError }: AppRouterProps) => {
  const dispatch = useDispatch();
  useNotifier();
  const { user } = useTypedSelector((state) => state.auth);
  const [isInitialized, setInitialized] = useState(false);
  const layout = useSyndicationLayout();
  const { isSyndicate } = layout;

  const initializeAuthToken = useCallback((currentUser: CognitoUser | null) => {
    if (!currentUser) return authService.updateApiToken(undefined);

    return authService.updateApiToken(
      currentUser?.signInUserSession?.idToken?.jwtToken
    );
  }, []);

  const initialize = useCallback(async () => {
    if (isInitialized) return;

    initializeAuthToken(user);

    if (user) dispatch(fetchUserProfile());

    setInitialized(true);
  }, [dispatch, initializeAuthToken, isInitialized, user]);

  useEffect(() => {
    if (isInitialized) return;

    initialize();
  }, [initialize, isInitialized]);

  if (!isInitialized) {
    return null;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Helmet>
        <link
          rel="icon"
          href={
            layout.isSyndicate
              ? layout.mainInfo?.logo?.favicon ?? "/favicon.ico"
              : "/favicon.ico"
          }
        />
      </Helmet>
      <Routes>
        <Route element={<RememberMeCheck />}>
          <Route
            path="/"
            element={<CommonLayout hasLayoutError={hasLayoutError} />}
          >
            <Route
              path={paths.oauthFederatedSignin}
              element={<FederatedSignIn />}
            />
            <Route
              index
              element={
                isSyndicate ? (
                  <AsyncSyndicateLogin />
                ) : isBullpen() ? (
                  <BullpenHomePage />
                ) : (
                  <AsyncLandingV2 />
                )
              }
            />
            {!isSyndicate && (
              <Route
                element={
                  <RestrictedByFeatureFlagOutlet
                    featureFlag={FeatureFlagId.Deals}
                  />
                }
              >
                <Route path={paths.landingInvest}>
                  <Route index element={<AsyncLandingInvest />} />
                  <Route
                    path="calculator"
                    element={<AsyncLandingInvestAdvancedCalculator />}
                  />
                </Route>
                <Route path={paths.landingISyndicate}>
                  <Route index element={<AsyncLandingSyndicate />} />
                  <Route
                    path="calculator"
                    element={<AsyncLandingSyndicateAdvancedCalculator />}
                  />
                </Route>
                <Route path={paths.landingAi} element={<AsyncLandingAI />} />
                <Route
                  path={paths.landingAIWaitList}
                  element={<AsyncLandingAIWaitList />}
                />
                <Route
                  path={paths.podcasts}
                  element={<AsyncPodcasts type={PodcastListType.Emergent} />}
                />
                <Route
                  path={paths.dealPodcasts}
                  element={<AsyncPodcasts type={PodcastListType.Deals} />}
                />
              </Route>
            )}
            <Route
              path={paths.signInPath}
              element={isSyndicate ? <AsyncSyndicateLogin /> : <AsyncSignIn />}
            />
            <Route path={paths.helpCenter} element={<HelpCenter />} />
            <Route path={paths.signUpPath} element={<RegistrationWrapper />}>
              <Route element={<NotLoggedOutlet />}>
                <Route index element={<AsyncRegistrationBasicInformation />} />
                <Route path="invite" element={<AsyncRegistrationInvite />} />
              </Route>
              <Route
                path="phone-confirmation"
                element={<AsyncRegistrationPhoneConfirmation />}
              />
              <Route
                path="email-confirmation"
                element={<AsyncRegistrationEmailConfirmation />}
              />
              <Route element={<AuthorizedOutlet dismissRedirect />}>
                <Route
                  path="position"
                  element={<AsyncRegistrationPosition />}
                />
                <Route
                  path="accreditation"
                  element={<AsyncRegistrationAccreditation />}
                />
                <Route
                  path="interests"
                  element={<AsyncRegistrationInterests />}
                />
                <Route path="follow" element={<AsyncRegistrationFollow />} />
                <Route path="nda" element={<AsyncRegistrationNDA />} />
                <Route path="welcome" element={<AsyncRegistrationWelcome />} />
              </Route>
            </Route>
            <Route
              path={paths.confirmSignUpPath}
              element={<AsyncConfirmSignUp />}
            />
            <Route
              path={paths.privacyPolicy}
              element={<AsyncPrivacyPolicy />}
            />
            <Route
              path={paths.termsOfService}
              element={<AsyncTermsAndService />}
            />

            <Route path="/" element={<AuthorizedOutlet />}>
              <Route path={paths.firstSteps} element={<FirstSteps />} />
              <Route element={<RestrictedByGroupOutlet developer />}>
                <Route
                  path={paths.assistantManagement}
                  element={<AssistantManagementWrapper />}
                >
                  <Route
                    path="templates"
                    element={<AssistantManagementTemplates />}
                  />
                  <Route
                    path="settings"
                    element={<AssistantManagementSettings />}
                  />
                  <Route
                    path="conversations"
                    element={<AssistantManagementConversations />}
                  />
                  <Route path="email-conversations">
                    <Route
                      path="recipients"
                      element={<AssistantManagementEmailRecipients />}
                    />
                    <Route
                      index
                      element={<AssistantManagementEmailConversations />}
                    />
                  </Route>
                  <Route path="cache" element={<AssistantManagementCache />} />
                </Route>
              </Route>
              <Route
                path={"manage/"}
                element={<RestrictedByGroupOutlet admin syndicationAdmin />}
              >
                <Route element={<AdminManageWrapper />}>
                  <Route path="users" element={<AdminManageUsers />} />
                  <Route
                    element={
                      <RestrictedByFeatureFlagOutlet
                        featureFlag={FeatureFlagId.Deals}
                      />
                    }
                  >
                    <Route path="deals" element={<AdminManageDeals />} />
                  </Route>
                  <Route
                    element={
                      config.env === "prod" ? (
                        <RestrictedByGroupOutlet developer />
                      ) : (
                        <RestrictedByGroupOutlet />
                      )
                    }
                  >
                    <Route
                      element={
                        <RestrictedByFeatureFlagOutlet
                          featureFlag={FeatureFlagId.Projects}
                        />
                      }
                    >
                      <Route
                        path="projects"
                        element={<AdminManageProjects />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="dev-actions"
                    element={<AdminManageDevActions />}
                  />
                  <Route path="flags" element={<AdminManageFlags />} />
                  <Route
                    path="syndication-users"
                    element={<AdminManageSyndicationUsers />}
                  />
                  <Route
                    path="syndication-campaigns"
                    element={<AdminManageSyndicationCampaigns />}
                  />
                  <Route
                    path="invites"
                    element={<SyndicationManagementInviteList />}
                  />
                  <Route
                    path="partners"
                    element={<SyndicationManagementPartnerList />}
                  />
                </Route>

                <Route path="partner">
                  <Route
                    path="create"
                    element={<SyndicationManagementPartnerCreateWrapper />}
                  >
                    <Route
                      index
                      element={<SyndicationManagementPartnerDetailsCreate />}
                    />
                  </Route>

                  <Route path=":partnerId">
                    <Route
                      path="preview"
                      element={<SyndicationManagementLoginPreview />}
                    />
                    <Route
                      path="update"
                      element={<SyndicationManagementPartnerCreateWrapper />}
                    >
                      <Route
                        index
                        element={<SyndicationManagementPartnerDetailsUpdate />}
                      />
                      <Route
                        path="legal"
                        element={<SyndicationManagementPartnerLegalUpdate />}
                      />
                      <Route
                        path="branding"
                        element={<SyndicationManagementPartnerBrandingUpdate />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="invite/:inviteCode">
                  <Route
                    path="conversions"
                    element={<SyndicationManagementInviteConversions />}
                  />
                </Route>

                <Route path="invite">
                  <Route
                    path="create"
                    element={<SyndicationManagementCreateInvite />}
                  />
                  <Route
                    path="edit/:inviteCode"
                    element={<SyndicationManagementCreateInvite />}
                  />
                </Route>

                <Route path="user/:id" element={<AdminManageUser />} />
              </Route>
              <Route path="podcast">
                <Route element={<RestrictedByGroupOutlet admin />}>
                  <Route path="create" element={<PodcastCreate />} />
                </Route>
                <Route path=":mediaId">
                  <Route index element={<PodcastDetail />} />
                  <Route element={<RestrictedByGroupOutlet admin />}>
                    <Route path="edit">
                      <Route index element={<PodcastEdit />} />
                      <Route
                        path="transcription"
                        element={<PodcastEditTranscription />}
                      />
                      <Route path="review" element={<PodcastReview />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="campaign">
                <Route
                  element={<RestrictedByGroupOutlet admin syndicationAdmin />}
                >
                  <Route element={<CampaignsWrapper />}>
                    <Route path="regular" element={<Campaigns />} />
                    <Route
                      path="contact-lists"
                      element={<CampaignsContactLists />}
                    />
                  </Route>
                  <Route path="create" element={<CampaignCreate />} />
                  <Route path="contact-list">
                    <Route path=":id" element={<CampaignsContactList />} />
                  </Route>
                </Route>
                <Route path=":campaignId">
                  <Route
                    element={<RestrictedByGroupOutlet admin syndicationAdmin />}
                  >
                    <Route path="edit">
                      <Route index element={<CampaignEdit />} />
                      <Route path="summary" element={<CampaignReview />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route
                path={paths.auditActivities}
                element={<RestrictedByGroupOutlet admin />}
              >
                <Route index element={<AuditActivities />} />
              </Route>
              <Route
                element={
                  <RestrictedByFeatureFlagOutlet
                    featureFlag={FeatureFlagId.Deals}
                  />
                }
              >
                <Route
                  path="deal/redirect/:id"
                  element={<DealRedirectById />}
                />
                <Route path="deal/:alias" element={<DealDetailsWrapper />}>
                  <Route element={<DealPageLayout />}>
                    <Route index element={<DealDetail />} />

                    <Route path="manage" element={<DealManageNavs />} />

                    <Route
                      path="questions-and-answers"
                      element={<DealQuestionsAndAnswers />}
                    />
                    <Route path="reports" element={<DealReports />} />
                  </Route>
                  <Route path="report/" element={<DealManagePageLayout />}>
                    <Route
                      path="data-room-performance"
                      element={<DealDataRoomPerformance />}
                    />
                    <Route
                      path="deal-performance"
                      element={<DealDataPerformance />}
                    />
                  </Route>

                  <Route element={<DealManagePageLayout />}>
                    <Route path="dashboard" element={<DealDashboard />} />
                  </Route>

                  <Route path="assistant" element={<DealAssistant />} />

                  <Route path="dataroom/vdr" element={<DealDataRoom />}>
                    <Route path="*" element={<DealDataRoom />} />
                  </Route>
                </Route>

                <Route
                  path={paths.dealDataroomUserPermissions}
                  element={<DealDataRoomUserPermissions />}
                />
              </Route>

              <Route path="company/:id" element={<CompanyPageLayout />}>
                <Route element={<CompanyPageWrapper />}>
                  <Route path="people" element={<CompanyPeople />} />
                  <Route path="about" element={<CompanyAbout />} />
                </Route>
              </Route>

              <Route
                path={paths.notificationsPath}
                element={<AsyncUserNotifications />}
              />
              <Route
                path={`${paths.preferencesPath}/*`}
                element={<Preferences />}
              />
              <Route path={paths.userProfile} element={<UserProfileWrapper />}>
                <Route index element={<AsyncUserProfile />} />
                <Route path="posts" element={<NotFound />} />
                <Route path="contacts" element={<NotFound />} />
                <Route path="stats" element={<NotFound />} />
                <Route path="edit" element={<AsyncUserProfileEdit />} />
              </Route>
              <Route path={paths.inviteContacts} element={<InviteContacts />} />
              <Route
                element={
                  <RestrictedByFeatureFlagOutlet
                    featureFlag={FeatureFlagId.Deals}
                  />
                }
              >
                <Route path={paths.deals} element={<DealsWrapper />}>
                  <Route
                    path="all"
                    element={<DealsList dealsType={DealsType.ALL} />}
                  />
                  <Route
                    path="your"
                    element={<DealsList dealsType={DealsType.MY} />}
                  />
                </Route>
              </Route>

              <Route
                element={
                  config.env === "prod" ? (
                    <RestrictedByGroupOutlet developer />
                  ) : (
                    <RestrictedByGroupOutlet />
                  )
                }
              >
                <Route
                  element={
                    <RestrictedByFeatureFlagOutlet
                      featureFlag={FeatureFlagId.Projects}
                    />
                  }
                >
                  <Route path={paths.projectList} element={<ProjectList />} />
                  <Route
                    path={paths.projectCreate}
                    element={<ProjectCreate />}
                  />

                  <Route path="project/:alias" element={<ProjectPageWrapper />}>
                    <Route path="dataroom/vdr">
                      <Route index element={<ProjectDataRoom />} />
                      <Route path="*" element={<ProjectDataRoom />} />
                    </Route>
                    <Route path="assistant" element={<ProjectAssistant />} />
                    <Route path="reports" element={<ProjectReports />} />

                    <Route element={<ProjectGuard permissions={["W"]} />}>
                      <Route path="settings" element={<ProjectSettings />} />
                    </Route>
                  </Route>
                  <Route element={<ProjectGuard permissions={["W"]} />}>
                    <Route
                      path={paths.projectDataroomPermissions}
                      element={<ProjectDataRoomPermissions />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                element={
                  <RestrictedByFeatureFlagOutlet
                    featureFlag={FeatureFlagId.Deals}
                  />
                }
              >
                <Route path={paths.portfolioPath} element={<Portfolio />} />
                <Route path="deal" element={<DealRoot />}>
                  <Route
                    path="create"
                    element={
                      <RestrictedByGroupOutlet fundManager syndicationAdmin />
                    }
                  >
                    <Route
                      index
                      element={
                        <DealCreate
                          routeAfterSubmit={"/" + paths.createDealLeadInvestor}
                        />
                      }
                    />
                    <Route path="ai" element={<DealAssistantCreate />} />
                  </Route>
                  <Route path=":alias">
                    {/* <Route path="ask/" element={<DealAsk />} /> */}
                    <Route
                      path="create"
                      element={
                        <RestrictedByGroupOutlet fundManager syndicationAdmin />
                      }
                    >
                      <Route
                        path="lead-investor"
                        element={
                          <DealEditPersonLeadInvestor
                            routeAfterSubmit={"/" + paths.createDealSponsor}
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="sponsor"
                        element={
                          <DealEditPersonSponsor
                            routeAfterSubmit={"/" + paths.createDealAdvisor}
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="advisor"
                        element={
                          <DealEditPersonAdvisor
                            routeAfterSubmit={
                              "/" + paths.createDealHistoricalPerformance
                            }
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="historical-performance"
                        element={
                          <DealEditHistoricalPerformance
                            routeAfterSubmit={
                              "/" + paths.createDealKeyBiographies
                            }
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="key-biographies"
                        element={
                          <DealEditKeyBiographies
                            routeAfterSubmit={
                              "/" + paths.createDealKeyHighlights
                            }
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="key-highlights"
                        element={
                          <DealEditKeyHighlights
                            routeAfterSubmit={"/" + paths.createDealSummary}
                            type={DealEditPageType.Create}
                          />
                        }
                      />
                      <Route
                        path="summary"
                        element={
                          <DealEditSummary
                            type={DealEditPageType.Create}
                            routeAfterSubmit={"/" + paths.dealDetails}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="generating/ai"
                      element={<DealAssistantGenerating />}
                    />
                    <Route path="edit/">
                      <Route index element={<DealUpdate />} />
                      <Route
                        path="lead-investor"
                        element={
                          <DealEditPersonLeadInvestor
                            type={DealEditPageType.Edit}
                          />
                        }
                      />
                      <Route
                        path="sponsor"
                        element={
                          <DealEditPersonSponsor type={DealEditPageType.Edit} />
                        }
                      />
                      <Route
                        path="advisor"
                        element={
                          <DealEditPersonAdvisor type={DealEditPageType.Edit} />
                        }
                      />
                      <Route
                        path="historical-performance"
                        element={
                          <DealEditHistoricalPerformance
                            type={DealEditPageType.Edit}
                          />
                        }
                      />
                      <Route
                        path="key-biographies"
                        element={
                          <DealEditKeyBiographies
                            type={DealEditPageType.Edit}
                          />
                        }
                      />
                      <Route
                        path="key-highlights"
                        element={
                          <DealEditKeyHighlights type={DealEditPageType.Edit} />
                        }
                      />
                      <Route
                        path="summary"
                        element={
                          <DealEditSummary type={DealEditPageType.Edit} />
                        }
                      />
                      <Route path="podcast">
                        <Route index element={<DealEditPodcast />} />
                        <Route path=":mediaId">
                          <Route
                            path="transcription"
                            element={<DealEditPodcastTranscription />}
                          />
                          <Route
                            path="review"
                            element={<DealEditPodcastReview />}
                          />
                        </Route>
                      </Route>
                      <Route path="campaign">
                        <Route element={<CampaignsDealBreadcrumbsLayout />}>
                          <Route index element={<DealCreateCampaign />} />
                          <Route path=":campaignId">
                            <Route index element={<DealEditCampaign />} />
                            <Route
                              path="summary"
                              element={<DealEditCampaignReview />}
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path="role-permissions"
                      element={<DealRolePermission />}
                    />
                    <Route
                      path="user-permissions"
                      element={<DealUserPermissions />}
                    />
                    <Route
                      path="exclusion-lists"
                      element={<DealExclusionList />}
                    />
                    <Route path="campaign/">
                      <Route element={<CampaignsWrapper />}>
                        <Route path="regular" element={<DealCampaigns />} />
                        <Route
                          path="contact-lists"
                          element={<CampaignsContactLists />}
                        />
                      </Route>
                      <Route path="contact-list">
                        <Route path=":id" element={<CampaignsContactList />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
