import React from "react";
import { CreateOrEditDealCampaignForm } from "../CampaignCreateForm/CampaignCreate.form";
import { useDealId } from "../../hooks";

const DealCreateCampaign = () => {
  const dealId = useDealId();
  return <CreateOrEditDealCampaignForm title="New campaign" dealId={dealId} />;
};

export default DealCreateCampaign;
