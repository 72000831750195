import { isUndefined } from "lodash";
import adminService from "services/admin.service";
import useSWR from "swr";

export const useAdminUserProfile = (userId: string) => {
  const { data, error, mutate } = useSWR(`/Admin/${userId}/manage`, () =>
    adminService.getUserProfile(userId)
  );

  const loading = [data, error].every(isUndefined);

  return { data, error, mutate, loading };
};
