import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "ui/hooks";
import {
  BasicInformationSharedData,
  RegistrationDealsOnly,
  RegistrationStepPaths,
} from "./RegistrationForm.domain";
import { RegistrationStepEnum } from "./RegistrationForm.enums";
import { usePaths } from "ui/hooks/usePaths";
import { IRegistrationSteps } from "types";

export const useRegistrationOrder = (): {
  order: RegistrationStepEnum[];
  steps: (keyof IRegistrationSteps)[];
} => {
  return {
    order: RegistrationDealsOnly,
    steps: ["position", "accreditation", "interests", "signatureDocument"],
  };
};

export const useRegistrationFormUtils = (
  currentStep: RegistrationStepEnum,
  customOrder?: RegistrationStepEnum[]
) => {
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { order } = useRegistrationOrder();
  const { mainRedirectPath } = usePaths();

  const localRegistrationFormOrderLocal = useMemo(
    () => customOrder || order,
    [customOrder, order]
  );

  const getRouteByIncrement = useCallback(
    (increment: number) => {
      const selectedStepIndex =
        localRegistrationFormOrderLocal.indexOf(currentStep) + increment;
      const selectedStep = localRegistrationFormOrderLocal[selectedStepIndex];

      return RegistrationStepPaths[selectedStep] || mainRedirectPath;
    },
    [currentStep, mainRedirectPath, localRegistrationFormOrderLocal]
  );

  const nextRoute = useMemo(
    () => getRouteByIncrement(1),
    [getRouteByIncrement]
  );

  const previousRoute = useMemo(
    () => getRouteByIncrement(-1),
    [getRouteByIncrement]
  );

  const navigateToNextRoute = useCallback(
    (state?: BasicInformationSharedData) => {
      navigate("/" + nextRoute, state ? { state } : undefined);
    },
    [navigate, nextRoute]
  );

  const navigateToPreviousRoute = useCallback(() => {
    navigate("/" + previousRoute);
  }, [navigate, previousRoute]);

  const onError = (error: unknown) => {
    handleError(error, "It was not possible to get the registration data.");
  };

  return {
    nextRoute,
    previousRoute,
    navigateToNextRoute,
    navigateToPreviousRoute,
    onError,
  };
};
