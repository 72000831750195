import React from "react";
import { IMaskInput } from "react-imask";

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask?: string;
}

const TelNationalInput = React.forwardRef(function TelNationalInput(
  props: Props,
  ref: React.ForwardedRef<HTMLElement>
) {
  const { onChange, mask = "000 000 0000", ...other } = props;

  return (
    <IMaskInput
      {...(other as any)}
      mask={mask}
      definitions={{
        "#": /[1-9]/,
      }}
      lazy={false}
      inputRef={ref as any}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});

export default TelNationalInput;
