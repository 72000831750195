import { ICampaignContactListByPartner } from "types/campaign.service";
import { create, enforce, test } from "vest";
import { FormInputs } from "./CampaignCreate.form";

const customTest = (
  title: NestedKeyOf<FormInputs>,
  errorMessage: string,
  enforceCallback: () => void
) => test(title, errorMessage, enforceCallback);

export const getSchema = (contactLists: ICampaignContactListByPartner[]) =>
  create<(data: FormInputs) => void>(
    ({ title, subject, contactListIds, message }) => {
      customTest("title", "The field is required", () => {
        enforce(title).isNotEmpty();
      });
      customTest("subject", "The field is required", () => {
        enforce(subject).isNotEmpty();
      });

      customTest("message", "The field is required", () => {
        enforce(message).isNotEmpty();
      });

      customTest("contactListIds", "The field is required", () => {
        const itemAmount = Object.keys(contactListIds).filter(
          (key) => contactListIds[key]
        ).length;

        enforce(itemAmount).greaterThan(0);
      });

      customTest("contactListIds", "The maximum list amount is 50", () => {
        const itemAmount = Object.keys(contactListIds).filter(
          (key) => contactListIds[key]
        ).length;

        enforce(itemAmount).lessThanOrEquals(50);
      });

      customTest(
        "promotedFundIds",
        "The selected partners don't have access to one or more of the selected deals.",
        () => {
          const indexedContactList =
            contactLists?.reduce((out: Record<string, boolean>, item) => {
              for (const list of item.contactLists) {
                out[list.id] = item.hasAccessToAttachedFunds;
              }

              return out;
            }, {} as Record<string, boolean>) || {};

          const hasInvalid = Object.keys(contactListIds)
            .filter((listId) => contactListIds[listId])
            ?.some((currentListId) => !indexedContactList[currentListId]);

          enforce(hasInvalid).isFalsy();
        }
      );
    }
  );
