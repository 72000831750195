import React from "react";
import { Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import * as paths from "../../Router/paths";
import Button from "@mui/material/Button";
import { updateGtagWithData } from "../../../application/utils";

interface SnackbarCookiesProps {
  open: boolean;
  handleClose: () => any;
}

const SnackbarCookies = ({ open, handleClose }: SnackbarCookiesProps) => {
  const handleAllAcceptance = (isAccepted: boolean) => {
    const updatedConsentMode = {
      functionality_storage: isAccepted ? "granted" : "denied",
      security_storage: isAccepted ? "granted" : "denied",
      analytics_storage: isAccepted ? "granted" : "denied",
    };

    updateGtagWithData(updatedConsentMode);

    localStorage.setItem("consentMode", JSON.stringify(updatedConsentMode));
    handleClose();
  };

  return (
    <Snackbar
      open={open}
      onClose={(_, reason: "timeout" | "clickaway" | "escapeKeyDown") => {
        if (!reason) {
          handleClose();
        } else {
          return;
        }
      }}
      sx={{
        maxWidth: "620px",
        "& .MuiSnackbarContent-root": {
          backgroundColor: "#fff",
          color: "#03140C",
        },
      }}
      message={
        <div className="p-3">
          <Typography variant="body1" className="font-bold">
            We respect your privacy.
          </Typography>
          <p>
            This website uses cookies for analytics to help us better understand
            how you use our website and to enhance your user experience.
          </p>

          <p>
            By clicking "Accept", you consent to the use of ALL the cookies. If
            you choose to "Decline", only essential cookies necessary for the
            proper functioning of the site will be used.
          </p>

          <p>
            Remember, you can change your cookie preferences at any time by
            accessing our "Cookie Settings" within our{" "}
            <Link
              to={{
                pathname: "/" + paths.privacyPolicy,
              }}
            >
              Privacy Policy
            </Link>
            .
          </p>
          <div className="flex justify-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className="mr-2"
              onClick={() => handleAllAcceptance(false)}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleAllAcceptance(true)}
            >
              Accept
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default SnackbarCookies;
