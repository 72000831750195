import organizationService from "services/organization.service";
import useSWR from "swr";

export const useOrganization = (
  organizationId?: string,
  options?: { onError: (err: Error) => void; shouldRetryOnError?: boolean }
) =>
  useSWR(
    organizationId && `/organization/${organizationId}`,
    () => organizationService.getOrganizationById(organizationId as string),
    {
      ...options,
    }
  );
