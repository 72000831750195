import { getSearchParams } from "application/searchParams";
import {
  closeInviteModal,
  setConfirmedInviteCode,
  setInviteeData,
  setInviterData,
} from "redux/Invite/reducer";
import InviteService from "services/invite.service";
import { FormInput } from "ui/components";
import * as paths from "ui/Router/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormHelperText, Stack, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FC, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "ui/components/Modal";
import resolver from "./formValidation";

interface FormInputs {
  inviteCode: string;
}

interface ReferralModalProps {
  open: boolean;
  onClose: () => void;
}

const ReferralModal: FC<ReferralModalProps> = ({ open = false, onClose }) => {
  const { control, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      inviteCode:
        getSearchParams()?.invitecode || getSearchParams()?.inviteCode,
    },
    resolver,
  });
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    async ({ inviteCode }) => {
      setLoading(true);

      try {
        const confirmInviteResponse = await InviteService.validateInvite(
          inviteCode
        );

        if (!confirmInviteResponse.response) {
          throw new Error(
            confirmInviteResponse?.err?.msg ||
              "It has occurred some unexpected error while trying to confirm the invite code. Please try again later."
          );
        }

        const { email, inviter, phone, userName } =
          confirmInviteResponse.response;

        dispatch(
          setInviteeData({
            username: userName,
            email,
            phone,
          })
        );
        dispatch(setInviterData(inviter));
        dispatch(setConfirmedInviteCode(inviteCode));
        dispatch(closeInviteModal());

        enqueueSnackbar("The invite has been confirmed successfully.", {
          variant: "success",
        });

        navigate("/" + paths.signUpPath);
      } catch (error) {
        const defaultMessage =
          "It was not possible to confirm the invite code. Please try again later.";

        if (!(error as AxiosError)?.isAxiosError) {
          enqueueSnackbar((error as Error).message || defaultMessage, {
            variant: "error",
          });

          return;
        }

        enqueueSnackbar(
          (error as AxiosError)?.response?.data?.err?.msg || defaultMessage,
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, enqueueSnackbar, navigate]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Referral Code"
      bodyProps={{ className: "xs:w-[340px] md:w-[488px]" }}
    >
      <Typography variant="caption" className="text-gray-500">
        <b>Emerge9 can only be accessed via invitation.</b> If you received
        email or SMS, use the link on your messsage to register. Otherwise, you
        could use an invitation code to access here.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-6">
        <Controller
          name="inviteCode"
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <>
              <FormInput
                {...field}
                error={Boolean(fieldState?.error)}
                label="Code"
                type="text"
                placeholder="XXXXXX"
                autoComplete="inviteCode"
              />
              {fieldState?.error && (
                <FormHelperText error>
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <Stack spacing={2} className="mt-14">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            className="w-full"
            loading={isLoading}
          >
            Next
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            onClick={onClose}
          >
            Cancel
          </LoadingButton>
        </Stack>
      </form>
    </Modal>
  );
};

export default ReferralModal;
