import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";
import { IconButton, Tooltip } from "@mui/material";
import { IMediaItem } from "../../../types/media.service";
import CloseIcon from "@mui/icons-material/Close";
import { setCurrentMediaItem } from "../../../redux/MediaPlayer/reducer";
import { useDispatch } from "react-redux";
import "./style.scss";
import sanitizeHtml from "sanitize-html";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends IMediaItem {}

const VideoPlayer = ({ mediaUrl, title, description, fundId }: Props) => {
  const dispatch = useDispatch();
  const handlePlayerClose = () => {
    dispatch(setCurrentMediaItem(null));
  };

  const sanitizedDescription = useMemo(() => {
    return sanitizeHtml(description, {
      allowedTags: [],
    });
  }, [description]);

  return (
    <div className="fixed bottom-0 right-0 md:!m-3 max-w-[456px] w-full h-[417px] bg-white">
      <div className="video-container w-full h-[294px] border-b-4 border-t-0 border-x-0 border-solid border-[#FF0202] bg-black">
        <ReactPlayer
          url={mediaUrl}
          controls={true}
          volume={0.3}
          width="100%"
          height="290px"
        />
      </div>
      <div className="description-container py-3 px-4 flex flex-col justify-start h-[calc(100%_-_294px)]">
        <Tooltip placement="top-start" title={<>{title}</>}>
          <Typography
            component="div"
            className="font-semibold text-primary-600 truncate max-w-[300px] md:max-w-[634px] mb-1 text-[21px] leading-7"
          >
            {title}
          </Typography>
        </Tooltip>
        <Tooltip placement="top-start" title={<>{sanitizedDescription}</>}>
          <Typography
            variant="caption"
            className="video-card-description mb-2 text-gray-400"
          >
            {sanitizedDescription}
          </Typography>
        </Tooltip>
        {fundId && (
          <Link
            to={`/deal/${fundId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="whitespace-nowrap font-bold"
          >
            Deal page
          </Link>
        )}
      </div>
      <div className="bg-white absolute top-[10px] right-[10px] text-white bg-white rounded-full">
        <IconButton onClick={handlePlayerClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default VideoPlayer;
