import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";

export interface IBreadcrumbsItem {
  label: string | ReactElement;
  to?: string;
  isLast?: boolean;
  hidden?: boolean;
}

export interface BreadcrumbsProps {
  items: IBreadcrumbsItem[];
  className?: string;
}

export const Breadcrumbs = ({ items, className }: BreadcrumbsProps) => {
  const filteredItems = useMemo(
    () => items.filter((item) => !item.hidden),
    [items]
  );

  return (
    <div className={clsx("pt-[20px] pb-[10px]", className)}>
      <MUIBreadcrumbs
        separator={
          <ArrowForwardIosIcon
            className="text-complementary-400"
            fontSize="inherit"
          />
        }
        classes={{ separator: "mx-3" }}
      >
        {filteredItems.length &&
          filteredItems.map((item, index) => {
            return item.hidden ? null : (
              <BreadcrumbsItem
                key={index}
                {...item}
                isLast={filteredItems.length - 1 === index}
              />
            );
          })}
      </MUIBreadcrumbs>
    </div>
  );
};

const BreadcrumbsItem = ({ label, to, isLast }: IBreadcrumbsItem) => {
  if (to) {
    return (
      <Link to={to} className="no-underline">
        <Typography
          variant="button2"
          className={clsx("font-bold break-words", {
            "text-gray-500": isLast,
            "text-complementary-400": !isLast,
          })}
        >
          {label}
        </Typography>
      </Link>
    );
  }

  return (
    <Typography
      variant="button2"
      className={clsx("font-bold break-words", {
        "text-gray-500": isLast,
        "text-complementary-400": !isLast,
      })}
    >
      {label}
    </Typography>
  );
};

export default Breadcrumbs;
