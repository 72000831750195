import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import { usePaths } from "../usePaths";

export const useSyndicationPartnerId = (
  routeToRedirectWhenNotFound?: string
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { partnerId } = useParams();
  const { mainRedirectPath } = usePaths();
  const redirectUrl = routeToRedirectWhenNotFound
    ? `/${routeToRedirectWhenNotFound}`
    : mainRedirectPath;

  if (!partnerId) {
    navigate(redirectUrl);
    enqueueSnackbar("The partner could not be found. Please try again later.", {
      variant: "error",
      title: "Partner not found",
    });
    return "";
  }

  return partnerId;
};
