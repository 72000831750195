export enum AssistantThreadMessageRole {
  User = "user",
  Assistant = "assistant",
}

export type FieldAssistantType = "markdown" | "default";

export enum AssistantAttachableField {
  DealName = "deal.name",
  DealShortDescription = "deal.shortDescription",
  DealPublicOverview = "deal.publicOverview",
  DealPrivateOverview = "deal.privateOverview",
  DealSector = "deal.sectors",
  DealGeographicalRegion = "deal.geographicalRegion",
  DealStrategy = "deal.strategy",
  DealType = "deal.dealType",
  DealAssetType = "deal.assetType",
  DealSettings = "deal.settings",
  LeadInvestorItem = "leadInvestor.item",
  AdvisorItem = "advisor.item",
  SponsorItem = "sponsor.item",
  HistoricalPerformanceBlock = "historicalPerformance.block",
  KeyBiographiesItem = "keyBiographies.item",
  KeyHighlightsItem = "keyHighlights.item",
  SummaryItem = "summary.item",
}

export enum AssistantFieldContext {
  Deal = "Deal",
  LeadInvestor = "LeadInvestor",
  Sponsor = "Sponsor",
  Advisor = "Advisor",
  HistoricalPerformance = "HistoricalPerformance",
  KeyBiographies = "KeyBiographies",
  KeyHighlights = "KeyHighlights",
  Summary = "Summary",
}

export const AssistantFieldContextLabelMap: Record<
  AssistantFieldContext,
  string
> = {
  [AssistantFieldContext.Deal]: "Deal",
  [AssistantFieldContext.LeadInvestor]: "Lead investor",
  [AssistantFieldContext.Sponsor]: "Sponsor",
  [AssistantFieldContext.Advisor]: "Advisor",
  [AssistantFieldContext.HistoricalPerformance]: "Historical performance",
  [AssistantFieldContext.KeyBiographies]: "Key biographies",
  [AssistantFieldContext.KeyHighlights]: "Key highlights",
  [AssistantFieldContext.Summary]: "Summary",
};

export const ASSISTANT_FIELD_CONTEXT_OPTIONS: Option[] = Object.keys(
  AssistantFieldContextLabelMap
).map((key) => {
  return {
    label: AssistantFieldContextLabelMap[key as AssistantFieldContext],
    value: key,
  };
});

export const DealFieldInfoMap: Record<
  AssistantAttachableField,
  { label: string; context: AssistantFieldContext }
> = {
  [AssistantAttachableField.DealName]: {
    label: "Name",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealShortDescription]: {
    label: "Short description",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealPublicOverview]: {
    label: "Public overview",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealPrivateOverview]: {
    label: "Private overview",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealSector]: {
    label: "Sectors",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealGeographicalRegion]: {
    label: "Geographical region",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealStrategy]: {
    label: "Strategy",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealType]: {
    label: "Deal type",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealAssetType]: {
    label: "Deal asset type",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.DealSettings]: {
    label: "Deal settings",
    context: AssistantFieldContext.Deal,
  },
  [AssistantAttachableField.LeadInvestorItem]: {
    label: "Lead investor item",
    context: AssistantFieldContext.LeadInvestor,
  },
  [AssistantAttachableField.AdvisorItem]: {
    label: "Advisor item",
    context: AssistantFieldContext.Advisor,
  },
  [AssistantAttachableField.SponsorItem]: {
    label: "Sponsor item",
    context: AssistantFieldContext.Sponsor,
  },
  [AssistantAttachableField.HistoricalPerformanceBlock]: {
    label: "Historical performance block",
    context: AssistantFieldContext.HistoricalPerformance,
  },
  [AssistantAttachableField.KeyBiographiesItem]: {
    label: "Key biographies item",
    context: AssistantFieldContext.KeyBiographies,
  },
  [AssistantAttachableField.KeyHighlightsItem]: {
    label: "Key highlights item",
    context: AssistantFieldContext.KeyHighlights,
  },
  [AssistantAttachableField.SummaryItem]: {
    label: "Summary item",
    context: AssistantFieldContext.Summary,
  },
};

export const getAttachableFieldOptionsByAssistantFieldContext = (
  context: AssistantFieldContext
): Option[] => {
  const items = Object.keys(DealFieldInfoMap)
    .map((key) => {
      const value = DealFieldInfoMap[key as AssistantAttachableField];

      if (value.context !== context) return null;

      return {
        label: value.label,
        value: key,
      };
    })
    .filter(Boolean);

  return items as Option[];
};

export const DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE =
  "Deal Assistant is currently warming up and unable to respond to your query. Please try again in a few minutes.";

export interface IDealSettingsAssistantValue {
  dealSize: number;
  minContribution: number;
  availablePart: number;
  startDate: string;
  closeDate: string;
}
