import useSWR from "swr";
import AdminService from "services/admin.service";

export const useSingleUserOnboarding = (customUsername: string) => {
  return useSWR(`/Admin/User/${customUsername}/onboarding`, () =>
    AdminService.getUserOnboarding(customUsername)
  );
};

export default useSingleUserOnboarding;
