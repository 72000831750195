import Switch from "@mui/material/Switch";
import { FeedbackError } from "application/errors";
import React from "react";
import DealService from "services/deal.service";
import { CardAction } from "ui/components";
import { useDealGeneralData, useDealId, useErrorHandler } from "ui/hooks";

export const DealManageNavDataRoomWatermark = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const { handleError } = useErrorHandler();
  const isEnabled = React.useMemo(() => !data?.skipDataRoomWatermark, [data]);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new FeedbackError(
          "It was not possible to get the deal data. Please try again later."
        );
      }

      await DealService.toggleDealWatermark(
        dealId,
        !data.skipDataRoomWatermark
      );
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to update the deal watermark data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardAction
      label="Data Room Watermark"
      description={
        isEnabled ? (
          <span title="Documents on data room will have the watermark. (Obs.: Not applied to protected files).">
            Documents on data room <b>will have the watermark</b>.{" "}
            <i>(Obs.: Not applied to protected files)</i>.
          </span>
        ) : (
          <span title="The data room watermark is disabled">
            The data room watermark is <b>disabled</b>
          </span>
        )
      }
      onClick={handleClick}
      loading={loading}
      endAdornment={<Switch checked={isEnabled} disabled={loading} />}
    />
  );
};
