import { AutoAwesome } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { getAssistantJSONValue, pollRequest } from "application/utils";
import clsx from "clsx";
import { AssistantAttachableField } from "domain/assistant";
import { useCallback, useMemo, useState } from "react";
import dealAssistantService from "services/dealAssistant.service";
import { useDealAssistantFields, useErrorHandler } from "ui/hooks";
import { ConfirmationModal } from "../Modal";

interface AssistantAutoCompleteProps<T> {
  dealId: string;
  fieldId: AssistantAttachableField;
  onData: (data: T) => void;
  validate: (data?: T) => boolean;
  size?: "small" | "default";
  autocompleteType?: "overwrite" | "append";
}

export function AssistantAutoComplete<T>({
  onData,
  dealId,
  fieldId,
  validate,
  size,
  autocompleteType = "overwrite",
}: AssistantAutoCompleteProps<T>) {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const fieldsIndex = useDealAssistantFields(dealId);
  const isValid = Boolean(fieldsIndex[fieldId] && dealId);
  const { handleError } = useErrorHandler();

  const onGetValue = useCallback(async () => {
    setLoading(true);

    try {
      const thread = await dealAssistantService.createAndRunFieldThread(
        dealId,
        fieldId
      );
      const data = await pollRequest(
        async () => dealAssistantService.getThreadById(dealId, thread.threadId),
        (thread) => !thread.hasPendingRuns,
        2000,
        300000
      );

      const [lastMessage] = [...(data?.messages || [])].reverse();

      const parsed = getAssistantJSONValue<any>(lastMessage?.content || "");

      if (!validate(parsed)) {
        throw new FeedbackError(
          "The assistant didn't return the expected field value. Please try again later."
        );
      }

      onData(parsed);
    } catch (e) {
      handleError(
        e,
        "It was not possible to get the assistant date. Please try again later. "
      );
    } finally {
      setLoading(false);
    }
  }, [dealId, fieldId, handleError, onData, validate]);

  const isSmall = size === "small";

  const message = useMemo(() => {
    switch (autocompleteType) {
      case "append":
        return "When using the assistant, your existing content is safe. All new entries are made without overwriting your current data.";
      default:
        return "The assistant will replace the existing values with its own data. Are you sure you want to continue?";
    }
  }, [autocompleteType]);

  if (!isValid) {
    return null;
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        loading={isLoading}
        startIcon={<AutoAwesome className={clsx(isSmall && "w-[0.85rem]")} />}
        onClick={() => setConfirmationModalOpen(true)}
        size="small"
        className={clsx(isSmall ? "h-2 px-2" : "")}
        color="primary"
      >
        <Typography
          className="text-inherit"
          variant={isSmall ? "caption2" : "button2"}
        >
          Generate content
        </Typography>
      </LoadingButton>
      <ConfirmationModal
        title="Generate content"
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={() => {
          setConfirmationModalOpen(false);
          onGetValue();
        }}
        onCancel={() => setConfirmationModalOpen(false)}
      >
        <Typography variant="body2">{message}</Typography>
      </ConfirmationModal>
    </>
  );
}
