import { DealCampaignStepEnum } from "./DealEditCampaignForm.enums";

export const DEAL_CAMPAIGN_STEP_LABELS = {
  [DealCampaignStepEnum.Create]: "Create",
  [DealCampaignStepEnum.Summary]: "Summary",
};

export const DEAL_CAMPAIGN_STEP_ORDER = [
  DealCampaignStepEnum.Create,
  DealCampaignStepEnum.Summary,
];
