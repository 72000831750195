import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ImageIcon } from "ui/icons";
import Loading from "../Loading/Loading";
import { HelperTooltip } from "../HelperTooltip";
import { FeedbackError } from "application/errors";
import { useErrorHandler } from "ui/hooks";
import clsx from "clsx";
import { FormInputWrapper } from "../FormInputWrapper";
import { FieldError } from "react-hook-form";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ImageUploadProps {
  title?: string;
  caption?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  imgSrc?: string;
  onChange: (file: File | null) => void;
  loading?: boolean;
  sizeLimit?: number;
  disabled?: boolean;
  error?: FieldError;
}

export const ImageUploadHelperTooltip = () => (
  <HelperTooltip
    id="cropper-image-upload"
    title="Image file"
    place="right"
    content={
      <ul className="p-0 m-0 list-inside">
        <li>Max. size: 3MB</li>
        <li>Allowed extensions</li>
        <ul className="pl-3 list-inside">
          <li>.png</li>
          <li>.jpg</li>
          <li>.jpeg</li>
          <li>.webp</li>
        </ul>
      </ul>
    }
  />
);

const ImageUpload: React.FC<ImageUploadProps> = ({
  title = "",
  caption = "",
  inputRef,
  imgSrc,
  onChange,
  loading,
  sizeLimit = 5e6,
  disabled,
  error,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedFile] = React.useState<File | null>(null);
  const [imgUrl, setImgUrl] = React.useState<string | null>(null);
  const { handleError } = useErrorHandler();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0] ?? null;

    try {
      setSelectedFile(file);

      if (!file) return onChange(null);

      if (file.size > sizeLimit) {
        throw new FeedbackError(
          `The file size exceeds the limit. (Max.: ${(sizeLimit / 1e6).toFixed(
            1
          )}mb)`
        );
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = function () {
        setImgUrl(reader.result as string);
      };

      return onChange(file);
    } catch (e) {
      handleError(e, "It was not possible to upload the file.");
    }
  };

  const clearImageValue = () => {
    setImgUrl(null);
    onChange(null);
  };

  return (
    <FormInputWrapper errorMessage={error?.message}>
      <div className="flex max-w-full">
        <div>
          <div
            className={clsx(
              "w-[128px] border-2 border-solid h-[128px] bg-gray-200 overflow-hidden rounded-md flex items-center justify-center mr-8 relative",
              {
                "border-gray-200": !error,
                "border-error": Boolean(error),
              }
            )}
          >
            {imgUrl || imgSrc ? (
              <>
                <img
                  className="h-[inherit] w-[inherit] rounded-[inherit] object-cover"
                  src={imgUrl || imgSrc}
                  alt=""
                />
                <IconButton
                  className="absolute top-2 right-2 p-0.5 bg-white bg-opacity-80 shadow-md hover:bg-white hover:bg-opacity-100"
                  onClick={clearImageValue}
                  size="small"
                  disabled={disabled}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <ImageIcon />
            )}
            {loading && (
              <div className="absolute top-0 left-0 bg-white bg-opacity-50 w-full h-full flex justify-center items-center">
                <Loading />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <Typography
            variant="body2"
            className="font-bold text-primary-600 items-center flex space-x-2"
          >
            <span>{title}</span>
            <ImageUploadHelperTooltip />
          </Typography>
          {caption && (
            <Typography variant="caption2" className="grow">
              {caption}
            </Typography>
          )}
          <input
            ref={inputRef}
            accept="image/png, image/jpeg, image/jpg, image/webp"
            id="logo-upload"
            type="file"
            onChange={handleFileSelect}
            hidden
          />
          <label className="mt-3" htmlFor="logo-upload">
            <Button
              disabled={disabled || loading}
              onClick={() => {
                inputRef && inputRef.current && inputRef.current.click();
              }}
              variant="outlined"
              size="small"
              className="self-start w-[122px]"
            >
              Upload
            </Button>
          </label>
        </div>
      </div>
    </FormInputWrapper>
  );
};

export default ImageUpload;
