import React from "react";
import {
  useDealPermissions,
  useDealQuestions,
  useDealPaths,
  useDealGeneralData,
} from "ui/hooks";
import PostQuestion from "./PostQuestion";
import PostAnswer from "./PostAnswer";
import QuestionsBlock from "./QuestionsBlock";
import { DealQuestionStatusEnum } from "domain/deal";
import { Navigate } from "react-router-dom";

const sortByPriority = (a: { priority: number }, b: { priority: number }) =>
  b.priority - a.priority;

export const DealQuestionsAndAnswers = () => {
  const { data: dealData } = useDealGeneralData();
  const { dealPath } = useDealPaths();
  const { dealEdit, dealAccess } = useDealPermissions();
  const { data } = useDealQuestions();

  const questions = React.useMemo(() => {
    if (data) {
      const _pending = data
        .filter(({ status }) => status === DealQuestionStatusEnum.Pending)
        .sort(sortByPriority);

      const pending = [..._pending.sort(sortByPriority)];

      const _answered = data.filter(
        ({ status }) => status === DealQuestionStatusEnum.Answered
      );

      const answered = [..._answered.sort(sortByPriority)];

      return { pending, answered };
    }
    return {};
  }, [data]);

  if (!dealAccess || (dealData && !dealData.isQuestionAndAnswersEnabled)) {
    return <Navigate to={dealPath} replace />;
  }

  return (
    <div className="space-y-6">
      {dealEdit ? <PostAnswer /> : <PostQuestion />}
      {questions?.pending && questions.pending.length > 0 && (
        <QuestionsBlock
          title="Pending questions"
          answerAble={dealEdit}
          data={questions.pending}
        />
      )}
      {questions?.answered && questions.answered.length > 0 && (
        <QuestionsBlock title="Answered questions" data={questions.answered} />
      )}
    </div>
  );
};

export default DealQuestionsAndAnswers;
