import { useMemo } from "react";
import { generatePath, Outlet, useMatch, useParams } from "react-router-dom";
import { Breadcrumbs, IBreadcrumbsItem } from "ui/components";
import { useDealAlias, useDealGeneralData, useDealPaths } from "ui/hooks";
import * as paths from "ui/Router/paths";

interface CampaignsDealBreadcrumbsLayoutProps {
  rootRoute?: boolean;
}

export const CampaignsDealBreadcrumbsLayout = ({
  rootRoute,
}: CampaignsDealBreadcrumbsLayoutProps) => {
  const { dealPath, dealManagePath, dealCampaignsPath } = useDealPaths();
  const { data: dealData } = useDealGeneralData();
  const isSummary = useMatch(paths.editDealCampaignSummary);
  const { campaignId } = useParams();
  const dealAlias = useDealAlias();

  // @TODO Generate breadcrumbs regarding current route / Fix hidden param
  const breadcrumbItems = useMemo<IBreadcrumbsItem[]>(() => {
    const baseItems = [
      {
        label: dealData?.name || "Deal",
        to: dealPath,
      },
      {
        label: "Manage Deal",
        to: dealManagePath,
      },
      {
        label: "Campaign list",
        ...(!rootRoute && { to: dealCampaignsPath }),
      },
      {
        label: "Campaign edit",
        to: campaignId
          ? "/" +
            generatePath(paths.editDealCampaignWithCampaignId, {
              campaignId,
              alias: dealAlias,
            })
          : undefined,
        hidden: rootRoute,
      },
    ];

    if (isSummary) {
      return [
        ...baseItems,
        {
          label: "Campaign preview",
          isLast: true,
        },
      ];
    }

    return baseItems;
  }, [
    campaignId,
    dealAlias,
    dealCampaignsPath,
    dealData?.name,
    dealManagePath,
    dealPath,
    isSummary,
    rootRoute,
  ]);

  return (
    <div>
      <Breadcrumbs items={breadcrumbItems} className="px-[20px]" />
      <Outlet />
    </div>
  );
};
