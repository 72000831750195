import { AnyAction } from "redux";
import { UserProfile } from "types/user.service";
import { FetchStatus } from "domain/common";
import { ActionType } from "./actions";

export interface UserState {
  profile: UserProfile | null;
  fetch: {
    profile: FetchStatus;
  };
}

export const initialUserState: UserState = {
  profile: null,
  fetch: {
    profile: FetchStatus.NotStarted,
  },
};

const reducer = (state = initialUserState, action: AnyAction): UserState => {
  switch (action.type) {
    case ActionType.FetchUserProfileStarted: {
      return {
        ...state,
        fetch: {
          ...state.fetch,
          profile: FetchStatus.Started,
        },
      };
    }
    case ActionType.FetchUserProfileFinished: {
      return {
        ...state,
        fetch: {
          ...state.fetch,
          profile: FetchStatus.Finished,
        },
      };
    }
    case ActionType.FetchUserProfileError: {
      return {
        ...state,
        fetch: {
          ...state.fetch,
          profile: FetchStatus.Error,
        },
      };
    }
    case ActionType.SetUserProfile: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
