export class FeedbackError extends Error {}

export class RedirectError extends Error {
  public redirectTo: string;

  constructor(message: string, redirectTo: string) {
    super(message);
    this.redirectTo = redirectTo;
  }
}

export class PermissionError extends Error {}

export class TimeoutError extends Error {}
