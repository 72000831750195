import { vestResolver } from "@hookform/resolvers/vest";
import { create, each, enforce, test } from "vest";
import { FormInputs } from "./DealEditPersonMultiple.form";

const schema = create<(data: FormInputs) => void>(({ people }) => {
  each(people, ({ description, url, name, imageUrl }, index) => {
    test(`people.${index}.name`, "Name is required", () => {
      enforce(name).isNotBlank();
    });

    test(`people.${index}.description`, "Description is required", () => {
      enforce(description).isNotEmpty();
    });

    // TODO: Check if it is really necessary
    // test(`people.${index}.url`, "URL is required", () => {
    //   enforce(url).isNotEmpty();
    // });
  });
});

export default vestResolver(schema);
