export const TEMPORARY_ENTITY_TYPE = "temporary";

export enum ITEM_TYPES {
  FOLDER = "folder",
  FILE = "file",
}

export interface BreadCrumbsPathEntity {
  name: string;
  temporary?: boolean;
}

export type IBreadCrumbsPath = BreadCrumbsPathEntity[];

export interface IDataRoomRowItem {
  name: string;
  title: string;
  modifiedDate: string;
  itemType: ITEM_TYPES;
  size: number;
  isIndexedOnAssistant: boolean;
  myPermissions: RolePermissions;
  entityType?: typeof TEMPORARY_ENTITY_TYPE;
}
export type IDataRoomRowItems = IDataRoomRowItem[];

export type DataRoomResponse = IDataRoomRowItems;

export enum ACTION_NAMES {
  UPLOAD = "upload",
  MOVE = "move",
  REMOVE = "remove",
  RENAME = "rename",
  CREATE = "create",
}

export interface IDataRoomChangesItem {
  id: string;
  actionName: ACTION_NAMES;
  targetType: ITEM_TYPES;
  targetName: string;
  targetPath: string;
  oldTargetPath: string;
  oldTargetName: string;
  actionDate: string;
}

export type DataRoomChangesResponse = IDataRoomChangesItem[];

export enum DataRoomActionTypes {
  DELETE = "delete",
  DOWNLOAD = "download",
  VIEW = "view",
  ADD_ASSISTANT_INDEX = "add_assistant_index",
  REMOVE_ASSISTANT_INDEX = "remove_assistant_index",
}

export interface DataRoomNotificationPayload {
  actionIds: string[];
  message: string;
}

export enum RolePermissionValues {
  VIEW = "VIEW",
  ALLOW_DOWNLOAD = "ALLOW_DOWNLOAD",
  ALLOW_RAW_DOWNLOAD = "ALLOW_RAW_DOWNLOAD",
  ALLOW_SHARE = "ALLOW_SHARE",
  SKIP_WATERMARK = "SKIP_WATERMARK",
}

export enum PredefinedRoles {
  ALL = "ALL",
  MANAGER = "MANAGER",
  DOWNLOADER = "DOWNLOADER",
  VIEWER = "VIEWER",
  INVESTOR = "INVESTOR",
  USER = "USER",
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}

export interface RolePermission {
  Value: string;
}

export type RolePermissions = RolePermission[];

export type DataRoomPermissionRole = Omit<DataRoomRole, "lastModifiedDate">;
export type DataRoomPermissionRoles = DataRoomPermissionRole[];

export interface DataRoomPermission {
  id: string;
  roles: DataRoomPermissionRoles;
}

export type DataRoomPermissions = DataRoomPermission[];

export interface DataRoomPermissionResponse {
  permissionsFromPath: string;
  permissions: DataRoomPermissions;
}
export interface DataRoomRole {
  roleId: string;
  roleName: string;
  fundId: string;
  isRemovable: boolean;
  lastModifiedDate: string;
  dataRoomResourceId?: string;
}

export type DataRoomRoles = DataRoomRole[];

export interface UserWithRoles {
  userId: string;
  email: string;
  name: string;
  roles: DataRoomPermissionRoles;
  addedAt: string;
  imageUrl?: string;
}

export type UsersWithRoles = UserWithRoles[];
