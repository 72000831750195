import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Slider, { SliderProps } from "@mui/material/Slider";

export interface Props extends SliderProps {
  label: string;
  error?: boolean;
  min?: number;
  max?: number;
  step?: number;
  labelLeft?: string;
  labelRight?: string;
}

const FormSliderInput = React.forwardRef<SliderProps, Props>(
  function FormSliderInput(
    {
      label,
      error,
      min = 0,
      max = 100,
      step = 1,
      labelLeft,
      labelRight,
      ...props
    },
    ref?
  ) {
    return (
      <FormControl fullWidth error={error} variant="standard">
        <InputLabel disableAnimation className="relative pb-3">
          {label}
        </InputLabel>
        <div className="px-3">
          <Slider min={min} max={max} step={step} {...props} />
        </div>
        {(labelLeft || labelRight) && (
          <div className="flex justify-between">
            {labelLeft && (
              <Typography
                variant="caption"
                className="font-semibold text-primary-600"
              >
                {labelLeft}
              </Typography>
            )}
            {labelRight && (
              <Typography
                variant="caption"
                className="font-semibold text-primary-600"
              >
                {labelRight}
              </Typography>
            )}
          </div>
        )}
      </FormControl>
    );
  }
);

export default FormSliderInput;
