import clsx from "clsx";
import Modal, { ModalProps } from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Close from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props extends ModalProps {
  title?: string;
  smallTitle?: boolean;
}

export const ModalComponent = ({
  title,
  children,
  smallTitle,
  ...props
}: Props) => {
  const onCloseClick = () => {
    if (props?.onClose) {
      props.onClose({}, "escapeKeyDown");
    }
  };

  return (
    <Modal
      className={clsx("flex items-center justify-center", {})}
      {...props}
      disableScrollLock
    >
      <Paper
        className={clsx(
          "flex flex-col md:py-9 md:px-8 md:rounded-3xl md:w-[488px] outline-none rounded-none w-full h-full md:h-auto overflow-hidden md:overflow-auto py-9 px-4"
        )}
      >
        <div>
          {props?.onClose && (
            <div
              onClick={onCloseClick}
              className="w-[24px] h-[24px] md:hidden flex items-center justify-center cursor-pointer transition-colors text-primary-600 mb-3"
            >
              <ArrowBackIcon />
            </div>
          )}
          <div className="flex justify-between items-center mb-4">
            <Typography
              variant={smallTitle ? "h4" : "h3"}
              className={clsx(
                "text-primary-600 tracking-tight",
                smallTitle ? "font-medium" : "font-normal"
              )}
            >
              {title}
            </Typography>
            {props?.onClose && (
              <div
                onClick={onCloseClick}
                className="border-2 hidden shrink-0 border-gray-200 w-[40px] h-[40px] rounded-full md:flex items-center justify-center cursor-pointer hover:border-gray-300 border-solid transition-colors text-primary-600 ml-4"
              >
                <Close />
              </div>
            )}
          </div>
        </div>
        <div className="overflow-auto md:overflow-visible h-full">
          {children}
        </div>
      </Paper>
    </Modal>
  );
};

export default ModalComponent;
