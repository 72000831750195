/* eslint-disable react/prop-types */
import clsx from "clsx";
import React, { FC } from "react";

export const SlimFormPageLayout: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={clsx(
        "flex flex-col justify-center grow self-center w-full max-w-[417px] md:max-w-none md:w-[401px] p-4 md:p-2",
        className
      )}
    >
      {children}
    </div>
  );
};
