import Typography from "@mui/material/Typography";
import { DividerDashed, LabeledValue } from "ui/components";
import { useDealSummary, useDealPaths } from "ui/hooks";
import { DetailSection } from "../DetailSection/DetailSection";
import { LABEL_TO_VALUE_MAP } from "../../../DealEditSummary/DealEditSummary.options";
import { Grid } from "@mui/material";
import { useRef } from "react";

export const SUMMARY_LABELS = ["Returns & fees", "Schedule", "Structure"];

const DealSummary = () => {
  const { editDealSummaryPath: editPath } = useDealPaths();
  const { data } = useDealSummary();
  const ref = useRef(null);

  return (
    <DetailSection
      title="Summary"
      getCopyContent={() => ref.current as any}
      editRoute={editPath}
      isEmpty={!data}
    >
      {data && (
        <>
          <div className="m9CardSurface py-5 px-6 w-full">
            <Grid container columnSpacing={2.5}>
              {Object.values(data?.items || {}).map(
                ({ label, items: blockItems }, index) => (
                  <Grid item xs={4} key={index} className="space-y-5 relative">
                    <Typography
                      variant="body2"
                      className="font-bold text-gray-500"
                    >
                      {label || SUMMARY_LABELS[index]}
                    </Typography>
                    <DividerDashed />
                    <div className="flex flex-col space-y-[26px]">
                      {blockItems &&
                        blockItems.map(({ label, value }, index) => {
                          return (
                            <LabeledValue
                              key={index}
                              label={
                                typeof label === "string"
                                  ? LABEL_TO_VALUE_MAP[label] || label
                                  : label.label
                              }
                              value={value}
                              labelProps={{ className: "!font-semibold" }}
                            />
                          );
                        })}
                    </div>
                    {index > 0 && (
                      <div className="absolute top-0 left-2 h-full flex !mt-0">
                        <DividerDashed vertical />
                      </div>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </div>
          <div className="hidden">
            <div ref={ref}>
              <div className="m9CardSurface w-[650px] w-max-full px-6">
                <table className="w-full border-collapse table-fixed">
                  <tbody>
                    <tr className="border-0 border-b border-dashed border-gray-200">
                      {Object.values(data?.items || {}).map(
                        ({ label }, index) => (
                          <td
                            key={index}
                            className="pr-5 py-5 border-0 border-r border-dashed border-gray-200 w-1/3 align-baseline last:border-r-0 pl-5 first:pl-0"
                          >
                            <Typography
                              variant="body2"
                              className="font-bold text-gray-500"
                            >
                              {label || SUMMARY_LABELS[index]}
                            </Typography>
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      {Object.values(data?.items || {}).map(
                        ({ items: blockItems }, index) => (
                          <td
                            key={index}
                            className="pr-5 py-5 border-0 border-r border-dashed border-gray-200 w-1/3 space-y-5 align-baseline last:border-r-0 pl-5 first:pl-0"
                          >
                            <div className="space-y-[26px]">
                              {blockItems &&
                                blockItems.map(({ label, value }, index) => {
                                  return (
                                    <div key={`${index}-${label}`}>
                                      <Typography
                                        variant="caption2"
                                        component="h5"
                                        className="font-semibold text-gray-300 inline-block"
                                      >
                                        {typeof label === "string"
                                          ? LABEL_TO_VALUE_MAP[label] || label
                                          : label.label}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        component="h4"
                                        className="text-[0.875rem] leading-4 text-gray-500 mt-1"
                                      >
                                        {value}
                                      </Typography>
                                    </div>
                                  );
                                })}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </DetailSection>
  );
};

export default DealSummary;
