import { Permissions, PermissionsArray } from "types";
import { useGroupValidation } from "../useGroupValidation";
import { useCurrentProject } from "./useCurrentProject";

const hasPermission =
  (userPermissions: Permissions) => (permissions: PermissionsArray) =>
    permissions.every((permission) => userPermissions[permission]);

export const useProjectPermissions = () => {
  const { data } = useCurrentProject();
  const { hasAdminLevel, loading } = useGroupValidation();

  const isOwner = typeof data?.isShared === "boolean" && !data.isShared;
  const hasEditPermission = Boolean(isOwner || hasAdminLevel);

  const userPermissions: Permissions = {
    R: true,
    W: hasEditPermission,
    X: hasEditPermission,
    D: hasEditPermission,
  };

  return {
    isOwner,
    hasEditPermission,
    loading,
    userPermissions,
    hasPermission: hasPermission(userPermissions),
  };
};
