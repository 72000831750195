export enum MetricResourceType {
  FUND = "fund",
}
export enum MetricEventType {
  VIEW_PAGE = "viewPage",
  VIEW_DOCUMENT = "viewDocument",
}

export enum MetricType {
  FUND_METRIC = "fund",
  DATA_ROOM_METRIC = "dataroom",
}
