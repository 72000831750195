import { PersonAddAlt } from "@mui/icons-material";
import { Button } from "@mui/material";
import { isEmpty, isString, sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SWRResponse } from "swr";
import {
  CreateOrUpdateDealPersonPayload,
  GetDealPersonDataResponse,
} from "types/deal";
import { defaultValues as personDefaultValues } from "ui/components/DealEditPerson/DealEditPerson.form";
import { useDealId, useDealPaths, useErrorHandler } from "ui/hooks";
import useDealAlias from "ui/hooks/Deal/useDealAlias";
import {
  DealCreateOrEditPage,
  DealEditPageType,
  DealFormLayout,
} from "ui/pages/Form";
import { useDealEditPostUpdate } from "ui/pages/Form/DealForm.hooks";
import { DealEditPersonProps } from "../DealEditPerson/DealEditPerson";
import { SectionTitle } from "../SectionTitle";
import {
  DealEditSponsorSectionItem,
  defaultValues,
  FormInputs,
} from "./DealEditPersonMultiple.form";
import resolver from "./DealEditPersonMultiple.validation";
import { AssistantAutoComplete } from "../AssistantAutoComplete";
import { AssistantAttachableField } from "domain/assistant";
import { useSnackbar } from "notistack";

interface DealEditPersonMultipleProps
  extends Omit<
    DealEditPersonProps & DealCreateOrEditPage,
    | "updateDealPersonImageCallback"
    | "createDealPersonCallback"
    | "fetchPersonDataHook"
    | "updateDealPersonCallback"
    | "fieldId"
  > {
  fetchPersonDataHook: () => SWRResponse<GetDealPersonDataResponse[]> & {
    loading: boolean;
    hasAccess?: boolean;
  };
  updateDealPersonCallback: (
    dealId: string,
    payload: CreateOrUpdateDealPersonPayload[]
  ) => Promise<void>;
  assistantFieldId: AssistantAttachableField;
}

type IAssistantValue = Array<{ name: string; description: string }>;

export const DealEditPersonMultiple: React.FC<DealEditPersonMultipleProps> = ({
  type,
  routeAfterSubmit,
  BasicInfoSectionProps,
  OtherDealsSectionProps,
  fetchPersonDataHook,
  imageInputTitle,
  title,
  updateDealPersonCallback,
  stepName,
  assistantFieldId,
}) => {
  const dealId = useDealId();
  const dealAlias = useDealAlias();
  const navigate = useNavigate();
  const { dealPath } = useDealPaths();
  const { handleError } = useErrorHandler();
  const { goToTheNextRouteIfExists } = useDealEditPostUpdate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: peopleData,
    loading,
    error: peopleError,
  } = fetchPersonDataHook();

  React.useEffect(() => {
    setError(peopleError);
  }, [peopleError]);

  const { reset, control, register, handleSubmit, formState, watch, setValue } =
    useForm({
      defaultValues,
      resolver,
    });
  const { append, remove, fields } = useFieldArray({
    control,
    name: "people",
  });

  const nameVariants = useMemo(() => {
    switch (stepName) {
      case "advisor":
        return { singularVariant: "Advisor", pluralVariant: "Advisors" };
      case "leadInvestor":
        return {
          singularVariant: "Lead Investor",
          pluralVariant: "Lead investors",
        };
      case "sponsor":
      default:
        return {
          singularVariant: "Sponsor - GP",
          pluralVariant: "Sponsors - GP",
        };
    }
  }, [stepName]);

  useEffect(() => {
    if (isEmpty(peopleData)) return;
    reset(
      {
        people: sortBy(peopleData, "createdAt").map((item) => ({
          id: item.id,
          description: item.description,
          imageUrl: item.imageUrl,
          name: item.name,
          otherDeals: item.otherDeals?.map((id) => ({ id })),
          url: item.url,
        })),
      },
      { keepDirty: true, keepDirtyValues: true }
    );
  }, [reset, setValue, peopleData]);

  const onSubmit: SubmitHandler<FormInputs> = async ({ people }) => {
    setSubmitting(true);

    const parsedPayload = people.map((data) => {
      const isNameString = isString(data.name);

      return {
        id: data.id,
        description: data.description,
        name: isNameString ? data.name : (data.name as Option).label,
        organizationId: isNameString ? null : (data.name as Option).value,
        url: data.url || "",
        otherDeals: data?.otherDeals?.map(({ id }) => id).filter(Boolean) || [],
        imageUrl: data.imageUrl,
      } as CreateOrUpdateDealPersonPayload;
    });

    try {
      await updateDealPersonCallback(dealId, parsedPayload);
      goToTheNextRouteIfExists({ routeAfterSubmit, dealAlias });
      if (type === DealEditPageType.Edit) {
        navigate(dealPath);
      }

      enqueueSnackbar(
        `The ${nameVariants.pluralVariant?.toLowerCase()} have been saved successfully`,
        {
          variant: "success",
          title: "Saved successfully.",
        }
      );
    } catch (error) {
      handleError(error, "It was not possible to update the data");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DealFormLayout
      title={title}
      type={type}
      formState={formState}
      onReset={() => reset(defaultValues)}
      onSkip={() => goToTheNextRouteIfExists({ routeAfterSubmit, dealAlias })}
      isLoading={loading}
      isSubmitting={isSubmitting}
      error={error}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col space-y-8">
        <div className="flex items-center justify-between">
          <SectionTitle>Items</SectionTitle>
          <AssistantAutoComplete
            dealId={dealId}
            fieldId={assistantFieldId}
            validate={(data) => {
              return (
                Array.isArray(data) &&
                data.every(
                  (item) => isString(item.name) && isString(item.description)
                )
              );
            }}
            onData={(data: IAssistantValue) => {
              reset({
                people: data.map(({ description, name }) => ({
                  name,
                  description,
                })),
              });
            }}
          />
        </div>
        {fields.map((field, index) => (
          <DealEditSponsorSectionItem
            key={field.id}
            nameVariants={nameVariants}
            index={index}
            control={control}
            register={register}
            watch={watch}
            fields={fields}
            onItemIncrement={() => append(personDefaultValues)}
            onItemRemove={(index) => remove(index)}
            itemsLimit={10}
            setValue={setValue}
            BasicInfoSectionProps={BasicInfoSectionProps}
            OtherDealsSectionProps={OtherDealsSectionProps}
            imageInputTitle={imageInputTitle}
            reset={reset}
          />
        ))}
        <div className="mt-6 flex justify-end">
          <Button
            variant="text"
            size="small"
            onClick={() => append(personDefaultValues)}
            startIcon={<PersonAddAlt />}
          >
            Create another one
          </Button>
        </div>
      </div>
    </DealFormLayout>
  );
};

export default DealEditPersonMultiple;
