import { uniqBy } from "lodash";
import { UsersWithRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";

export const removeRolesDuplicates = (
  users: UsersWithRoles
): UsersWithRoles => {
  return users?.map(({ roles, ...rest }) => ({
    roles: uniqBy(roles, "roleId"),
    ...rest,
  }));
};
