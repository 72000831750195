import {
  formatCurrency,
  formatDate,
  FormatDateOptions,
  formatDuration,
  formatNumber,
  formatPhoneNumber,
} from "application/utils";
import { FormatType } from "domain/common";
import { isNil } from "lodash";
import React, { useCallback } from "react";
import { HTMLEditorFieldViewer } from "ui/components";

export type UseFormattedValueOptions = FormatDateOptions;

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 2,
});

export const useFormattedValue = () => {
  const format = useCallback(
    (value: any, type: FormatType, options?: UseFormattedValueOptions) => {
      if (isNil(value) && type === FormatType.Duration) return "-:-:-";
      if (isNil(value)) return "-";
      if (value === "") return "-";

      switch (type) {
        case FormatType.Number:
          return formatNumber(value);
        case FormatType.LongDate:
          return formatDate(value, {
            long: true,
            ...(options as FormatDateOptions),
          });
        case FormatType.Date:
          return formatDate(value, options as FormatDateOptions);
        case FormatType.Percent:
          return percentFormatter.format(value);
        case FormatType.Money:
          return formatCurrency(value);
        case FormatType.MoneyCompact:
          return formatCurrency(value, { compact: true });
        case FormatType.Duration:
          return formatDuration(value);
        case FormatType.Phone:
          return formatPhoneNumber(value);
        case FormatType.Markdown:
          return React.createElement(HTMLEditorFieldViewer, {
            htmlContent: value,
          });
        default:
          return value;
      }
    },
    []
  );

  return format;
};
