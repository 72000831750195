import { InvestorQualificationEnum } from "domain/authorization";
import { IRegistrationSteps, OnboardingUserData } from "types";
import {
  IAdminUserCreatePayload,
  ISyndicationPartnerUserGroupsResponse,
  ISyndicationPartnerUserGroupsUpdateRequest,
  IUpdateFundParticipationPayload,
  IUpdateParticipationPayload,
  IUpdateUserAdminEntity,
  IUserAdminEntity,
  IUserAvailableFeatures,
  IUserConfigurationOverride,
  IUserDealParticipationSummary,
} from "types/admin.service";
import { IGetInviteOverview } from "types/invite.service";
import { apiClient } from "./clients";
import { PaginationState } from "domain/common";
import { ParticipationRequestPayload } from "types/deal";

class AdminService {
  private readonly basePath = "/Admin";

  async getUserList(
    filter = "",
    paginationState: PaginationState = { pageNumber: 1, pageSize: 10 },
    partnerId?: string
  ): Promise<PaginatedResponse<IUserAdminEntity[]>> {
    const { data } = await apiClient.get(`${this.basePath}/users`, {
      params: { filter, ...paginationState },
      headers: {
        ...(partnerId && { "partner-id": partnerId }),
      },
    });

    return data;
  }

  async confirmUserByEmail(emailAddress: string, syndicationId: string) {
    const { data } = await apiClient.post(`${this.basePath}/confirm-user`, {
      emailAddress,
      syndicationId,
    });

    return data.response;
  }

  async createUser(
    payload: IAdminUserCreatePayload
  ): Promise<IUserAdminEntity> {
    const { data } = await apiClient.post(`${this.basePath}/users`, payload);

    return data.response;
  }

  async getUserProfile(customUsername: string): Promise<IUserAdminEntity> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/profile`
    );

    return data.response;
  }

  async getUserGroupsBySyndication(
    customUsername: string
  ): Promise<ISyndicationPartnerUserGroupsResponse[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/groups`
    );

    return data.response;
  }

  async getUserInvites(customUsername: string): Promise<IGetInviteOverview> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/invites`
    );

    return data.response;
  }

  async getAllUsersReport(): Promise<Blob> {
    const { data } = await apiClient.get(`${this.basePath}/report/users`, {
      responseType: "blob",
    });

    return data;
  }

  async downloadUserSignatureDocument(customUsername: string): Promise<Blob> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/profile/signature-document/download`,
      {
        responseType: "blob",
      }
    );

    return data;
  }

  async updateUserRegistrationSteps(
    customUsername: string,
    payload: RequireAtLeastOne<IRegistrationSteps>
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/registration-steps`,
      payload
    );

    return data;
  }

  async getUserInvestorQualifications(
    customUsername: string
  ): Promise<InvestorQualificationEnum[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/investor-qualifications`
    );

    return data;
  }

  async updateUserInvestorQualifications(
    customUsername: string,
    payload: InvestorQualificationEnum[]
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/investor-qualifications`,
      {
        investorQualifications: payload,
      }
    );

    return data;
  }

  async updateUserProfile(
    customUsername: string,
    payload: Partial<IUpdateUserAdminEntity>
  ): Promise<IUserAdminEntity> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/profile`,
      payload
    );

    return data.response;
  }

  async updateUserImage(
    formData: FormData,
    userCustomUsername: string
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${userCustomUsername}/image`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return data;
  }

  async getUserDealParticipationSummary(
    customUsername: string
  ): Promise<IUserDealParticipationSummary[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/participation-summary`
    );

    return data.response;
  }

  async createOrUpdateParticipationForUser(
    customUsername: string,
    dealId: string,
    payload: IUpdateParticipationPayload[]
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/fund/${dealId}/participation`,
      payload
    );

    return data;
  }

  async batchCreateOrUpdateParticipationForUser(
    customUsername: string,
    payload: IUpdateFundParticipationPayload[]
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/participation`,
      payload
    );

    return data;
  }

  async getParticipationForUserAndFund(
    customUsername: string,
    dealId: string
  ): Promise<ParticipationRequestPayload[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/fund/${dealId}/participation`
    );

    return data.response;
  }

  async updateUserGroups(
    partnerId: string,
    customUsername: string,
    payload: ISyndicationPartnerUserGroupsUpdateRequest
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/groups`,
      payload,
      { headers: { "partner-id": partnerId } }
    );

    return data;
  }

  async updateMainSyndication(
    customUsername: string,
    partnerId: string
  ): Promise<IUserAdminEntity> {
    const { data } = await apiClient.post(
      `${this.basePath}/users/${customUsername}/syndication/main`,
      null,
      { headers: { "partner-id": partnerId } }
    );

    return data.response;
  }

  async updateUserConfigurationOverride(
    customUsername: string,
    payload: Partial<IUserConfigurationOverride>
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/configuration-override`,
      payload
    );

    return data;
  }

  async updateUserAvailableFeatures(
    customUsername: string,
    payload: Partial<IUserAvailableFeatures>
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/features`,
      payload
    );

    return data;
  }

  async getUserOnboarding(customUsername: string): Promise<OnboardingUserData> {
    const { data } = await apiClient.get(
      `${this.basePath}/users/${customUsername}/onboarding`
    );

    return data.response;
  }

  async updateUserActiveStatus(
    customUsername: string,
    payload: { isActive: boolean }
  ): Promise<void> {
    const { data } = await apiClient.put(
      `${this.basePath}/users/${customUsername}/activate`,
      payload
    );

    return data;
  }
}

export default new AdminService();
