import { Check, CopyAll } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { delayAsync } from "application/utils";
import { FC, ReactElement, useCallback, useState } from "react";

interface CopyButtonProps extends Omit<IconButtonProps, "children"> {
  icon?: ReactElement;
  textToCopy: string;
  overrideCopyFn?: () => void;
}

export const CopyButton: FC<CopyButtonProps> = ({
  textToCopy,
  icon = <CopyAll />,
  overrideCopyFn,
  ...props
}) => {
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const handleClick = useCallback(async () => {
    await navigator.clipboard.writeText(textToCopy);
    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [textToCopy]);

  return (
    <Tooltip title={displayCopyFeedback ? "Copied!" : "Copy"}>
      <IconButton {...props} onClick={overrideCopyFn || handleClick}>
        {displayCopyFeedback ? <Check /> : icon}
      </IconButton>
    </Tooltip>
  );
};
