import defaultTheme from "tailwindcss/defaultTheme";

const preset = {
  theme: {
    screens: {
      xs: "360px",
      ...defaultTheme.screens,
    },
    extend: {
      colors: {
        error: "#F50057",
        primary: "var(--main-color)",
        "primary-0": "var(--main-color-0)",
        "primary-100": "var(--main-color-100)",
        "primary-200": "var(--main-color-200)",
        "primary-300": "var(--main-color-300)",
        "primary-400": "var(--main-color-400)",
        "primary-600": "var(--main-color-600)",
        secondary: "#F6FAFC",
        "secondary-0": "#FDF9E9",
        "secondary-100": "#EAE3CC",
        "secondary-200": "#D8C792",
        "secondary-300": "#C1AE73",
        "primary-aparawa": "#0D115C",
        "complementary-100": "#C1EBFC",
        "complementary-200": "#A0D9F0",
        "complementary-300": "#85C9E5",
        "complementary-400": "#6FB2CD",
        "danger-100": "#FDCCDD",
        "danger-200": "#FB99BC",
        "danger-300": "#F50057",
        "danger-600": "#930034",
        "gray-100": "#FCFCFD",
        "gray-150": "#F4F5F6",
        "gray-200": "#E6E8EC",
        "gray-300": "#B1B5C3",
        "gray-400": "#777E91",
        "gray-500": "#494E5B",
        "dark-text": "var(--text-color)",
        "semantic-green0": "#24CA7F1A",
        "semantic-green200": "#5BCF9B",
        "semantic-blue150": "#B5EAFF",
        "semantic-blue300": "#6CD4FF",
        "semantic-blue500": "#0AB8FF",
        "semantic-pink700": "#942A44",
        "semantic-pink300": "#FCDAE2",
        "semantic-orange150": "#FFEDE9",
        "semantic-orange300": "#FFDBD3",
        "semantic-orange500": "#FF4921",
        "semantic-orange700": "#992C14",
        "semantic-yellow150": "#FFECD0",
        "semantic-yellow300": "#FFC773",
        "semantic-yellow500": "#FFA115",
        "semantic-yellow900": "#664008",
      },
    },
  },
};

export default preset;
