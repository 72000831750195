import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { hideDisclosureModal } from "redux/UI/reducer";
import { MarkdownViewer } from "ui/components/MarkdownViewer";
import { useSyndicationLayout, useTypedSelector } from "ui/hooks";
import ModalComponent from "../ModalComponent";
import { defaultDisclosure } from "domain/policy";

export const DisclosureModal = () => {
  const { showDisclosureModal } = useTypedSelector((state) => state.UI);
  const syndicationLayout = useSyndicationLayout();
  const dispatch = useDispatch();
  const close = () => {
    dispatch(hideDisclosureModal());
  };

  return (
    <ModalComponent
      open={showDisclosureModal}
      onClose={close}
      title="Disclosure"
    >
      <div>
        <Typography component="p" variant="body2" className="text-gray-500">
          {syndicationLayout.isSyndicate &&
          syndicationLayout?.legalInfo?.disclosure ? (
            <MarkdownViewer>
              {syndicationLayout.legalInfo.disclosure}
            </MarkdownViewer>
          ) : (
            defaultDisclosure(syndicationLayout?.platformLegalName)
          )}
        </Typography>
        {/* <br /> */}
        {/* <Typography component="p" variant="caption">
          Broker-dealer services are provided by Finalis Securities LLC, a
          FINRA/SIPC member. For additional information, please see here.
          Emerge9 and Finalis Securities LLC are separate, unaffiliated entities
        </Typography> */}
      </div>
    </ModalComponent>
  );
};

export default DisclosureModal;
