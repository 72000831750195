import React from "react";
import { getPersonData } from "application/deal";
import PersonBlock from "../PersonBlock/PersonBlock";
import { useDealAdvisor, useDealPaths } from "ui/hooks";
import OtherDeals from "../OtherDeals/OtherDeals";
import { DetailSection } from "../DetailSection/DetailSection";

export const DealAdvisor = () => {
  const { editDealAdvisorPath: editPath } = useDealPaths();
  const { data } = useDealAdvisor();
  const person = React.useMemo(() => {
    if (data) {
      return getPersonData(data);
    }
    return null;
  }, [data]);

  return (
    <DetailSection title="Advisor" editRoute={editPath} isEmpty={!person}>
      {person && <PersonBlock {...person} />}
      {person?.formData?.deals && (
        <OtherDeals
          deals={person.formData.deals}
          from={person?.formData?.name}
        />
      )}
    </DetailSection>
  );
};

export default DealAdvisor;
