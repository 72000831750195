import { createSlice } from "@reduxjs/toolkit";

type RootConfigState = Record<string, unknown>;

const initialState: RootConfigState = {};

const rootConfigSlice = createSlice({
  name: "rootConfig",
  initialState,
  reducers: {
    resetAllStores() {
      //  ONLY FOR EXPORT ACTION TYPE
    },
  },
});

export const { resetAllStores } = rootConfigSlice.actions;

export default rootConfigSlice.reducer;
