import React, { FC } from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { Author as AuthorInterface } from "types/deal";
import Badge from "@mui/material/Badge";
import { Avatar, Chip } from "@mui/material";
import { getNameInitials } from "application/utils";
import { ISyndicationPartner } from "types/syndication.service";
import { useDealPermissions } from "ui/hooks";

interface AuthorDetailsProps {
  fullName: string;
  jobTitle: string;
}

export const AuthorDetails: FC<AuthorDetailsProps> = ({
  fullName = "Anonymous",
  jobTitle,
}) => (
  <div>
    <Typography className="font-bold text-gray-500" variant="body2">
      {fullName}
    </Typography>
    <Typography className="font-semibold text-gray-400" variant="caption2">
      {jobTitle}
    </Typography>
  </div>
);

export const AuthorAvatar: FC<
  AuthorInterface & React.ComponentPropsWithoutRef<"div">
> = ({
  onlineStatus,
  imageUrl,
  fullName = "Anonymous",
  jobTitle,
  className,
}) => (
  <Badge
    className={clsx(className, "mr-3")}
    overlap="circular"
    badgeContent=" "
    invisible={!onlineStatus}
    variant="dot"
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    classes={{ dot: "bg-primary-200" }}
  >
    <Avatar src={imageUrl} style={{ height: 44, width: 44 }} alt="User Avatar">
      {getNameInitials(fullName)}
    </Avatar>
  </Badge>
);

export const Author: FC<{
  author: AuthorInterface;
  partner?: ISyndicationPartner;
}> = ({ author, partner }) => {
  const { dealEdit } = useDealPermissions();
  const { fullName = "Anonymous", jobTitle } = author || {};
  const partnerName = partner?.shortName || "Emerge9";

  return (
    <div className="flex items-center">
      <AuthorAvatar {...author} />
      <AuthorDetails fullName={fullName} jobTitle={jobTitle} />
      {dealEdit && (
        <Chip
          className="ml-2"
          variant="outlined"
          size="small"
          avatar={<Avatar>{getNameInitials(partnerName)}</Avatar>}
          label={`From: ${partnerName}`}
        />
      )}
    </div>
  );
};

export default Author;
