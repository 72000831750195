import { DataRoomPermissionRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";
import { ColumnResizedEvent } from "ag-grid-community";
import { debounce } from "lodash";
import { Dispatch, SetStateAction } from "react";

export const compareRoles = (
  a: DataRoomPermissionRoles,
  b: DataRoomPermissionRoles
) =>
  a
    .map(({ roleId }) => roleId)
    .filter((roleId) => !b.map(({ roleId }) => roleId).includes(roleId));

export const debouncedWidth = (
  event: ColumnResizedEvent,
  setTake?: Dispatch<SetStateAction<number>>,
  isMounted?: boolean
) =>
  debounce(() => {
    if (!isMounted) return;
    if (event.columns && setTake) {
      const width = event?.columns?.[2]?.getActualWidth();
      if (width) {
        if (width > 190 && width < 300) {
          setTake(1);
        } else {
          setTake(width < 190 ? 0 : 2);
        }
      }
    }
  }, 1000);
