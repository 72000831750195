import React from "react";
import UserAvatar from "../UserAvatar";
import { useUserProfile } from "ui/hooks";

const CurrentUserAvatar: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const { data } = useUserProfile();

  return (
    <UserAvatar src={data?.imageUrl} {...props}>
      {data?.initials}
    </UserAvatar>
  );
};

export default CurrentUserAvatar;
