import { EditOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { format } from "date-fns";
import { FormatType } from "domain/common";
import { MediaStatus } from "domain/media";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { setCurrentMediaItem } from "redux/MediaPlayer/reducer";
import mediaService from "services/media.service";
import { IMediaItem } from "types/media.service";
import { ActionDescription, MenuButton } from "ui/components";
import { PlayButton } from "ui/components/Button/Button.play";
import { FormattedValue } from "ui/components/FormattedValue";
import { useErrorHandler, useGroupValidation } from "ui/hooks";
import { PODCAST_TYPES } from "ui/pages/Podcasts/contants";
import * as paths from "ui/Router/paths";

interface MediaCardProps {
  media: IMediaItem;
  hideOptions?: boolean;
}

export const MediaCard: FC<MediaCardProps> = ({ media, hideOptions }) => {
  const {
    id,
    fundAlias,
    status,
    title = "Title not found",
    duration = "Duration not found",
    type,
    createdAt,
    thumbnailImageUrl,
    hasMedia,
  } = media;
  const { isAdmin } = useGroupValidation();
  const [isLoadingItem, setLoadingItem] = useState(false);
  const { handleError } = useErrorHandler();
  const [isPublishing, setPublishing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isPublished = status === MediaStatus.Published;
  const isAudio = type === PODCAST_TYPES.AUDIO;
  const isDealPodcast = Boolean(fundAlias);

  const onPublishToggle = useCallback(async () => {
    setPublishing(true);

    try {
      if (isPublished) {
        return await mediaService.unpublishMedia(id);
      }

      return await mediaService.publishMedia(id);
    } catch (e) {
      handleError(e, "It was not possible to update the publish status");
    } finally {
      setPublishing(false);
    }
  }, [handleError, id, isPublished]);

  const onPlayClick = useCallback(async () => {
    if (!hasMedia) {
      enqueueSnackbar("Please request access to stream this podcast.", {
        title: "Private podcast",
        variant: "info",
        persist: true,
      });
      return;
    }

    setLoadingItem(true);
    try {
      await dispatch(setCurrentMediaItem(media));
    } catch (e) {
      handleError(e, "It was not possible to load the media content.");
    } finally {
      setLoadingItem(false);
    }
  }, [dispatch, enqueueSnackbar, handleError, hasMedia, media]);

  const editRoute = useMemo(() => {
    if (isDealPodcast) {
      return generatePath("/" + paths.editDealPodcast, {
        alias: fundAlias,
        mediaId: id,
      });
    }

    return generatePath("/" + paths.podcastEdit, { mediaId: id });
  }, [fundAlias, id, isDealPodcast]);

  const mediaButton = useMemo(() => {
    if (isAudio) {
      return <PlayButton loading={isLoadingItem} onClick={onPlayClick} />;
    }

    return (
      <div className="w-[88px] h-[68px] relative">
        <img
          src={thumbnailImageUrl}
          alt={`${title} podcast thumbnail`}
          className="w-full h-full object-cover"
        />
        <div className="absolute flex w-full h-full left-0 top-0 justify-center items-center">
          <PlayButton loading={isLoadingItem} onClick={onPlayClick} />
        </div>
      </div>
    );
  }, [isAudio, isLoadingItem, onPlayClick, thumbnailImageUrl, title]);

  return (
    <div className="flex overflow-hidden bg-white shadow-sm justify-between px-5 py-4 rounded-xl border border-solid border-primary-200 w-full">
      <div className="flex h-full space-x-4 items-center overflow-hidden">
        {mediaButton}
        <div className="grow truncate">
          <ActionDescription
            label={title || "No title"}
            description={
              <div>
                <b>
                  {hasMedia ? (
                    <FormattedValue
                      value={duration}
                      formatType={FormatType.Duration}
                    />
                  ) : (
                    "Private"
                  )}
                </b>{" "}
                | {format(new Date(createdAt), "MMM, dd yyyy")}
              </div>
            }
          />
        </div>
      </div>
      {isAdmin && !hideOptions && (
        <div className="flex items-start pl-4 space-y-1 md:space-y-0">
          <MenuButton
            transformOrigin={{ horizontal: "right", vertical: "center" }}
            size="small"
            items={[
              {
                label: "Edit",
                onClick: (e) => {
                  e.stopPropagation();
                  navigate(editRoute);
                },
                icon: <EditOutlined />,
              },
              {
                label: isPublished ? "Unpublish" : "Publish",
                onClick: (e) => {
                  e.stopPropagation();
                  onPublishToggle();
                },
                icon: isPublished ? <VisibilityOff /> : <Visibility />,
                loading: isPublishing,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
