import React from "react";
import Typography from "@mui/material/Typography";
import { Message as MessageInterface } from "types/deal";
import Author from "../Author";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { HTMLEditorFieldViewer } from "ui/components";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  ActionPanel?: React.ReactNode;
}

export const Message = ({
  className,
  ActionPanel,
  author,
  createdAt,
  text,
  sourcePartner,
}: Props & MessageInterface) => {
  const dateFromNow = React.useMemo(
    () => `${formatDistanceToNowStrict(parseISO(createdAt))} ago`,
    [createdAt]
  );

  return (
    <div className={className}>
      <div className="flex">
        <Author author={author} partner={sourcePartner} />
        <div className="grow flex justify-end items-center">
          <div className="flex flex-col items-end">
            {ActionPanel}
            <Typography className="text-gray-300" variant="caption2">
              {dateFromNow}
            </Typography>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <HTMLEditorFieldViewer htmlContent={text} />
      </div>
      {/* <Typography  className="text-gray-500 pt-5" variant="body2">
      </Typography> */}
    </div>
  );
};

export default Message;
