import { useCallback, useEffect } from "react";
import MetricService from "services/metric.service";
import { useLocation } from "react-router-dom";
import { useDealAlias, useGroupValidation } from "../index";
import { MetricEventType, MetricResourceType } from "domain/metric";

export default function useDealMetric(): { sendMetric: () => void } {
  const timeToTrackDuration = 30000;
  const { hasAdminLevel } = useGroupValidation();
  const alias = useDealAlias();

  const location = useLocation();

  const sendMetric = useCallback(
    async (fileName?: string) => {
      await MetricService.sendMetric({
        eventType: fileName
          ? MetricEventType.VIEW_DOCUMENT
          : MetricEventType.VIEW_PAGE,
        resource: MetricResourceType.FUND,
        resourceId: alias,
        path: location.pathname,
        ...(fileName && {
          metadata: {
            fileName,
          },
        }),
      });
    },
    [alias, location]
  );

  useEffect(() => {
    if (alias && !hasAdminLevel) {
      sendMetric();
    }
  }, [sendMetric, alias, hasAdminLevel]);

  useEffect(() => {
    if (!alias || hasAdminLevel) return;

    let metricTimer = setInterval(() => {
      sendMetric();
    }, timeToTrackDuration);

    let hidden: string | null = null;
    let visibilityChange: null | string = null;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
      // @ts-ignore-next-line
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
      // @ts-ignore-next-line
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    if (visibilityChange && hidden) {
      document.addEventListener(visibilityChange, function () {
        // @ts-ignore-next-line
        if (document[hidden]) {
          if (metricTimer) {
            clearInterval(metricTimer);
          }
        } else {
          metricTimer = setInterval(() => {
            sendMetric();
          }, timeToTrackDuration);
        }
      });
    }

    return () => clearInterval(metricTimer);
  }, [alias, hasAdminLevel, sendMetric]);

  return { sendMetric };
}
