import { isUndefined } from "lodash";
import syndicationService from "services/syndication.service";
import useSWR, { SWRConfiguration } from "swr";
import { ISyndicationPartner } from "types/syndication.service";

export const useSyndicationPartner = (
  partnerId: string,
  config?: SWRConfiguration<ISyndicationPartner>
) => {
  const swr = useSWR<ISyndicationPartner>(
    `/Syndication/partner/${partnerId}`,
    () => syndicationService.getPartner(partnerId),
    config
  );

  return { ...swr, loading: [swr.data, swr.error].every(isUndefined) };
};
