import React from "react";
import Typography from "@mui/material/Typography";
import IconMap from "./IconMap";
import DealHistoricalPerformanceImage from "./DealHistoricalPerformanceImage";

interface Props {
  type: "iconAndText" | "imageAndText";
  iconName: string;
  iconText: string;
  imageUrl: string;
}

const DealHistoricalPerformanceItemHeader = ({
  type,
  iconName,
  iconText,
  imageUrl,
}: Props) => {
  switch (type) {
    case "iconAndText": {
      return (
        <div className="h-[100px]">
          <div className="pb-9">
            <IconMap iconName={iconName} />
          </div>
          <Typography
            variant="body11"
            className="text-2xl font-bold text-gray-500"
          >
            {iconText}
          </Typography>
        </div>
      );
    }
    case "imageAndText": {
      return <DealHistoricalPerformanceImage imageUrl={imageUrl} />;
    }
    default: {
      return null;
    }
  }
};

export default DealHistoricalPerformanceItemHeader;
