import useSWR, { SWRConfiguration } from "swr";
import useDealId from "./useDealId";
import campaignService from "../../../services/campaign.service";
import isUndefined from "lodash/isUndefined";
import { useParams } from "react-router-dom";
import {
  ICampaign,
  ICampaignContactList,
  ICampaignContactListByPartner,
  ICampaignQuota,
} from "types/campaign.service";
import { useCallback, useMemo, useState } from "react";

export const useDealCommunication = (
  configuration?: SWRConfiguration<ICampaign[] | null>,
  partnerId?: string
) => {
  const dealId = useDealId();
  const [paginationIndex, setPaginationIndex] = useState<number>(-1);
  const [paginationTokens, setPaginationTokens] = useState<
    Record<number, string>
  >({});
  const { data, error, mutate } = useSWR<ICampaign[] | null>(
    [paginationIndex, partnerId, "/Campaign"],
    async (indexValue: number) => {
      const response = await campaignService.getCampaigns({
        params: {
          fundId: dealId,
          lastEvaluatedKey: paginationTokens[paginationIndex],
        },
        headers: {
          ...(partnerId && { "partner-id": partnerId }),
        },
      });

      if (
        typeof paginationTokens[indexValue] === "string" ||
        indexValue === -1
      ) {
        setPaginationTokens((oldValue) => ({
          ...oldValue,
          [indexValue + 1]: response?.lastEvaluatedKey as string,
        }));
      }

      return response.items;
    },
    configuration
  );

  const loading = [data, error].every(isUndefined);

  const getNextPage = useCallback(
    () => setPaginationIndex((value) => value + 1),
    []
  );

  const getPreviousPage = useCallback(
    () => setPaginationIndex((value) => value - 1),
    []
  );

  const hasNextPage = useMemo(() => {
    const nextIndex = paginationIndex + 1;
    return Boolean(paginationTokens[nextIndex]);
  }, [paginationIndex, paginationTokens]);
  const hasPreviousPage = useMemo(() => {
    const previousIndex = paginationIndex - 1;

    return Boolean(previousIndex === -1 || paginationTokens[previousIndex]);
  }, [paginationIndex, paginationTokens]);

  return {
    data,
    error,
    mutate,
    loading,
    getNextPage,
    getPreviousPage,
    hasNextPage,
    hasPreviousPage,
  };
};

export const useCampaignId = () => {
  const { campaignId } = useParams();

  return campaignId as string;
};

export const useCampaignItem = (
  configuration?: SWRConfiguration<ICampaign>,
  forTenant?: string
) => {
  const campaignId = useCampaignId();
  const { data, error, mutate } = useSWR<ICampaign>(
    `/Campaign/${campaignId}?forTenant=${forTenant}`,
    () => campaignService.getCampaignById(campaignId, forTenant),
    configuration
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};

export const useCampaignContactList = (
  config?: SWRConfiguration<ICampaignContactList[]>
) => {
  const { data, error, mutate } = useSWR<ICampaignContactList[]>(
    `/Campaign/campaign-list`,
    () => campaignService.getContactList(),
    config
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};

export const useCampaignQuota = (
  partnerId?: string,
  config?: SWRConfiguration<ICampaignQuota>
) => {
  const { data, error, mutate } = useSWR<ICampaignQuota>(
    `/Campaign/quota`,
    () => campaignService.getCampaignQuota(partnerId),
    config
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};

export const useAllContactLists = (
  attachedDealIds?: string[],
  config?: SWRConfiguration<ICampaignContactListByPartner[]>
) => {
  const { data, error, mutate, isValidating } = useSWR<
    ICampaignContactListByPartner[]
  >(
    [attachedDealIds, `/Campaign/campaign-list/available`],
    () => campaignService.getAllContactLists(attachedDealIds),
    config
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
    isValidating,
  };
};
