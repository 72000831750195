import {
  DataRoomPermissionResponse,
  DataRoomPermissions,
  DataRoomRoles,
  UserWithRoles,
} from "ui/pages/DealDataRoom/DealDataRoom.types";
import { baseApi } from "./BaseApi";
import { removeRolesDuplicates } from "./DataroomPermissionsApi.utils";
import { ResourceType } from "types/resource.service";

interface DataRoomBaseArgs {
  resourceId: string;
  type: ResourceType;
}

interface GetFilePermissionsArgs extends DataRoomBaseArgs {
  itemName: string;
  path: string;
}

export interface PermissionToRoleArgs extends GetFilePermissionsArgs {
  permissionId: string;
  roleId: string;
}

interface CreateRoleArgs extends DataRoomBaseArgs {
  roleName: string;
}

interface RoleArgs extends DataRoomBaseArgs {
  roleId: string;
}

interface RoleArgsWithIds extends RoleArgs {
  usersIds: string[];
}

interface RoleArgsWithId extends RoleArgs {
  userId: string;
}

export type PaginatedUsersWithRoles = Omit<
  PaginatedTokenResponse<UserWithRoles>,
  "pageSize" | "totalItems"
>;

type PaginatedUsersWithRolesArgs = Partial<PaginatedTokenQuery> &
  DataRoomBaseArgs;

const dataRoomPermissionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFilePermissions: build.query<
      DataRoomPermissions,
      GetFilePermissionsArgs
    >({
      query: ({ resourceId, itemName, path, type }) => {
        const url = `${type}/${resourceId}/dataroom/management/item/permissions`;
        const params = { itemName, path };
        return { url, params };
      },
      providesTags: ["DataRoomPermissions"],
      transformResponse: ({
        response,
      }: GenericResponse<DataRoomPermissionResponse>) => response?.permissions,
    }),
    getRoles: build.query<DataRoomRoles, DataRoomBaseArgs>({
      query: ({ resourceId, type }) => {
        const url = `${type}/${resourceId}/dataroom/management/roles`;
        return { url };
      },
      providesTags: ["DataRoomGetRoles"],
      transformResponse: ({ response }: GenericResponse<DataRoomRoles>) =>
        response,
    }),
    createRole: build.mutation<unknown, CreateRoleArgs>({
      query: ({ resourceId, roleName, type }) => {
        const url = `${type}/${resourceId}/dataroom/management/roles`;
        const data = { roleName };
        return { url, method: "POST", data };
      },
      invalidatesTags: ["DataRoomGetRoles"],
    }),
    removeRole: build.mutation<unknown, RoleArgs>({
      query: ({ resourceId, roleId, type }) => {
        const url = `${type}/${resourceId}/dataroom/management/roles/${roleId}`;
        return { url, method: "DELETE" };
      },
      invalidatesTags: ["DataRoomGetRoles"],
    }),
    addPermissionToRole: build.mutation<unknown, PermissionToRoleArgs>({
      query: ({ resourceId, permissionId, roleId, itemName, path, type }) => ({
        url: `${type}/${resourceId}/dataroom/management/item/permissions/${permissionId}/roles`,
        method: "POST",
        data: { roleId: [roleId] },
        params: { itemName, path },
      }),
      invalidatesTags: ["DataRoomPermissions"],
    }),
    removePermissionFromRole: build.mutation<unknown, PermissionToRoleArgs>({
      query: ({ resourceId, permissionId, roleId, itemName, path, type }) => ({
        url: `${type}/${resourceId}/dataroom/management/item/permissions/${permissionId}/roles/${roleId}`,
        method: "DELETE",
        params: { itemName, path },
      }),
      invalidatesTags: ["DataRoomPermissions"],
    }),
    getUsersWithRoles: build.query<
      PaginatedUsersWithRoles,
      PaginatedUsersWithRolesArgs
    >({
      query: ({ resourceId, pageSize = 10, lastEvaluatedKey, type }) => ({
        url: `${type}/${resourceId}/dataroom/management/users`,
        params: { pageSize, lastEvaluatedKey },
      }),
      providesTags: ["GetUsersWithRoles"],
      transformResponse: ({
        response,
      }: GenericResponse<PaginatedUsersWithRoles>) => {
        if (response && response.items) {
          const { items, ...rest } = response;
          return {
            items: removeRolesDuplicates(items),
            ...rest,
          };
        } else {
          return response;
        }
      },
    }),
    addRoleToUser: build.mutation<unknown, RoleArgsWithIds>({
      query: ({ resourceId, roleId, usersIds, type }) => ({
        url: `${type}/${resourceId}/dataroom/management/roles/${roleId}/users`,
        data: { usersIds },
        method: "POST",
      }),
      invalidatesTags: ["GetUsersWithRoles"],
    }),
    removeRoleFromUser: build.mutation<unknown, RoleArgsWithId>({
      query: ({ resourceId, roleId, userId, type }) => ({
        url: `${type}/${resourceId}/dataroom/management/roles/${roleId}/users/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetUsersWithRoles"],
    }),
  }),
});

export const {
  useGetFilePermissionsQuery,
  useGetRolesQuery,
  useAddPermissionToRoleMutation,
  useRemovePermissionFromRoleMutation,
  useCreateRoleMutation,
  useRemoveRoleMutation,
  useGetUsersWithRolesQuery,
  useAddRoleToUserMutation,
  useRemoveRoleFromUserMutation,
} = dataRoomPermissionsApi;
