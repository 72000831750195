import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import EmailSubscriptionService from "services/emailSubscription.service";
import { useErrorHandler } from "../../hooks";
import { FormInputWrapper } from "../FormInputWrapper";
import resolver from "./NewsLetterSubscribe.validation";

interface FormInputs {
  email: string;
}

export const NewsLetterSubscribe = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const [submitting, setSubmitting] = useState(false);

  const { control, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues: {
      email: "",
    },
    resolver,
  });

  const onSubmit = useCallback<SubmitHandler<FormInputs>>(
    async ({ email }) => {
      setSubmitting(true);

      try {
        if (email) {
          await EmailSubscriptionService.subscribeToNewsletter(email);

          enqueueSnackbar("Successfully subscribed to newsletter", {
            title: "Approved!",
            variant: "success",
          });
        }
        reset();
      } catch (e) {
        handleError(e, "It was not possible to subscribe");
      } finally {
        setSubmitting(false);
      }
    },
    [enqueueSnackbar, handleError, reset]
  );

  return (
    <form className="w-full mt-6" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <FormInputWrapper
            errorMessage={fieldState?.error?.message}
            helperText={fieldState?.error?.message ? "" : <>&nbsp;</>}
          >
            <Input
              {...field}
              error={Boolean(fieldState?.error)}
              className="h-10 rounded-3xl w-full font-normal mt-0 py-0"
              placeholder="Enter your email"
              disableUnderline
              disabled={submitting}
              inputProps={{ className: "pr-2" }}
              endAdornment={
                <IconButton
                  disabled={submitting}
                  type="submit"
                  color="primary"
                  className="p-1 mr-[-5px] bg-primary text-white hover:bg-primary-400 active:bg-primary disabled:bg-primary-100"
                >
                  <ArrowRightAltIcon style={{ color: "#fff" }} />
                </IconButton>
              }
            />
          </FormInputWrapper>
        )}
      />
    </form>
  );
};
