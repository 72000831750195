import { isUndefined } from "lodash";
import dealService from "services/deal.service";
import useSWR, { SWRConfiguration } from "swr";
import { IMedia } from "types/media.service";
import useDealId from "./useDealId";
import { useGroupValidation } from "../useGroupValidation";

export const useDealMedia = (
  configuration?: SWRConfiguration<IMedia | null>
) => {
  // Temporary solution
  // https://emerge9.atlassian.net/browse/EM-899
  // useDealMedia fetch data only for Admin group
  const { isAdmin } = useGroupValidation();
  const dealId = useDealId();
  const { data, error, mutate } = useSWR<IMedia | null>(
    isAdmin ? `/fund/${dealId}/media` : null,
    () => dealService.getDealMedia(dealId),
    configuration
  );
  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};
