import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { useProjectAlias } from "./useProjectAlias";
import projectService from "services/project.service";
import { IProject } from "types/project.service";
import { isUndefined } from "lodash";

export const useCurrentProject = (
  config?: SWRConfiguration<IProject>
): SWRResponse<IProject> & { loading: boolean } => {
  const alias = useProjectAlias();

  const { isValidating, data, mutate, error } = useSWR(
    `/project/${alias}`,
    () => {
      return projectService.getProject(alias);
    },
    config
  );

  return {
    isValidating,
    data,
    mutate,
    error,
    loading: [data, error].every(isUndefined),
  };
};
