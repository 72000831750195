import React, { useCallback } from "react";
import useSWR from "swr";
import { useDealPermissions } from "ui/hooks";
import { getDealGeneralData } from "application/deal";
import DealService from "services/deal.service";
import useDealAlias from "./useDealAlias";

export default function useDealGeneralData(overwriteDealId?: string) {
  const alias = useDealAlias();
  const dealId = overwriteDealId || alias || "";

  const { dealView, mutate: mutatePermissions } =
    useDealPermissions(overwriteDealId);

  const singleDeal = dealId && dealView;

  const {
    data: singleDealData,
    mutate: mutateSingleDeal,
    ...getSingleDealRest
  } = useSWR(
    () => singleDeal && `fund/${dealId}`,
    () => DealService.getSingleDeal(dealId)
  );

  const data = React.useMemo(() => {
    if (singleDealData) {
      return getDealGeneralData(singleDealData.response);
    }
    return;
  }, [singleDealData]);

  const loading = !singleDealData;
  const error = getSingleDealRest.error;
  const isValidating = getSingleDealRest.isValidating;

  const mutate = useCallback(async () => {
    await mutatePermissions();
    await mutateSingleDeal();
  }, [mutatePermissions, mutateSingleDeal]);

  return {
    data,
    loading,
    error,
    isValidating,
    mutate,
    mutateDeal: mutateSingleDeal,
  };
}
