import React, { forwardRef } from "react";
import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox";
import UncheckedIcon from "./UncheckedIcon";
import CheckedIcon from "./CheckedIcon";

const Checkbox = forwardRef<
  HTMLButtonElement,
  CheckboxProps & React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <CheckboxMui
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      disableRipple
      {...props}
      ref={ref}
    />
  );
});

export default Checkbox;
