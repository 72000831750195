import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import DealHistoricalPerformanceItemHeader from "./DealHistoricalPerformanceItemHeader";
import { DealHistoricalPerformanceItemProps } from "types/deal";
import { DividerDashed } from "ui/components";

const DealHistoricalPerformanceItem = ({
  className,
  iconName,
  iconText,
  title,
  text,
  type,
  imageUrl,
}: DealHistoricalPerformanceItemProps &
  React.ComponentPropsWithoutRef<"div">) => {
  const headerProps = {
    type,
    imageUrl,
    iconName,
    iconText,
  };

  return (
    <div
      className={clsx(
        className,
        "flex flex-col space-y-5 p-6 min-w-[200px] grow"
      )}
    >
      <DealHistoricalPerformanceItemHeader {...headerProps} />
      <DividerDashed />
      <Typography
        component="h3"
        variant="caption2"
        className="block font-sans font-semibold text-gray-500"
      >
        {title}
      </Typography>
      <Typography
        component="p"
        variant="caption2"
        className="block font-sans font-semibold text-gray-400"
      >
        {text}
      </Typography>
    </div>
  );
};

export default DealHistoricalPerformanceItem;
