import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { DividerDashed, LabeledValue } from "ui/components";
import { useDealKeyHighlights, useDealPaths } from "ui/hooks";
import chunk from "lodash/chunk";
import { DetailSection } from "../DetailSection/DetailSection";
import { useRef } from "react";

const DealKeyHighlights = () => {
  const { editDealKeyHighlightsPath: editPath } = useDealPaths();
  const { data } = useDealKeyHighlights();
  const ref = useRef(null);

  const { description, items = [] } = data || {};
  const itemsChunks = chunk(items, 2);

  return (
    <>
      <DetailSection
        title="Key Highlights"
        getCopyContent={async () => ref?.current as any as HTMLElement}
        editRoute={editPath}
        isEmpty={!data}
      >
        {description && (
          <Typography
            component="p"
            variant="body2"
            className="text-gray-500 block pb-4"
          >
            {description}
          </Typography>
        )}
        {itemsChunks.length > 0 && (
          <Stack
            className="m9CardSurface w-full py-5 px-6"
            spacing={2.5}
            divider={<DividerDashed />}
          >
            {itemsChunks.map((chunkEntry, index) => (
              <div key={index} className="grid grid-cols-[1fr_41px_1fr]">
                {chunkEntry?.[0] && (
                  <LabeledValue
                    label={chunkEntry[0].label}
                    value={chunkEntry[0].value}
                    labelProps={{
                      variant: "caption2",
                      component: "h5",
                      className: "font-semibold text-gray-300",
                    }}
                    valueProps={{
                      variant: "body2",
                      component: "h4",
                      className: "!font-bold text-gray-500",
                    }}
                  />
                )}
                <div className="flex justify-center">
                  <DividerDashed vertical />
                </div>
                {chunkEntry?.[1] && (
                  <LabeledValue
                    label={chunkEntry[1].label}
                    value={chunkEntry[1].value}
                    labelProps={{
                      variant: "caption2",
                      component: "h5",
                      className: "font-semibold text-gray-300",
                    }}
                    valueProps={{
                      variant: "body2",
                      component: "h4",
                      className: "!font-bold text-gray-500",
                    }}
                  />
                )}
              </div>
            ))}
          </Stack>
        )}
        <div className="hidden">
          <div ref={ref}>
            {description && (
              <Typography
                component="p"
                variant="body2"
                className="text-gray-500 block pb-4"
              >
                {description}
              </Typography>
            )}
            <div className="m9CardSurface w-[650px] w-max-full px-6">
              <table className="w-full border-collapse table-fixed">
                <tbody>
                  {itemsChunks.map((chunkEntry, index) => (
                    <tr
                      key={index}
                      className={
                        index < itemsChunks.length - 1
                          ? "border-0 border-b border-dashed border-gray-200"
                          : ""
                      }
                    >
                      <td className="pr-5 py-5 border-0 border-r border-dashed border-gray-200 w-1/2">
                        <Typography
                          variant="caption2"
                          component="h5"
                          className="font-semibold text-gray-300"
                        >
                          {chunkEntry[0].label}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="h4"
                          className="font-bold text-gray-500"
                        >
                          {chunkEntry[0].value}
                        </Typography>
                      </td>
                      <td className="pl-5 py-5">
                        {chunkEntry?.[1] && (
                          <>
                            <Typography
                              variant="caption2"
                              component="h5"
                              className="font-semibold text-gray-300"
                            >
                              {chunkEntry[1].label}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="h4"
                              className="font-bold text-gray-500"
                            >
                              {chunkEntry[1].value}
                            </Typography>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DetailSection>
    </>
  );
};

export default DealKeyHighlights;
