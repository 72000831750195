import React from "react";
import NavigationItem, { NavigationItemProps } from "./NavigationItem";

interface Props {
  items: NavigationItemProps[];
}

export const Navigation = ({ items }: Props) => {
  return (
    <div className="grid grid-flow-row sm:grid-cols-2 md:grid-cols-3 lg:grid-flow-col lg:grid-cols-6 gap-8 md:gap-12">
      {Boolean(items.length) &&
        items.map((item, index) => <NavigationItem key={index} {...item} />)}
    </div>
  );
};

export default Navigation;
