import React from "react";
import Avatar from "@mui/material/Avatar";

interface UserAvatarProps {
  src?: string;
  children?: string;
  sizes?: string;
}

const UserAvatar: React.FC<
  UserAvatarProps & React.HTMLAttributes<HTMLDivElement>
> = ({ src, children, sizes, ...props }) => {
  if (children) {
    return (
      <Avatar sizes={sizes} src={src} {...props}>
        {children}
      </Avatar>
    );
  }

  return <Avatar src={src} {...props} />;
};

export default UserAvatar;
