import { DealCampaignStepEnum } from "./DealEditCampaignForm.enums";
import * as paths from "ui/Router/paths";
import { useCallback, useMemo } from "react";
import { DEAL_CAMPAIGN_STEP_ORDER } from "./DealEditCampaignForm.domain";
import { generatePath, useNavigate } from "react-router-dom";
import { useErrorHandler } from "ui/hooks";
import { useCampaignId } from "ui/hooks/Deal/useDealCommunication";
import { isNil } from "lodash";
import useDealAlias from "ui/hooks/Deal/useDealAlias";

const generalStepRoutes = {
  [DealCampaignStepEnum.Create]: paths.campaignEdit,
  [DealCampaignStepEnum.Summary]: paths.campaignEdiSummary,
};

const dealStepRoutes = {
  [DealCampaignStepEnum.Create]: paths.editDealCampaignWithCampaignId,
  [DealCampaignStepEnum.Summary]: paths.editDealCampaignSummary,
};

export const useDealCampaignFormUtils = (currentStep: DealCampaignStepEnum) => {
  const dealAlias = useDealAlias();
  const campaignId = useCampaignId();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const isDeal = useMemo(() => !isNil(dealAlias), [dealAlias]);

  const defaultRoute = useMemo(() => {
    if (isDeal) {
      return paths.dealCampaigns;
    }

    return paths.campaigns;
  }, [isDeal]);

  const getParsedRoute = useCallback(
    (route: string, overwriteCampaignId?: string) => {
      return generatePath("/" + route, {
        campaignId: overwriteCampaignId || campaignId || "",
        id: dealAlias || "",
        alias: dealAlias,
      });
    },
    [dealAlias, campaignId]
  );

  const getRouteByIncrement = useCallback(
    (increment: number) => {
      const selectedStepIndex =
        DEAL_CAMPAIGN_STEP_ORDER.indexOf(currentStep) + increment;
      const selectedStep = DEAL_CAMPAIGN_STEP_ORDER[selectedStepIndex];

      if (isDeal) {
        return dealStepRoutes[selectedStep] || defaultRoute;
      }

      return generalStepRoutes[selectedStep] || defaultRoute;
    },
    [currentStep, defaultRoute, isDeal]
  );

  const nextRoute = useMemo(
    () => getRouteByIncrement(1),
    [getRouteByIncrement]
  );

  const previousRoute = useMemo(
    () => getRouteByIncrement(-1),
    [getRouteByIncrement]
  );

  const parsedNextRoute = useMemo(
    () => getParsedRoute(nextRoute),
    [getParsedRoute, nextRoute]
  );

  const parsedPreviousRoute = useMemo(
    () => getParsedRoute(previousRoute),
    [getParsedRoute, previousRoute]
  );

  const navigateToNextRoute = useCallback(
    (campaignId?: string) => {
      navigate(getParsedRoute(nextRoute, campaignId));
    },
    [getParsedRoute, navigate, nextRoute]
  );

  const navigateToPreviousRoute = useCallback(
    (campaignId?: string) => {
      navigate(getParsedRoute(previousRoute, campaignId));
    },
    [getParsedRoute, navigate, previousRoute]
  );

  const onError = (error: unknown) => {
    handleError(error, "It was not possible to get the campaigns data.");
    navigate(generatePath("/" + defaultRoute, { alias: dealAlias }));
  };

  return {
    parsedNextRoute,
    parsedPreviousRoute,
    navigateToNextRoute,
    navigateToPreviousRoute,
    onError,
  };
};
