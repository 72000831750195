import { AssistantAttachableField } from "domain/assistant";
import React from "react";
import dealService from "services/deal.service";
import { DealEditPersonMultiple } from "ui/components/DealEditPersonMultiple";
import { useDealLeadInvestor } from "ui/hooks";
import { DealCreateOrEditPage } from "ui/pages/Form";

export const DealEditPersonLeadInvestor: React.FC<DealCreateOrEditPage> = (
  props
) => {
  return (
    <DealEditPersonMultiple
      assistantFieldId={AssistantAttachableField.LeadInvestorItem}
      title="Lead investor"
      BasicInfoSectionProps={{
        inputLabels: {
          name: "Lead Investor Name",
          url: "Lead Investor URL",
          description: "Description",
        },
        descriptionPlaceholder: "About the lead investor in a few words",
      }}
      OtherDealsSectionProps={{
        title: "Other deals by the lead investor",
        selectPlaceholder: "Deals from the lead investor",
      }}
      fetchPersonDataHook={useDealLeadInvestor}
      imageInputTitle="Lead investor image"
      stepName="leadInvestor"
      updateDealPersonCallback={dealService.updateLeadInvestors}
      {...props}
    />
  );
};

export default DealEditPersonLeadInvestor;
