import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { ReactComponent as IconArrowRightSVG } from "assets/svg/arrow-right-symbol.svg";
import { FC } from "react";

export const NextButton: FC<LoadingButtonProps> = ({
  children = "Next",
  ...props
}) => {
  return (
    <LoadingButton endIcon={<IconArrowRightSVG />} {...props}>
      {children}
    </LoadingButton>
  );
};
