import React, { FC } from "react";
import numeral from "numeral";
import { LabeledValue } from "ui/components";
import { LabeledValueProps } from "../../LabeledValue/LabeledValue";
import { useLookupEnums } from "ui/hooks";
import { getCurrencySymbol } from "application/utils";

interface Props {
  leadInvestor?: string;
  fundSize: number;
  minContribution: number;
  sector?: string[] | string;
  dealType?: string[] | string;
  defaultCurrency: string;
}

const LabeledValueWithTruncate: FC<LabeledValueProps> = (props) => (
  <LabeledValue
    {...props}
    valueProps={{
      className: "whitespace-nowrap overflow-hidden text-ellipsis",
    }}
  />
);

const DEFAULT_VALUE = "TBD";

const DealSummary = ({
  leadInvestor,
  fundSize,
  minContribution,
  sector,
  dealType,
  defaultCurrency,
}: Props) => {
  const { getSectorNameFromId, getDealTypeNameFromId } = useLookupEnums();
  const [_dealType, _sectorMap] = React.useMemo(() => {
    const _sectorMap = Array.isArray(sector)
      ? sector.map(getSectorNameFromId)
      : null;
    const _dealType = Array.isArray(dealType)
      ? dealType.map(getDealTypeNameFromId)
      : null;

    return [_dealType, _sectorMap];
  }, [dealType, getDealTypeNameFromId, getSectorNameFromId, sector]);

  return (
    <div className="p-4 bg-gray-150 flex flex-col space-y-4 rounded-xl">
      {leadInvestor && (
        <div>
          <LabeledValueWithTruncate
            label="Lead Investor"
            value={leadInvestor}
          />
        </div>
      )}
      <div className="columns-2 gap-1.5">
        <LabeledValueWithTruncate
          label="Deal size"
          value={
            fundSize
              ? `${getCurrencySymbol(defaultCurrency)}${numeral(
                  fundSize
                ).format("0a")}`
              : DEFAULT_VALUE
          }
        />
        <LabeledValueWithTruncate
          noWrapTitle
          label="Min. contribution"
          value={
            minContribution
              ? `${getCurrencySymbol(defaultCurrency)}${numeral(
                  minContribution
                ).format("0a")}`
              : DEFAULT_VALUE
          }
        />
      </div>
      <div className="columns-2 gap-1.5">
        <LabeledValueWithTruncate
          label="Sector"
          value={_sectorMap?.length ? _sectorMap : DEFAULT_VALUE}
        />
        <LabeledValueWithTruncate
          label="Type"
          value={_dealType?.length ? _dealType : DEFAULT_VALUE}
        />
      </div>
    </div>
  );
};

export default DealSummary;
