export const defaultDisclosure = (syndicationPartnerAlias = "Emerge9") => {
  const intro =
    syndicationPartnerAlias === "Emerge9"
      ? `This site is operated by Emerge9, Inc. ("Emerge9"), Delaware
  corporation.`
      : "";
  const disclosure = `${syndicationPartnerAlias} does not make investment recommendations for
  any securities listed on this website or other company
  communications. Alternative investments in private assets funds,
  direct securities or otherwise are generally illiquid and involve
  significant risk. There is no guarantee investors will recoup their
  investment and the entirety of the principal is at risk. It is the
  responsibility of any investor to carefully review relevant deal
  documents and consider all associated risks, costs, and expenses
  before making an investment. Private asset valuations reflect
  restricted membership interest in underlying assets that are
  typically subject to holding periods with limited liquidity access,
  typically for 5 years or more.`;

  return `${intro} ${disclosure}`.trim();
};
