import { FeedbackError, PermissionError } from "application/errors";
import { Close, Replay } from "@mui/icons-material";
import React, { ReactElement } from "react";
import { NavigateFunction } from "react-router-dom";
import { SlimFormPageLayout } from "../PageLayout/SlimFormPageLayout";
import { ResultScreen } from "../ResultScreen";
import * as Sentry from "@sentry/react";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: { title: string; message: string; retryable: boolean };
}

interface ErrorBoundaryProps {
  navigate: NavigateFunction;
  children?: ReactElement | ReactElement[];
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError = (
    error: Error | FeedbackError | PermissionError
  ) => {
    if (error instanceof PermissionError) {
      return {
        hasError: true,
        error: {
          message: error.message,
          title: "Not Allowed",
        },
      } as ErrorBoundaryState;
    }

    if (error instanceof FeedbackError) {
      return {
        hasError: true,
        error: {
          message: error.message,
          title: "Something went wrong",
        },
      } as ErrorBoundaryState;
    }

    return {
      hasError: true,
      error: {
        message:
          "The application have experienced an unexpected error. Please try again later.",
        title: "Something went wrong",
      },
    } as ErrorBoundaryState;
  };

  recoverFromError = () => {
    this.setState({ hasError: false });
  };

  goBack = () => {
    this.props.navigate(-1);
    setTimeout(() => {
      this.recoverFromError();
    }, 100);
  };

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      const isRetryable = this.state.error?.retryable;

      return (
        <div className="flex flex-col grow relative">
          <SlimFormPageLayout>
            <ResultScreen
              title={this.state.error?.title}
              subtitle={this.state.error?.message}
              variant="error"
              button={{
                onSubmit: isRetryable ? this.recoverFromError : this.goBack,
                icon: isRetryable ? <Replay /> : <Close />,
                title: isRetryable ? "Reload" : "Go back",
              }}
            />
          </SlimFormPageLayout>
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
