import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as IconCircleClose } from "assets/svg/icon-circle-close.svg";

interface Props {
  children: string;
  onClose: () => void;
}

const DialogTitle = ({ children, onClose }: Props) => {
  return (
    <div className="flex items-center p-0">
      <Typography
        variant="h4"
        className="text-serif font-medium text-primary-600 grow"
      >
        {children}
      </Typography>
      <IconButton className="p-0" onClick={onClose}>
        <IconCircleClose />
      </IconButton>
    </div>
  );
};

export default DialogTitle;
