import { Typography } from "@mui/material";
import { FC } from "react";
import { BetaTag } from "../BetaTag";

interface ActionDescriptionProps {
  label: string;
  description: string | React.ReactElement;
  beta?: boolean;
}

export const ActionDescription: FC<ActionDescriptionProps> = ({
  description,
  label,
  beta,
}) => {
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex items-center space-x-2">
        <Typography
          variant="body2"
          className="font-bold block text-primary truncate"
        >
          {label}
        </Typography>
        {beta && <BetaTag />}
      </div>
      <Typography variant="caption" className="text-gray-400 block truncate">
        {description}
      </Typography>
    </div>
  );
};
