import { isUndefined } from "lodash";
import syndicationService from "services/syndication.service";
import useSWR, { SWRConfiguration } from "swr";
import { ISyndicationPartnerAddress } from "types/syndication.service";

export const useSyndicationPartnerAddress = (
  partnerId: string,
  config?: SWRConfiguration<ISyndicationPartnerAddress>
) => {
  const swr = useSWR<ISyndicationPartnerAddress>(
    `/Syndication/partner/${partnerId}/company/address`,
    () => syndicationService.getSyndicationPartnerAddress(partnerId),
    config
  );

  return { ...swr, loading: [swr.data, swr.error].every(isUndefined) };
};
