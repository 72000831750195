import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { SocialNetworkName } from "domain/common";
import { FC } from "react";
import { IOrganization } from "types/organization.service";
import { useLookupEnums } from "ui/hooks";
import CategoryChip from "../CategoryChip/CategoryChip";
import { DealCardTitle } from "../DealCard/components/DealCardTitle";
import DealLogo from "../DealCard/components/DealLogo";
import { SocialItemWrapper } from "./CompanyCard.components";

export interface CompanyCardProps {
  company?: IOrganization;
  dealCardClassName?: string;
  onFollowClick: () => void | Promise<void>;
  isFollowLoading?: boolean;
}

const CompanyCard: FC<CompanyCardProps> = ({
  dealCardClassName,
  company,
  onFollowClick,
  isFollowLoading,
}) => {
  const { getSectorNameFromId } = useLookupEnums();
  const { imageUrl, name, sector, website } = company || {};

  if (!company) {
    return null;
  }

  return (
    <Paper className="rounded-xl shadow-depth-2">
      <div
        className={clsx(
          dealCardClassName,
          "m9-deal-card relative flex flex-col h-full p-8"
        )}
      >
        <div className="flex flex-col">
          <DealLogo size="large" imageUrl={imageUrl} />
          {company && (
            <div className="mt-2">
              <DealCardTitle
                size="large"
                dealName={name || ""}
                dealOwnerName={company.title}
              />
            </div>
          )}
        </div>

        <div className="flex justify-start mt-4">
          {Object.keys(company.socialNetworks || {}).map((key, index) => (
            <SocialItemWrapper
              networkName={key as SocialNetworkName}
              linkTo={company.socialNetworks[key as SocialNetworkName]}
              key={index}
            />
          ))}
        </div>
        <div className="flex flex-col items-start mt-8">
          <Typography
            component="span"
            variant="hairline2"
            className="text-gray-300 block uppercase mb-2"
          >
            Sector
          </Typography>
          <CategoryChip>{getSectorNameFromId(sector as string)}</CategoryChip>
        </div>

        {/* <div className="flex flex-col items-start mb-7">
          <AvatarGroup total={20} className="mb-3">
            <Avatar
              alt="Remy Sharp"
              src="https://docs.nestjs.com/assets/logo-small.svg"
            />
            <Avatar
              alt="Travis Howard"
              src="https://docs.nestjs.com/assets/logo-small.svg"
            />
            <Avatar
              alt="Agnes Walker"
              src="https://docs.nestjs.com/assets/logo-small.svg"
            />
            <Avatar
              alt="Trevor Henderson"
              src="https://docs.nestjs.com/assets/logo-small.svg"
            />
          </AvatarGroup>

          <div>20 of your friends connected to this company</div>
        </div> */}
        <div className="mt-8">
          <LoadingButton
            variant={company?.following ? "outlined" : "contained"}
            color="primary"
            loading={isFollowLoading}
            onClick={onFollowClick}
            fullWidth
          >
            {company?.following ? "Unfollow" : "Follow"}
          </LoadingButton>

          {website && (
            <a href={website} rel="noopener noreferrer" target="_blank">
              <LoadingButton
                fullWidth
                variant="outlined"
                color="primary"
                className="mt-3"
              >
                Visit website
              </LoadingButton>
            </a>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default CompanyCard;
