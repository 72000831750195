import React from "react";
import Typography from "@mui/material/Typography";
import { DealPerson } from "types/deal";

export const PersonBlock = (props: DealPerson) => {
  const {
    formData: { name, description, url },
    imageUrl,
  } = props;
  return (
    <div>
      <div className="flex pb-[20px]">
        {imageUrl && (
          <img
            src={imageUrl}
            className="h-[68px] w-[68px] rounded-[20px] mr-4"
            alt={name}
          />
        )}
        <div className="flex flex-col justify-end">
          <Typography variant="body11" className="text-gray-500 font-bold">
            {name}
          </Typography>
          {url && (
            <Typography
              variant="caption2"
              className="text-gray-300 font-semibold"
            >
              {url}
            </Typography>
          )}
        </div>
      </div>
      {description && (
        <Typography variant="body2" className="text-gray-500">
          {description}
        </Typography>
      )}
    </div>
  );
};

export default PersonBlock;
