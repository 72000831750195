import { useMemo, useState } from "react";

interface Options {
  pageSize?: number;
}

export const useDataPaginated = (
  data?: any[],
  { pageSize = 10 }: Options = {}
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfPages = useMemo(() => {
    if (!data) return 0;

    return Math.ceil(data.length / pageSize);
  }, [data, pageSize]);

  const pageData = useMemo(() => {
    const validData = data || [];
    const initialIndex = (currentPage - 1) * pageSize;

    return validData.slice(initialIndex, initialIndex + pageSize);
  }, [currentPage, data, pageSize]);

  return {
    currentPage,
    setCurrentPage,
    pageData,
    numberOfPages,
  };
};
