import { Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import { FC, HTMLProps, useMemo } from "react";
import styles from "./MarkdownInput.legacy.styles.module.scss";

function convertNewLinesToBr(markdown: string): string {
  return markdown
    .split("\n")
    .reduce((acc: string, line: string, index: number, array: string[]) => {
      if (line.trim().match(/^-{3,}$/)) {
        return acc + "<hr>" + (index < array.length - 1 ? "\n" : "");
      } else if (!line.match(/^(#{1,6} |[*+-] |\d+\. )/)) {
        const outputLine = line + (index < array.length - 1 ? "<br>" : "");
        return acc + outputLine + "\n\n";
      }
      return acc + line + "\n";
    }, "");
}

const cleanProps = (props: any) => {
  return Object.keys(props).reduce((agg, key) => {
    const currentValue = props[key];
    const isInvalid = typeof currentValue === "boolean";

    if (isInvalid) return agg;

    return {
      ...agg,
      [key]: currentValue,
    };
  }, {});
};

interface MarkdownInputViewerProps {
  defaultTextColor?: string;
  overrideVariant?: Partial<
    Record<
      "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "li",
      keyof TypographyPropsVariantOverrides | Variant
    >
  >;
  children: string;
}

const MarkdownWrapper: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={clsx(className, styles.Container)}>
      {children}
    </div>
  );
};

export const MarkdownInputViewer: FC<MarkdownInputViewerProps> = ({
  children,
  overrideVariant,
}) => {
  const parsedValue = useMemo(() => {
    return children ? convertNewLinesToBr(children) : "";
  }, [children]);

  return (
    <Markdown
      options={{
        wrapper: MarkdownWrapper,
        overrides: {
          h1: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h1"] || "h5"}
              className={clsx(props.className, "font-sans font-[1.25rem]")}
              style={{ ...props.style }}
            />
          ),
          h2: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h2"] || "h5"}
              className={clsx(props.className, "font-sans font-[1.15rem]")}
              style={{ ...props.style }}
            />
          ),
          h3: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h3"] || "h6"}
              className={clsx(props.className, "font-bold italic")}
              style={{ ...props.style }}
            />
          ),
          h4: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h4"] || "h6"}
              style={{ ...props.style }}
            />
          ),
          h5: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h5"] || "body1"}
              className={clsx(props.className, "font-bold italic")}
              style={{ ...props.style }}
            />
          ),
          h6: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["h6"] || "body1"}
              className={clsx(props.className)}
              style={{ ...props.style }}
            />
          ),
          p: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["p"] || "body2"}
              style={{ ...props.style }}
            />
          ),
          li: (props) => (
            <Typography
              {...cleanProps(props)}
              component="li"
              variant={overrideVariant?.["li"] || "body2"}
              style={{ ...props.style }}
            />
          ),
          span: (props) => (
            <Typography
              {...cleanProps(props)}
              variant={overrideVariant?.["span"] || "body2"}
              style={{ ...props.style }}
            />
          ),
        },
      }}
    >
      {parsedValue}
    </Markdown>
  );
};
