import React, { createContext, useState } from "react";
import { PermissionError } from "application/errors";
import { Outlet } from "react-router-dom";
import { useDealGeneralData, useDealPermissions } from "ui/hooks";
import { Loading } from "ui/components";
import DealCardWrapper from "./DealCardWrapper/DealCardWrapper";

export const DealPageLayoutContext = createContext<{
  isFundAccessLoading: boolean;
  setIsFundAccessLoading: React.Dispatch<React.SetStateAction<boolean>> | null;
}>({ isFundAccessLoading: false, setIsFundAccessLoading: null });

export const DealPageLayout = () => {
  const { data, error } = useDealGeneralData();
  const { permissions, loading: isLoadingPermissions } = useDealPermissions();
  const [isFundAccessLoading, setIsFundAccessLoading] = useState(false);

  const isLoading = typeof data === "undefined" && !error;

  if (!isLoadingPermissions && permissions?.view === false) {
    throw new PermissionError("You have no permission to this deal data.");
  }

  if (isLoading || isLoadingPermissions) {
    return <Loading full />;
  }

  return (
    <DealPageLayoutContext.Provider
      value={{ isFundAccessLoading, setIsFundAccessLoading }}
    >
      <div className="grid grid-cols-1 md:grid-cols-[minmax(282px,384px)_minmax(384px,777px)] gap-[39px] py-10">
        <div>
          <DealCardWrapper />
        </div>
        <Outlet />
      </div>
    </DealPageLayoutContext.Provider>
  );
};
