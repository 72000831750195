import { Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { ModalComponent } from "../ModalComponent";
import { clearBrowserCache } from "application/utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

interface OutdatedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const OutdatedModal: FC<OutdatedModalProps> = ({ isOpen, onClose }) => {
  const [submitting, setSubmitting] = useState(false);

  const onRefresh = useCallback(() => {
    setSubmitting(true);
    clearBrowserCache();
    window.location.reload();
  }, []);

  return (
    <ModalComponent
      smallTitle
      title="Application updates"
      open={isOpen}
      onClose={onClose}
    >
      <div className="h-full flex flex-col justify-between">
        <Typography variant="body2">
          You are using an outdated version of our app, please refresh it to get
          the latest updates!
        </Typography>

        <div className="md:pt-12 flex">
          <LoadingButton
            loading={submitting}
            onClick={onRefresh}
            variant="contained"
            className="w-full"
          >
            Refresh
          </LoadingButton>
        </div>
      </div>
    </ModalComponent>
  );
};
