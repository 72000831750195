import React from "react";
import clsx from "clsx";
import { ImageIcon } from "ui/icons";

interface Props {
  size: "medium" | "large";
  imageUrl?: string;
}

const DealLogo = ({ size, imageUrl }: Props) => {
  return (
    <div
      className={clsx(
        "bg-gray-200 rounded-xl flex items-center justify-center",
        {
          "w-[48px] h-[48px] min-h-[48px]": size === "medium",
          "w-[68px] h-[68px] min-h-[68px]": size === "large",
        }
      )}
    >
      {imageUrl ? (
        <img
          className="w-[inherit] h-[inherit] rounded-[inherit]"
          alt="Deal Logo"
          src={imageUrl}
        />
      ) : (
        <ImageIcon />
      )}
    </div>
  );
};

export default DealLogo;
