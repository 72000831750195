import notificationsService from "services/notifications.service";
import useSWR, { SWRConfiguration } from "swr";

export const useUnreadNotificationCount = (config?: SWRConfiguration) => {
  const { data, error, mutate } = useSWR(
    "/Notification/count/unread",
    async () => notificationsService.getUnreadNotifications(),
    config
  );

  return { data, error, mutate };
};
