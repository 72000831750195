import { create, test, enforce } from "vest";

export const validationSuite = (roleNames: string[] = []) =>
  create((data = {}) => {
    test("role", "The field cannot be empty", () => {
      enforce(data.role).isNotEmpty().isNotBlank();
    });
    test("role", "Role name should be at least 2 characters", () => {
      enforce(data.role).longerThanOrEquals(2);
    });
    test("role", "Role name cannot be longer than 20 characters", () => {
      enforce(data.role).shorterThanOrEquals(20);
    });
    test("role", "Role name already exists", () => {
      enforce(data.role.toLowerCase()).notInside(roleNames);
    });
  });
