import { AnyAction, combineReducers, Reducer } from "redux";

import authReducer from "./Auth/reducer";
import userReducer from "./User/reducer";
import inviteReducer from "./Invite/reducer";
import syndicateReducer from "./Syndicate/reducer";
import notificationsReducer from "./Notifications/reducer";
import registrationReducer from "./Registration/reducer";
import uiReducer from "./UI/reducer";
import mediaPlayerReducer from "./MediaPlayer/reducer";
import storage from "redux-persist/lib/storage";
import { resetAllStores } from "./RootConfig/reducer";
import { Auth } from "aws-amplify";
import authService from "services/auth.service";
import { baseApi } from "./Api/BaseApi";

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  invite: inviteReducer,
  notifications: notificationsReducer,
  registration: registrationReducer,
  UI: uiReducer,
  mediaPlayer: mediaPlayerReducer,
  syndicate: syndicateReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === resetAllStores.type) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    localStorage.removeItem("rememberMe");
    authService.updateApiToken(undefined);
    Auth.signOut();

    const reset = appReducer(undefined, action);

    return {
      ...reset,
      syndicate: state.syndicate,
    } as RootState;
  }
  return appReducer(state, action);
};

export default rootReducer;

//  This RootState is required to use useSelector later on
export type RootState = ReturnType<typeof appReducer>;
