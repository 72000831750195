import { isUndefined } from "lodash";
import dealService from "services/deal.service";
import useSWR, { SWRConfiguration } from "swr";
import { ParticipationRequestPayload } from "types/deal";

export const useParticipationRequestsForCurrentUser = (
  dealId: string,
  config?: SWRConfiguration<ParticipationRequestPayload[]>
) => {
  const { data, error, mutate } = useSWR<ParticipationRequestPayload[]>(
    `/Fund/participate/${dealId}/me`,
    () => dealService.getParticipationRequestsForCurrentUser(dealId),
    config
  );

  const loading = [data, error].every(isUndefined);

  return { data, error, mutate, loading };
};
