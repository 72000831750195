import { useMemo, useRef } from "react";
import { IMediaItem } from "types/media.service";
import { MarkdownInputViewer } from "ui/components/MarkdownInput";
import {
  useDealGeneralData,
  useDealMedia,
  useDealMediaItem,
  useErrorHandler,
} from "ui/hooks";
import { DetailSection } from "../DetailSection/DetailSection";
import { MediaCard } from "../MediaCard";

interface Props {
  privateDescription?: boolean;
}

// TODO Read more
const DealOverview = ({ privateDescription }: Props) => {
  const { data } = useDealGeneralData();
  const { handleError, getSummarizedError } = useErrorHandler();
  const { data: dealMedia } = useDealMedia();
  const ref = useRef(null);
  const { data: dealMediaItem } = useDealMediaItem({
    onError: (e) => {
      const { responseStatusCode } = getSummarizedError(e);
      if (responseStatusCode === 403) return;

      handleError(e, "It was not possible to get podcast data");
    },
    shouldRetryOnError: false,
  });

  const description = privateDescription
    ? data?.longDescription
    : data?.publicDescription;

  const dealMediaData = useMemo(() => {
    if (dealMediaItem?.hasPrivateContentAccess && !privateDescription) {
      if (!dealMediaItem.hasPublicInfo) return null;

      return {
        ...dealMediaItem,
        ...dealMedia?.publicInfo,
      };
    }

    return dealMediaItem;
  }, [dealMedia?.publicInfo, dealMediaItem, privateDescription]);

  const hasMediaAccess = useMemo(() => {
    if (!dealMediaData) return false;

    return dealMediaData.hasPublicInfo || dealMediaData.hasPrivateContentAccess;
  }, [dealMediaData]);

  if (description || dealMediaItem) {
    return (
      <DetailSection
        getCopyContent={() => ref.current as any}
        title="Description"
      >
        {hasMediaAccess && (
          <div className="mb-4">
            <MediaCard media={dealMediaData as IMediaItem} />
          </div>
        )}
        <div ref={ref}>
          {description && (
            <MarkdownInputViewer>{description}</MarkdownInputViewer>
          )}
        </div>
      </DetailSection>
    );
  }

  return null;
};

export default DealOverview;
