import { createTheme } from "@mui/material/styles";
import { getTheme } from "./theme";
import { defaultBrandingColors } from "domain/common";

export const bullpenTheme = createTheme(
  getTheme(defaultBrandingColors) as any,
  {
    palette: {
      background: {
        default: "#fcfcfd",
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      body1: {
        fontFamily: "Poppins, sans-serif",
      },
      body2: {
        fontFamily: "Poppins, sans-serif",
      },
      button: {
        fontFamily: "Poppins, sans-serif",
      },
      h1: {
        fontFamily: "Poppins, sans-serif",
      },
      h2: {
        fontFamily: "Poppins, sans-serif",
      },
      h3: {
        fontFamily: "Poppins, sans-serif",
      },
      h4: {
        margin: "0",
        fontFamily: "Poppins, sans-serif",
      },
      h5: {
        fontFamily: "Poppins, sans-serif",
      },
      h6: {
        fontFamily: "Poppins, sans-serif",
      },
      hairline1: {
        fontFamily: "Poppins, sans-serif",
      },
      hairline2: {
        fontFamily: "Poppins, sans-serif",
      },
      caption: {
        fontFamily: "Poppins, sans-serif",
      },
      overline: {
        fontFamily: "Poppins, sans-serif",
      },
      subtitle1: {
        fontFamily: "Poppins, sans-serif",
      },
      subtitle2: {
        fontFamily: "Poppins, sans-serif",
      },
    },
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontFamily: "Poppins, sans-serif",
          },
        },
      },
    },
  }
);
