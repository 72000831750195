import { uninterceptedApiClient } from "./clients";

class VersionService {
  private readonly basePath = "Version";

  async getLatestWebappVersion(): Promise<string> {
    const { data } = await uninterceptedApiClient.get(
      `${this.basePath}/webapp/latest`
    );

    return data.response;
  }
}

export default new VersionService();
