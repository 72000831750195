import { Navigate, Outlet } from "react-router-dom";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { usePaths } from "ui/hooks/usePaths";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import * as paths from "ui/Router/paths";

export const NotLoggedOutlet = () => {
  const { authorized } = useTypedSelector((state) => state.auth);
  const { mainRedirectPath } = usePaths();
  const { isFederatedSignUp, isRegularSignUp } = useTypedSelector(
    (state) => state.registration
  );
  const { isProjectsOnly } = useFeatureFlag();

  if (
    (authorized && isProjectsOnly && isFederatedSignUp) ||
    (authorized && isProjectsOnly && isRegularSignUp)
  ) {
    return <Navigate to={"/" + paths.signUpWelcome} replace />;
  }

  return authorized ? <Navigate to={mainRedirectPath} replace /> : <Outlet />;
};
