import Switch from "@mui/material/Switch";
import React from "react";
import DealService from "services/deal.service";
import { CardAction } from "ui/components";
import { useDealGeneralData, useDealId, useErrorHandler } from "ui/hooks";

const DealPublishAction = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const { handleError } = useErrorHandler();
  const isPublished = React.useMemo(() => data?.isPublished, [data]);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await DealService.publishDeal(dealId, {
        isPublished: !isPublished,
      });
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to publish the deal");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardAction
      label="Publish deal"
      description={
        isPublished
          ? "This deal is currently published"
          : "This deal is not published"
      }
      onClick={handleClick}
      loading={loading}
      endAdornment={<Switch checked={isPublished} disabled={loading} />}
    />
  );
};

export default DealPublishAction;
