import { Dispatch } from "redux";
import { Auth } from "aws-amplify";
import jwtDecode, { JwtPayload } from "jwt-decode";
import UserService from "services/user.service";
import AuthService from "services/auth.service";
import {
  AuthGroupEnum,
  NDAStatusEnum,
  OnboardingEnum,
  RegistrationProgressEnum,
} from "domain/authorization";
import { DocumentStatusEnum } from "domain/document";
import { resetAllStores } from "redux/RootConfig/reducer";
import { RootState } from "redux/rootReducer";
import { getEnvironmentUrl } from "application/utils";
import syndicationService from "services/syndication.service";
import * as paths from "ui/Router/paths";
import { RedirectError } from "application/errors";
import { CognitoUser } from "./types";

export enum ActionType {
  SignInPending = "SignInPending",
  SignInSuccess = "SignInSuccess",
  SignInFail = "SignInFail",
  SetOnboarding = "SetOnboarding",
  SetNDAStatus = "SetNDAStatus",
  SetLoginRedirect = "SetLoginRedirect",
  FederatedSignIn = "FederatedSignIn",
}

export const signInPending = () => ({
  type: ActionType.SignInPending,
});

interface SignInPayload {
  user: object;
  postConfimationDone: boolean;
  onboarding: string;
}

export const signInSuccess = (payload: SignInPayload) => ({
  type: ActionType.SignInSuccess,
  payload: payload,
});

export const setOnboarding = (payload: OnboardingEnum) => ({
  type: ActionType.SetOnboarding,
  payload: payload,
});

export const signInFail = (error: any) => ({
  type: ActionType.SignInFail,
  payload: error?.message || "Unknown Login Error",
});

export const signOut = () => ({
  type: resetAllStores.type,
});

export const federatedSignIn = (payload: { user: CognitoUser }) => ({
  type: ActionType.FederatedSignIn,
  payload,
});

export const setLoginRedirect = (payload: boolean) => ({
  type: ActionType.SetLoginRedirect,
  payload,
});

export const setNDAStatus = (payload: {
  ndaStatusStep: RegistrationProgressEnum | undefined;
  ndaStatus: NDAStatusEnum;
}) => ({
  type: ActionType.SetNDAStatus,
  payload,
});

type AuthActions = ReturnType<
  | typeof signInPending
  | typeof signInSuccess
  | typeof signInFail
  | typeof signOut
  | typeof setOnboarding
  | typeof setNDAStatus
  | typeof setLoginRedirect
>;

//=============================================================================
//Async Operations
//=============================================================================

interface SignInOptions {
  // rememberMe?: boolean;
  checkNDA?: boolean;
  avoidRetry?: boolean;
  checkSyndications?: boolean;
}

export const signIn = (
  username: string,
  password: string,
  options?: SignInOptions
) => {
  return async (
    dispatch: Dispatch<AuthActions>,
    getState: () => RootState
  ): Promise<void> => {
    dispatch({
      type: ActionType.SignInPending,
    });

    try {
      const user = await Auth.signIn(username, password);
      const session = await Auth.currentSession();
      const jwt = session.getIdToken().getJwtToken();

      if (session && jwt && options?.checkSyndications) {
        const decode = jwtDecode<
          JwtPayload | { ["cognito:groups"]?: string[] }
        >(jwt);
        const groups =
          "cognito:groups" in decode ? decode["cognito:groups"] : [];
        const state = getState();
        const isSyndicate = Boolean(state?.syndicate?.syndicateLayout);
        const { syndicateLayout } = state.syndicate;
        const isSuperAdmin = groups?.includes(AuthGroupEnum.Admin);
        const isE9User = isSuperAdmin || groups?.includes(AuthGroupEnum.User);

        const hasSyndicateAccess =
          isSuperAdmin ||
          groups?.some(
            (group) =>
              group === AuthGroupEnum.Admin ||
              group ===
                `syndication-${syndicateLayout?.mainInfo?.id}-user-group` ||
              group ===
                `syndication-${syndicateLayout?.mainInfo?.id}-admin-group`
          );

        if (isSyndicate && !hasSyndicateAccess) {
          if (isE9User) {
            await dispatch(signOut());

            throw new RedirectError(
              "The user doesn't belong to this syndication",
              getEnvironmentUrl() + "/" + paths.signInPath
            );
          }

          const syndicationsWithAccess =
            await syndicationService.getSyndicationPartnersWithAccess();

          await dispatch(signOut());

          throw new RedirectError(
            "The user doesn't belong to this syndication",
            getEnvironmentUrl(syndicationsWithAccess?.[0]?.alias) +
              "/" +
              paths.signInPath
          );
        }

        if (!isSyndicate && !isE9User) {
          const syndicationsWithAccess =
            await syndicationService.getSyndicationPartnersWithAccess();

          await dispatch(signOut());

          throw new RedirectError(
            "The user doesn't belong to this syndication",
            getEnvironmentUrl(syndicationsWithAccess?.[0]?.alias) +
              "/" +
              paths.signInPath
          );
        }
      }

      // if (options?.rememberMe) localStorage.setItem("rememberMe", "true");

      let postConfimationDone =
        user.attributes["custom:postConfimationDone"] === "true";
      const onboarding = user.attributes["custom:onboarding"];

      if (!postConfimationDone) {
        if (session) {
          const decode = jwtDecode<
            JwtPayload | { ["cognito:groups"]?: string[] }
          >(jwt);

          if ("cognito:groups" in decode) {
            const groups = decode["cognito:groups"];
            if (groups) {
              const hasUserGroup = groups.includes("emerge9-user-group");
              if (!hasUserGroup) {
                await UserService.addMeToUserGroup();
                await AuthService.refreshIdToken();
                await AuthService.updateUserAttributes({
                  "custom:postConfimationDone": "true",
                });
                postConfimationDone = true;
              } else if (hasUserGroup) {
                await AuthService.updateUserAttributes({
                  "custom:postConfimationDone": "true",
                });
                postConfimationDone = true;
              }
            }
          } else {
            await UserService.addMeToUserGroup();
            await AuthService.refreshIdToken();
            await AuthService.updateUserAttributes({
              "custom:postConfimationDone": "true",
            });
            postConfimationDone = true;
          }
        }
      }

      if (options?.checkNDA) {
        let ndaStatusStep;
        let ndaStatus = NDAStatusEnum.Unknown;
        try {
          const { response } = await UserService.getUserOnboardingSteps();
          ndaStatusStep = response?.signatureDocument;
          const { response: documentResponse } =
            await UserService.getSignatureDocument();
          if (!documentResponse) {
            ndaStatus = NDAStatusEnum.NotStarted;
          } else if (
            documentResponse.status === DocumentStatusEnum.Draft ||
            documentResponse.status === DocumentStatusEnum.Sent ||
            documentResponse.status === DocumentStatusEnum.Viewed
          ) {
            ndaStatus = NDAStatusEnum.Started;
          } else if (documentResponse.status === DocumentStatusEnum.Completed) {
            ndaStatus = NDAStatusEnum.Completed;
          } else {
            ndaStatus = NDAStatusEnum.Unknown;
          }
        } catch (error) {
          // TODO handle nda check error
        } finally {
          dispatch(setNDAStatus({ ndaStatusStep, ndaStatus }));
        }
      }

      const payload = {
        user: { ...user },
        postConfimationDone,
        onboarding,
      };

      dispatch(signInSuccess(payload));
    } catch (error) {
      if (error instanceof RedirectError) {
        await dispatch(signOut());
        window.localStorage.clear();
        window.location.replace(error.redirectTo);
        return;
      }

      if (
        !options?.avoidRetry &&
        (error as Error)?.name === "QuotaExceededError"
      ) {
        window.localStorage.clear();
        return await signIn(username, password, {
          ...options,
          avoidRetry: true,
        })(dispatch, getState);
      }
      dispatch(signInFail(error));
    }
  };
};
