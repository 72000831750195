import { Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import React, { FC } from "react";

interface DealCardTitleProps {
  size: "medium" | "large";
  imageUrl?: string;
  dealName: string;
  dealOwnerName: string;
}

export const DealCardTitle: FC<DealCardTitleProps> = ({
  size,
  dealName,
  dealOwnerName,
}) => {
  const isMediumSize = size === "medium";
  const isLargeSize = size === "large";

  return (
    <div className="flex flex-col">
      <Tooltip placement="bottom-start" title={<>{dealName}</>}>
        <Typography
          variant={isMediumSize ? "body2" : "h3"}
          className={clsx("text-ellipsis overflow-hidden text-dark-text", {
            "font-bold overflow-hidden text-ellipsis whitespace-nowrap":
              isMediumSize,
            "font-medium font-serif": isLargeSize,
          })}
        >
          {dealName}
        </Typography>
      </Tooltip>
      <Typography
        variant={isMediumSize ? "caption2" : "button1"}
        className={clsx({
          "font-semibold text-gray-300": isMediumSize,
          "font-bold text-gray-400 mt-2": isLargeSize,
        })}
      >
        {dealOwnerName ? `By ${dealOwnerName}` : " "}
      </Typography>
    </div>
  );
};
