import DealService from "services/deal.service";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import useSWR from "swr";
import { KeyBiographersData } from "types/deal";
import { AxiosError } from "axios";

export default function useDealKeyBiographies() {
  const alias = useDealAlias();
  const { dealAccess: hasAccess } = useDealPermissions();

  const { data, error, ...rest } = useSWR(
    () => hasAccess && `fund/${alias}/key-biographies`,
    async () => {
      try {
        const data = await DealService.getDealKeyBiographers(alias);

        return data as KeyBiographersData;
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          return null;
        }

        throw error;
      }
    }
  );

  const loading = typeof data === "undefined" && !error;
  return { data, loading, error, hasAccess, ...rest };
}
