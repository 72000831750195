import DealService from "services/deal.service";
import { SummaryData } from "types/deal";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import { AxiosError } from "axios";
import useSWR from "swr";

export default function useDealSummary() {
  const alias = useDealAlias();
  const { dealView, dealAccess } = useDealPermissions();

  const hasAccess = dealView || dealAccess;

  const { data, error, ...rest } = useSWR(
    () => hasAccess && `fund/${alias}/summary`,
    async () => {
      try {
        const { description, summary } = await DealService.getDealSummary(
          alias
        );

        return {
          description,
          items: summary.reduce(
            (acc, { Key, Value }) => ({
              ...acc,
              [Key]: JSON.parse(Value),
            }),
            {}
          ),
        } as SummaryData;
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          return null;
        }

        throw error;
      }
    }
  );

  const loading = typeof data === "undefined" && !error;

  return { data, loading, error, hasAccess, ...rest };
}
