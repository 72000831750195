import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { usePaths } from "ui/hooks/usePaths";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";

interface ErrorCodes {
  [key: string]: {
    title: string;
    description: string;
    subscription?: string;
    hideButton?: boolean;
  };
}

const errorCodes: ErrorCodes = {
  404: {
    title: "404",
    description: "Sorry, the page not found",
    subscription: "Please, contact to your Deal Manager",
  },
  500: {
    title: "500",
    description: "Sorry, the page not found",
    subscription:
      "Please try again later or contact the website administrator for assistance. Thank  you!",
    hideButton: true,
  },
  default: {
    title: "404",
    description: "Sorry, the page not found",
    hideButton: true,
  },
};

interface NotFoundProps {
  errorCode?: number;
}

const NotFound = ({ errorCode }: NotFoundProps) => {
  const { mainRedirectPath } = usePaths();
  const { isProjectsOnly } = useFeatureFlag();
  const errorEntity = errorCode ? errorCodes[errorCode] : errorCodes["default"];
  return (
    <div
      className={clsx(
        "w-full flex flex-col justify-center items-center grow h-auto bg-secondary",
        styles.PageWrapper
        // {
        //   "min-h-screen": errorCode,
        //   "min-h-[calc(100vh_-_64px)]": !errorCode,
        // }
      )}
    >
      <div className="flex flex-col justify-center items-center mb-28">
        <div>
          <Typography
            component="h1"
            variant="h1"
            className="font-normal text-primary text-[134px] mb-7 font-mono leading-none"
          >
            {errorEntity.title}
          </Typography>
        </div>
        {errorEntity.description && (
          <div className="mb-8">
            <Typography
              component="h4"
              variant="h4"
              className="font-normal text-primary-600 font-serif"
            >
              {errorEntity.description}
            </Typography>
          </div>
        )}

        {errorEntity.subscription && (
          <div>
            <Typography
              component="h5"
              variant="body1"
              className="font-normal text-primary-600 font-serif"
            >
              {errorEntity.subscription}
            </Typography>
          </div>
        )}
      </div>

      {!errorEntity.hideButton && (
        <Link to={mainRedirectPath}>
          <Button variant="contained" size="small">
            {`Back to the ${isProjectsOnly ? "Projects" : "Deal"} page`}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default NotFound;
