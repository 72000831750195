import useSWR from "swr";
import UserService from "services/user.service";

export const useOnboardingSteps = () => {
  return useSWR("/User/profile/registration-steps", () =>
    UserService.getUserOnboardingSteps()
  );
};

export default useOnboardingSteps;
