import {
  getDealListQuery,
  refreshTokenAndGetNewToken,
} from "application/utils";
import { ImageUploadResponse } from "domain/common";
import {
  DealQuestionPriorityEnum,
  ParticipationTypeNumberEnum,
} from "domain/deal";
import qs from "query-string";
import {
  AssignOwnerPayload,
  ChangeParticipationRequestStatusPayload,
  ChangeParticipationRequestStatusResponse,
  CreateOrUpdateDealPersonPayload,
  CreateOrUpdateLeadInvestorPayload,
  DealAskResponse,
  DealCardAdvancedPayload,
  DealHistoricalPerformanceItemProps,
  DealKeyHighlightPayload,
  DealPayload,
  DealPermissions,
  DealPolicyItem,
  DealQueryParams,
  DealQuestion,
  DealQuestionID,
  DealRegistrationSteps,
  DealSummaryPayload,
  EnableDealQuestionAndAnswers,
  GetAllDealsResponse,
  GetDealPersonDataResponse,
  GetDealPolicy,
  GetDealsListResponse,
  GetParticipateResponse,
  ICreateDealAutomatedResponse,
  IDealExclusion,
  IDealSizeSummary,
  KeyBiographersPayload,
  ParticipationPayload,
  ParticipationRequestPayload,
  PostDealQuestion,
  PostDealQuestionAnswer,
  PutParticipateResponse,
  UpdateDealPublishedPayload,
  UpdateDealResponse,
} from "types/deal";
import { IMedia, IMediaItem } from "types/media.service";
import { apiClient, uninterceptedApiClient } from "./clients";

class DealService {
  private readonly basePath = "/fund";

  async createNewDeal(
    payload: DealPayload,
    config: object = {}
  ): Promise<GenericResponse<{ id: string; alias: string }>> {
    const { data } = await apiClient.post(this.basePath, payload, {
      ...config,
    });

    return data;
  }

  async createDealAutomated(
    dealName: string,
    files: File[]
  ): Promise<ICreateDealAutomatedResponse> {
    const formData = new FormData();
    const token = await refreshTokenAndGetNewToken();

    formData.append("name", dealName);

    for (const file of files) {
      formData.append("files", file);
    }

    const { data } = await apiClient.post(
      `${this.basePath}/automated-creation`,
      formData,
      {
        params: {
          token,
        },
      }
    );

    return data.response;
  }

  async retryDealAutomated(id: string): Promise<ICreateDealAutomatedResponse> {
    const token = await refreshTokenAndGetNewToken();

    const { data } = await apiClient.post(
      `${this.basePath}/${id}/automated-creation`,
      null,
      {
        params: {
          token,
        },
      }
    );

    return data.response;
  }

  async updateDeal(
    id: string,
    payload: DealPayload,
    config: object = {}
  ): Promise<UpdateDealResponse> {
    const { data } = await apiClient.put(`${this.basePath}/${id}`, payload, {
      ...config,
    });

    return data;
  }

  async updateDealRegistrationSteps(
    dealId: string,
    payload: Partial<DealRegistrationSteps>
  ): Promise<DealRegistrationSteps> {
    const { data } = await apiClient.put(
      `${this.basePath}/${dealId}/registration-steps`,
      payload
    );

    return data;
  }

  async getDealAutomatedJobsStatus(
    dealId: string
  ): Promise<ICreateDealAutomatedResponse> {
    const { data } = await apiClient.get(
      `${this.basePath}/${dealId}/automated-creation`
    );

    return data.response;
  }

  async getDealSizeSummary(): Promise<IDealSizeSummary> {
    const { data } = await uninterceptedApiClient.get(
      `${this.basePath}/size/summary`
    );

    return data.response;
  }

  async publishDeal(
    id: string,
    payload: UpdateDealPublishedPayload
  ): Promise<DealPayload> {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/publish`,
      payload
    );

    return data;
  }

  async toggleDealWatermark(
    id: string,
    isEnabled: boolean
  ): Promise<DealPayload> {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/skip-watermark/enable`,
      {
        isEnabled,
      }
    );

    return data;
  }

  async togglePDFDownload(
    id: string,
    isEnabled: boolean
  ): Promise<DealPayload> {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/pdf-download/enable`,
      {
        isEnabled,
      }
    );

    return data;
  }

  async getDealMedia(id: string): Promise<IMedia | null> {
    const { data } = await apiClient.get(`${this.basePath}/${id}/media`);

    return data.response || null;
  }

  async getDealMediaItem(id: string): Promise<IMediaItem | null> {
    const { data } = await apiClient.get(`${this.basePath}/${id}/media/item`);

    return data.response || null;
  }

  async enableDealQuestionAndAnswers(
    id: string,
    payload: EnableDealQuestionAndAnswers
  ) {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/questions/enable`,
      payload
    );

    return data;
  }

  async getAllDeals(params: DealQueryParams): Promise<GetAllDealsResponse> {
    const { data } = await apiClient.get(`${this.basePath}/query`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "index" }),
    });

    return data;
  }

  async getMyDeals(params: DealQueryParams): Promise<GetAllDealsResponse> {
    const { data } = await apiClient.get(`${this.basePath}/my`, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "index" }),
    });

    return data;
  }

  async getDealsList(
    dealList: string[],
    config = {}
  ): Promise<GetDealsListResponse> {
    const { data } = await apiClient.get(
      this.basePath + getDealListQuery(dealList),
      {
        ...config,
      }
    );

    return data;
  }

  async getSingleDeal(
    id: string,
    config = {}
  ): Promise<GenericResponse<DealCardAdvancedPayload>> {
    const { data } = await apiClient.get(`${this.basePath}/${id}`, {
      ...config,
    });

    return data;
  }

  async addDealLike(id: string): Promise<void> {
    const { data } = await apiClient.post(`${this.basePath}/${id}/like`);

    return data;
  }

  async removeDealLike(id: string): Promise<void> {
    const { data } = await apiClient.delete(`${this.basePath}/${id}/like`);

    return data;
  }

  async getDealPDFUrl(alias: string): Promise<string> {
    const { data } = await apiClient.get(
      `${this.basePath}/${alias}/download-pdf`
    );

    return data.response;
  }

  async updateDealLogo(
    id: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async getLeadInvestor(
    fundId: string,
    config = {}
  ): Promise<GetDealPersonDataResponse[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/leadinvestor`,
      {
        ...config,
      }
    );

    return data;
  }

  async createLeadInvestor(
    fundId: string,
    payload: CreateOrUpdateLeadInvestorPayload,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/leadinvestor`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async updateLeadInvestor(
    fundId: string,
    investorId: string,
    payload: CreateOrUpdateLeadInvestorPayload
  ) {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/leadinvestor/${investorId}`,
      payload
    );

    return data;
  }

  async updateLeadInvestorImage(
    fundId: string,
    investorId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/leadinvestor/${investorId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async getSponsor(
    fundId: string,
    config = {}
  ): Promise<GetDealPersonDataResponse[]> {
    const { data } = await apiClient.get(`${this.basePath}/${fundId}/sponsor`, {
      ...config,
    });

    return data;
  }

  async createSponsor(
    fundId: string,
    payload: CreateOrUpdateDealPersonPayload,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/sponsor`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async updateSponsor(
    fundId: string,
    sponsorId: string,
    payload: CreateOrUpdateDealPersonPayload
  ) {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/sponsor/${sponsorId}`,
      payload
    );

    return data;
  }

  updateSponsors = async (
    fundId: string,
    payload: CreateOrUpdateDealPersonPayload[]
  ) => {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/sponsor`,
      payload
    );

    return data;
  };

  async getDealExclusionList(
    dealId: string,
    lastEvaluatedKey?: string
  ): Promise<PaginatedTokenResponse<IDealExclusion>> {
    const { data } = await apiClient.get(
      `${this.basePath}/${dealId}/restricted-user`,
      { params: { LastEvaluatedKey: lastEvaluatedKey } }
    );
    return data.response;
  }

  async addUsersToExclusionList(
    dealId: string,
    customUsernameList: string[]
  ): Promise<IDealExclusion[]> {
    const { data } = await apiClient.post(
      `${this.basePath}/${dealId}/restricted-user`,
      { customUserIds: customUsernameList }
    );
    return data.response;
  }

  async removeUserFromExclusionList(
    dealId: string,
    customUsername: string
  ): Promise<IDealExclusion[]> {
    const { data } = await apiClient.delete(
      `${this.basePath}/${dealId}/restricted-user/${customUsername}`
    );
    return data.response;
  }

  updateLeadInvestors = async (
    fundId: string,
    payload: CreateOrUpdateDealPersonPayload[]
  ) => {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/leadinvestor`,
      payload
    );

    return data;
  };

  async updateSponsorImage(
    fundId: string,
    sponsorId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/sponsor/${sponsorId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async getAdvisor(
    fundId: string,
    config = {}
  ): Promise<GetDealPersonDataResponse[]> {
    const { data } = await apiClient.get(`${this.basePath}/${fundId}/advisor`, {
      ...config,
    });

    return data;
  }

  async createAdvisor(
    fundId: string,
    payload: CreateOrUpdateDealPersonPayload,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/advisor`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async updateAdvisor(
    fundId: string,
    advisorId: string,
    payload: CreateOrUpdateDealPersonPayload
  ) {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/advisor/${advisorId}`,
      payload
    );

    return data;
  }

  async updateAdvisorImage(
    fundId: string,
    advisorId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/advisor/${advisorId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async getPermissions(
    fundId: string,
    config = {}
  ): Promise<GenericResponse<DealPermissions>> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/checkpermissions`,
      {
        ...config,
      }
    );

    return data;
  }

  async sendParticipationRequest(
    fundId: string,
    payload: ParticipationPayload,
    config = {}
  ): Promise<PutParticipateResponse> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/participate`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async getParticipationRequests(
    fundId: string,
    participationType?: ParticipationTypeNumberEnum,
    config = {}
  ): Promise<GetParticipateResponse> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/participate`,
      {
        ...config,
        params: {
          participationType,
        },
      }
    );

    return data;
  }

  async getParticipationRequestsForCurrentUser(
    dealId: string
  ): Promise<ParticipationRequestPayload[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${dealId}/participate/me`
    );

    return data?.response;
  }

  async changeParticipationRequestStatus(
    {
      fundId,
      requestId,
      participationStatus,
    }: ChangeParticipationRequestStatusPayload,
    config = {}
  ): Promise<ChangeParticipationRequestStatusResponse> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/participate/${requestId}/status`,
      null,
      {
        params: {
          participationStatus,
        },
        ...config,
      }
    );

    return data;
  }

  async fillParticipationRequestAmount(
    fundId: string,
    requestId: string,
    amount: number
  ) {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/participate/${requestId}/sum`,
      {},
      {
        params: { sum: amount },
      }
    );

    return data;
  }

  async getDealSummary(
    fundId: string,
    config = {}
  ): Promise<DealSummaryPayload> {
    const { data } = await apiClient.get(`${this.basePath}/${fundId}/summary`, {
      ...config,
    });

    return data;
  }

  async updateDealSummary(
    fundId: string,
    payload: DealSummaryPayload,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/summary`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async getDealKeyHighlights(
    fundId: string,
    config = {}
  ): Promise<DealKeyHighlightPayload> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/keyhighlights`,
      {
        ...config,
      }
    );

    return data;
  }

  async updateDealKeyHighlights(
    fundId: string,
    payload: DealKeyHighlightPayload,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/keyhighlights`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async getDealKeyBiographers(
    fundId: string,
    config = {}
  ): Promise<KeyBiographersPayload> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/keybiographer`,
      {
        ...config,
      }
    );

    return data;
  }

  async updateDealKeyBiographers(
    fundId: string,
    payload: KeyBiographersPayload,
    config = {}
  ): Promise<KeyBiographersPayload> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/keybiographer`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async updateDealKeyBiographerImage(
    fundId: string,
    biographerId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/keybiographer/${biographerId}/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async getDealHistoricalPerformanceAllEntries(
    fundId: string,
    config = {}
  ): Promise<DealHistoricalPerformanceItemProps[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/historicalperformance`,
      {
        ...config,
      }
    );

    return data;
  }

  async getDealHistoricalPerformanceDataEntry(
    fundId: string,
    id: string,
    config = {}
  ): Promise<DealHistoricalPerformanceItemProps> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/historicalperformance/${id}`,
      {
        ...config,
      }
    );

    return data;
  }

  async uploadDealImage(formData: FormData): Promise<ImageUploadResponse> {
    const { data } = await apiClient.post(
      `${this.basePath}/upload-image`,
      formData
    );

    return data;
  }

  async addDealHistoricalPerformanceEntry(
    fundId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/historicalperformance`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async updateDealHistoricalPerformanceEntry(
    fundId: string,
    entryId: string,
    formData: FormData,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/historicalperformance/${entryId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...config,
      }
    );

    return data;
  }

  async removeDealHistoricalPerformanceEntry(
    fundId: string,
    entryId: string,
    config = {}
  ): Promise<string> {
    const { data } = await apiClient.delete(
      `${this.basePath}/${fundId}/historicalperformance/${entryId}`,
      {
        ...config,
      }
    );

    return data;
  }

  async deleteDeal(fundId: string, config = {}): Promise<string> {
    const { data } = await apiClient.delete(`${this.basePath}/${fundId}`, {
      ...config,
    });

    return data;
  }

  async getDealPolicy(
    fundId: string,
    config: object = {}
  ): Promise<GetDealPolicy> {
    const { data } = await apiClient.get(`${this.basePath}/${fundId}/policy`, {
      ...config,
    });

    return data;
  }

  async updateDealPolicyRole(
    fundId: string,
    role: string,
    payload: DealPolicyItem,
    config: object = {}
  ): Promise<GetDealPolicy> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/policy/${role}`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async uploadDealDescriptionImage(
    formData: FormData
  ): Promise<{ data: { link: string } }> {
    const { data } = await apiClient.post(
      `${this.basePath}/upload-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { data: { link: data.imageUrl } };
  }

  async assignDealOwner(
    id: string,
    payload: AssignOwnerPayload,
    config: object = {}
  ): Promise<UpdateDealResponse> {
    const { data } = await apiClient.put(
      `${this.basePath}/${id}/owner`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async getDealQuestionsAndAnswers(
    fundId: string,
    config: object = {}
  ): Promise<GenericResponse<DealQuestion[]>> {
    const { data } = await apiClient.get(
      `${this.basePath}/${fundId}/questions`,
      {
        ...config,
      }
    );

    return data;
  }

  async postDealQuestion(
    fundId: string,
    payload: PostDealQuestion,
    config: object = {}
  ): Promise<GenericResponse<DealQuestionID | undefined>> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/questions`,
      payload,
      {
        ...config,
      }
    );

    return data;
  }

  async putDealQuestion(
    fundId: string,
    questionId: DealQuestionID,
    payload: PostDealQuestion,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/questions/${questionId}`,
      payload,
      {
        ...config,
      }
    );

    return data?.response;
  }

  async deleteDealQuestion(
    fundId: string,
    questionId: DealQuestionID,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.delete(
      `${this.basePath}/${fundId}/questions/${questionId}`,
      {
        ...config,
      }
    );

    return data?.response;
  }

  async deleteDealQuestionAnswer(
    fundId: string,
    questionId: DealQuestionID,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.delete(
      `${this.basePath}/${fundId}/questions/${questionId}/answer`,
      {
        ...config,
      }
    );

    return data?.response;
  }

  async postDealQuestionPriority(
    fundId: string,
    questionId: DealQuestionID,
    priority: DealQuestionPriorityEnum,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.post(
      `${this.basePath}/${fundId}/questions/${questionId}/prioritize`,
      { type: priority },
      {
        ...config,
      }
    );

    return data?.response;
  }

  async putDealQuestionPin(
    fundId: string,
    questionId: DealQuestionID,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/questions/${questionId}/pin`,
      {
        ...config,
      }
    );

    return data?.response;
  }

  async putDealQuestionAnswer(
    fundId: string,
    questionId: DealQuestionID,
    payload: PostDealQuestionAnswer,
    config: object = {}
  ): Promise<unknown> {
    const { data } = await apiClient.put(
      `${this.basePath}/${fundId}/questions/${questionId}/answer`,
      payload,
      {
        ...config,
      }
    );

    return data?.response;
  }

  async postDealAskChat(
    fundId: string,
    question: string,
    config: object = {}
  ): Promise<DealAskResponse> {
    const { data } = await apiClient.post(`${this.basePath}/${fundId}/ask`, {
      question,
      ...config,
    });

    return data?.response;
  }
}

export default new DealService();
