import computedStyleToInlineStyle from "computed-style-to-inline-style";
import { copyToClip } from "application/utils";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useCopyHTML = () => {
  const { enqueueSnackbar } = useSnackbar();

  const copyHTML = useCallback(
    async (content: HTMLElement) => {
      if (!content) {
        enqueueSnackbar(
          "Some error occurred and it was not possible to copy the elements. Please try again later",
          { variant: "error", title: "Something went wrong" }
        );
        return;
      }

      computedStyleToInlineStyle(content, {
        recursive: true,
      });

      const html = content.outerHTML;

      copyToClip(html);
    },
    [enqueueSnackbar]
  );

  return copyHTML;
};
