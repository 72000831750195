import { DealCardPayload } from "types/deal";
import {
  IOrganization,
  IOrganizationKeyBiographies,
} from "types/organization.service";
import { apiClient } from "./clients";

class OrganizationService {
  private readonly basePath = "/organization";

  async getOrganizationById(organizationId: string): Promise<IOrganization> {
    const { data } = await apiClient.get(
      `${this.basePath}/${organizationId}`,
      {}
    );
    return data.response;
  }

  async followOrganization(organizationId: string): Promise<IOrganization> {
    const { data } = await apiClient.post(
      `${this.basePath}/${organizationId}/follow`
    );
    return data.response;
  }

  async unFollowOrganization(organizationId: string): Promise<IOrganization> {
    const { data } = await apiClient.delete(
      `${this.basePath}/${organizationId}/follow`
    );
    return data.response;
  }

  async getOrganizationKeyBiographies(
    organizationId: string
  ): Promise<IOrganizationKeyBiographies> {
    const { data } = await apiClient.get(
      `${this.basePath}/${organizationId}/key-biography`
    );
    return data.response;
  }

  async getOrganizationDealsSponsored(
    organizationId: string
  ): Promise<DealCardPayload[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${organizationId}/fund/sponsor`
    );

    return data.response;
  }

  async getOrganizationDealsAdvised(
    organizationId: string
  ): Promise<DealCardPayload[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${organizationId}/fund/advisor`
    );

    return data.response;
  }
}

export default new OrganizationService();
