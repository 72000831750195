import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import dealService from "services/deal.service";
import { CardAction, FormInput, FormInputWrapper } from "ui/components";
import { ConfirmationModal } from "ui/components/Modal";
import { useDealGeneralData, useDealId, useErrorHandler } from "ui/hooks";
import * as paths from "ui/Router/paths";

export interface FormFields {
  confirmationText: string;
}

const defaultValues: FormFields = {
  confirmationText: "",
};

export const DealManageNavDeleteDeal = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const { handleError } = useErrorHandler();
  const [loading, setLoading] = React.useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { control, watch } = useForm<FormFields>({
    defaultValues,
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const confirmationTextValue = watch("confirmationText");

  const handleDeleteConfirmation = async () => {
    setLoading(true);

    try {
      await dealService.deleteDeal(dealId);
      await mutate();
      navigate("/" + paths.dealsAll);
      enqueueSnackbar("Deal deleted successfully.", {
        title: "Deal Deleted",
        variant: "success",
      });
    } catch (e) {
      handleError(e, "It was not possible to delete the deal.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isDeleteModalOpen && (
        <ConfirmationModal
          title="Delete deal"
          isOpen={isDeleteModalOpen}
          disabled={
            data == null ||
            confirmationTextValue?.toLowerCase() !== data?.name.toLowerCase()
          }
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirmation}
        >
          <Typography
            style={{ whiteSpace: "pre-wrap" }}
            variant="body1"
            className="text-gray-500 mb-5"
          >
            By deleting this deal you will also delete all dataroom files
          </Typography>

          <Typography
            style={{ whiteSpace: "pre-wrap" }}
            variant="body1"
            className="text-gray-500 mb-2"
          >
            Type <b>{data?.name}</b> to permanently delete the deal:
          </Typography>

          <Controller
            name="confirmationText"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <div className="w-full">
                <FormInputWrapper errorMessage={fieldState?.error?.message}>
                  <FormInput {...field} placeholder={data?.name} />
                </FormInputWrapper>
              </div>
            )}
          />
        </ConfirmationModal>
      )}
      {data?.isDeleted === false && (
        <CardAction
          label="Delete deal"
          description={"Delete the deal permanently"}
          onClick={() => setDeleteModalOpen(true)}
          loading={loading}
        />
      )}
    </>
  );
};
