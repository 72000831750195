import { isUndefined } from "lodash";
import syndicationService from "services/syndication.service";
import useSWR, { SWRConfiguration } from "swr";
import { ISyndicationPartnerBranding } from "types/syndication.service";

export const useSyndicationPartnerBranding = (
  partnerId: string,
  config?: SWRConfiguration<ISyndicationPartnerBranding>
) => {
  const swr = useSWR<ISyndicationPartnerBranding>(
    `/Syndication/partner/${partnerId}/branding`,
    () => syndicationService.getSyndicationPartnerBranding(partnerId),
    config
  );

  return { ...swr, loading: [swr.data, swr.error].every(isUndefined) };
};
