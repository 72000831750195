import { AutoAwesome } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { CommonLinks } from "domain/common";
import { FC, ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import * as paths from "ui/Router/paths";

interface NavigationItemsProps {
  light?: boolean;
  isAuthorized: boolean;
  isSyndicate: boolean;
}

const Item: FC<{
  label: string;
  light?: boolean;
  to: string;
  external?: boolean;
  icon?: ReactElement;
}> = ({ to, label, light, external, icon }) => {
  const content = useMemo(
    () => (
      <Typography
        component="span"
        variant="button2"
        className={clsx("flex items-center space-x-0.5", {
          "text-gray-400 hover:text-primary-400 font-bold": !light,
          "text-white hover:text-gray-200 font-bold": light,
        })}
      >
        <span>{label}</span>
        <span>{icon}</span>
      </Typography>
    ),
    [icon, label, light]
  );

  if (external) {
    return (
      <a href={to} rel="noopener noreferrer" target="_blank" className="flex">
        {content}
      </a>
    );
  }
  return (
    <Link to={"/" + to} className="flex">
      {content}
    </Link>
  );
};

export const NavigationItems: FC<NavigationItemsProps> = ({
  light,
  isAuthorized,
  isSyndicate,
}) => {
  //TODO: We might pick which items will be shown to the syndicates, so leave it as a prop, instead to hide it by the wrapper
  if (isSyndicate) {
    return null;
  }

  if (isAuthorized) {
    return (
      <Stack direction="row" spacing={4} className="flex items-center">
        <Item to={paths.landingInvest} label="Invest" light={light} />
        <Item to={paths.landingISyndicate} label="Syndicate" light={light} />
        <Item
          to={paths.landingAi}
          label="AI"
          icon={<AutoAwesome style={{ fontSize: 12 }} />}
          light={light}
        />
        <Item to={paths.podcasts} label="Podcast" light={light} />
        <Item to={CommonLinks.Blog} external label="Blog" light={light} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={4} className="flex items-center">
      <Item to={paths.landingInvest} label="Invest" light={light} />
      <Item to={paths.landingISyndicate} label="Syndicate" light={light} />
      <Item
        to={paths.landingAi}
        label="AI"
        icon={<AutoAwesome style={{ fontSize: 12 }} />}
        light={light}
      />
      <Item to={paths.podcasts} label="Podcast" light={light} />
      <Item to={CommonLinks.Blog} external label="Blog" light={light} />
      {/* <Item to={"#"} label="About us" light={light} /> */}
    </Stack>
  );
};
