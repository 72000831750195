import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CircleClose } from "ui/icons";
import { v4 as uuidv4 } from "uuid";
import { FormInput } from "ui/components";
import { generatePath } from "react-router-dom";
import { copyTextToClipboard } from "application/utils";

interface Props {
  open: boolean;
  onClose: (value?: any) => void;
}

export const ShareUrlModal = ({ onClose, ...props }: Props) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const openTooltip = () => {
    setShowTooltip(true);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };

  const handleClose = () => {
    onClose();
  };

  const [shareUrl] = React.useState(
    `${document.location.host}/${generatePath("/deal/share/:id", {
      id: uuidv4(),
    })}`
  );

  const copy = () => {
    copyTextToClipboard(shareUrl).then(() => {
      openTooltip();
    });
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <Modal onClose={handleClose} {...props}>
      <Paper className="w-[488px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 py-9">
        <div className="space-y-6">
          <div className="flex">
            <Typography
              variant="h4"
              className="text-serif font-medium text-primary-600 grow"
            >
              Share URL
            </Typography>
            <IconButton className="p-0" onClick={handleClose}>
              <CircleClose />
            </IconButton>
          </div>
          <div className="flex space-x-6 items-end">
            <FormInput
              label="Share url"
              value={shareUrl}
              readOnly
              autoFocus
              onFocus={handleFocus}
            />
            <Tooltip
              title="URL copied to clipboard!"
              open={showTooltip}
              leaveDelay={1500}
              onClose={closeTooltip}
            >
              <IconButton
                className="h-12 w-12 border-2 border-solid border-[#E6E8EC]"
                onClick={copy}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default ShareUrlModal;
