/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, DependencyList } from "react";

export function useDebounceEffect(
  fn: () => void,
  delay: number,
  values: DependencyList,
  runOnInitialize = false
) {
  const didMountRef = useRef<boolean | null>(null);

  useEffect(() => {
    if (!runOnInitialize && !didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    const handler = setTimeout(() => {
      fn();
    }, delay);

    return () => clearTimeout(handler);
  }, values);
}

export default useDebounceEffect;
