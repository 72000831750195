import { isUndefined, uniqBy } from "lodash";
import { useMemo } from "react";
import lookupService from "services/lookup.service";
import useSWR, { SWRConfiguration } from "swr";
import { LookupUser } from "types";

export const useLookupUsers = (
  fundId?: string | null,
  configuration?: SWRConfiguration<LookupUser[]>
) => {
  const swrData = useSWR<LookupUser[]>(
    `/Lookup/users?fundId=${fundId || ""}`,
    () => lookupService.getUsers(fundId || ""),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configuration,
    }
  );
  const loading = [swrData.data, swrData.error].every(isUndefined);

  const usersOptions = useMemo(
    () =>
      uniqBy<Option>(
        [
          ...(swrData.data?.map((u) => ({
            label:
              `${u?.fullName?.trim()} ${
                u?.email ? "(" + u?.email + ")" : ""
              }` || u.userId,
            value: u.userId,
          })) || []),
        ],
        "label"
      ),
    [swrData.data]
  );
  return {
    ...swrData,
    usersOptions,
    loading,
  };
};
