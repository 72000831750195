import { PhotoLibraryRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { FC, ReactElement } from "react";
import styles from "./EmptyContentScreen.styles.module.scss";

interface EmptyContentScreenProps {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  icon?: ReactElement;
  children?: React.ReactNode;
}

export const EmptyContentScreen: FC<EmptyContentScreenProps> = ({
  subtitle,
  title,
  icon = <PhotoLibraryRounded />,
  children,
}) => {
  return (
    <div
      className={clsx(
        styles.Content,
        "flex flex-col min-h-[384px] w-[384px] max-w-full items-center justify-center m-auto !pointer-events-auto space-y-6"
      )}
    >
      <div className={styles.IconWrapper}>
        <div className={clsx(styles.IconContent, "text-gray-400")}>{icon}</div>
      </div>

      <div className="space-y-2">
        <Typography
          variant="h6"
          className="text-center font-serif text-dark-text font-medium"
        >
          {title}
        </Typography>
        <Typography variant="body2" className="text-gray-400 text-center">
          {subtitle}
        </Typography>
      </div>

      {children}
    </div>
  );
};
