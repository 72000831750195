import React from "react";
import Button from "@mui/material/Button";
import { DialogTitle, DialogTextSection } from "ui/components";
import { useDealGeneralData } from "ui/hooks";

interface Props {
  onClose: () => void;
}

const AccessRequestSentBody = ({ onClose }: Props) => {
  const { data } = useDealGeneralData();
  const name = data?.name || "fund";

  return (
    <>
      <DialogTitle onClose={onClose}>Request sent</DialogTitle>
      <div className="pt-9">
        <DialogTextSection>
          Your request to access <span className="font-bold">{name}</span> has
          been sent to the deal manager. We will send you a notification once{" "}
          access is granted.
        </DialogTextSection>
        <Button
          className="mt-11 w-full"
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default AccessRequestSentBody;
