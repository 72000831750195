import { getSyndicationId } from "application/utils";
import { isUndefined } from "lodash";
import { initSyndicateLayout } from "redux/Syndicate/reducer";
import syndicationService from "services/syndication.service";
import useSWR, { SWRConfiguration } from "swr";
import { ISyndicationPartnerLayout } from "types/syndication.service";
import store from "redux/store";

export const useSyndicationConfig = (
  config?: SWRConfiguration<ISyndicationPartnerLayout>,
  forceStateUpdate = false
) => {
  const syndicationId = getSyndicationId();

  const swr = useSWR<ISyndicationPartnerLayout>(
    syndicationId && `/Syndication/me`,
    async () => {
      const data = await syndicationService.getPartnerLayoutData();

      if (forceStateUpdate) {
        store.dispatch(initSyndicateLayout(data));
      }

      return data;
    },
    {
      revalidateOnFocus: false,
      ...config,
    }
  );

  return { ...swr, loading: [swr.data, swr.error].every(isUndefined) };
};
