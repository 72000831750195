import DealService from "services/deal.service";
import { useDealAlias, useDealPermissions } from "ui/hooks";
import { isUndefined } from "lodash";
import useSWR from "swr";

export function useDealSponsor() {
  const alias = useDealAlias() as string;
  const { dealAccess: hasAccess } = useDealPermissions();

  const { data, error, ...rest } = useSWR(
    () => hasAccess && `fund/${alias}/sponsor`,
    async () => {
      const data = await DealService.getSponsor(alias);

      return data || null;
    }
  );

  const loading = isUndefined(data) && isUndefined(error);

  return { data, loading, error, hasAccess, ...rest };
}

export default useDealSponsor;
