import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setLoginRedirect } from "redux/Auth/actions";
import { useTypedSelector } from "ui/hooks/useTypeSelector";
import { signInPath } from "ui/Router/paths";

const RememberMeCheck = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginRedirect } = useTypedSelector((state) => state.auth);

  React.useEffect(() => {
    if (loginRedirect) {
      const timeout = setTimeout(() => {
        navigate("/" + signInPath);
        dispatch(setLoginRedirect(false));
      });

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginRedirect]);

  return <Outlet />;
};

export default RememberMeCheck;
