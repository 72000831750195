import {
  ICampaign,
  ICampaignContact,
  ICampaignContactList,
  ICampaignContactListByPartner,
  ICampaignQuota,
  ICampaignRequest,
  IMoveOrCopyContacts,
} from "../types/campaign.service";
import { apiClient } from "./clients";
import qs from "query-string";

class CampaignService {
  private readonly basePath = "/Campaign";

  async getCampaigns(
    config: object = {}
  ): Promise<PaginatedTokenResponse<ICampaign>> {
    const { data } = await apiClient.get(`${this.basePath}`, {
      ...config,
    });

    return data.response;
  }

  async createCampaign(
    payload: Partial<ICampaignRequest>
  ): Promise<ICampaignRequest> {
    const { data } = await apiClient.post(`${this.basePath}`, payload);
    return data.response;
  }

  async getCampaignById(
    campaignId?: string,
    forTenant?: string
  ): Promise<ICampaign> {
    const { data } = await apiClient.get(`${this.basePath}/${campaignId}`, {
      params: { forTenant },
    });
    return data.response;
  }

  async unscheduleCampaign(campaignId: string): Promise<ICampaign> {
    const { data } = await apiClient.post(
      `${this.basePath}/${campaignId}/unschedule`
    );

    return data.response;
  }

  async updateCampaignById(
    campaignId: string,
    payload: Partial<ICampaignRequest>
  ): Promise<ICampaignRequest> {
    const { data } = await apiClient.put(
      `${this.basePath}/${campaignId}`,
      payload
    );

    return data;
  }

  async getContactList(): Promise<ICampaignContactList[]> {
    const { data } = await apiClient.get(`${this.basePath}/contact-list`);
    return data.response;
  }

  async getAllContactLists(
    attachedDealIds?: string[]
  ): Promise<ICampaignContactListByPartner[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/contact-list/available`,
      {
        params: { attachedFundIds: attachedDealIds },
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "index" }),
      }
    );
    return data.response;
  }

  async getContactListById(campaignId: string): Promise<ICampaignContactList> {
    const { data } = await apiClient.get(
      `${this.basePath}/contact-list/${campaignId}`
    );
    return data.response;
  }

  async deleteContactId(campaignId: string): Promise<ICampaignContactList> {
    const { data } = await apiClient.delete(
      `${this.basePath}/contact-list/${campaignId}`
    );
    return data.response;
  }

  async deleteCampaign(campaignId: string): Promise<void> {
    const { data } = await apiClient.delete(`${this.basePath}/${campaignId}`);
    return data;
  }

  async duplicateCampaign(campaignId: string): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/${campaignId}/duplicate`
    );
    return data;
  }

  async getCampaignQuota(partnerId?: string): Promise<ICampaignQuota> {
    const headers: Record<string, string> = partnerId
      ? { "partner-id": partnerId }
      : {};

    const { data } = await apiClient.get(`${this.basePath}/quota`, {
      headers,
    });

    return data.response;
  }

  async sendNowCampaign(campaignId: string): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/${campaignId}/send`
    );
    return data;
  }

  async sendTestCampaign(
    campaignId: string,
    payload: { emails: string[] }
  ): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/${campaignId}/send-test`,
      payload
    );
    return data;
  }

  async scheduleCampaign(campaignId: string): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/${campaignId}/schedule`
    );
    return data;
  }

  async addContactList(payload: {
    name: string;
    isGlobal: boolean;
    isExclusionList?: boolean;
  }): Promise<string> {
    const { data } = await apiClient.post(
      `${this.basePath}/contact-list`,
      payload
    );
    return data.response.id;
  }

  async getContactsByListId(
    listId: string,
    lastEvaluatedKey?: string
  ): Promise<PaginatedTokenResponse<ICampaignContact>> {
    const { data } = await apiClient.get(
      `${this.basePath}/contact-list/${listId}/recipient`,
      { params: { LastEvaluatedKey: lastEvaluatedKey, pageSize: 10 } }
    );

    return data.response;
  }

  async updateContactList(listId: string, formData: FormData) {
    const { data } = await apiClient.put(
      `${this.basePath}/contact-list/${listId}/recipient`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  }

  async moveContacts(sourceListId: string, payload: IMoveOrCopyContacts) {
    const { data } = await apiClient.post(
      `${this.basePath}/contact-list/${sourceListId}/recipient/move`,
      payload
    );
    return data;
  }

  async copyContacts(sourceListId: string, payload: IMoveOrCopyContacts) {
    const { data } = await apiClient.post(
      `${this.basePath}/contact-list/${sourceListId}/recipient/copy`,
      payload
    );
    return data;
  }

  async removeContactsFromList(listId: string, contactIds: string[]) {
    const { data } = await apiClient.post(
      `${this.basePath}/contact-list/${listId}/recipient/remove`,
      { contactIds }
    );
    return data;
  }
}

export default new CampaignService();
