import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { DealQuestion } from "types/deal";
import Question from "../Question";

interface Props {
  answerAble?: boolean;
  title: string;
  data: DealQuestion[];
}

export const QuestionsBlock = ({ title, data, answerAble }: Props) => {
  return (
    <div className="px-8 pt-5 pb-9 bg-white rounded-2xl relative shadow-depth-2 border border-solid border-[#F4F5F6] space-y-5">
      <div className="flex items-center">
        <Typography className="text-gray-400 font-semibold" variant="caption">
          {title}
        </Typography>
      </div>
      <Divider />
      {data.map((props) => (
        <Question
          key={props.id}
          {...props}
          canChangePosition={data.length > 1}
          answerAble={answerAble}
        />
      ))}
    </div>
  );
};

export default QuestionsBlock;
