import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./styles.scss";
import clsx from "clsx";

interface DragAndDropFieldProps {
  isLoading: boolean;
  hasError: boolean;
  uploadProgress: number;
  onFilesChanged: (e: SyntheticEvent, files: FileList) => void;
  isMultipleUpload?: boolean | undefined;
  accept?: string;
  onClick?: () => void;
}

export const DragAndDropField: FC<DragAndDropFieldProps> = ({
  isLoading,
  uploadProgress,
  onFilesChanged,
  hasError,
  isMultipleUpload,
  accept,
  onClick,
}) => {
  const [activeClass, setActiveClass] = useState(false);
  const handleFileUploadChange = (e: ChangeEvent) => {
    const filesUploaded = (e.target as any).files;
    onFilesChanged(e, filesUploaded);
  };

  const handleFileUploadClick = (e: any) => {
    onClick?.();
    e.target.value = "";
  };

  const handleFileUploadDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onFilesChanged(e, e.dataTransfer.files);
  };

  return (
    <label
      id="drop_zone"
      className={clsx(
        "flex flex-col min-h-[240px] cursor-pointer w-full max-w-full space-y-3 items-center justify-center m-auto !pointer-events-auto bg-gray-150 rounded-xl p-2 text-center",
        {
          active: activeClass,
          error: hasError,
        }
      )}
      onDrop={handleFileUploadDrop}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveClass(true);
      }}
      onDragLeave={() => {
        setActiveClass(false);
      }}
    >
      <input
        type="file"
        id="upload"
        accept={accept || undefined}
        multiple={isMultipleUpload === undefined ? true : isMultipleUpload}
        hidden
        onChange={handleFileUploadChange}
        onClick={handleFileUploadClick}
      />
      <div className="flex items-center justify-center download-file-icon rounded-full bg-white w-20 h-20 text-gray-400">
        <CircularProgress
          size="80px"
          variant="determinate"
          value={uploadProgress}
        />
        <UploadFileIcon color={isLoading ? "disabled" : "primary"} />
      </div>
      {isLoading ? (
        <>
          <Typography variant="body1" className="text-gray-300">
            Uploading file...
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="button1" className="text-primary">
            Select file from your device to upload
          </Typography>
          <Typography variant="body1" className="text-gray-300">
            Or drag and drop it here
          </Typography>
        </>
      )}
    </label>
  );
};
