import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { ReactComponent as EnterIconSVG } from "assets/svg/enter-icon.svg";
import clsx from "clsx";
import { FC } from "react";

export const EnterButton: FC<LoadingButtonProps> = ({
  children = "Submit",
  ...props
}) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      className={clsx("flex", props.className)}
    >
      {children}
      <EnterIconSVG className="ml-1.5 shrink-0" />
    </LoadingButton>
  );
};
