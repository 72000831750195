import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import clsx from "clsx";

interface LoadingProps {
  full?: boolean;
  size?: number;
  className?: string;
}

const Loading: FC<LoadingProps> = ({ full, className, size }) => {
  return (
    <Box
      sx={{ display: "flex", maxHeight: "fill-available" }}
      className={clsx(
        "items-center justify-center my-8",
        full && "grow h-full w-full self-center",
        className
      )}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loading;
