import { Typography } from "@mui/material";
import React, { useRef } from "react";
import { DealHistoricalPerformanceItem } from "ui/components";
import DealHistoricalPerformanceItemHeader from "ui/components/DealHistoricalPerformanceItem/DealHistoricalPerformanceItemHeader";
import { useDealHistoricalPerformance, useDealPaths } from "ui/hooks";
import { DetailSection } from "../DetailSection/DetailSection";

const DealHistoricalPerformance = () => {
  const { editDealHistoricalPerformancePath: editPath } = useDealPaths();
  const { data } = useDealHistoricalPerformance();
  const ref = useRef(null);

  const copyData = new Array(3)
    .fill(null)
    .map((_, index) => data?.[index] || null);

  return (
    <DetailSection
      getCopyContent={async () => ref.current as any}
      title="Historical Performance"
      editRoute={editPath}
      isEmpty={!data}
    >
      {data && (
        <div className="m9CardSurface w-full">
          <div className="py-5 w-full grid xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {data.map((item, index) => (
              <div
                key={index}
                className="flex border-y-0 border-l-0 first:border-r border-r-0 xl:border-r last:border-r-0 border-dashed border-[#E6E8EC]"
              >
                <DealHistoricalPerformanceItem {...item} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="hidden">
        <div ref={ref}>
          {copyData && (
            <div className="m9CardSurface w-[650px] w-max-full p-6">
              <table className="w-full border-collapse table-fixed p-6">
                <tbody>
                  <tr className="border-0 border-b border-dashed border-gray-200">
                    {copyData.map((item, index) => (
                      <td
                        key={index}
                        className="border-y-0 border-l-0 first:border-r pb-5 border-r-0 xl:border-r last:border-r-0 border-dashed border-[#E6E8EC] w-1/3 first:pl-0 last:pr-0 px-6"
                      >
                        {item ? (
                          <DealHistoricalPerformanceItemHeader {...item} />
                        ) : null}
                      </td>
                    ))}
                  </tr>
                  <tr className="border-0 border-dashed border-gray-200">
                    {copyData.map((item, index) => (
                      <td
                        key={index}
                        className="border-y-0 align-baseline pt-5 border-l-0 first:border-r border-r-0 xl:border-r last:border-r-0 border-dashed border-[#E6E8EC] w-1/3 first:pl-0 last:pr-0 px-6"
                      >
                        {item ? (
                          <>
                            <Typography
                              component="h3"
                              variant="caption2"
                              className="block font-sans font-semibold text-gray-500"
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              component="p"
                              variant="caption2"
                              className="block font-sans font-semibold text-gray-400 mt-6"
                            >
                              {item.text}
                            </Typography>
                          </>
                        ) : null}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </DetailSection>
  );
};

export default DealHistoricalPerformance;
