export enum SyndicationLogoPlaceholderName {
  WideLogo = "[SYNDICATION_WIDE_LOGO]",
  SquareLogo = "[SYNDICATION_SQUARE_LOGO]",
}

export enum SyndicationStatus {
  Pending = "pending",
  Registered = "registered",
  Participating = "participating",
  Expired = "expired",
}
