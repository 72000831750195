import React from "react";
import { ReactComponent as RadioButtonIconSVG } from "assets/svg/icon-ratio-button.svg";
import { ReactComponent as RadioButtonIconCheckedSVG } from "assets/svg/icon-ratio-button-checked.svg";

interface Props {
  checked?: boolean;
}

const RadioButtonIcon = ({ checked }: Props) => {
  if (checked) {
    return <RadioButtonIconCheckedSVG />;
  }

  return <RadioButtonIconSVG />;
};

export default RadioButtonIcon;
