import { vestResolver } from "@hookform/resolvers/vest";
import { create, test, enforce } from "vest";
import { formatCurrency, parseCurrencyString } from "application/utils";

const schema = create((data = {}) => {
  test("amount", "Amount is required", () => {
    enforce(data.amount).isNotEmpty();
  });
  test(
    "amount",
    `The minimum amount is ${
      data.minAmount ? formatCurrency(data.minAmount) : "Value not found"
    }`,
    () => {
      enforce(parseCurrencyString(data.amount)).greaterThanOrEquals(
        data.minAmount
      );
    }
  );
  test(
    "amount",
    `The maximum amount is ${
      data.maxAmount ? formatCurrency(data.maxAmount) : "Value not found"
    }`,
    () => {
      enforce(parseCurrencyString(data.amount)).lessThanOrEquals(
        data.maxAmount
      );
    }
  );
});

export default vestResolver(schema);
