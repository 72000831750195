import React from "react";
import { getPersonData } from "application/deal";
import PersonBlock from "../PersonBlock/PersonBlock";
import { useDealLeadInvestor, useDealPaths } from "ui/hooks";
import OtherDeals from "../OtherDeals/OtherDeals";
import { DetailSection } from "../DetailSection/DetailSection";
import { sortBy } from "lodash";

const DealLeadInvestor = () => {
  const { editDealLeadInvestorPath: editPath } = useDealPaths();
  const { data } = useDealLeadInvestor();
  const people = React.useMemo(() => {
    if (data) {
      return sortBy(data, "createdAt").map(getPersonData);
    }
    return null;
  }, [data]);

  return (
    <DetailSection title="Lead investor" editRoute={editPath} isEmpty={!people}>
      <div className="space-y-8">
        {people?.map((person) => (
          <div key={person.id}>
            <PersonBlock {...person} />
            <OtherDeals
              deals={person.formData.deals}
              from={person?.formData?.name}
            />
          </div>
        ))}
      </div>
    </DetailSection>
  );
};

export default DealLeadInvestor;
