import lookupService from "services/lookup.service";
import {
  AutocompleteController,
  FormInputController,
  FormSelectController,
} from "ui/components";
import { SectionTitle } from "ui/components/SectionTitle";
import { Add, RemoveCircleOutline } from "@mui/icons-material";
import { Button, IconButton, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, ReactElement, useEffect, useMemo } from "react";
import useSWR from "swr";
import { ExpandableSectionProps, SectionProps } from "ui/pages/Form";
import { useOrganizationLookup } from "ui/hooks";
import { isUndefined } from "lodash";

export interface BasicInfoSectionProps {
  inputLabels: {
    name: string;
    url: string;
    description: string;
  };
  inputNames: {
    name: string;
    url: string;
    description: string;
  };
  isOrganization: boolean;
  descriptionPlaceholder: string;
  titleAction?: ReactElement | ReactElement[];
}

export interface OtherDealsSectionProps {
  selectPlaceholder: string;
  title: string;
  name: string;
}

export interface FormInputs {
  id?: string;
  name: string | Option;
  url: string;
  description: string;
  otherDeals: { id: string }[];
  imageUrl?: string;
  imageFile?: Blob | null;
}

export const defaultValues: FormInputs = {
  name: "",
  url: "",
  description: "",
  otherDeals: [{ id: "" }],
  imageUrl: "",
};

export const BasicInfoSection: FC<
  Omit<SectionProps<any>, "reset"> & BasicInfoSectionProps
> = ({
  control,
  inputLabels,
  descriptionPlaceholder,
  isOrganization,
  inputNames,
  titleAction,
}) => {
  const { data: organizations, error } = useOrganizationLookup();
  const isLoading = [organizations, error].every(isUndefined);

  const organizationOptions = useMemo<Option[]>(() => {
    if (!organizations) return [];

    return organizations.map((organization) => ({
      label: organization.name,
      value: organization.id,
    }));
  }, [organizations]);

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex items-center justify-between">
        <SectionTitle>Basic info</SectionTitle>
        {titleAction}
      </div>
      <div className="grid grid-cols-1 space-y-8 md:space-y-0 md:grid-cols-2 gap-x-6">
        <AutocompleteController
          disableCloseOnSelect
          autoSelect
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option?.label ?? ""
          }
          options={organizationOptions}
          name={inputNames.name}
          freeSolo
          control={control}
          label={inputLabels.name}
          showError
          loading={isLoading}
        />
        <FormInputController
          name={inputNames.url}
          disabled={isOrganization}
          control={control}
          label={inputLabels.url}
          showError
        />
      </div>
      <FormInputController
        name={inputNames.description}
        disabled={isOrganization}
        control={control}
        label={inputLabels.description}
        placeholder={descriptionPlaceholder}
        showError
        multiline
        rows={5}
      />
    </div>
  );
};

export const OtherDealsSection: FC<
  Omit<ExpandableSectionProps<any>, "reset"> & OtherDealsSectionProps
> = ({
  control,
  fields,
  onItemIncrement,
  onItemRemove,
  itemsLimit = 10,
  title,
  selectPlaceholder,
  name,
}) => {
  const { data: optionsData, error } = useSWR("/lookup/deals", () =>
    lookupService.getLookupDeals()
  );
  const { enqueueSnackbar } = useSnackbar();
  const parsedOptions = useMemo(
    () =>
      optionsData?.response?.map(({ fundId, fundName }) => ({
        label: fundName,
        value: fundId,
      })) || [],
    [optionsData]
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar("It was not possible to fetch the deals options", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, error]);

  return (
    <div className="flex flex-col space-y-8">
      <SectionTitle>{title}</SectionTitle>
      {fields.map((field, index) => (
        <div className="flex items-center justify-between" key={field.id}>
          <FormSelectController
            className="flex-grow ml-2"
            name={`${name}.${index}.id`}
            control={control}
            showError
            placeholder={!optionsData ? "Loading..." : selectPlaceholder}
            disabled={!optionsData}
          >
            {parsedOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormSelectController>
          <IconButton
            color="primary"
            onClick={() => onItemRemove(index)}
            className="ml-1"
          >
            <RemoveCircleOutline />
          </IconButton>
        </div>
      ))}
      <div className="flex justify-end">
        <Button
          size="small"
          color="primary"
          variant="outlined"
          disabled={fields?.length + 1 >= itemsLimit}
          startIcon={<Add />}
          onClick={() => onItemIncrement()}
        >
          Add more
        </Button>
      </div>
    </div>
  );
};
