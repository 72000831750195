import { NotificationItem } from "ui/pages/UserNotifications/constants";
import { apiClient } from "./clients";

class NotificationsService {
  private readonly basePath = "/Notification";

  async getUnreadNotifications(config = {}) {
    return apiClient.get(`${this.basePath}/count/unread`, {
      ...config,
    });
  }

  async getNotifications(
    query: PaginatedTokenQuery
  ): Promise<PaginatedTokenResponse<NotificationItem>> {
    const { data } = await apiClient.get(this.basePath, {
      params: query,
    });

    return data.response;
  }

  async approveNotification(notificationId: string): Promise<void> {
    await apiClient.post(`${this.basePath}/${notificationId}/approve`);
  }

  async declineNotification(notificationId: string): Promise<void> {
    await apiClient.post(`${this.basePath}/${notificationId}/decline`);
  }

  async markNotificationAsRead(notificationIds?: string[], config = {}) {
    return apiClient.post(this.basePath, notificationIds, {
      ...config,
    });
  }

  async deleteNotification(notificationIds: string[] = [], config = {}) {
    return apiClient.delete(this.basePath, {
      ...config,
      data: notificationIds,
    });
  }

  async sendEmailMessageNotification(
    message: string,
    recipients: string[],
    config = {}
  ) {
    return apiClient.post(
      `${this.basePath}/email-message`,
      { message, recipients },
      {
        ...config,
      }
    );
  }
}

export default new NotificationsService();
