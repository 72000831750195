import React from "react";
import Typography from "@mui/material/Typography";
import defaultBackgroundImage from "../../../assets/svg/default-background-vector.svg";

interface Props {
  url?: string;
}

const PageHeaderAction = ({ url }: Props) => {
  return (
    <div
      className="w-full bg-[rgba(187, 191, 200, 0.2)] py-8 md:py-0"
      style={{
        backgroundImage: `url(${url ? url : defaultBackgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="m9-container min-h-[316px] space-y-4 md:space-y-0 flex-col md:flex-row flex justify-between items-center h-full py-0">
        <div className="flex flex-col md:mr-10 w-full items-center md:items-start">
          <Typography
            component="p"
            variant="hairline2"
            className="block text-primary-300 uppercase"
          >
            Podcast
          </Typography>
          <Typography
            component="div"
            variant="h2"
            className="font-serif text-black"
          >
            Emergent
            <br />
            Investments
          </Typography>
        </div>
        <Typography
          className="max-w-[630px] font-bold text-black"
          variant="caption2"
        >
          <p>
            Exploring the growing opportunities in GP-led secondary markets with
            the Emerge9 team and key industry partners and thought leaders.
          </p>

          <p>
            Emerge9 is a web-based investment and liquidity platform focused on
            providing compelling opportunities to a broader universe of private
            assets investors. The initial product on our platform is centered
            around GP-led secondary markets in the form of continuation
            vehicles. We, along with industry participants and thought leaders,
            will be discussing the nuances of the private assets markets and the
            compelling opportunities found within the secondary space.
          </p>
        </Typography>
      </div>
    </div>
  );
};

export default PageHeaderAction;
