import Switch from "@mui/material/Switch";
import { FeedbackError } from "application/errors";
import React from "react";
import DealService from "services/deal.service";
import { CardAction } from "ui/components";
import { useDealGeneralData, useDealId, useErrorHandler } from "ui/hooks";

export const DealManageNavPDFDownload = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const { handleError } = useErrorHandler();
  const isEnabled = React.useMemo(() => data?.isPDFDownloadEnabled, [data]);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new FeedbackError(
          "It was not possible to get the deal data. Please try again later."
        );
      }

      await DealService.togglePDFDownload(dealId, !data.isPDFDownloadEnabled);
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to update the pdf settings.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardAction
      label="Enable PDF download"
      description={
        isEnabled ? (
          <span title="Everyone that can see the deal are allowed to download the PDF copy.">
            <b>Everyone</b> with <b>view</b> permission are allowed to download
            the deal PDF version.
          </span>
        ) : (
          <span title="The PDF download is currently restricted to admins only">
            The PDF download is currently restricted to <b>admins only</b>
          </span>
        )
      }
      onClick={handleClick}
      loading={loading}
      endAdornment={<Switch checked={isEnabled} disabled={loading} />}
    />
  );
};
