import React from "react";
import {
  DealCategory,
  DealOverview,
  DealLeadInvestor,
  DealSponsor,
  DealAdvisor,
  DealHistoricalPerformance,
  DealKeyBiographies,
  DealKeyHighlights,
  DealSummary,
} from "../index";

const DealDetailPrivate = () => {
  return (
    <div className="space-y-6">
      <DealCategory />
      <DealOverview privateDescription />
      <DealLeadInvestor />
      <DealSponsor />
      <DealAdvisor />
      <DealHistoricalPerformance />
      <DealKeyBiographies />
      <DealKeyHighlights />
      <DealSummary />
    </div>
  );
};

export default DealDetailPrivate;
