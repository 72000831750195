import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import { FC, ReactChild, createElement } from "react";
import styles from "./MarkdownViewer.module.scss";

interface ICustomComponents {
  p?: React.ComponentClass | React.FunctionComponent;
  h1?: React.ComponentClass | React.FunctionComponent;
  h2?: React.ComponentClass | React.FunctionComponent;
  h3?: React.ComponentClass | React.FunctionComponent;
  h4?: React.ComponentClass | React.FunctionComponent;
  h5?: React.ComponentClass | React.FunctionComponent;
  h6?: React.ComponentClass | React.FunctionComponent;
  ol?: React.ComponentClass | React.FunctionComponent;
  ul?: React.ComponentClass | React.FunctionComponent;
  li?: React.ComponentClass | React.FunctionComponent;
}

interface MarkdownViewerProps {
  children: string;
  customElements?: ICustomComponents;
}

export const MarkdownViewer: FC<MarkdownViewerProps> = ({
  children,
  customElements,
}) => {
  const createElementFn = customElements
    ? (
        type: Parameters<MarkdownToJSX.CreateElement>[0],
        props: JSX.IntrinsicAttributes,
        children: ReactChild
      ) => {
        const component =
          customElements?.[type as keyof ICustomComponents] || type;

        const richProps = {
          ...props,
          component: type,
        };

        return createElement(component, richProps, children);
      }
    : undefined;

  return (
    <div className={styles.Container}>
      <Markdown
        options={{
          createElement: createElementFn,
        }}
      >
        {children}
      </Markdown>
    </div>
  );
};
