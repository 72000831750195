import { CardAction } from "ui/components";
import React, { useState } from "react";
import { DealAssignOwnerModal } from "./DealAssignOwnerModal/DealAssignOwnerModal";
import { useDealGeneralData } from "ui/hooks";

export const DealAssignOwnerAction = () => {
  const { data: dealData, mutate: mutateDealGeneralData } =
    useDealGeneralData();
  const [modalOpen, setModalOpen] = useState(false);

  const postedBy = React.useMemo(() => {
    if (dealData) {
      const { fundOwner } = dealData;

      return fundOwner.name && fundOwner.familyName
        ? `${fundOwner.name} ${fundOwner.familyName}`
        : "";
    }

    return "";
  }, [dealData]);

  const handleAssignOwnerClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <CardAction
        label="Assign a new deal owner"
        description={`${postedBy} is current deal owner`}
        onClick={handleAssignOwnerClick}
      />

      <DealAssignOwnerModal
        isOpen={modalOpen}
        onSubmitHandler={() => {
          mutateDealGeneralData();
          setModalOpen(false);
        }}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};
