export enum ParticipationTypeEnum {
  FundAccess = "fund-access",
  DataRoom = "data-room",
  FundParticipation = "fund-participation",
}

export enum ParticipationRequestStatus {
  Init = "init",
  Pending = "pending",
  AutoApproved = "autoapproved",
  Approved = "approved",
  Declined = "declined",
  AdminReview = "admin-review",
  AdminReviewDeclined = "admin-review-decliend",
}

export enum DealAssistantGenerationStatus {
  Init = "init",
  Generating = "generating",
  Finished = "finished",
  Error = "error",
}

export enum ParticipationTypeNumberEnum {
  FundAccess = 0,
  DataRoom = 1,
  FundParticipation = 2,
}

export enum DealAnalyticsTimeInterval {
  LastDay = "oneDay",
  LastWeek = "oneWeek",
  LastMonth = "oneMonth",
  LastThreeMonths = "threeMonths",
  LastYear = "oneYear",
  All = "ALL",
}

export enum DealAnalyticsTimeIntervalNormalized {
  oneDay = "1D",
  oneWeek = "1W",
  oneMonth = "1M",
  threeMonths = "3M",
  oneYear = "1Y",
  ALL = "ALL",
}

export enum DealAnalyticsVisitType {
  UniqueViews = "uniqueViews",
  TotalViews = "totalViews",
}

export enum DealStatusEnum {
  Available = "Available",
  Draft = "Draft",
  Closed = "Closed",
  Invalid = "Invalid",
  Deleted = "Deleted",
}

export enum DealQuestionStatusEnum {
  Pending = "Pending",
  Answered = "Answered",
}

export enum DealQuestionPriorityEnum {
  Decrease = "Decrease",
  Increase = "Increase",
}

export enum EditQuestionResourceEnum {
  Question = "Question",
  Answer = "Answer",
}

export enum DealStatusFilter {
  Available = "available",
  Closed = "closed",
  All = "all",
  Deleted = "deleted",
}

export enum DealAutomatedJobStatus {
  None = "none",
  Pending = "pending",
  Finished = "finished",
  Error = "error",
}
