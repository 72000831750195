import { IFeatureFlagConfig, IGetFeatureFlag } from "types/featureFlagService";
import { apiClient, uninterceptedApiClient } from "./clients";

class FeatureFlagService {
  private readonly basePath = "/FeatureFlag";

  async getAllFlags(partnerId?: string): Promise<IGetFeatureFlag> {
    const { data } = await uninterceptedApiClient.get(this.basePath, {
      headers: {
        ...(partnerId && { "partner-id": partnerId }),
      },
    });
    return data.response;
  }

  async updateFlags(
    flags: Record<string, IFeatureFlagConfig>,
    partnerId?: string
  ): Promise<string> {
    const payload = { flags };
    const { data } = await apiClient.post(this.basePath, payload, {
      headers: {
        ...(partnerId && { "partner-id": partnerId }),
      },
    });
    return data.response;
  }
}

export default new FeatureFlagService();
