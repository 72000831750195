import { Link } from "react-router-dom";
import { BullpenLogoIcon, BullpenLogoText } from "../Icons";
import { clsxm } from "application/utils";
import { ClassValue } from "clsx";

interface BullpenLogoProps {
  href?: string;
  className?: string;
  logoClassName?: string;
  iconClassName?: string;
  titleClassName?: string;
  iconOnly?: boolean;
}

const commonTWStyles = (...args: ClassValue[]) =>
  clsxm("w-24 flex items-center justify-evenly", ...args);

const commonSVGTWStyles = (...args: ClassValue[]) =>
  clsxm("fill-bullpen-primary-300", ...args);

export const BullpenLogo = ({
  href,
  className,
  logoClassName,
  iconClassName,
  titleClassName,
  iconOnly,
}: BullpenLogoProps) => {
  const content = (
    <>
      <BullpenLogoIcon
        className={commonSVGTWStyles(
          !iconOnly && "w-[25%]",
          logoClassName,
          iconClassName
        )}
      />
      {!iconOnly && (
        <>
          <div className="w-[6%]" />
          <BullpenLogoText
            className={commonSVGTWStyles(
              "w-[69%]",
              logoClassName,
              titleClassName
            )}
          />
        </>
      )}
    </>
  );

  return href ? (
    <Link
      to={href}
      className={clsxm("hover:bg-inherit p-0", commonTWStyles(className))}
    >
      {content}
    </Link>
  ) : (
    <div className={clsxm(commonTWStyles(className))}>{content}</div>
  );
};
