import React from "react";
import { CategoryChip } from "ui/components";
import { useDealGeneralData, useDealPaths, useLookupEnums } from "ui/hooks";
import { DetailSection } from "../DetailSection/DetailSection";
import { DealCategories } from "types/deal";

const mapCategoriesToItems = (
  acc: { [key: string]: string }[],
  [key, value]: [string, any]
): { [key: string]: string }[] => {
  const className = `m9-dealCategory m9-dealCategory--color-${key}`;

  if (Array.isArray(value) && value.length) {
    return [
      ...acc,
      {
        className,
        label: value.join(", "),
      },
    ];
  } else if (typeof value === "string" && value) {
    return [
      ...acc,
      {
        className,
        label: value,
      },
    ];
  }

  return acc;
};

const DealCategory = () => {
  const { editDealPath: editPath } = useDealPaths();
  const { data } = useDealGeneralData();
  const {
    getAssetTypeNameFromId,
    getDealTypeNameFromId,
    getEquityTypeNameFromId,
    getSectorNameFromId,
    getGeographicalRegionFromId,
  } = useLookupEnums();

  const categories = React.useMemo(() => {
    if (data?.categories && Object.values(data.categories)) {
      const { assetType, dealType, equityType, sector, geographicalRegion } =
        data?.categories || {};

      const parsedCategories: DealCategories = {
        geographicalRegion:
          (Array.isArray(geographicalRegion) &&
            geographicalRegion?.map(getGeographicalRegionFromId)) ||
          [],
        sector:
          (Array.isArray(sector) && sector?.map(getSectorNameFromId)) || [],
        dealType:
          (Array.isArray(dealType) && dealType?.map(getDealTypeNameFromId)) ||
          [],
        equityType:
          (Array.isArray(equityType) &&
            equityType?.map(getEquityTypeNameFromId)) ||
          [],
        assetType: getAssetTypeNameFromId(assetType as string),
      };
      const items = Object.entries(parsedCategories).reduce<
        { [key: string]: string }[]
      >(mapCategoriesToItems, []);

      if (items.length) return items;
    }
    return null;
  }, [
    data,
    getAssetTypeNameFromId,
    getDealTypeNameFromId,
    getEquityTypeNameFromId,
    getSectorNameFromId,
    getGeographicalRegionFromId,
  ]);

  return (
    <DetailSection noPaddingTop title="Overview" editRoute={editPath}>
      {categories && (
        <div className="flex flex-wrap gap-2">
          {categories.map(({ className, label }, index) => (
            <CategoryChip key={index} className={className}>
              {label}
            </CategoryChip>
          ))}
        </div>
      )}
    </DetailSection>
  );
};

export default DealCategory;
