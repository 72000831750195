import { SUPPORTED_PHONE_COUNTRIES } from "domain/common";
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js";
import { useCallback, useMemo } from "react";

const supportedPhoneCountriesByCode: Record<string, CountryCode> =
  SUPPORTED_PHONE_COUNTRIES.reduce(
    (acc, countryName) => ({
      ...acc,
      [getCountryCallingCode(countryName)]: countryName,
    }),
    {}
  );

export const usePhoneUtils = () => {
  const getCountryISOByDialingCode = useCallback((dialingCode?: string) => {
    return (
      supportedPhoneCountriesByCode[dialingCode?.replace("+", "") as string] ||
      "US"
    );
  }, []);

  const formatByDialingCode = useCallback(
    (dialingCode: string | undefined, value: string) => {
      const country = getCountryISOByDialingCode(dialingCode);
      return new AsYouType(country).input(value);
    },
    [getCountryISOByDialingCode]
  );

  const countryOptions: Option[] = useMemo(() => {
    return Object.keys(supportedPhoneCountriesByCode).map(
      (key) =>
        ({
          label: "+" + key,
          value: "+" + key,
        } as Option)
    );
  }, []);

  return {
    getCountryCallingCode,
    formatByDialingCode,
    countryOptions,
  };
};
