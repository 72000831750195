import { create, test, enforce, each, omitWhen } from "vest";
import { emailRegex } from "../../../application/validation";

export const getSchema = (addContacts: boolean) =>
  create(({ emails, files, name }) => {
    omitWhen(files.length, () => {
      test(`emails`, "Email field is required", () => {
        enforce(emails).isNotEmpty();
      });
    });

    each(emails, (email, index) => {
      test(
        `emails`,
        "Email is invalid",
        () => {
          enforce(email).matches(emailRegex);
        },
        `email.${index}.contactList`
      );
    });

    omitWhen(addContacts, () => {
      test(`name`, "Name field is required", () => {
        enforce(name).isNotEmpty();
      });
    });
  });
