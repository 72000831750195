import {
  Control,
  FieldArrayWithId,
  FormState,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  UseFormReset,
  FieldValues,
} from "react-hook-form";

export enum DealEditPageType {
  Create,
  Edit,
}

export interface DealCreateOrEditPage {
  type: DealEditPageType;
  routeAfterSubmit?: string;
}

export interface SectionProps<T extends FieldValues> {
  control: Control<T>;
  reset: UseFormReset<T>;
}

export interface ExpandableSectionProps<T extends FieldValues>
  extends SectionProps<T> {
  fields: FieldArrayWithId[];
  onItemIncrement: () => void;
  onItemRemove: (index?: number) => void;
  title?: string;
  itemsLimit?: number;
  options?: Option[];
}

export interface ExpandableWatchableSectionProps<T extends FieldValues>
  extends ExpandableSectionProps<T> {
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  formState?: FormState<T>;
  setValue?: UseFormSetValue<T>;
}
