import { useParams } from "react-router-dom";
import useSWR from "swr";
import dealService from "../../../services/deal.service";
import { useErrorHandler } from "../useErrorHandler";
import { DealCardAdvancedPayload } from "../../../types/deal";

export default function useDealId(): string {
  const { handleError } = useErrorHandler();
  const { alias } = useParams();

  const { data } = useSWR<GenericResponse<DealCardAdvancedPayload>>(
    () => (alias ? `/fund/${alias}` : null),
    () => dealService.getSingleDeal(alias as string),
    {
      onError: (err) => handleError(err, "It was not possible to get dealId"),
    }
  );

  return data?.response.id as string;
}
