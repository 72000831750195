import { PermissionError } from "application/errors";
import { Loading } from "ui/components";
import { useDealGeneralData, useDealPermissions } from "ui/hooks";
import * as paths from "ui/Router/paths";
import { isBoolean, isUndefined } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const DealManagePageLayout = () => {
  const { data, error } = useDealGeneralData();
  const { dealEdit, error: permissionsError } = useDealPermissions();
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = [data, error, dealEdit, permissionsError].every(
    isUndefined
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (isBoolean(dealEdit) && !dealEdit) {
    throw new PermissionError("You have no permission to this deal data.");
  }

  if (permissionsError) {
    enqueueSnackbar(
      "An unexpected error occurred when trying to get the permissions",
      {
        variant: "error",
      }
    );

    return <Navigate to={paths.dealsAll} />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};
