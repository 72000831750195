import { IMediaItem, IMediaItemPreview } from "types/media.service";

export enum PODCAST_TYPES {
  VIDEO = "video",
  AUDIO = "audio",
}

export type IPodcastItems = IMediaItemPreview[];

export type PodcastsResponse = IPodcastItems;
export type PodcastItemResponse = IMediaItem;
