import { FC, HTMLProps } from "react";
import styles from "./RadioButton.styles.module.scss";

export const RadioButton: FC<HTMLProps<HTMLInputElement>> = ({
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <input {...props} className={styles.Radio} type="radio" />
      <label htmlFor={props.id} />
    </div>
  );
};
