import { isUndefined } from "lodash";
import mediaService from "services/media.service";
import useSWR, { SWRConfiguration } from "swr";
import { IMediaItem } from "types/media.service";
import { useMediaId } from "./useMediaId";

export const useMediaItem = (configuration?: SWRConfiguration<IMediaItem>) => {
  const mediaId = useMediaId();
  const { data, error, mutate } = useSWR<IMediaItem>(
    `/Media/${mediaId}/item`,
    () => mediaService.getMediaItem(mediaId),
    configuration
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};
