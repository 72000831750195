import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarKey, SnackbarMessage, OptionsObject } from "notistack";

interface Notification {
  dismissed?: boolean;
  key: SnackbarKey;
  message: SnackbarMessage;
  options: OptionsObject;
}

export interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    enqueueSnackbar(
      state: NotificationsState,
      { payload }: PayloadAction<Omit<Notification, "key">>
    ) {
      const keyPayload = payload?.options?.key;
      if (keyPayload) {
        const hasKey = state.notifications.some(
          (notification) => notification.key === keyPayload
        );
        if (hasKey) {
          return;
        }
      }

      const key = keyPayload || new Date().getTime() + Math.random();

      state.notifications = [
        ...state.notifications,
        {
          ...payload,
          key,
        },
      ];
    },
    closeSnackbar(
      state: NotificationsState,
      { payload }: PayloadAction<SnackbarKey>
    ) {
      const dismissAll = !payload;

      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar(
      state: NotificationsState,
      { payload }: PayloadAction<SnackbarKey>
    ) {
      state.notifications = state.notifications.filter(
        ({ key }) => key !== payload
      );
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
