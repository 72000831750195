import {
  IGetInviteOverview,
  IInvite,
  IInviteSummary,
  IInviteSummaryConversionDetailed,
  ISentInviteOverviewResponse,
  IUpsertInvite,
  IValidateInvite,
} from "types/invite.service";
import { apiClient, uninterceptedApiClient } from "./clients";

class InviteService {
  private readonly basePath = "/Invite";

  async getInviteOverview(): Promise<GenericResponse<IGetInviteOverview>> {
    const { data } = await apiClient.get(this.basePath);

    return data;
  }

  async getReferralCode(): Promise<GenericResponse<string>> {
    const { data } = await apiClient.get(`${this.basePath}/code`);

    return data;
  }

  async cancelInvite(
    invite: IInvite
  ): Promise<GenericResponse<ISentInviteOverviewResponse>> {
    const { data } = await apiClient.delete(this.basePath, {
      data: [invite],
    });

    return data;
  }

  async getInviteSummaryList(
    createdBy: string | "Emerge9",
    partnerId: string
  ): Promise<IInviteSummary[]> {
    const { data } = await apiClient.get(`${this.basePath}/summary`, {
      params: { createdBy },
      headers: { "partner-id": partnerId },
    });

    return data.response;
  }

  async createInvite(
    payload: IUpsertInvite,
    partnerId?: string
  ): Promise<void> {
    const headers: Record<string, string> = partnerId
      ? { "partner-id": partnerId }
      : {};
    const { data } = await apiClient.post(`${this.basePath}/code`, payload, {
      headers,
    });

    return data.response;
  }

  async updateInvite(
    inviteCode: string,
    payload: IUpsertInvite,
    partnerId?: string
  ): Promise<void> {
    const headers: Record<string, string> = partnerId
      ? { "partner-id": partnerId }
      : {};

    const { data } = await apiClient.put(
      `${this.basePath}/code/${inviteCode}`,
      payload,
      { headers }
    );

    return data.response;
  }

  async deleteInvite(inviteCode: string, partnerId?: string): Promise<void> {
    const headers: Record<string, string> = partnerId
      ? { "partner-id": partnerId }
      : {};

    const { data } = await apiClient.delete(
      `${this.basePath}/code/${inviteCode}`,
      { headers }
    );

    return data;
  }

  async getInviteSummary(
    inviteCode: string,
    partnerId?: string
  ): Promise<IInviteSummary> {
    const headers: Record<string, string> = partnerId
      ? { "partner-id": partnerId }
      : {};

    const { data } = await apiClient.get(
      `${this.basePath}/${inviteCode}/summary`,
      { headers }
    );

    return data.response;
  }

  async getInviteCodeConversions(
    inviteCode: string
  ): Promise<IInviteSummaryConversionDetailed[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${inviteCode}/conversions`
    );

    return data.response;
  }

  async exportInviteCodeConversions(inviteCode: string) {
    const { data, headers } = await apiClient.get(
      `${this.basePath}/${inviteCode}/conversions/export`,
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return { data, headers };
  }

  async resendInvite(
    invite: IInvite
  ): Promise<GenericResponse<ISentInviteOverviewResponse>> {
    const { data } = await apiClient.put(this.basePath, [invite]);

    return data;
  }

  async sendInvites(
    invites: IInvite[]
  ): Promise<GenericResponse<ISentInviteOverviewResponse>> {
    const { data } = await apiClient.post(this.basePath, invites);

    return data;
  }

  async validateInvite(
    code: string
  ): Promise<GenericResponse<IValidateInvite>> {
    const { data } = await uninterceptedApiClient.post(
      `${this.basePath}/code/${code}/validate`
    );

    return data;
  }

  async confirmInvite(code: string): Promise<GenericResponse<void>> {
    const { data } = await apiClient.post(
      `${this.basePath}/confirm/${code}`,
      {}
    );

    return data;
  }
}

export default new InviteService();
