export const enum DEFAULT_PAGE {
  title = "Emerge9 Application",
  description = "Emerge9 - Co-invest alongside the top private equity managers. We provide exclusive access to a diversified set of co-investments with a focus on liquidity and transparency",
}

export enum PODCASTS_PAGE {
  title = "Podcasts",
  description = "Podcasts. Exploring the growing opportunities in GP-led secondary markets with the Emerge9 team and key industry partners and thought leaders.",
}

export enum SIGN_IN_PAGE {
  title = "Sign In",
  description = "Sign In. Exploring the growing opportunities in GP-led secondary markets with the Emerge9 team and key industry partners and thought leaders.",
}
