import React from "react";
import clsx from "clsx";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { CircleClose } from "ui/icons";

interface DealHistoricalPerformanceImageProps {
  imageUrl: string;
}

export const DealHistoricalPerformanceImage = ({
  imageUrl,
}: DealHistoricalPerformanceImageProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    if (imageUrl && !open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={clsx({
        "cursor-pointer": imageUrl,
      })}
      onClick={handleClick}
    >
      <img src={imageUrl} width="100%" alt="Thumbnail" />
      <Modal
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e?.stopPropagation();
        }}
        disableAutoFocus
      >
        <Paper className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 space-y-4">
          <div className="flex justify-end">
            <IconButton className="p-0" onClick={handleClose}>
              <CircleClose />
            </IconButton>
          </div>
          <img
            src={imageUrl}
            className="max-h-[90vh] max-w-[90vw]"
            alt="Full thumbnail"
          />
        </Paper>
      </Modal>
    </div>
  );
};

export default DealHistoricalPerformanceImage;
