import { FC, useCallback, useState } from "react";
import { LINK_TABS_HEIGHT } from "ui/components/LinkTabs";
import CompanyCard from "ui/components/CompanyCard/CompanyCard";
import { useErrorHandler, useOrganization, useOrganizationId } from "ui/hooks";
import { FeedbackError } from "application/errors";
import organizationService from "services/organization.service";

const CompanyCardWrapper: FC = () => {
  const organizationId = useOrganizationId();
  const { data, mutate } = useOrganization(organizationId);
  const [isFollowLoading, setFollowLoading] = useState(false);
  const { handleError } = useErrorHandler();

  const followToggle = useCallback(() => {
    if (!data)
      throw new FeedbackError(
        "It was not possible to find the target company."
      );

    if (data.following) {
      return organizationService.unFollowOrganization(data.id);
    }

    return organizationService.followOrganization(data.id);
  }, [data]);

  const handleFollowClick = useCallback(async () => {
    setFollowLoading(true);

    try {
      await followToggle();
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to proceed with follow interaction.");
    } finally {
      setFollowLoading(false);
    }
  }, [followToggle, handleError, mutate]);

  return (
    <div className="sticky" style={{ top: `calc(24px + ${LINK_TABS_HEIGHT})` }}>
      <CompanyCard
        company={data}
        onFollowClick={handleFollowClick}
        isFollowLoading={isFollowLoading}
      />
    </div>
  );
};

export default CompanyCardWrapper;
