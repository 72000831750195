import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RegistrationState {
  isFederatedSignUp: boolean;
  isRegularSignUp: boolean;
}

const initialState: RegistrationState = {
  isFederatedSignUp: false,
  isRegularSignUp: false,
};

const registrationState = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setIsFederatedSignUp(state, { payload }: PayloadAction<boolean>) {
      state.isFederatedSignUp = payload;
    },
    setIsRegularSignUp(state, { payload }: PayloadAction<boolean>) {
      state.isRegularSignUp = payload;
    },
  },
});

export const { setIsFederatedSignUp, setIsRegularSignUp } =
  registrationState.actions;
export default registrationState.reducer;
