import type { SVGProps } from "react";
const SvgBullpenLogoText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 18"
    fill="none"
    {...props}
  >
    <path d="M0 14v-1.5h1.95v-11H0V0h6.982q1.215 0 2.149.44.954.44 1.472 1.24.537.78.537 1.84v.16q0 .98-.378 1.62-.378.62-.895.98a4.2 4.2 0 0 1-1.015.5v.28q.498.12 1.035.48.537.34.895.98.378.64.378 1.62v.2q0 1.14-.557 1.98a3.47 3.47 0 0 1-1.492 1.26q-.935.42-2.148.42zm3.62-1.52h3.164q1.313 0 2.009-.62t.696-1.68V10q0-1.06-.696-1.68t-2.01-.62H3.622zm0-6.3h3.223q1.194 0 1.91-.6.716-.62.716-1.62v-.2q0-1.02-.716-1.62-.695-.62-1.91-.62H3.621zM17.19 14.18a4.2 4.2 0 0 1-1.95-.46 3.4 3.4 0 0 1-1.392-1.36q-.497-.92-.497-2.22V4.22h1.571v5.8q.001 1.46.716 2.12.737.66 1.97.66 1.392 0 2.288-.92t.895-2.74V4.22h1.571V14h-1.531v-1.66h-.279q-.318.7-1.094 1.28-.776.56-2.268.56M25.253 14V0h1.571v14zM29.731 14V0h1.572v14zM33.21 18V4.22h1.531v1.62h.279q.378-.74 1.213-1.32.856-.58 2.367-.58 1.254 0 2.288.62 1.054.6 1.671 1.72.637 1.12.637 2.7v.26q0 1.56-.617 2.7t-1.671 1.74q-1.035.6-2.308.6-1.014 0-1.73-.28-.696-.26-1.134-.68a3.6 3.6 0 0 1-.677-.86h-.278V18zm4.973-5.12q1.512 0 2.447-.96.954-.98.954-2.72v-.18q0-1.74-.955-2.7-.934-.98-2.446-.98-1.492 0-2.467.98-.955.96-.955 2.7v.18q0 1.74.955 2.72.975.96 2.467.96M50.07 14.28q-1.473 0-2.586-.62a4.46 4.46 0 0 1-1.731-1.78q-.617-1.14-.617-2.64V9q0-1.52.617-2.66a4.36 4.36 0 0 1 1.71-1.76q1.095-.64 2.507-.64 1.372 0 2.427.6a4.1 4.1 0 0 1 1.651 1.68q.597 1.1.597 2.58v.72h-7.937q.06 1.58 1.014 2.48.956.88 2.388.88 1.252 0 1.93-.58.675-.58 1.034-1.38l1.352.66a5.7 5.7 0 0 1-.835 1.24q-.518.62-1.373 1.04-.836.42-2.148.42m-3.342-6.06h6.326q-.08-1.36-.935-2.12-.835-.76-2.149-.76-1.333 0-2.188.76t-1.054 2.12M56.989 14V4.22h1.531v1.66h.279q.318-.7 1.074-1.26.776-.58 2.268-.58 1.094 0 1.95.46.874.46 1.392 1.36T66 8.08V14h-1.572V8.2q0-1.46-.736-2.12-.715-.66-1.95-.66-1.41 0-2.307.92-.875.92-.875 2.74V14z" />
  </svg>
);
export default SvgBullpenLogoText;
