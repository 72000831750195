import { AutoAwesome, Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import { AIButton } from "ui/components/Button";
import * as paths from "ui/Router/paths";
import styles from "./styles.module.scss";

interface BetaBannerProps {
  dark?: boolean;
  onClose: () => void;
}

export const BetaBanner: FC<BetaBannerProps> = ({ dark, onClose }) => {
  return (
    <div
      className={clsx(styles.Banner, dark ? "bg-[#060A14]" : "bg-primary-600")}
    >
      <div className="m9-container !py-0 w-full flex justify-between items-center">
        <div className="grow flex flex-col md:flex-row justify-center items-center">
          <div className="flex items-center shrink-0">
            <div className="rounded-lg bg-white bg-opacity-10 h-10 w-10 flex items-center justify-center shrink-0 grow-0">
              <AutoAwesome className="text-[1.5rem] text-white" />
            </div>
            <div className="flex flex-col ml-4">
              <Typography variant="body2" className="font-bold text-white">
                Boost Your Productivity with Analyst AI!
              </Typography>
              <Typography variant="caption2" className="text-gray-400">
                Standalone feature launching soon. Join the waitlist for early
                access!
              </Typography>
            </div>
          </div>
          <div className="ml-0 md:ml-8 mt-4 md:mt-0">
            <Link to={"/" + paths.landingAIWaitList}>
              <AIButton>
                <Typography variant="hairline2" className="text-inherit">
                  JOIN WAITLIST
                </Typography>
              </AIButton>
            </Link>
          </div>
        </div>
        <div className="shrink-0 grow-0">
          <IconButton
            className="text-white hover:bg-black hover:bg-opacity-75 active:bg-black focus:bg-black"
            onClick={() => onClose()}
          >
            <Close />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
