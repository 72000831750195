import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { ParticipationTypeEnum } from "domain/deal";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hidePodcastModal, resetUI } from "redux/UI/reducer";
import dealService from "services/deal.service";
import { useTypedSelector } from "ui/hooks";
import { useDealParticipationRequestErrorHandler } from "ui/hooks/Deal/useDealParticipationRequestErrorHandler";
import * as paths from "ui/Router/paths";
import ModalComponent from "../ModalComponent";
import { usePaths } from "ui/hooks/usePaths";

export const PodcastAccessModal: FC = () => {
  const dispatch = useDispatch();
  const { showPodcastModal: open, podcastModalDealId: dealId } =
    useTypedSelector((state) => state.UI);
  const { authorized } = useTypedSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const { handleError } = useDealParticipationRequestErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { mainRedirectPath } = usePaths();

  const onClose = useCallback(() => {
    dispatch(hidePodcastModal());
  }, [dispatch]);

  const handleAccessRequestClick = useCallback(async () => {
    setLoading(true);

    try {
      if (!dealId) {
        dispatch(resetUI());
        throw new FeedbackError(
          "It was not possible to find the related deal."
        );
      }

      const payload = {
        participationType: ParticipationTypeEnum.FundAccess,
      };

      await dealService.sendParticipationRequest(dealId, payload);

      enqueueSnackbar(
        "Your request to access the deal has been sent to the deal manager. We will send you a notification once access is granted.",
        {
          title: "Request sent",
          variant: "success",
        }
      );
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  }, [dealId, enqueueSnackbar, dispatch, handleError]);

  const actionButton = useMemo(() => {
    if (!authorized) {
      return (
        <Link to={"/" + paths.signInPath} state={{ from: pathname }}>
          <Button onClick={onClose} fullWidth variant="contained">
            Log in
          </Button>
        </Link>
      );
    }

    if (dealId) {
      return (
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleAccessRequestClick}
        >
          Request access
        </LoadingButton>
      );
    }

    return (
      <Link to={mainRedirectPath}>
        <Button onClick={onClose} fullWidth variant="contained">
          Check deals
        </Button>
      </Link>
    );
  }, [
    authorized,
    dealId,
    handleAccessRequestClick,
    isLoading,
    onClose,
    pathname,
    mainRedirectPath,
  ]);

  return (
    <ModalComponent
      title="Access to private podcast"
      open={open}
      onClose={onClose}
      smallTitle
    >
      <div className="flex flex-col h-full">
        <Typography variant="body2" className="text-dark-text">
          To access private materials, you need to have:
          <ul>
            <li>Investor account</li>
            <li>Access to private materials</li>
          </ul>
        </Typography>
        <div className="mt-auto pt-8">{actionButton}</div>
      </div>
    </ModalComponent>
  );
};
