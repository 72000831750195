import { formatRFC3339, subSeconds } from "date-fns";
import { AssistantThreadMessageRole } from "domain/assistant";
import { isEmpty, keys, sortBy, uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { IAssistantMessage } from "types/assistant.service";

export const useAssistantStream = (dealId: string) => {
  const [sourceMessage, setSourceMessage] = useState<string | undefined>();
  const [value, setValue] = useState<Record<string, string>>({});

  const isStreaming = useMemo(() => {
    return !isEmpty(value);
  }, [value]);

  const resetValue = useCallback(() => {
    setValue({});
    setSourceMessage(undefined);
  }, []);

  const readStream = useCallback(
    async (stream: ReadableStream, sourceMessage?: string) => {
      const reader = stream.getReader();
      const decoder = new TextDecoder("utf-8");

      setSourceMessage(sourceMessage);

      let isDone = false;

      while (!isDone) {
        const { done, value: rawValue } = await reader.read();

        isDone = done;

        const value = decoder.decode(rawValue).replace(/\[KEEP_ALIVE\]/g, "");

        if (!value) continue;

        const groupedValue = value
          .split("\n\n")
          .filter(Boolean)
          .reduce((acc, item) => {
            const jsonValue = JSON.parse(item);
            const [currentKey] = Object.keys(jsonValue);
            const currentValue = jsonValue[currentKey];
            const accumulatedValueForKey = acc[currentKey] || "";

            return {
              ...acc,
              [currentKey]: accumulatedValueForKey + currentValue,
            };
          }, {} as Record<string, string>);

        setValue((current) => {
          const allKeys = uniq([...keys(current), ...keys(groupedValue)]);

          const result = allKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: (current[key] || "") + (groupedValue[key] || ""),
            }),
            {}
          );

          return result;
        });
      }

      reader.releaseLock();
    },
    []
  );

  const streamMessages = useMemo(() => {
    if (isEmpty(value)) return [];

    const assistantMessages = Object.keys(value).map((key) => {
      const content = value[key];

      const [createdAt, id] = key.split("#id#");

      return {
        assistantId: dealId,
        content: content,
        role: AssistantThreadMessageRole.Assistant,
        createdAt,
        messageId: key,
        openAiId: id,
        threadId: id,
        updatedAt: createdAt,
      } as IAssistantMessage;
    });

    const sortedAssistantMessages = sortBy(
      assistantMessages,
      (message) => message.createdAt
    ).reverse();

    const tempMessageDate = formatRFC3339(
      subSeconds(new Date(sortedAssistantMessages[0]?.createdAt), 20)
    );

    const tempMessageId = `${tempMessageDate}#id#${sourceMessage}`;

    const sourceMessages: IAssistantMessage[] = sourceMessage
      ? [
          {
            assistantId: dealId,
            content: sourceMessage as string,
            role: AssistantThreadMessageRole.User,
            createdAt: tempMessageDate,
            messageId: tempMessageId,
            openAiId: tempMessageId,
            threadId: tempMessageId,
            updatedAt: tempMessageDate,
          },
        ]
      : [];

    return [...sourceMessages, ...assistantMessages];
  }, [dealId, sourceMessage, value]);

  const data = useMemo(
    () => ({
      readStream,
      resetValue,
      streamMessages,
      isStreaming,
    }),
    [isStreaming, readStream, resetValue, streamMessages]
  );

  return data;
};
