import Button from "@mui/material/Button";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { isString } from "lodash";
import { FC, SVGProps, VFC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSyndicationLayout } from "ui/hooks";
import "./styles.scss";

export interface IAsideItem {
  label: string;
  Icon?:
    | VFC<SVGProps<SVGSVGElement>>
    | (OverridableComponent<SvgIconTypeMap> & { muiName: string });
  to?: string;
  onClick?: () => void;
  hiddenOnSyndication?: boolean;
  active?: boolean;
}

interface Params {
  asideItems: IAsideItem[];
  baseLocation?: string;
  className?: string;
  title?: string;
}

const AsideItem: FC<IAsideItem & { baseLocation?: string }> = (
  { label, Icon, to, onClick, baseLocation, active },
  index
) => {
  const location = useLocation();

  const hasRouteTo = useMemo(() => isString(to), [to]);

  const isActive = useMemo(
    () =>
      hasRouteTo
        ? `/${baseLocation}${to}` === `${location.pathname}${location.hash}`
        : active,
    [active, baseLocation, location.hash, location.pathname, to, hasRouteTo]
  );

  const routeTo = hasRouteTo ? `/${baseLocation}${to}` : "";

  return (
    <Button
      component={hasRouteTo ? Link : Button}
      to={routeTo}
      onClick={onClick}
      variant={isActive ? "contained" : "text"}
      className={`flex justify-start hover:text-gray-500 leading-none w-full text-left items-center rounded-lg h-12 ${
        isActive ? "bg-primary-0 bg-opacity-10" : "text-gray-500"
      }`}
      startIcon={
        Icon ? (
          <div className="w-8">
            <Icon
              fontSize="medium"
              className={`${
                isActive ? "aside-active-icon" : "aside-icon"
              } mr-2`}
            />
          </div>
        ) : undefined
      }
      key={index}
    >
      {isActive ? (
        <Typography variant="button1">{label}</Typography>
      ) : (
        <>{label}</>
      )}
    </Button>
  );
};

const Aside = ({ asideItems, title, baseLocation = "", className }: Params) => {
  const { isSyndicate } = useSyndicationLayout();

  return (
    <div className="mb-5">
      {title && (
        <Typography
          variant="body1"
          className="text-gray-400 font-bold px-5 py-2 mb-3"
        >
          {title}
        </Typography>
      )}
      <aside className={clsx("space-y-2", className)}>
        {asideItems &&
          asideItems
            .filter((item) => !(isSyndicate && item.hiddenOnSyndication))
            .map((props, index) => (
              <AsideItem {...props} baseLocation={baseLocation} key={index} />
            ))}
      </aside>
    </div>
  );
};

export default Aside;
