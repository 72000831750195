import { useMemo } from "react";
import dealAssistantService from "services/dealAssistant.service";
import useSWR from "swr";

export const useDealAssistantFields = (dealId?: string) => {
  const { data: fieldsData } = useSWR(
    dealId && `fund/${dealId}/assistant/fields`,
    () => dealAssistantService.getFields(dealId as string)
  );

  const fieldsIndex = useMemo(() => {
    if (!fieldsData) return {};

    return fieldsData.reduce(
      (agg, item) => ({
        ...agg,
        [item.fieldId]: true,
      }),
      {} as Record<string, boolean>
    );
  }, [fieldsData]);

  return fieldsIndex || {};
};
