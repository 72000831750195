import { Block } from "@mui/icons-material";
import {
  Badge,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  linearProgressClasses,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { FormatType } from "domain/common";
import { isEmpty } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Control, useController } from "react-hook-form";
import {
  ICampaignContactList,
  ICampaignContactListByPartner,
  ICampaignQuota,
} from "types/campaign.service";
import {
  CardSection,
  Checkbox,
  FormInputWrapper,
  FormSelectController,
} from "ui/components";
import { FormattedValue } from "ui/components/FormattedValue";
import { FormInputs } from "./CampaignCreate.form";

interface AttachContentListProps<T> {
  title?: string;
  data: T[];
  getLabel: (item: T) => string;
  getValue: (item: T) => string;
  getDisabled?: (item: T) => boolean;
  control: Control<FormInputs>;
  name: keyof Pick<FormInputs, "promotedFundIds" | "promotedMediaIds">;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  withoutApproval?: boolean;
}

export function AttachContentList<T>({
  getDisabled = () => false,
  getLabel,
  getValue,
  control,
  data,
  name,
  title,
  placeholder,
  loading,
  disabled,
  withoutApproval,
}: AttachContentListProps<T>) {
  const { field } = useController({ name, control });
  const [isActive, setIsActive] = useState(!isEmpty(field.value));

  const { onChange } = field;

  useEffect(() => {
    if (withoutApproval) {
      setIsActive(withoutApproval);
    }
  }, [withoutApproval]);

  useEffect(() => {
    if (!isActive && onChange) {
      onChange({ target: { value: [] } });
    }
  }, [isActive, onChange]);

  const valueToLabelMap = useMemo(() => {
    if (!data) return {};

    return data.reduce((out: Record<string, string>, current: T) => {
      const currentValue = getValue(current);
      const currentLabel = getLabel(current);

      return {
        ...out,
        [currentValue]: currentLabel,
      };
    }, {});
  }, [data, getLabel, getValue]);

  return (
    <div className="flex flex-col w-full">
      <div>
        {!withoutApproval && (
          <FormControlLabel
            className="m-0"
            label={
              <Typography
                variant="caption"
                className="text-dark-text font-bold"
              >
                {title}
              </Typography>
            }
            control={
              <Checkbox
                checked={isActive}
                disabled={disabled}
                onChange={() => setIsActive(!isActive)}
              />
            }
          />
        )}
      </div>
      <div className="mt-5">
        <FormSelectController
          selectAll
          selectAllDisabled={!data.length}
          handleSelectAll={(status) => {
            onChange({
              target: {
                value: data.map((item) => (status ? getValue(item) : [])),
              },
            });
          }}
          placeholder={placeholder}
          displayEmpty
          className="w-full max-w-full overflow-hidden"
          classes={{
            select: "overflow-hidden",
          }}
          MenuProps={{ PaperProps: { className: "!max-h-[15em]" } }}
          disabled={!isActive}
          renderValue={(selected) => {
            if (isEmpty(selected))
              return <div className="text-gray-300">{placeholder}</div>;

            return (selected as string[])
              .map((value) => valueToLabelMap[value] || "Not found")
              .join(", ");
          }}
          multiple
          control={control}
          name={name}
          showError
        >
          {loading
            ? [
                <MenuItem key={1} disabled>
                  Loading...
                </MenuItem>,
              ]
            : data.map((item) => {
                const value = getValue(item);
                const isDisabled = getDisabled(item);

                return (
                  <MenuItem disabled={isDisabled} key={value} value={value}>
                    <Checkbox
                      size="small"
                      disabled={isDisabled}
                      checked={(field?.value?.indexOf(value) ?? -1) > -1}
                    />
                    <ListItemText primary={getLabel(item)} />
                  </MenuItem>
                );
              })}
        </FormSelectController>
      </div>
    </div>
  );
}

interface ScrollableListSearchProps<T> {
  data?: ICampaignContactListByPartner[];
  getDisabled?: (item: T) => boolean;
  control: Control<FormInputs>;
  name: keyof Pick<FormInputs, "contactListIds" | "excludedContactListIds">;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  label: string;
  indexedContactListsRecipientAmount: Record<string, number>;
}

export function ScrollableListSearch<T>({
  data,
  name,
  label,
  disabled,
  control,
  indexedContactListsRecipientAmount,
}: ScrollableListSearchProps<T>) {
  const [filter, setFilter] = useState("");
  const { field, fieldState } = useController({ control, name });

  const summaryHelperText = useMemo(() => {
    if (!data) return "";

    const listsSelected = Object.keys(field.value).filter(
      (key) => field.value[key]
    );
    const recipientAmount = listsSelected.reduce((out, key) => {
      return out + (indexedContactListsRecipientAmount[key] || 0);
    }, 0);

    return `${recipientAmount} recipients from ${listsSelected.length} lists`;
  }, [data, field.value, indexedContactListsRecipientAmount]);

  const checkIsAllSelected = useCallback(
    (contactLists: ICampaignContactList[]) => {
      return contactLists
        .filter((list) => list.totalOfRecipients > 0)
        .every(({ id }) => field.value[id]);
    },
    [field.value]
  );

  const onSelectAllToggle = useCallback(
    (contactLists: ICampaignContactList[]) => {
      if (disabled) return;
      const isAllSelected = checkIsAllSelected(contactLists);
      const allChanged = contactLists
        .filter((list) => list.totalOfRecipients > 0)
        .reduce((out, item) => ({ ...out, [item.id]: !isAllSelected }), {});

      field.onChange({
        target: { value: { ...field.value, ...allChanged } },
      });
    },
    [checkIsAllSelected, disabled, field]
  );

  return (
    <div className="flex flex-col w-full">
      <FormInputWrapper
        label={label}
        helperText={summaryHelperText}
        errorMessage={fieldState.error?.message}
      >
        <TextField
          placeholder="Filter lists"
          className="w-full"
          value={filter}
          size="medium"
          error={!!fieldState.error?.message}
          onChange={(e) => setFilter(e.target.value)}
          sx={{
            "& fieldset": {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          inputProps={{
            className: "py-3",
          }}
          InputProps={{
            inputProps: {
              className: "rounded-b-none",
              name: field.name,
              ref: field.ref,
            },
          }}
        />
        <List
          className={clsx(
            fieldState.error?.message ? "border-error" : "border-gray-200",
            "w-full h-80 overflow-auto bg-gray-100 rounded-xl border-2 border-t-0 rounded-t-none  border-solid"
          )}
          sx={{
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {data?.map(({ partner, contactLists, hasAccessToAttachedFunds }) => {
            const selectedCount = contactLists.reduce((out, item) => {
              if (field.value[item.id]) {
                return out + 1;
              }

              return out;
            }, 0);

            const showAll = partner.shortName
              ?.toLowerCase()
              .includes(filter.toLowerCase());

            const filtered = showAll
              ? contactLists
              : contactLists.filter(({ title }) =>
                  title?.toLowerCase()?.includes(filter.toLowerCase())
                );

            if (filtered.length === 0) {
              return null;
            }

            return (
              <li key={`section-${partner.id}`}>
                <ul>
                  <ListSubheader
                    className={clsx(
                      (fieldState.error?.message ||
                        !hasAccessToAttachedFunds) &&
                        "shadow-error",
                      "shadow-sm flex justify-between items-center py-3"
                    )}
                  >
                    <Badge
                      componentsProps={{
                        badge: { className: "-right-3 top-1" },
                      }}
                      color="primary"
                      badgeContent={selectedCount}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        className="text-gray-800 font-semibold"
                      >
                        {partner.shortName}
                      </Typography>
                    </Badge>
                    <div className="flex items-center space-x-1 ml-2">
                      {!hasAccessToAttachedFunds && (
                        <Tooltip
                          placement="top"
                          title="The partner doesn't have access to one or more of the attached deals."
                          arrow
                        >
                          <IconButton size="small">
                            <Block className="text-error" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Typography
                        component="span"
                        variant="caption2"
                        onClick={() => onSelectAllToggle(contactLists)}
                        className="font-bold cursor-pointer select-none text-gray-400 hover:text-gray-500"
                        tabIndex={-1}
                      >
                        {checkIsAllSelected(contactLists)
                          ? "Deselect all"
                          : "Select all"}
                      </Typography>
                    </div>
                  </ListSubheader>
                  {filtered.map(
                    ({ id, title, totalOfRecipients, isSyncing }) => {
                      const value = !!field.value[id];
                      const isInvalid = totalOfRecipients === 0;
                      const blockChanges = isInvalid || disabled || isSyncing;

                      return (
                        <label key={id}>
                          <ListItem
                            key={`item-${partner}-${id}`}
                            className="hover:bg-primary-0 transition-colors duration-75 cursor-pointer"
                          >
                            <ListItemIcon className="pl-2">
                              <Checkbox
                                name={id}
                                checked={value}
                                tabIndex={-1}
                                disabled={blockChanges}
                                onChange={(_) =>
                                  field.onChange({
                                    target: {
                                      value: {
                                        ...field.value,
                                        [id]: !value,
                                      },
                                    },
                                  })
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <div
                                  className={clsx(
                                    blockChanges && "text-gray-300",
                                    "flex justify-between items-center"
                                  )}
                                >
                                  <div className="w-full truncate py-1">
                                    {title}
                                  </div>
                                  <div className="ml-2">
                                    {isSyncing
                                      ? "Syncing..."
                                      : `(${totalOfRecipients})`}
                                  </div>
                                </div>
                              }
                            />
                          </ListItem>
                        </label>
                      );
                    }
                  )}
                </ul>
              </li>
            );
          })}
        </List>
      </FormInputWrapper>
    </div>
  );
}

interface QuotaCalculatorProps {
  quota: ICampaignQuota;
  selectedAmount: number;
}

export const QuotaCalculator: FC<QuotaCalculatorProps> = ({
  quota,
  selectedAmount,
}) => {
  const { monthlyLimit, sentOrScheduled } = quota;
  const theme = useTheme();
  const total = quota.sentOrScheduled + selectedAmount;
  const monthlyUsagePercentage = Math.min(
    (quota.sentOrScheduled / quota.monthlyLimit) * 100,
    100
  );
  const totalUsagePercentage = Math.min(
    (total / quota.monthlyLimit) * 100,
    100
  );
  const isExceeded = totalUsagePercentage >= 100;

  return (
    <CardSection
      outsideTitle="Monthly usage"
      className={isExceeded ? "!border-error" : ""}
    >
      <LinearProgress
        className="w-full"
        variant="buffer"
        value={monthlyUsagePercentage}
        valueBuffer={totalUsagePercentage}
        sx={{
          height: 10,
          borderRadius: 10,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.customColors.gray[200],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: !isExceeded
              ? theme.customColors.primary[200]
              : theme.palette.error.main,
          },
          [`& .${linearProgressClasses.bar1Buffer}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
          },
          [`& .${linearProgressClasses.dashed}`]: {
            borderRadius: 5,
            backgroundImage: "none",
            animation: "none",
          },
        }}
      />
      <div className="flex justify-between items-start mt-1">
        <div className="flex flex-col space-y-1/2">
          <Typography variant="caption">
            <b>
              <FormattedValue
                value={selectedAmount}
                formatType={FormatType.Number}
              />
            </b>{" "}
            on this campaign
          </Typography>
          <Typography variant="caption">
            <b>
              <FormattedValue
                value={sentOrScheduled}
                formatType={FormatType.Number}
              />
            </b>{" "}
            used
          </Typography>

          <Typography
            variant="caption"
            className={isExceeded ? "text-error" : ""}
          >
            <b>
              <FormattedValue
                value={sentOrScheduled + selectedAmount}
                formatType={FormatType.Number}
              />
            </b>{" "}
            total
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            <FormattedValue
              value={monthlyLimit}
              formatType={FormatType.Number}
            />
          </Typography>
        </div>
      </div>
    </CardSection>
  );
};
