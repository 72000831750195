import applicationStatusService from "services/version.service";
import useSWR, { SWRConfiguration } from "swr";

export const useApplicationVersion = (config?: SWRConfiguration<string>) => {
  return useSWR(
    "/ApplicationService/latest-version",
    () => applicationStatusService.getLatestWebappVersion(),
    config
  );
};
