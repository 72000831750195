import { createTypedFormData, delayAsync } from "application/utils";
import {
  IChangePartnerCampaignSettings,
  ICreateSyndicationPartner,
  ICreateSyndicationPartnerAddress,
  ICreateSyndicationPartnerBranding,
  ICreateSyndicationPartnerLegalInfo,
  ICreateSyndicationPartnerResponse,
  ICreateSyndicationPartnerSettings,
  ISyndication,
  ISyndicationPartner,
  ISyndicationPartnerAddress,
  ISyndicationPartnerBranding,
  ISyndicationPartnerLayout,
  ISyndicationPartnerLegalInfo,
  ISyndicationPartners,
  ISyndicationPartnerSettings,
} from "types/syndication.service";
import { apiClient, uninterceptedApiClient } from "./clients";
import { DealStatusEnum } from "domain/deal";
import { SyndicationStatus } from "domain/syndication";

class SyndicationService {
  private readonly basePath = "/Syndication";

  async getSyndicationList(config = {}): Promise<ISyndication[]> {
    const mockData = {
      email: "test@example.com",
      link: "http://link.example.com:3000",
      phoneNumber: "+5511988893317",
      status: SyndicationStatus.Pending,
      deal: {
        id: "a76809e1-06c0-4f2c-9c6f-04ad64c8ee8c",
        ownerId: "76900dfa-2fb9-5282-ad16-ae694dc467f2",
        availablePercent: 0,
        fundOwner: {
          username: "9ba52af2-0e11-59f8-b3f7-789d6560d58e",
          name: "Cole",
          familyName: "Dennis",
          job: "aCxNDqErjQC",
          email: "",
        },
        imageUrl:
          "https://www.designevo.com/res/templates/thumb_small/blue-and-yellow-mansion.webp?v=1.0.0.2",
        longDescription:
          '<p style="text-align:start;"><span style="color: rgba(0,0,0,0.87);background-color: rgb(252,252,253);font-size: 16px;font-family: IBM Plex Sans;">Invest with a health- and wellness-focused PE sponsor in a high-growth opportunity in the US fitness sector. The target company has generated impressive performance and is poised for continued growth driven by an exceptional manageme</span></p>\n<p style="text-align:start;"></p>\n<p><br>Agora sim</p>\n<ul>\n<li>Invest with a health- and wellness-focused PE sponsor in a high-growth opportunity in the US fitness sector.</li>\n<li>Numero 2</li>\n<li>Numero 3</li>\n<ul>\n<li>Numero 3.1</li>\n<li>Numero 3.2</li>\n<ul>\n<li>Numero 3.2.1</li>\n</ul>\n</ul>\n</ul>\n<p style="text-align:start;"></p>\n<p style="text-align:left;"></p>\n',
        liked: false,
        name: "AXB Fund Trinity",
        url: "axb-fund-trinity",
        sector: ["semiconductor"],
        geographicalRegion: ["western-europe"],
        equityType: ["venture"],
        dealType: ["gp-led"],
        completedRegistrationSteps: {
          leadInvestor: true,
          historicalPerformance: true,
          keyBiographies: true,
          keyHighlights: true,
          summary: true,
          advisor: true,
          sponsor: true,
        },
        assetType: "single-asset",
        fundSize: 120000000,
        availablePart: 0,
        initialAvailablePart: 80000000,
        actualCommitedAmount: 90055000,
        minContribution: 500000,
        startDate: "1652103642261",
        endDate: "1693057242000",
        isPublished: true,
        isQuestionAndAnswersEnabled: true,
        isClosed: false,
        shortDescription:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et fermentum dui. Ut orci quam, ornare sed lorem sed, hendrerit auctor dolor. Nulla viverra, nibh quis ultrices malesuada, ligula ipsum vulputate diam, aliquam egestas nibh ante vel dui. Sed in tellus interdum eros vulputate placerat sed non enim. Pellentesque eget.",
        publicDescription:
          "<p>Lottie is graduated from Hardvard dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>\n<p></p>\n<p>Lottie is graduated from Hardvard dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lottie is graduated from Hardvard dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;</p>\n<p></p>\n<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...</p>\n",
        status: DealStatusEnum.Available,
        categories: {
          sector: ["semiconductor"],
          geographicalRegion: ["western-europe"],
          dealType: ["gp-led"],
          equityType: ["venture"],
          assetType: "single-asset",
        },
        fundId: "a76809e1-06c0-4f2c-9c6f-04ad64c8ee8c",
        alias: "1234",
      },
    } as any;

    await delayAsync(3000);

    return [mockData];

    // return apiClient.get("/Syndication");
  }

  async createSyndicationPartner(
    payload: ICreateSyndicationPartner
  ): Promise<ICreateSyndicationPartnerResponse> {
    const { data } = await apiClient.post(this.basePath, payload);

    return data.response;
  }

  async uploadPartnerImages(
    partnerId: string,
    payload: FormData
  ): Promise<void> {
    await apiClient.post(`${this.basePath}/${partnerId}/logo`, payload);
  }

  async updateSyndicationPartner(
    partnerId: string,
    payload: ICreateSyndicationPartner
  ): Promise<ICreateSyndicationPartnerResponse> {
    const { data } = await apiClient.put(
      `${this.basePath}/${partnerId}`,
      payload
    );

    return data.response;
  }

  async getSyndicationPartnerCampaignSettings(
    partnerId: string
  ): Promise<IChangePartnerCampaignSettings> {
    const { data } = await apiClient.get(
      `${this.basePath}/${partnerId}/settings`
    );

    return data.response;
  }

  async updateSyndicationPartnerCampaignSettings(
    partnerId: string,
    payload: IChangePartnerCampaignSettings
  ): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/${partnerId}/settings`,
      payload
    );

    return data.response;
  }

  async getSyndicationPartnerAddress(
    partnerId: string
  ): Promise<ISyndicationPartnerAddress> {
    const { data } = await apiClient.get(
      `${this.basePath}/${partnerId}/company/address`
    );

    return data.response || {};
  }

  async createOrUpdateSyndicationPartnerAddress(
    partnerId: string,
    payload: ICreateSyndicationPartnerAddress
  ): Promise<ISyndicationPartnerAddress> {
    const { data } = await apiClient.post(
      `${this.basePath}/${partnerId}/company/address`,
      payload
    );

    return data.response;
  }

  async getSyndicationPartnerLegalInfo(
    partnerId: string
  ): Promise<ISyndicationPartnerLegalInfo> {
    const { data } = await apiClient.get(
      `${this.basePath}/${partnerId}/company/legal-info`
    );

    return data.response || {};
  }

  async createOrUpdateSyndicationPartnerLegalInfo(
    partnerId: string,
    payload: ICreateSyndicationPartnerLegalInfo
  ): Promise<ISyndicationPartnerLegalInfo> {
    const { data } = await apiClient.post(
      `${this.basePath}/${partnerId}/company/legal-info`,
      createTypedFormData(payload)
    );

    return data.response;
  }

  async getSyndicationPartnerBranding(
    partnerId: string
  ): Promise<ISyndicationPartnerBranding> {
    const { data } = await apiClient.get(
      `${this.basePath}/${partnerId}/branding`
    );

    return data.response || {};
  }

  async createOrUpdateSyndicationPartnerBranding(
    partnerId: string,
    payload: ICreateSyndicationPartnerBranding
  ): Promise<ISyndicationPartnerBranding> {
    const { data } = await apiClient.post(
      `${this.basePath}/${partnerId}/branding`,
      createTypedFormData(payload)
    );

    return data.response;
  }

  async getSyndicationPartnerSettings(
    partnerId: string
  ): Promise<ISyndicationPartnerSettings> {
    const { data } = await apiClient.get(
      `${this.basePath}/${partnerId}/settings`
    );

    return data.response || {};
  }

  async createOrUpdateSyndicationPartnerSettings(
    partnerId: string,
    payload: ICreateSyndicationPartnerSettings
  ): Promise<ISyndicationPartnerSettings> {
    const { data } = await apiClient.post(
      `${this.basePath}/${partnerId}/settings`,
      payload
    );

    return data.response;
  }

  async getPartnerLayoutData(): Promise<ISyndicationPartnerLayout> {
    const { data } = await uninterceptedApiClient.get(`${this.basePath}/me`);

    return data.response;
  }

  async getPartnerList(
    lastEvaluatedKey?: string
  ): Promise<PaginatedTokenResponse<ISyndicationPartner>> {
    const { data } = await apiClient.get(this.basePath, {
      params: { lastEvaluatedKey },
    });

    return data.response;
  }

  async deletePartner(partnerId: string): Promise<void> {
    await apiClient.delete(`${this.basePath}/${partnerId}`);
  }

  async getPartner(partnerId: string): Promise<ISyndicationPartner> {
    const { data } = await apiClient.get(`${this.basePath}/${partnerId}`);

    return data.response;
  }

  async getManageableSyndicationPartners(): Promise<ISyndicationPartners> {
    const { data } = await apiClient.get(`${this.basePath}/available`);

    return data.response;
  }

  async promoteSyndicationUser(
    partnerId: string,
    customUserName: string
  ): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/promote/${customUserName}`,
      null,
      {
        headers: {
          "partner-id": partnerId,
        },
      }
    );

    return data.response;
  }

  async getSyndicationPartnersWithAccess(): Promise<ISyndicationPartners> {
    const { data } = await apiClient.get(`${this.basePath}/access`);

    return data.response;
  }

  async revokeSyndicationUser(
    partnerId: string,
    customUserName: string
  ): Promise<void> {
    const { data } = await apiClient.post(
      `${this.basePath}/revoke/${customUserName}`,
      null,
      {
        headers: {
          "partner-id": partnerId,
        },
      }
    );

    return data.response;
  }
}

export default new SyndicationService();
