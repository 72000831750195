import { FeedbackError } from "application/errors";
import dealService from "services/deal.service";
import { DealGeneralData, DealRegistrationSteps } from "types/deal";
import { useDealGeneralData } from "ui/hooks";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DealEditPageType } from "./DealForm.interface";

interface HandlePostUpdateParams {
  type: DealEditPageType;
  routeAfterSubmit?: string;
  stepName: keyof DealRegistrationSteps;
}

interface HandlePostUpdateCreateParams extends HandlePostUpdateParams {
  dealId: string;
}

interface HandlePostUpdateNavigateParams extends HandlePostUpdateParams {
  dealAlias: string;
}

export const useDealEditPostUpdate = () => {
  const navigate = useNavigate();
  const { data: dealData, mutate } = useDealGeneralData();

  const updateCreateProgress = useCallback(
    async ({
      type,
      stepName,
      dealId,
    }: Omit<HandlePostUpdateCreateParams, "routeAfterSubmit">) => {
      if (type !== DealEditPageType.Create) return;

      if (!dealData) {
        throw new FeedbackError("It was not possible to find the deal data.");
      }

      try {
        const { completedRegistrationSteps } = dealData as DealGeneralData;

        await dealService.updateDealRegistrationSteps(dealId, {
          ...completedRegistrationSteps,
          [stepName]: true,
        });

        mutate();
      } catch (e) {
        throw new FeedbackError("It was not possible to update the progress.");
      }
    },
    [dealData, mutate]
  );

  const goToTheNextRouteIfExists = useCallback(
    ({
      routeAfterSubmit,
      dealAlias,
    }: Omit<HandlePostUpdateNavigateParams, "type" | "stepName">) => {
      if (routeAfterSubmit) {
        navigate(routeAfterSubmit.replace(":alias", dealAlias));
      }
    },
    [navigate]
  );

  return {
    goToTheNextRouteIfExists,
    updateCreateProgress,
  };
};
