import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { differenceInDays, startOfDay } from "date-fns";
import format from "date-fns/format";
import { DealStatusEnum } from "domain/deal";
import { clamp } from "lodash";
import { useMemo } from "react";
import { CircularProgressWithLabel } from "ui/components";

interface Props {
  status: DealStatusEnum;
  amount: string;
  size: "medium" | "large";
  closeDate?: string;
}

const DealAvailable = ({ status, closeDate, amount, size }: Props) => {
  const sizeValue = size === "large" ? 80 : 52;
  const theme = useTheme();

  const statusValue = useMemo(() => {
    switch (status) {
      case DealStatusEnum.Draft:
        return { label: "Draft", color: "#EBBB27" };
      case DealStatusEnum.Available:
        return {
          label: "Available",
          color: theme.customColors.semantic.green200,
        };
      case DealStatusEnum.Closed:
        return { label: "Closed", color: theme.customColors.danger[300] };
      case DealStatusEnum.Deleted:
        return { label: "Deleted", color: theme.customColors.danger[100] };
      default:
        return { label: "Invalid", color: theme.customColors.gray[200] };
    }
  }, [status, theme]);

  const formattedCloseDate = useMemo(() => {
    if (!closeDate || isNaN(Number(closeDate))) return null;
    const formattedDate = format(new Date(Number(closeDate)), "MMM, d yyyy");

    return `Target close date: ${formattedDate}`;
  }, [closeDate]);

  const { text, percentage, textColor } = useMemo(() => {
    if (!closeDate || isNaN(Number(closeDate)))
      return {
        text: "TBD",
        percentage: 0,
        textColor: theme.customColors.gray[300],
      };
    const MAX_DAYS = 14;

    const daysDiff = clamp(
      differenceInDays(
        startOfDay(new Date(Number(closeDate))),
        startOfDay(new Date())
      ),
      0,
      MAX_DAYS
    );

    const percentage = (daysDiff / MAX_DAYS) * 100;

    if (daysDiff >= 14) {
      return {
        text: "2w+",
        percentage,
        textColor: theme.customColors.semantic.green200,
      };
    }

    if (daysDiff >= 7) {
      return { text: `${daysDiff}d`, percentage, textColor: "#EBBB27" };
    }

    return {
      text: `${daysDiff}d`,
      percentage,
      textColor:
        daysDiff === 0
          ? theme.customColors.gray[300]
          : theme.customColors.danger[300],
    };
  }, [closeDate, theme]);

  return (
    <div className="flex">
      <div className="grow flex flex-col">
        <Typography
          variant={size === "medium" ? "body11" : "h3"}
          className={clsx({
            "font-bold text-gray-500": size === "medium",
            "font-medium text-dark-text font-serif": size === "large",
          })}
        >
          {amount}
        </Typography>
        <Typography
          variant="caption2"
          style={{ color: statusValue.color }}
          className={clsx("font-bold")}
        >
          {statusValue.label}
        </Typography>
      </div>
      <CircularProgressWithLabel
        textColor={textColor}
        variant="determinate"
        thickness={4}
        text={text}
        percentage={percentage}
        size={sizeValue}
        textSize={size}
        tooltip={formattedCloseDate}
      />
    </div>
  );
};

export default DealAvailable;
