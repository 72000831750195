import * as React from "react";
import type { SVGProps } from "react";
const SvgKkr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={84}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M17.2.2 3.3 10.4V.2H0V20h3.3v-6l6-4.4L18.8 20H23L11.8 7.7 21.9.2zM47.9.2 34 10.4V.2h-3.2V20H34v-6l6-4.4L49.5 20h4.2L42.5 7.7 52.6.2z"
    />
    <mask
      id="kkr_svg__a"
      width={84}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M84 0H0v20h84z" />
    </mask>
    <g mask="url(#kkr_svg__a)">
      <path
        fill="#fff"
        d="M80.1 6.4c0-2.2-1.3-3.3-4.1-3.3H64.4v6.3h11.8c2.6.1 3.9-1 3.9-3M79.8 20l-5.4-7.6h-10V20h-3.3V.2h15.6c4.2 0 6.7 2.4 6.7 6.2 0 3.5-2.2 5.4-5.1 5.9l5.3 7.7z"
      />
    </g>
  </svg>
);
export default SvgKkr;
