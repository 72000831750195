export enum MediaStatus {
  Pending = "pending",
  Processing = "processing",
  Draft = "draft",
  Published = "published",
  Deleted = "deleted",
}

export enum MediaTranscriptionStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Queued = "QUEUED",
}
