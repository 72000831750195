import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

interface Props {
  to: string;
}

export const EditNavButton = (props: Props) => {
  return (
    <Link {...props}>
      <Button
        variant="outlined"
        color="primary"
        className="py-2 px-3 leading-4 text-[0.875rem] h-[32px] w-[52px] text-primary"
      >
        Edit
      </Button>
    </Link>
  );
};
