import { AssistantAttachableField } from "domain/assistant";
import React from "react";
import dealService from "services/deal.service";
import { DealEditPersonMultiple } from "ui/components/DealEditPersonMultiple";
import { useDealSponsor } from "ui/hooks";
import { DealCreateOrEditPage } from "ui/pages/Form";

export const DealEditPersonSponsor: React.FC<DealCreateOrEditPage> = ({
  type,
  routeAfterSubmit,
}) => {
  return (
    <DealEditPersonMultiple
      assistantFieldId={AssistantAttachableField.SponsorItem}
      title="Sponsors GP"
      BasicInfoSectionProps={{
        descriptionPlaceholder: "About the sponsor in a few words",
        inputLabels: {
          description: "Description",
          name: "Name",
          url: "Url",
        },
      }}
      type={type}
      OtherDealsSectionProps={{
        selectPlaceholder: "Select deals",
        title: "Other deals from sponsor",
      }}
      fetchPersonDataHook={useDealSponsor}
      imageInputTitle="Sponsor image"
      stepName="sponsor"
      updateDealPersonCallback={dealService.updateSponsors}
      routeAfterSubmit={routeAfterSubmit}
    />
  );
};

export default DealEditPersonSponsor;
