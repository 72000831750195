import { Outlet } from "react-router-dom";
import { PermissionsArray } from "types";
import { useProjectPermissions } from "ui/hooks";
import { NotFound } from "ui/pages";

export const ProjectGuard = ({
  permissions,
}: {
  permissions: PermissionsArray;
}) => {
  const { hasPermission } = useProjectPermissions();

  if (!hasPermission(permissions)) {
    return <NotFound />;
  }

  return <Outlet />;
};
