import React, { useCallback } from "react";
import WaveSurfer from "wavesurfer.js";
import CursorPlugin from "wavesurfer.js/src/plugin/cursor";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import Forward30Icon from "@mui/icons-material/Forward30";
import Replay10Icon from "@mui/icons-material/Replay10";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link } from "react-router-dom";
import { Tooltip, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CircleClose } from "../../icons/CircleClose/CircleClose";
import { useDispatch } from "react-redux";
import { IMediaItem } from "../../../types/media.service";
import { setCurrentMediaItem } from "../../../redux/MediaPlayer/reducer";
import { noop } from "lodash";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends IMediaItem {}

interface Meta {
  duration?: string;
  current?: string;
}

const classesPlayPause = {
  root: "h-[50px] w-[50px] md:h-[30px] md:w-[30px] cursor-pointer",
};
const classesControls = { root: "h-[30px] w-[30px] cursor-pointer" };
const skipBackwardValue = 10; // Note to change icon to show correct number
const skipForwardValue = 30; // Note to change icon to show correct number

// const urlMock = "https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3";

const playbackRates = [1.0, 1.25, 1.5, 1.75, 2.0, 0.5, 0.75];

export const AudioPlayer = ({
  mediaUrl,
  thumbnailImageUrl,
  title,
  audioWaveformData = [1],
  fundId,
}: Props) => {
  const waveformRef = React.useRef<null>(null);
  const wavesurfer = React.useRef<WaveSurfer | null>(null);
  const [playing, setPlay] = React.useState(false);
  const [volume, setVolume] = React.useState(0.5);
  const [, setMute] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [slide, setSlide] = React.useState(false);
  const [meta, setMeta] = React.useState<Meta>({});
  const [playbackRate, setPlaybackRate] = React.useState(1.0);

  const { palette } = useTheme();

  const dispatch = useDispatch();

  const formWaveSurferOptions = useCallback(
    (ref: any): WaveSurferParams => ({
      container: ref,
      backend: "MediaElement",
      waveColor: "#eee",
      progressColor: palette.primary.main,
      cursorColor: palette.primary.main,
      barWidth: 3,
      barRadius: 3,
      responsive: true,
      height: 30,
      // If true, normalize by the maximum peak instead of 1.0.
      normalize: true,
      // Use the PeakCache to improve rendering speed of large waveforms.
      partialRender: true,
      hideScrollbar: true,
      plugins: [CursorPlugin.create({})],
    }),
    [palette.primary.main]
  );

  React.useEffect(() => {
    setPlay(false);
    setMeta({});

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(mediaUrl, audioWaveformData);
    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current?.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);

        const durationNumber = wavesurfer.current.getDuration();
        const duration = durationNumber
          ? new Date(durationNumber * 1000).toISOString().substr(11, 8)
          : "";

        setMeta({
          duration,
          current: "00:00:00",
        });

        handlePlayPause();
      }
    });

    wavesurfer.current.on("audioprocess", () => {
      if (wavesurfer.current) {
        const currentTime = wavesurfer.current.getCurrentTime();
        const current = currentTime
          ? new Date(currentTime * 1000).toISOString().substr(11, 8)
          : "";
        setMeta((state) => ({
          ...state,
          current,
        }));
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaUrl]);

  const handlePlayPause = () => {
    wavesurfer.current?.playPause();
    setPlay(wavesurfer.current?.isPlaying() ?? false);
  };

  const onMuteToggle = () => {
    setMute(!wavesurfer.current?.getMute());
    wavesurfer.current?.toggleMute();
  };

  const onVolumeChange = (e: Event, value: number | number[]) => {
    if (typeof value === "number") {
      const newVolume = value / 100;
      setVolume(newVolume);
      wavesurfer.current?.setVolume(newVolume || 0);
    }
  };

  const skipBackward = () => {
    wavesurfer.current?.skipBackward(skipBackwardValue);
  };

  const skipForward = () => {
    wavesurfer.current?.skipForward(skipForwardValue);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const startSlide = () => {
    setSlide(true);
  };

  const stopSlide = () => {
    setSlide(false);
  };

  const handlePlayerClose = () => {
    dispatch(setCurrentMediaItem(null));
  };

  const updatePlaybackRate = () => {
    const currentPlaybackRateIndex = playbackRates.findIndex(
      (rate) => rate === playbackRate
    );

    const nextPlaybackRateIndex = currentPlaybackRateIndex + 1;

    const nextPlaybackRate = playbackRates[nextPlaybackRateIndex]
      ? playbackRates[nextPlaybackRateIndex]
      : playbackRates[0];
    setPlaybackRate(nextPlaybackRate);
    if (wavesurfer.current) {
      wavesurfer.current.setPlaybackRate(nextPlaybackRate);
    }
  };

  return (
    <div className="fixed bottom-0 left-0 w-full bg-white z-50 border-t-[3px] border-l-0 border-r-0 border-b-0 border-solid border-primary">
      <div className="m9-container">
        <div className="flex flex-col md:flex-row gap-6 relative w-full">
          {thumbnailImageUrl && (
            <div
              className="hidden md:flex h-[115px] w-full md:w-[120px] rounded-[20px] overflow-hidden"
              style={{
                backgroundImage: `url(${thumbnailImageUrl})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              {" "}
            </div>
          )}
          <div className="flex flex-col justify-between grow md:max-w-[calc(100%_-_145px)]">
            {title && (
              <Tooltip placement="bottom-start" title={<>{title}</>}>
                <div className="mb-2 md:mb-0 md:mr-[40px] max-w-[calc(100%_-_30px)] md:max-w-[calc(100%_-_60px)]">
                  <Typography
                    variant="h5"
                    className="font-bold truncate max-w-[100%] text-[14px] md:text-[21px]"
                  >
                    {title}
                  </Typography>
                </div>
              </Tooltip>
            )}
            <div className="flex gap-2">
              <div className="grow flex flex-col justify-between text-primary">
                <div className="flex flex-col md:flex-row">
                  <div className="mb-2 justify-center md:justify-start md:mb-0 flex gap-3 items-center">
                    <Replay10Icon
                      classes={classesControls}
                      onClick={skipBackward}
                    />
                    {playing ? (
                      <PauseCircleOutlineIcon
                        classes={classesPlayPause}
                        onClick={handlePlayPause}
                      />
                    ) : (
                      <PlayCircleOutlineIcon
                        classes={classesPlayPause}
                        onClick={handlePlayPause}
                      />
                    )}
                    <Forward30Icon
                      classes={classesControls}
                      onClick={skipForward}
                    />
                    <Typography
                      className="hidden md:flex text-xs font-bold cursor-pointer min-w-[46px] justify-center
                items-center border-2 border-primary border-solid rounded-[12px] text-[12px]"
                      onClick={updatePlaybackRate}
                    >
                      {`${playbackRate}x`}
                    </Typography>
                  </div>

                  <div className="flex items-center w-full">
                    <div className="max-w-[91px] w-full">
                      {meta?.current && (
                        <Typography
                          variant="caption"
                          className="hidden md:block px-4 text-primary"
                        >
                          {meta.current}
                        </Typography>
                      )}
                    </div>
                    <div id="waveform" className="w-full" ref={waveformRef} />
                    <div className="max-w-[91px] w-full">
                      {meta?.duration && (
                        <Typography
                          variant="caption"
                          className="hidden md:block px-4 text-primary"
                        >
                          {meta.duration}
                        </Typography>
                      )}
                    </div>

                    <div
                      className="hidden md:block"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {wavesurfer.current?.getMute() ? (
                        <VolumeOffIcon
                          classes={classesControls}
                          onClick={onMuteToggle}
                        />
                      ) : (
                        <VolumeUpIcon
                          classes={classesControls}
                          onClick={onMuteToggle}
                        />
                      )}
                      <Popper
                        disablePortal
                        className="z-[1300]"
                        id="mouse-over-popper"
                        open={Boolean(open || slide)}
                        anchorEl={anchorEl}
                        placement="top"
                        modifiers={[
                          {
                            name: "flip",
                            enabled: true,
                            options: {
                              altBoundary: true,
                              rootBoundary: "document",
                              padding: 8,
                            },
                          },
                          {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                              altAxis: true,
                              altBoundary: true,
                              tether: true,
                              rootBoundary: "document",
                              padding: 8,
                            },
                          },
                        ]}
                        placeholder=""
                        onPointerEnterCapture={noop}
                        onPointerLeaveCapture={noop}
                      >
                        <Paper className="h-[200px] overflow-hidden py-4">
                          <ClickAwayListener onClickAway={stopSlide}>
                            <Slider
                              componentsProps={{
                                thumb: {
                                  onMouseDown: startSlide,
                                  onMouseUp: stopSlide,
                                },
                              }}
                              aria-label="Volume"
                              orientation="vertical"
                              valueLabelDisplay="auto"
                              defaultValue={50}
                              value={volume * 100}
                              onChange={onVolumeChange}
                            />
                          </ClickAwayListener>
                        </Paper>
                      </Popper>
                    </div>
                  </div>
                  {fundId && (
                    <div className="flex items-center justify-center md:justify-start mt-3 md:mt-0">
                      <span className="hidden md:block mx-2">|</span>{" "}
                      <Link
                        to={`/deal/${fundId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whitespace-nowrap font-bold"
                      >
                        Deal page
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <IconButton
            className="p-0 absolute top-[0px] right-[0px] w-[24px] h-[24px]"
            onClick={handlePlayerClose}
          >
            <CircleClose />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
