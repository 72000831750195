import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import "./styles.scss";
import { ReactComponent as ArrowDropDownIcon } from "assets/svg/arrow-drop-down-icon.svg";

interface Props {
  label?: string;
  error?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const FormSelect = React.forwardRef(function FormSelect(
  {
    label,
    error = false,
    className,
    children,
    IconComponent,
    ...props
  }: Props & SelectProps,
  ref: React.ForwardedRef<SelectProps>
) {
  return (
    <FormControl fullWidth error={error} className={className}>
      {label && (
        <InputLabel disableAnimation className="pb-3 static">
          {label}
        </InputLabel>
      )}
      <Select
        IconComponent={IconComponent ?? ArrowDropDownIcon}
        ref={ref}
        className="FormSelect"
        {...props}
        MenuProps={{
          ...(props.MenuProps ? props.MenuProps : {}),
          classes: { paper: "FormSelect-Dropdown" },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
});

export default FormSelect;
