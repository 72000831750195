import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  CurrencyInput,
  FormInputController,
  DialogTitle,
  DialogTextSection,
} from "ui/components";
import resolver from "./formValidation";
import { formatCurrency, parseCurrencyString } from "application/utils";
import DealService from "services/deal.service";
import { ParticipationTypeEnum } from "domain/deal";
import { DialogContext } from "../../DealCardBody";
import { DialogTypes } from "../DealDialog";
import { useDealId, useDealGeneralData } from "ui/hooks";
import { useDealParticipationRequestErrorHandler } from "ui/hooks/Deal/useDealParticipationRequestErrorHandler";

interface Props {
  onClose: () => void;
}

export interface FormInputs {
  amount: string;
  minAmount: number | null;
  maxAmount: number | null;
}

const defaultValues: FormInputs = {
  amount: "",
  minAmount: null,
  maxAmount: null,
};

const ParticipationDialogBody = ({ onClose }: Props) => {
  const { handleError } = useDealParticipationRequestErrorHandler();
  const context = React.useContext(DialogContext);
  const dealId = useDealId() as string;
  const { data: generalData } = useDealGeneralData();
  const { control, handleSubmit, getValues, reset } = useForm<FormInputs>({
    defaultValues,
    resolver,
  });
  const [loading, setLoading] = React.useState(false);
  const amountHelperText = React.useMemo(() => {
    const minAmount = generalData?.minContribution || null;
    const maxAmount = generalData?.availablePart || null;

    return (
      <div>
        <div>{minAmount ? `Min: ${formatCurrency(minAmount)}` : ""}</div>
        <div>{maxAmount ? `Max: ${formatCurrency(maxAmount)}` : ""}</div>
      </div>
    );
  }, [generalData]);

  React.useEffect(() => {
    const amount = getValues("amount");
    const minAmount = generalData?.minContribution || null;
    const maxAmount = generalData?.availablePart || null;
    const values = {
      amount,
      minAmount,
      maxAmount,
    };
    reset(values);
  }, [generalData, getValues, reset]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true);
    try {
      const payload = {
        participationType: ParticipationTypeEnum.FundParticipation,
        participationAmmount: parseCurrencyString(data.amount),
      };

      await DealService.sendParticipationRequest(dealId, payload);
      if (context?.setDialogProps) {
        context.setDialogProps({
          open: true,
          type: DialogTypes.ParticipationRequestSent,
        });
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  return (
    <>
      <DialogTitle onClose={onClose}>Participate</DialogTitle>
      <div className="pt-8 space-y-6">
        <DialogTextSection
          tooltip={
            "We are currently only allowing qualified purchasers to participate."
          }
        >
          We are currently only allowing qualified purchasers to participate.
        </DialogTextSection>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormInputController
              name="amount"
              control={control}
              label="Amount"
              placeholder="Investment amount"
              inputComponent={CurrencyInput as any}
              helperText={amountHelperText}
              showError
            />
          </div>
          <div className="pt-6 flex flex-col items-center space-y-2">
            <LoadingButton
              className="w-full"
              variant="contained"
              color="primary"
              loading={loading}
              type="submit"
            >
              Participate
            </LoadingButton>
            <LoadingButton
              className="w-full"
              variant="outlined"
              loading={loading}
              onClick={onClose}
            >
              Cancel
            </LoadingButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default ParticipationDialogBody;
