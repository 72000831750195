import { FC, memo, MouseEvent } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { ReactComponent as IconFileChecked } from "assets/svg/icon-file-checked.svg";

interface DragAndDropFieldItemProps {
  name: string;
  handleRemoveFile: (e: MouseEvent) => void;
  downloadLink?: string;
}

export const DragAndDropFieldItem: FC<DragAndDropFieldItemProps> = memo(
  ({ name, handleRemoveFile, downloadLink }) => {
    return (
      <div className="flex items-center py-4 px-5 rounded-xl border-2 border-solid border-gray-150 bg-white">
        <IconFileChecked className="mr-3" />
        {downloadLink ? (
          <a
            href={downloadLink}
            target="_blank"
            rel="noreferrer noopener"
            download
          >
            {name}
          </a>
        ) : (
          <span>{name}</span>
        )}
        <ClearIcon
          className="ml-auto cursor-pointer"
          onClick={handleRemoveFile}
        />
      </div>
    );
  }
);
