import { AssistantAttachableField } from "domain/assistant";
import React from "react";
import DealService from "services/deal.service";
import {
  DealEditPerson,
  UpdateDealPersonImageCallback,
  UpdateDealPersonCallback,
  CreateDealPersonCallback,
} from "ui/components";
import { useDealAdvisor } from "ui/hooks";
import { DealCreateOrEditPage } from "ui/pages/Form";

export const DealEditPersonAdvisor: React.FC<DealCreateOrEditPage> = (
  props
) => {
  const updateDealPersonImageCallback =
    React.useCallback<UpdateDealPersonImageCallback>(
      (...args) => DealService.updateAdvisorImage(...args),
      []
    );
  const updateDealPersonCallback = React.useCallback<UpdateDealPersonCallback>(
    (...args) => DealService.updateAdvisor(...args),
    []
  );
  const createDealPersonCallback = React.useCallback<CreateDealPersonCallback>(
    (...args) => DealService.createAdvisor(...args),
    []
  );

  return (
    <DealEditPerson
      {...props}
      imageInputTitle="Advisor image"
      title="Advisor"
      stepName="advisor"
      fieldId={AssistantAttachableField.AdvisorItem}
      BasicInfoSectionProps={{
        inputLabels: {
          name: "Advisor Name",
          url: "Advisor URL",
          description: "Advisor description",
        },
        descriptionPlaceholder: "About the advisor in a few words",
      }}
      OtherDealsSectionProps={{
        title: "Other deals by the advisor",
        selectPlaceholder: "Deals from the advisor",
      }}
      fetchPersonDataHook={useDealAdvisor}
      updateDealPersonImageCallback={updateDealPersonImageCallback}
      updateDealPersonCallback={updateDealPersonCallback}
      createDealPersonCallback={createDealPersonCallback}
    />
  );
};

export default DealEditPersonAdvisor;
