import { Checkbox, TablePagination, TablePaginationProps } from "@mui/material";
import { PaginationData } from "domain/common";
import { FC, ReactElement } from "react";
import Loading from "../Loading/Loading";

interface CardTableProps {
  onSelectAll?: () => void;
  isAllSelected?: boolean;
  actions?: ReactElement[];
  paginationData: PaginationData;
  onPageChange: TablePaginationProps["onPageChange"];
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const CardTable: FC<CardTableProps> = ({
  paginationData,
  actions,
  onSelectAll,
  isAllSelected,
  onPageChange,
  isLoading,
  children,
}) => {
  const paginationLoadingParams = isLoading
    ? { disabled: isLoading }
    : undefined;

  return (
    <>
      <div className="flex items-center justify-between mb-6 rounded-lg shadow-[0_24px_32px_-16px_rgba(161,174,183,0.15)] px-3 border border-solid border-gray-150">
        <div className="flex">
          <Checkbox
            disabled={!paginationData}
            checked={isAllSelected}
            onChange={onSelectAll}
          />
          {actions && <div className="ml-2">{actions}</div>}
        </div>
        <div>
          {paginationData && (
            <TablePagination
              className="w-full"
              component="div"
              count={paginationData.totalItems}
              rowsPerPage={paginationData.pageSize}
              page={paginationData.pageNumber - 1}
              rowsPerPageOptions={[]}
              onPageChange={onPageChange}
              SelectProps={{
                disabled: isLoading,
              }}
              backIconButtonProps={paginationLoadingParams}
              nextIconButtonProps={paginationLoadingParams}
            />
          )}
        </div>
      </div>
      {isLoading ? <Loading full /> : children}
    </>
  );
};
