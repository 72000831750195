import { useTheme } from "@mui/material";
import { useMemo } from "react";

export const useChartColors = () => {
  const { customColors } = useTheme();

  const emptyValue = useMemo(() => [customColors.gray[300]], [customColors]);

  const twoTone = useMemo(
    () => [customColors.complementary[400], customColors.gray[200]],
    [customColors]
  );

  const allPallete = useMemo(
    () => [
      customColors.primary[200],
      customColors.primary[300],
      customColors.primary[600],
      customColors.primary[400],
      customColors.complementary[400],
      customColors.complementary[200],
      customColors.text.dark,
    ],
    [customColors]
  );

  return {
    allPallete,
    emptyValue,
    twoTone,
  };
};
