import { useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  useTheme,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { StyledMenu } from "ui/components/StyledMenu";
import { ReactComponent as IconReload } from "assets/svg/reload.svg";
import ManageRoles from "ui/pages/DataRoom/DataRoomPermissions/components/ManageRoles/ManageRoles";
import { UserWithRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";
import { useErrorHandler, useProjectAlias, useTypedSelector } from "ui/hooks";
import projectService from "services/project.service";
import { useSnackbar } from "notistack";
import { useGetUsersWithRolesQuery } from "redux/Api/DataroomPermissionsApi";

interface ProjectSharingPeopleWithAccessActionProps {
  data: UserWithRoles;
  projectId?: string;
}

const ProjectSharingPeopleWithAccessAction = ({
  data,
  projectId,
}: ProjectSharingPeopleWithAccessActionProps) => {
  const { palette } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuElementRef = useRef<any>(null);
  const alias = useProjectAlias();
  const {
    user: {
      attributes: { email },
    },
  } = useTypedSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useGetUsersWithRolesQuery({
    resourceId: projectId ?? alias,
    type: "Project",
  });

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleRevokeAccess = async () => {
    setIsLoading(true);
    try {
      await projectService.revokeAccess(
        projectId ?? alias,
        data.userId ?? data.email
      );
      refetch();
      enqueueSnackbar("Saved!", {
        title: "Successfully updated",
        variant: "success",
      });
    } catch (error) {
      handleError(
        error,
        "It was not possible to revoke access. Please try again later."
      );
    }
    setIsLoading(false);
    handleClose();
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={isMenuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        disabled={isLoading}
        ref={(ref) => {
          if (!ref) return;
          menuElementRef.current = ref;
          ref.onclick = handleClick;
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : <MoreVert />}
      </IconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={menuElementRef.current}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ManageRoles
          title="Change roles"
          onCloseMenu={handleClose}
          resourceId={projectId ?? alias}
          type="Project"
          data={data}
        />
        {email !== data.email && (
          <MenuItem
            className="text-error"
            sx={{
              "&:hover, &:focus": {
                color: `${palette.error.main} !important`,
              },
            }}
            onClick={handleRevokeAccess}
          >
            <IconReload className="mr-2" />
            Revoke access
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export default ProjectSharingPeopleWithAccessAction;
