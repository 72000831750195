import {
  IGetProjectList,
  IProject,
  ProjectShareContactPayload,
} from "types/project.service";
import { apiClient } from "./clients";
import { delayAsync } from "application/utils";
import { DealPermissions } from "types/deal";
import { IAssistantThread } from "types/assistant.service";

class ProjectService {
  private readonly basePath = "/Project";

  async createNewProject(
    payload: FormData,
    config: object = {}
  ): Promise<IProject> {
    const { data } = await apiClient.post(this.basePath, payload, {
      ...config,
    });

    return data.response;
  }

  async getThreads(projectId: string): Promise<IAssistantThread[]> {
    const { data } = await apiClient.get(
      `${this.basePath}/${projectId}/threads`
    );

    return data;
  }

  async getProject(id: string): Promise<IProject> {
    const { data } = await apiClient.get(`${this.basePath}/${id}`);

    return data.response;
  }

  async updateProject(
    id: string,
    payload: FormData,
    config: object = {}
  ): Promise<GenericResponse<{ id: string; alias: string }>> {
    const { data } = await apiClient.post(`${this.basePath}/${id}`, payload, {
      ...config,
    });

    return data;
  }

  async getProjectList(
    query: IGetProjectList
  ): Promise<PaginatedTokenResponse<IProject>> {
    const { data } = await apiClient.get(this.basePath, {
      params: query,
    });

    return data.response;
  }

  async getProjectPermissions(
    projectId: string,
    config = {}
  ): Promise<DealPermissions> {
    // const { data } = await apiClient.get(
    //   `${this.basePath}/${projectId}/checkpermissions`,
    //   {
    //     ...config,
    //   }
    // );

    const data: DealPermissions = {
      dataRoomAccess: true,
      edit: true,
      fundAccess: true,
      isParticipant: true,
      view: true,
    };

    await delayAsync(1500);

    return data;
  }

  async deleteProject(id: string): Promise<void> {
    return apiClient.delete(`${this.basePath}/${id}`);
  }

  async shareProject(projectId: string, contacts: ProjectShareContactPayload) {
    return apiClient.post(`${this.basePath}/${projectId}/share`, contacts);
  }

  async revokeAccess(projectId: string, userIdOrEmail: string) {
    return apiClient.delete(
      `${this.basePath}/${projectId}/participants/${userIdOrEmail}`
    );
  }
}

export default new ProjectService();
