import DealService from "services/deal.service";
import useSWR from "swr";
import { useDealAlias, useDealPermissions } from "ui/hooks";

export function useDealPolicy() {
  const alias = useDealAlias();
  const { permissions, dealAccess } = useDealPermissions();

  const hasAccess = permissions?.edit || dealAccess;

  return useSWR(
    () => hasAccess && `fund/${alias}/policy`,
    async () => {
      const { response } = await DealService.getDealPolicy(alias);
      return response?.permissions;
    }
  );
}
