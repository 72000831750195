import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { DealStatusFilter } from "domain/deal";
import { FormProvider, useForm } from "react-hook-form";
import { Link, Outlet, useLocation } from "react-router-dom";
import { LinkTabs } from "ui/components/LinkTabs";
import * as paths from "ui/Router/paths";
import "./styles.scss";
import { useEffect } from "react";
import { useDebounceEffect } from "ui/hooks";

export interface DealsFilterInputs {
  sortBy: string;
  sector: Option[];
  geographicalRegion: Option[];
  equityType: Option[];
  dealType: Option[];
  statusFilter: DealStatusFilter;
  fundSize: [number, number];
  minimumContribution: [number, number];
}

export const defaultValuesDealFilterForm: DealsFilterInputs = {
  sortBy: "trending",
  sector: [],
  dealType: [],
  equityType: [],
  geographicalRegion: [],
  statusFilter: DealStatusFilter.Available,
  fundSize: [0, 1000],
  minimumContribution: [0, 100],
};

const routes = [
  {
    routeTo: "/" + paths.dealsAll,
    label: "All deals",
  },
  {
    routeTo: "/" + paths.dealsYour,
    label: "Your deals",
  },
];

const DealsWrapper = () => {
  const { pathname } = useLocation();
  const dealsFilterForm = useForm<DealsFilterInputs>({
    defaultValues: defaultValuesDealFilterForm,
  });

  const dealsFilterFormWatch = dealsFilterForm.watch();

  useDebounceEffect(
    () => {
      localStorage.setItem(
        "dealsFilterInputs",
        JSON.stringify(dealsFilterFormWatch)
      );
    },
    1000,
    [dealsFilterFormWatch]
  );

  useEffect(() => {
    const dealFilterInputs = localStorage.getItem("dealsFilterInputs");

    if (dealFilterInputs) {
      dealsFilterForm.reset(JSON.parse(dealFilterInputs));
    }
  }, [dealsFilterForm]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinkTabs value={pathname}>
        {routes.map(({ routeTo, label }) => (
          <Tab
            className="text-[16px] py-6 px-2 font-bold capitalize hover:text-primary-400 transition-colors ease-in"
            key={routeTo}
            to={routeTo}
            value={routeTo}
            label={label}
            component={Link}
          />
        ))}
      </LinkTabs>
      <FormProvider {...dealsFilterForm}>
        <Outlet />
      </FormProvider>
    </Box>
  );
};
export default DealsWrapper;
