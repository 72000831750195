import React from "react";

import { ReactComponent as IconColumnsAverageSVG } from "assets/svg/icon-columns-average.svg";
import { ReactComponent as IconPieFilledSVG } from "assets/svg/icon-pie-filled.svg";

interface Props {
  iconName: string;
}

const IconMap = ({ iconName }: Props) => {
  switch (iconName) {
    case "columns-average": {
      return <IconColumnsAverageSVG />;
    }
    case "pie-filled": {
      return <IconPieFilledSVG />;
    }
    default: {
      return null;
    }
  }
};

export default IconMap;
