import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getNameCombined, getNameInitials } from "application/utils";
import clsx from "clsx";
import React, { useState } from "react";
import { IFeaturedInvestor } from "types";
import { UserAvatar } from "ui/components";
import { useErrorHandler } from "ui/hooks";
import styles from "./styles.module.scss";

export interface InvestorCardProps {
  handleFollow: (investor: IFeaturedInvestor) => void;
  investor: IFeaturedInvestor;
  className?: string;
}

const InvestorCard: React.FC<
  InvestorCardProps & React.HTMLAttributes<HTMLDivElement>
> = ({ className, investor, handleFollow, ...props }) => {
  const { familyName, isFollowed, name, username, job, profileImageUrl } =
    investor;
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleError } = useErrorHandler();
  const investorName = getNameCombined(name, familyName);

  const handleFollowClick = async () => {
    setSubmitting(true);

    try {
      await handleFollow(investor);
    } catch (e) {
      handleError(e, "It was not possible to follow the investor.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={clsx("w-[300px] min-h-[120px]", className)}>
      <Paper
        elevation={0}
        className={clsx("rounded-xl h-[inherit]", styles.paper)}
      >
        <div className={clsx("p-6")}>
          <div className="flex flex-row pb-2">
            <UserAvatar className="flex-start" src={profileImageUrl}>
              {getNameInitials(investorName)}
            </UserAvatar>
            <div className="grow" />
            {username && (
              <LoadingButton
                color="primary"
                variant={isFollowed ? "contained" : "outlined"}
                className={clsx("text-sm", styles.followButton)}
                onClick={handleFollowClick}
                loading={isSubmitting}
              >
                {isFollowed ? "Following" : "Follow"}
              </LoadingButton>
            )}
          </div>
          <div className="leading-none">
            <Typography variant="body2" className="font-bold">
              {investorName}
            </Typography>
            {job && (
              <Typography variant="caption2" className="font-semibold">
                {job}
              </Typography>
            )}
            {/* {job && (
              <Typography  className="block pt-3 font-medium text-gray-400 text-[0.75rem] leading-5">
                {job}
              </Typography>
            )} */}
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default InvestorCard;
