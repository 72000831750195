import { FeatureFlagId } from "domain/featureFlag";
import { Outlet, useNavigate } from "react-router-dom";
import { Loading } from "ui/components";
import { useErrorHandler } from "ui/hooks";
import { useFeatureFlag } from "ui/hooks/useFeatureFlag";
import { NotFound } from "ui/pages";

interface Props {
  featureFlag: FeatureFlagId;
}

export const RestrictedByFeatureFlagOutlet = ({ featureFlag }: Props) => {
  const navigate = useNavigate();
  const { isLoading, error, isFlagEnabled } = useFeatureFlag();
  const { handleError } = useErrorHandler();

  if (isLoading) {
    return <Loading full />;
  }

  if (error) {
    navigate(-1);

    handleError(
      error,
      "It was not possible to get the application configuration."
    );
  }

  if (!isFlagEnabled(featureFlag)) {
    return <NotFound />;
  }

  return <Outlet />;
};
