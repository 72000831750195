import dealService from "services/deal.service";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { ICreateDealAutomatedResponse } from "types/deal";

export const useDealAutomatedJob = (
  dealAlias: string,
  config?: SWRConfiguration<ICreateDealAutomatedResponse>,
  deps: any[] = []
): SWRResponse<ICreateDealAutomatedResponse> => {
  return useSWR(
    [`Fund/${dealAlias}/automated-creation`, ...deps],
    async () => {
      return await dealService.getDealAutomatedJobsStatus(dealAlias as string);
    },
    config
  );
};
