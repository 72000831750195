import Chip from "@mui/material/Chip";
import { ReactComponent as IconCheckSVG } from "assets/svg/icon-check.svg";
import React from "react";
import styles from "./styles.module.scss";

export interface MultiSelectChipsProps {
  name: string;
  label?: string;
  options: Option[];
  value: string[];
  onChange: (value: any) => void;
  singleSelect?: boolean;
}

const MultiSelectChips = React.forwardRef(function MultiSelectChips(
  { onChange, value, options, label, singleSelect }: MultiSelectChipsProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const handleClick = (clickedValue: string) => {
    const index = value.findIndex((v) => v === clickedValue);
    // TODO single select
    if (index >= 0) {
      let newValue: string[] = [];
      if (!singleSelect) {
        newValue = [...value];
        newValue.splice(index, 1);
      }
      onChange(newValue);
    } else {
      const newValue = [...(singleSelect ? [] : value), clickedValue];
      onChange(newValue);
    }
  };

  return (
    <div ref={ref}>
      {Array.isArray(options) && (
        <div className={styles.container}>
          {options.map((option, index) => {
            const active = value.find((v) => v === option.value);

            return (
              <Chip
                key={index}
                label={option.label}
                color="primary"
                onClick={() => handleClick(option.value)}
                {...(active
                  ? { variant: "filled", icon: <IconCheckSVG /> }
                  : { variant: "outlined" })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});

export default MultiSelectChips;
