import { downloadFile } from "application/utils";
import adminService from "services/admin.service";
import userService from "services/user.service";
import { useErrorHandler } from "./useErrorHandler";

export const useSignatureDocumentDownload = () => {
  const { handleError, parseBlobError } = useErrorHandler();

  const downloadSignatureDocumentFromUser = async (
    userCustomUsername: string,
    documentName: string
  ) => {
    try {
      const response = await adminService.downloadUserSignatureDocument(
        userCustomUsername
      );

      downloadFile(
        response,
        `emerge9-${userCustomUsername}-${documentName}.pdf`
      );
    } catch (e) {
      const error = await parseBlobError(e);

      handleError(
        error,
        "It was not possible to download the user signature document"
      );
    }
  };

  const downloadSignatureDocumentForCurrentUser = async (
    documentName: string
  ) => {
    try {
      const response = await userService.getSignatureDocumentDownload();

      downloadFile(response, `emerge9-${documentName}.pdf`);
    } catch (e) {
      const error = await parseBlobError(e);

      handleError(
        error,
        "It was not possible to download the user signature document"
      );
    }
  };

  return {
    downloadSignatureDocumentForCurrentUser,
    downloadSignatureDocumentFromUser,
  };
};
