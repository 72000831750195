import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { FC } from "react";

interface CircularProgressWithLabelProps
  extends Omit<CircularProgressProps, "value"> {
  text: string | number;
  textSize: string;
  percentage: number;
  textColor: string;
  tooltip?: string | null;
}

const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = ({
  textSize,
  className,
  percentage,
  text,
  textColor,
  tooltip,
  ...props
}) => {
  return (
    <Box className="h-fit relative flex">
      <CircularProgress
        className="text-gray-200"
        variant="determinate"
        value={100}
        size={props.size}
        thickness={props.thickness}
      />
      <CircularProgress
        variant="determinate"
        className={clsx(`absolute left-0 top-0`, className)}
        {...props}
        value={percentage}
        style={{ color: textColor }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={textSize === "large" ? "caption2" : "body2"}
          component="div"
          className={clsx(
            "font-sans font-semibold text-[0.875rem]",
            `!text-[${textColor}]`,
            {
              "font-bold text-[1rem] leading-6": textSize === "large",
            }
          )}
        >
          {tooltip ? (
            <Tooltip title={tooltip}>
              <div>{text}</div>
            </Tooltip>
          ) : (
            text
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
