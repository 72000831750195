export enum DocumentStatusEnum {
  Uploaded = "document.uploaded",
  Draft = "document.draft",
  Sent = "document.sent",
  Viewed = "document.viewed",
  WaitingApproval = "document.waiting_approval",
  Rejected = "document.rejected",
  Approved = "document.approved",
  WaitingPay = "document.waiting_pay",
  Paid = "document.paid",
  Completed = "document.completed",
  Voided = "document.voided",
  Declined = "document.declined",
}

export const DocumentStatusEnumNameMap = {
  [DocumentStatusEnum.Uploaded]: "Uploaded",
  [DocumentStatusEnum.Draft]: "Draft",
  [DocumentStatusEnum.Sent]: "Sent",
  [DocumentStatusEnum.Viewed]: "Viewed",
  [DocumentStatusEnum.WaitingApproval]: "WaitingApproval",
  [DocumentStatusEnum.Rejected]: "Rejected",
  [DocumentStatusEnum.Approved]: "Approved",
  [DocumentStatusEnum.WaitingPay]: "WaitingPay",
  [DocumentStatusEnum.Paid]: "Paid",
  [DocumentStatusEnum.Completed]: "Completed",
  [DocumentStatusEnum.Voided]: "Voided",
  [DocumentStatusEnum.Declined]: "Declined",
};
