import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Button, Chip, MenuItem, Tooltip } from "@mui/material";
import { getNameInitials } from "application/utils";
import { useSnackbar } from "notistack";
import { FC, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import DealService from "services/deal.service";
import { FormSelectController, Loading, Modal } from "ui/components";
import { useDealId, useErrorHandler } from "ui/hooks";
import { useLookupDealFundManagers } from "ui/hooks/Deal/useLookupDealFundManagers";

interface MessageModalProps {
  isOpen: boolean;
  onSubmitHandler?: () => void;
  onClose: () => void;
  exactDealId?: string;
}

interface FormInputs {
  dealOwner: string;
}

const defaultValues: FormInputs = {
  dealOwner: "",
};

export const DealAssignOwnerModal: FC<MessageModalProps> = ({
  isOpen,
  onSubmitHandler,
  onClose,
  exactDealId,
}) => {
  const dealId = useDealId();
  const { data: usersToSelect, loading: isLoading } =
    useLookupDealFundManagers();
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const { control, reset, setValue, handleSubmit, watch } = useForm<FormInputs>(
    {
      defaultValues,
    }
  );
  const watchShowOwner = watch("dealOwner");

  useEffect(() => {
    reset({
      dealOwner: defaultValues.dealOwner,
    });
  }, [reset]);

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const selectOptions = useMemo(() => {
    if (!usersToSelect) return [];

    return usersToSelect.map((u) => ({
      label: u?.fullName
        ? `${u?.fullName?.trim()} ${u?.email ? "(" + u?.email + ")" : ""}`
        : u.userId,
      value: u.userId,
    }));
  }, [usersToSelect]);

  const onSubmit: SubmitHandler<FormInputs> = async ({ dealOwner }) => {
    setSubmitting(true);

    try {
      await DealService.assignDealOwner(exactDealId || dealId, {
        ownerId: dealOwner,
      });

      enqueueSnackbar("Deal successfully assigned", {
        title: "Deal assigned!",
        variant: "success",
      });
      if (onSubmitHandler) {
        onSubmitHandler();
      }
    } catch (e) {
      handleError(e, "It was not possible to assign the deal");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Select a new deal owner"
      open={isOpen}
      onClose={handleClose}
      bodyProps={{ className: "xs:w-[340px] md:w-[488px]" }}
    >
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
          <FormSelectController
            name="dealOwner"
            label="Deal owner"
            control={control}
            displayEmpty
            renderValue={(selected) => {
              const selectedUser = usersToSelect?.find(
                (item) => item?.userId === selected
              );

              const onDelete = () => {
                setValue("dealOwner", "");
              };

              return (
                <div className="h-full min-h-[24px] -my-2 flex flex-wrap items-center">
                  {selected ? (
                    <Chip
                      avatar={
                        <Avatar src={selectedUser?.imageUrl}>
                          {getNameInitials(selectedUser?.fullName)}
                        </Avatar>
                      }
                      className="my-1 mr-1"
                      label={`${selectedUser?.fullName} ${
                        selectedUser?.email
                          ? "(" + selectedUser?.email + ")"
                          : ""
                      }`}
                      variant="outlined"
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onClick={onDelete}
                      onDelete={onDelete}
                    />
                  ) : null}
                </div>
              );
            }}
            showError
          >
            {selectOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                <Tooltip placement="top-start" title={<>{label}</>}>
                  <div>{label}</div>
                </Tooltip>
              </MenuItem>
            ))}
          </FormSelectController>
          <div className="space-y-2">
            <LoadingButton
              disabled={!watchShowOwner}
              loading={isSubmitting}
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
            >
              Set new owner
            </LoadingButton>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
