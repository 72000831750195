import { ImageUploadResponse } from "domain/common";
import {
  CreateMediaRequest,
  IMedia,
  IMediaItem,
  IMediaItemPreview,
  IMediaTranscriptionProcessSummary,
  UploadUrlResponse,
} from "types/media.service";
import { apiClient } from "./clients";

class MediaService {
  private readonly basePath = "/Media";

  async getAllMedia(): Promise<IMediaItemPreview[]> {
    const { data } = await apiClient.get(this.basePath);
    return data.response;
  }

  async createMedia(payload: CreateMediaRequest): Promise<IMedia> {
    const { data } = await apiClient.post(this.basePath, payload);
    return data;
  }

  async updateMedia(id: string, payload: CreateMediaRequest): Promise<IMedia> {
    const { data } = await apiClient.put(`${this.basePath}/${id}`, payload);
    return data;
  }

  async deletePodcast(id: string): Promise<void> {
    const { data } = await apiClient.delete(`${this.basePath}/${id}`);
    return data;
  }

  async uploadMediaImage(formData: FormData): Promise<ImageUploadResponse> {
    const { data } = await apiClient.post(
      `${this.basePath}/upload-image`,
      formData
    );
    return data;
  }

  async getUploadUrl(
    id: string,
    extension: string,
    isPrivate: boolean
  ): Promise<UploadUrlResponse> {
    const { data } = await apiClient.get(`${this.basePath}/${id}/upload-url`, {
      params: { extension, isPrivate },
    });
    return data.response;
  }

  async getMedia(id: string): Promise<IMedia> {
    const { data } = await apiClient.get(`${this.basePath}/${id}`);
    return data.response;
  }

  async getMediaTranscriptionProcessSummary(
    id: string
  ): Promise<IMediaTranscriptionProcessSummary> {
    const { data } = await apiClient.get(
      `${this.basePath}/${id}/transcription-process`
    );
    return data.response;
  }

  async getMediaItem(id: string): Promise<IMediaItem> {
    const { data } = await apiClient.get(`${this.basePath}/${id}/item`);
    return data.response;
  }

  async publishMedia(id: string): Promise<IMedia> {
    const { data } = await apiClient.post(`${this.basePath}/${id}/publish`);

    return data.response;
  }

  async unpublishMedia(id: string): Promise<IMedia> {
    const { data } = await apiClient.post(`${this.basePath}/${id}/unpublish`);

    return data.response;
  }
}

export default new MediaService();
