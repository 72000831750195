import {
  DeleteForeverOutlined,
  EditOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, Chip, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { FormatType } from "domain/common";
import { MediaStatus } from "domain/media";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { showPodcastModal } from "redux/UI/reducer";
import sanitizeHtml from "sanitize-html";
import mediaService from "services/media.service";
import { IMediaItemPreview } from "types/media.service";
import { useErrorHandler, useGroupValidation } from "ui/hooks";
import * as paths from "ui/Router/paths";
import { fetchMediaForPlayer } from "../../../redux/MediaPlayer/reducer";
import { PODCAST_TYPES } from "../../pages/Podcasts/contants";
import { PlayButton, PlayButtonVariant } from "../Button/Button.play";
import { FormattedValue } from "../FormattedValue";
import { MenuButton } from "../MenuButton";
import "./style.scss";

interface PodcastCardProps {
  media: IMediaItemPreview;
  onReload: () => Promise<any>;
  onDelete: () => void;
}

export const PodcastCard: FC<PodcastCardProps> = ({
  media,
  onReload,
  onDelete,
}) => {
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { isAdmin } = useGroupValidation();
  const {
    id,
    title,
    description,
    thumbnailImageUrl,
    hasPublicInfo,
    hasPrivateInfo,
    hasPrivateContentAccess,
    fundId,
    duration,
    createdAt,
    type,
    status,
    hasMedia,
    fundAlias,
  } = media;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingItem, setLoadingItem] = useState(false);
  const [isPublishing, setPublishing] = useState(false);

  const isDealPodcast = Boolean(fundAlias);
  const isPublished = useMemo(() => status === MediaStatus.Published, [status]);

  const isAudioPodcast = useMemo(() => type === PODCAST_TYPES.AUDIO, [type]);

  const isPlayable = useMemo(
    () => hasPublicInfo || hasPrivateContentAccess,
    [hasPrivateContentAccess, hasPublicInfo]
  );

  const sanitizedDescription = useMemo(() => {
    return sanitizeHtml(description, {
      allowedTags: [],
    });
  }, [description]);

  const onPlayClick = async () => {
    if (!hasMedia) {
      enqueueSnackbar("Please request access to stream this podcast.", {
        title: "Private podcast",
        variant: "info",
        persist: true,
      });
      return;
    }

    setLoadingItem(true);
    try {
      await dispatch(fetchMediaForPlayer(id));
    } catch (e) {
      handleError(e, "It was not possible to load the content audio.");
    } finally {
      setLoadingItem(false);
    }
  };

  const onPublishToggle = useCallback(async () => {
    setPublishing(true);

    try {
      if (isPublished) {
        return await mediaService.unpublishMedia(id);
      }

      return await mediaService.publishMedia(id);
    } catch (e) {
      handleError(e, "It was not possible to update the publish status");
    } finally {
      await onReload();
      setPublishing(false);
    }
  }, [handleError, id, isPublished, onReload]);

  const onDeleteClick = useCallback(() => {
    if (isPublished) {
      enqueueSnackbar("Please, unpublish the podcast before deleting it.", {
        variant: "warning",
        title: "Invalid operation",
      });
      return;
    }

    onDelete();
  }, [enqueueSnackbar, isPublished, onDelete]);

  return (
    <Box
      tabIndex={0}
      className={clsx(
        "flex xs:flex-col md:flex-row bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 border-solid",
        {
          "md:h-[240px]": isAudioPodcast,
          "md:h-[310px]": !isAudioPodcast,
        }
      )}
    >
      <Box
        sx={{
          backgroundImage: `url(${thumbnailImageUrl})`,
        }}
        className={clsx(
          "relative border bg-cover bg-center bg-no-repeat border-gray-200 border-solid overflow-hidden md:border-l-0 md:border-y-0 shrink-0 grow-0 aspect-[16_/_9]",
          {
            "md:h-[240px]": isAudioPodcast,
            "md:h-[310px]": !isAudioPodcast,
          }
        )}
      >
        {!isPublished && (
          <div className="absolute  w-full h-full p-2 flex justify-end items-start">
            <Typography
              variant="caption"
              className="text-white font-bold flex items-center"
            >
              <Tooltip title="This podcast is not visible to non-admin users">
                <Chip
                  label="Draft"
                  className="bg-orange-700 hover:bg-orange-800 text-white"
                />
              </Tooltip>
            </Typography>
          </div>
        )}
        {!isAudioPodcast && (
          <div
            onClick={onPlayClick}
            className="absolute w-full h-full left-0 top-0 flex justify-center items-center"
          >
            <PlayButton variant={PlayButtonVariant.Large} tabIndex={1} />
          </div>
        )}
      </Box>

      <div className="relative flex flex-col md:flex-row justify-between w-full px-5 md:pr-4 py-4 md:pb-6 rounded-[inherit] rounded-l-none">
        <div className="flex items-end justify-start w-full md:pt-[30px]">
          <Box className={clsx("flex justify-between flex-col w-full h-full")}>
            <div className="pb-5 w-[calc(100%_-_100px)]">
              <Tooltip placement="bottom-end" title={<>{title}</>}>
                <Link
                  to={generatePath("/" + paths.podcastDetail, { mediaId: id })}
                >
                  <Typography
                    component="div"
                    className={clsx(
                      "podcast-card-title font-semibold hover:underline text-primary-600 mb-2 text-[21px] leading-7",
                      { "video-card-title": !isAudioPodcast }
                    )}
                  >
                    {!isPublished && "[DRAFT] "}
                    {title}
                  </Typography>
                </Link>
              </Tooltip>
              <Tooltip
                placement="bottom-end"
                title={<>{sanitizedDescription}</>}
              >
                <Typography
                  variant="caption"
                  className={clsx(
                    "podcast-card-description mb-2 text-gray-400 text-sm",
                    { "video-card-description": !isAudioPodcast }
                  )}
                >
                  {sanitizedDescription}
                </Typography>
              </Tooltip>
            </div>

            <Box className="flex items-center justify-between">
              <Typography
                variant="caption"
                component="div"
                className="flex items-center justify-between text-gray-400"
              >
                <div className="flex flex-wrap items-center justify-center md:justify-start">
                  {isAudioPodcast && (
                    <div className="mr-4">
                      <PlayButton
                        loading={isLoadingItem}
                        disabled={!isPlayable}
                        tabIndex={2}
                        onClick={onPlayClick}
                      />
                    </div>
                  )}
                  <span className="font-bold mr-2">
                    {hasMedia ? (
                      <FormattedValue
                        value={duration}
                        formatType={FormatType.Duration}
                      />
                    ) : (
                      <div>Private</div>
                    )}
                  </span>{" "}
                  |
                  <span className="ml-2">
                    <FormattedValue
                      value={createdAt}
                      formatType={FormatType.Date}
                    />
                  </span>
                </div>
              </Typography>

              <div className="flex items-center">
                {hasPrivateInfo && hasPrivateContentAccess && isDealPodcast && (
                  <Link
                    to={generatePath("/" + paths.dealDetails, {
                      alias: fundAlias,
                    })}
                  >
                    <Button
                      color="inherit"
                      className="border-2 h-9 border-complementary-400 border-solid rounded-md text-complementary-400"
                      startIcon={<LockOpenOutlinedIcon />}
                    >
                      <Typography
                        variant="hairline2"
                        className="block uppercase"
                      >
                        Deal details
                      </Typography>
                    </Button>
                  </Link>
                )}
                {hasPrivateInfo && !hasPrivateContentAccess && (
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      dispatch(showPodcastModal(fundId || null));
                    }}
                    className="border-2 border-[#E6E8EC] border-solid rounded p-1"
                  >
                    <LockOutlinedIcon />
                  </IconButton>
                )}
              </div>
            </Box>
          </Box>
        </div>
        <div className="absolute right-[15px] top-[15px]">
          <div>
            {isAdmin && (
              <MenuButton
                outline
                boldItems
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                items={[
                  {
                    label: "Edit podcast",
                    icon: <EditOutlined />,
                    onClick: () =>
                      navigate(
                        generatePath(
                          "/" +
                            (isDealPodcast
                              ? paths.editDealPodcast
                              : paths.podcastEdit),
                          isDealPodcast
                            ? {
                                alias: fundAlias,
                                mediaId: id,
                              }
                            : { mediaId: id }
                        )
                      ),
                  },
                  {
                    label: isPublished ? "Unpublish" : "Publish",
                    loading: isPublishing,
                    onClick: () => {
                      onPublishToggle();
                    },
                    icon: isPublished ? <VisibilityOff /> : <Visibility />,
                  },
                  {
                    label: "Delete",
                    onClick: onDeleteClick,
                    icon: <DeleteForeverOutlined />,
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
