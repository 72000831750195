import React from "react";
import { CardAction } from "ui/components";
import Switch from "@mui/material/Switch";
import { useDealId, useDealGeneralData, useErrorHandler } from "ui/hooks";
import DealService from "services/deal.service";

const DealQuestionAndAnswersAction = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const isQuestionAndAnswersEnabled = data?.isQuestionAndAnswersEnabled;
  const [loading, setLoading] = React.useState(false);
  const { handleError } = useErrorHandler();

  const handleClick = async () => {
    setLoading(true);
    try {
      await DealService.enableDealQuestionAndAnswers(dealId, {
        isQuestionAndAnswersEnabled: !isQuestionAndAnswersEnabled,
      });
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to toggle the notifications.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardAction
      label="Enable Q&A"
      description={
        isQuestionAndAnswersEnabled
          ? "Questions & Answers feature is enabled."
          : "Questions & Answers feature is disabled."
      }
      loading={loading}
      onClick={handleClick}
      endAdornment={
        <Switch checked={isQuestionAndAnswersEnabled} disabled={loading} />
      }
    />
  );
};

export default DealQuestionAndAnswersAction;
