import { Button, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import clsx from "clsx";
import { DealStatusEnum } from "domain/deal";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CardAction } from "ui/components";
import {
  useDealGeneralData,
  useDealId,
  useDealMedia,
  useDealPaths,
  useDealPermissions,
  useGroupValidation,
  useTypedSelector,
} from "ui/hooks";
import ModalComponent from "ui/pages/CommonLayout/Modals/ModalComponent";
import GetFundAccess from "../DealCardWrapper/DealCardBody/GetFundAccess/GetFundAccess";
import DealSideBar from "../DealSideBar";
import DealDetailPrivate from "./DealDetailPrivate/DealDetailPrivate";
import DealDetailPublic from "./DealDetailPublic/DealDetailPublic";

interface DealPreviewProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const DealDetail = () => {
  const dealId = useDealId();
  const username = useTypedSelector((state) => state?.auth?.user?.username);
  const { data, mutate } = useDealGeneralData();
  const { dealView, dealAccess } = useDealPermissions();
  const [showPublic, setShowPublic] = React.useState(false);
  const [isPublicModalOpen, setPublicModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const viewedKey = useMemo(() => {
    return `${dealId}:${username}:viewed`;
  }, [dealId, username]);

  const content = useMemo(() => {
    if (dealAccess && !showPublic) return <DealDetailPrivate />;
    if (dealView || showPublic) return <DealDetailPublic />;

    return null;
  }, [dealAccess, dealView, showPublic]);

  useEffect(() => {
    const isViewed = localStorage.getItem(viewedKey) === String(true);

    if (
      dealView &&
      data?.status === DealStatusEnum.Available &&
      !isViewed &&
      !dealAccess
    ) {
      setPublicModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePublicModal = useCallback(() => {
    setPublicModalOpen(false);
    localStorage.setItem(viewedKey, String(true));
  }, [viewedKey]);

  const handleRequestAccessSuccess = useCallback(async () => {
    await mutate();
    enqueueSnackbar("The request has been sent successfully.", {
      title: "Request sent!",
      variant: "success",
    });
    handleClosePublicModal();
  }, [enqueueSnackbar, handleClosePublicModal, mutate]);

  return (
    <div className="relative">
      <div
        className={clsx(
          "flex flex-col-reverse md:grid md:gap-[39px] md:grid-cols-[minmax(297px,690px)_48px]"
        )}
      >
        <div>
          <DealAdminTools checked={showPublic} setChecked={setShowPublic} />
          {content}
        </div>
        <div className="h-full mb-8 -mt-5 md:mb-0 relative w-full flex md:block md:w-auto justify-center">
          <DealSideBar />
        </div>
      </div>

      <ModalComponent
        title="Request Access"
        open={isPublicModalOpen}
        onClose={handleClosePublicModal}
        smallTitle
      >
        <div className="h-full flex flex-col">
          <Typography variant="body2" className="text-gray-400">
            We are currently viewing the public detail. If you are interested
            and would like to learn more as a potential investor, please request
            to view private details.
          </Typography>
          <div className="flex flex-col space-y-2 pt-8 mt-auto">
            <GetFundAccess onSubmitSuccess={handleRequestAccessSuccess} />
            <Button variant="outlined" onClick={handleClosePublicModal}>
              View public details
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

const DealAdminTools = ({ checked, setChecked }: DealPreviewProps) => {
  const { editDealPodcastPath } = useDealPaths();
  const { dealEdit } = useDealPermissions();
  const { isAdmin, isSyndicationAdmin } = useGroupValidation();
  const { data, loading } = useDealMedia();
  const showPodcastUpload =
    (isAdmin || (isSyndicationAdmin && dealEdit)) && !loading && !data;

  const handleClick = () => {
    setChecked(!checked);
  };

  return (
    <div className="space-y-4 flex flex-col mb-12">
      {dealEdit && (
        <CardAction
          label="Public version"
          description="See a version of this deal shown to an unapproved investor"
          onClick={handleClick}
          endAdornment={<Switch checked={checked} />}
        />
      )}
      {showPodcastUpload && (
        <div>
          <Link to={editDealPodcastPath}>
            <CardAction label="Upload podcast" description="Open upload page" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default DealDetail;
