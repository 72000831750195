import type { SVGProps } from "react";
const SvgBullpenLogoCube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 384"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#F4F5F6"
      d="M14.857 94.068a1.605 1.605 0 0 1 0-2.905l184.39-90.156a1.72 1.72 0 0 1 1.506 0l184.39 90.156a1.605 1.605 0 0 1 0 2.905l-55.202 26.99a1.71 1.71 0 0 1-1.48.012L193.897 57.908a2.72 2.72 0 0 0-2.345.018l-64.644 31.608c-1.994.975-1.97 3.776.039 4.72l128.484 60.308a1.605 1.605 0 0 1 .024 2.916l-54.702 26.746a1.72 1.72 0 0 1-1.506 0zm251.782 78.069c1.13-.553 2.427.259 2.427 1.452v180.313c0 .61-.353 1.174-.922 1.452l-56.522 27.636c-1.129.553-2.426-.26-2.426-1.452V201.225c0-.61.352-1.174.921-1.452zm75.418 147.078c-1.129.552-2.426-.26-2.426-1.452V137.45c0-.61.352-1.174.921-1.453l56.521-27.635c1.13-.553 2.427.259 2.427 1.452v180.313a1.62 1.62 0 0 1-.921 1.452zM1.421 291.579a1.62 1.62 0 0 1-.921-1.452V109.814c0-1.193 1.297-2.005 2.426-1.452l186.957 91.411c.569.278.921.842.921 1.452v180.313c0 1.192-1.297 2.005-2.426 1.452z"
    />
  </svg>
);
export default SvgBullpenLogoCube;
