import campaignService from "services/campaign.service";
import useSWR, { SWRConfiguration } from "swr";
import { ICampaignContactList } from "types/campaign.service";

export const useContactList = (
  id: string,
  options?: SWRConfiguration<ICampaignContactList>
) => {
  return useSWR<ICampaignContactList>(
    `/Campaign/contact-list/${id}`,
    () => campaignService.getContactListById(id),
    options
  );
};
