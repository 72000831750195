import { FC } from "react";
import styles from "./styles.module.scss";
import parseHtml, { Element, domToReact } from "html-react-parser";

interface HTMLEditorFieldViewerProps {
  htmlContent: string;
}

export const HTMLEditorFieldViewer: FC<HTMLEditorFieldViewerProps> = ({
  htmlContent,
}) => (
  <div className={styles.Viewer}>
    {parseHtml(htmlContent, {
      trim: false,

      replace(domNode) {
        if (!(domNode instanceof Element)) {
          return domNode;
        }

        if (
          ["p", "span", "div"].includes(domNode.tagName) &&
          domNode.children.length === 0
        ) {
          return <br />;
        }

        if (
          (domNode.firstChild as Element)?.tagName === "img" &&
          domNode.attribs.style?.includes("text-align:none;")
        ) {
          return (
            <div style={{ textAlign: "center" }}>
              {domToReact(domNode.children)}
            </div>
          );
        }

        return domNode;
      },
    })}
  </div>
);
