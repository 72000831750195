import React from "react";

interface Props {
  vertical?: boolean;
}

export const DividerDashed = ({ vertical }: Props) => {
  if (vertical) {
    return (
      <div
        className="border-dashed border-[#E6E8EC]"
        style={{
          borderTop: "none",
          borderRight: "none",
          borderBottom: "none",
          borderLeftWidth: "1px",
        }}
      />
    );
  }

  return (
    <div
      className="border-dashed border-[#E6E8EC]"
      style={{
        borderTopWidth: "1px",
        borderRight: "none",
        borderBottom: "none",
        borderLeft: "none",
      }}
    />
  );
};

export default DividerDashed;
