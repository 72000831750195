import { Button, MenuItem, Typography } from "@mui/material";
import { ReactComponent as IconSettingsLine } from "assets/svg/icon-settings-line.svg";
import { useState } from "react";
import { Loading, Modal, UserAvatar } from "ui/components";
import { useManageRoles } from "../../hooks/useManageRoles";
import {
  PredefinedRoles,
  UserWithRoles,
} from "ui/pages/DealDataRoom/DealDataRoom.types";
import { Close, Done } from "@mui/icons-material";
import RolesField from "ui/pages/DataRoom/DataRoomFilePermissions/components/RolesField/RolesField";
import AddRole from "ui/pages/DataRoom/DataRoomFilePermissions/components/AddRole/AddRole";
import { ResourceType } from "types/resource.service";

interface ManageRolesProps {
  resourceId: string;
  type: ResourceType;
  data: UserWithRoles;
  onCloseMenu?: () => void;
  title?: string;
}

const ManageRoles = ({
  data,
  onCloseMenu,
  resourceId,
  type,
  title = "Manage roles",
}: ManageRolesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    roles,
    rolesAvailable,
    reset,
    toggle,
    roleNames,
    isLoading,
    save,
    isSaveDisabled,
  } = useManageRoles({ user: data, resourceId, type });

  const handleClose = () => {
    setIsOpen(false);
    onCloseMenu?.();
    if (!isOpen) {
      reset();
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onDelete = (id: string, roleId: string) => () => {
    toggle(roleId);
  };

  const onAdd = (id: string) => () => toggle(id);

  const onSave = async () => {
    await save();
    setIsOpen(false);
  };

  return (
    <MenuItem onClick={handleOpen}>
      <div className="flex items-center">
        <IconSettingsLine className="mr-2 aside-icon" />
        {title}
      </div>
      <Modal
        title="Manage roles"
        open={isOpen}
        onClose={handleClose}
        bodyProps={{
          className: "xs:w-[340px] md:w-[470px] max-w-[1200px]",
        }}
      >
        <div className="flex gap-2 text-complementary-400 text-sm font-semibold items-center mb-8">
          <UserAvatar
            className="w-10 h-10 sm:w-12 sm:h-12"
            src={data.imageUrl}
          />
          {data.name}
        </div>
        <Typography className="text-gray-300 text-xs" variant="hairline2">
          Assigned roles
        </Typography>
        <div className="flex flex-wrap items-center gap-2 mt-4 min-h-[40px] max-h-[200px] overflow-scroll">
          <RolesField
            id={data.userId}
            roles={roles}
            currRoleId={null}
            isLoading={false}
            onDelete={onDelete}
            useIsRemovable
          />
          <AddRole
            type={type}
            resourceId={resourceId}
            roles={rolesAvailable}
            roleNames={roleNames}
            addRoleHandler={onAdd}
            unassignable={[PredefinedRoles.ALL]}
            disableRemove={!isSaveDisabled}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-2 mt-8">
          <Button
            className="w-full order-2 md:order-1"
            variant="outlined"
            size="large"
            onClick={handleClose}
            endIcon={<Close className="w-4" />}
          >
            Cancel
          </Button>
          <Button
            className="w-full order-1 md:order-2"
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading || isSaveDisabled}
            onClick={onSave}
            endIcon={
              isLoading ? <Loading size={16} /> : <Done className="w-4" />
            }
          >
            Save
          </Button>
        </div>
      </Modal>
    </MenuItem>
  );
};

export default ManageRoles;
