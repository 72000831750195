import IconButton from "@mui/material/IconButton";
import { useCallback, useMemo, useState } from "react";
// import { ReactComponent as IconShareSVG } from "assets/svg/icon-share.svg";
import { ReactComponent as IconLikeFilledSVG } from "assets/svg/icon-like-filled.svg";
import { ReactComponent as IconLikeLineSVG } from "assets/svg/icon-like-line.svg";
// import { ReactComponent as IconMoreSVG } from "assets/svg/icon-more.svg";
import { Download } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { downloadFileByUrl } from "application/utils";
import dealService from "services/deal.service";
import { ShareUrlModal } from "ui/components";
import {
  useDealAlias,
  useDealGeneralData,
  useDealPermissions,
  useErrorHandler,
  useGroupValidation,
} from "ui/hooks";

export const DealSideBar = () => {
  const alias = useDealAlias();
  const { isAdmin } = useGroupValidation();
  const { data, loading, mutate } = useDealGeneralData();
  const { dealAccess } = useDealPermissions();
  const [openShare, setOpenShare] = useState(false);
  const [isSubmittingLike, setSubmittingLike] = useState(false);
  const { handleError } = useErrorHandler();
  const [isDownloading, setDownloading] = useState(false);
  // const dispatch = useDispatch();

  const isPDFEnabled = useMemo(
    () => data?.isPDFDownloadEnabled || isAdmin,
    [data?.isPDFDownloadEnabled, isAdmin]
  );

  const downloadPDFVersion = useCallback(async () => {
    setDownloading(true);

    try {
      const dealUrl = await dealService.getDealPDFUrl(alias);

      await downloadFileByUrl(dealUrl, `${data?.name}.pdf`);
    } catch (e) {
      handleError(
        e,
        "It was not possible to download the PDF. Please try again later."
      );
    } finally {
      setDownloading(false);
    }
  }, [alias, data?.name, handleError]);

  const toggleLike = useCallback(async () => {
    if (data?.liked) {
      return await dealService.removeDealLike(alias);
    }

    return await dealService.addDealLike(alias);
  }, [data?.liked, alias]);

  const onLikeClick = async () => {
    setSubmittingLike(true);

    try {
      await toggleLike();
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to like the deal.");
    } finally {
      setSubmittingLike(false);
    }
  };

  const closeShareModal = () => {
    setOpenShare(false);
  };

  const likeIcon = useMemo(() => {
    if (isSubmittingLike) {
      return <CircularProgress size={20} color="primary" />;
    }

    if (data?.liked) {
      return <IconLikeFilledSVG />;
    }

    return <IconLikeLineSVG />;
  }, [isSubmittingLike, data?.liked]);

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="md:sticky md:pt-6 md:top-24 md:left-0 flex space-x-4 md:space-x-0 md:flex-col md:space-y-4">
        <Tooltip title={data?.liked ? "Remove like" : "Like Deal"}>
          <IconButton
            disabled={isSubmittingLike}
            className="h-12 w-12 flex justify-center items-center border-2 border-solid border-gray-200 bg-white"
            onClick={onLikeClick}
          >
            {likeIcon}
          </IconButton>
        </Tooltip>
        {isPDFEnabled && dealAccess && (
          <Tooltip title="Download as PDF">
            <IconButton
              onClick={downloadPDFVersion}
              className="h-12 w-12 flex justify-center items-center border-2 border-solid border-gray-200 bg-white"
            >
              {!isDownloading ? (
                <Download />
              ) : (
                <CircularProgress size={22} color="inherit" />
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* <IconButton
          className="h-12 w-12 flex justify-center items-center border-2 border-solid border-gray-200 bg-white"
          sx={{
            "& path": {
              fill: "#777E91",
            },
          }}
        >
          <IconMoreSVG />
        </IconButton> */}
      </div>
      <ShareUrlModal open={openShare} onClose={closeShareModal} />
    </>
  );
};

export default DealSideBar;
