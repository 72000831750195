export enum RegistrationStepEnum {
  Invite = "invite",
  BasicInformation = "basicInformation",
  PhoneConfirmation = "phoneConfirmation",
  EmailConfirmation = "emailConfirmation",
  Position = "position",
  Interests = "interests",
  Follow = "follow",
  NDA = "nda",
  Welcome = "welcome",
  Guide = "guide",
  Accreditation = "accreditation",
  QualifyAsInvestor = "qualifyAsInvestor",
}
