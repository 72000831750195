import { delayAsync } from "application/utils";
import { FormInput } from "ui/components";
import { Check, ContentCopy } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormInputProps } from "../FormInput/FormInput";
import clsx from "clsx";

interface ClipboardInputProps extends Omit<FormInputProps, "ref" | "label"> {
  value: string;
  buttonDisabled?: boolean;
}

export const ClipboardInput: FC<ClipboardInputProps> = ({
  value,
  buttonDisabled,
  ...props
}) => {
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const handleClick = useCallback(async () => {
    await navigator.clipboard.writeText(value);
    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [value]);

  return (
    <div className="relative w-auto">
      <FormInput
        readOnly
        value={value}
        inputProps={{
          className: clsx("pr-[105px]", props.inputProps?.className),
        }}
        {...props}
      />
      <div className="absolute right-2 top-0 h-full flex items-center my-auto">
        <Button
          onClick={handleClick}
          startIcon={displayCopyFeedback ? <Check /> : <ContentCopy />}
          className="h-8"
          disabled={buttonDisabled}
        >
          <Typography variant="button2">
            {displayCopyFeedback ? "Copied!" : "Copy"}
          </Typography>
        </Button>
      </div>
    </div>
  );
};
