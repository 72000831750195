import { Campaign } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { SocialNetworkName } from "domain/common";
import { FC, useMemo } from "react";

interface SocialItemWrapperProps {
  linkTo: string;
  networkName: SocialNetworkName;
}

export const SocialItemWrapper: FC<SocialItemWrapperProps> = ({
  networkName,
  linkTo,
}) => {
  const icon = useMemo(() => {
    switch (networkName) {
      case SocialNetworkName.Instagram:
        return <InstagramIcon />;
      case SocialNetworkName.Twitter:
        return <TwitterIcon />;
      case SocialNetworkName.LinkedIn:
        return <LinkedInIcon />;
      default:
        return <Campaign />;
    }
  }, [networkName]);

  return (
    <a
      href={linkTo}
      target="_blank"
      rel="noopener noreferrer"
      className="mr-2 text-gray-300 hover:text-primary-400 transition-colors ease-in-out"
    >
      {icon}
    </a>
  );
};
