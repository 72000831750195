import React from "react";
import { DealCategory, DealOverview } from "../index";

const DealDetailPublic = () => {
  return (
    <div className="space-y-6">
      <DealCategory />
      <DealOverview />
    </div>
  );
};

export default DealDetailPublic;
