import React from "react";
import Typography from "@mui/material/Typography";
import { Message as MessageInterface } from "types/deal";
import { AuthorAvatar, AuthorDetails } from "../Author";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { HTMLEditorFieldViewer } from "ui/components";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  ActionPanel?: React.ReactNode;
}

export const AnswerMessage = ({
  ActionPanel,
  author,
  createdAt,
  text,
}: Props & MessageInterface) => {
  const dateFromNow = React.useMemo(
    () => `${formatDistanceToNowStrict(parseISO(createdAt))} ago`,
    [createdAt]
  );

  return (
    <div>
      <div className="flex">
        <AuthorAvatar className="pt-3" {...author} />
        <div className="bg-gray-150 rounded-b-lg rounded-tr-lg pl-5 pt-3 pb-4 pr-2 grow">
          <div className="flex justify-between mb-4">
            <AuthorDetails
              fullName={author.fullName}
              jobTitle={author.jobTitle}
            />
            <div className="flex flex-col space-x-4 items-end">
              {ActionPanel}
              <Typography
                className="text-gray-300 whitespace-nowrap"
                variant="caption2"
              >
                {dateFromNow}
              </Typography>
            </div>
          </div>
          <HTMLEditorFieldViewer htmlContent={text} />
        </div>
      </div>
    </div>
  );
};

export default AnswerMessage;
