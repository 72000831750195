export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/;

export const passwordSpecialCharacterRegex =
  // eslint-disable-next-line no-useless-escape
  /[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\':;|_~`\/\\+=]/;

// TODO: Replace it for the libphonenumber-js validation
export const phoneFormatRegex = /.+/;

export const usernameRegex = /^[0-9a-zA-Z+=,.@-]+$/;

export const usernameMinLength = 8;
export const usernameMaxLength = 128;

export const passwordMinLength = 8;
export const passwordMaxLength = 99;
