import { Chip } from "@mui/material";
import clsx from "clsx";
import { FC, useMemo } from "react";

export enum StatusBadgeType {
  Success = "Success",
  Error = "Error",
  Draft = "Draft",
  Default = "Default",
  Info = "Info",
}

export interface StatusBadgeProps {
  type?: StatusBadgeType;
  label: string;
  className?: string;
}

export const StatusBadge: FC<StatusBadgeProps> = ({
  type = StatusBadgeType.Default,
  className,
  label,
}) => {
  const { colorProps } = useMemo(() => {
    switch (type) {
      case StatusBadgeType.Draft:
        return {
          colorProps: "bg-orange-700 hover:bg-orange-800 text-gray-100",
        };
      case StatusBadgeType.Success:
        return {
          colorProps: "bg-primary-200 hover:bg-primary-300 text-gray-100",
        };
      case StatusBadgeType.Error:
        return {
          colorProps: "bg-error hover:bg-danger-300 text-gray-100",
        };
      case StatusBadgeType.Info:
        return {
          colorProps:
            "bg-complementary-300 hover:bg-complementary-400 text-gray-100",
        };
      case StatusBadgeType.Default:
      default:
        return {
          colorProps: "bg-gray-300 text-gray-100 hover:bg-gray-400",
        };
    }
  }, [type]);

  return <Chip label={label} className={clsx(colorProps, className)} />;
};
