import { isUndefined } from "lodash";
import mediaService from "services/media.service";
import useSWR, { SWRConfiguration } from "swr";
import { IMedia } from "types/media.service";
import { useMediaId } from "./useMediaId";

export const useMedia = (configuration?: SWRConfiguration<IMedia>) => {
  const mediaId = useMediaId();
  const { data, error, mutate } = useSWR<IMedia>(
    `/Media/${mediaId}`,
    () => mediaService.getMedia(mediaId),
    configuration
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};
