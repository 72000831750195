import { AuthGroupEnum } from "domain/authorization";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useSyndicationLayout } from "./Syndication";
import { USER_GROUP } from "ui/pages/FederatedSignIn/FederatedSignIn.constants";
import { includes } from "lodash";
import { Roles, RolesArray } from "types";

interface GroupValidation {
  isDeveloper?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  isUserOnly?: boolean;
  isInvestor?: boolean;
  isFundManager?: boolean;
  isSyndicationAdmin?: boolean;
  hasAdminLevel?: boolean;
  groups?: AuthGroupEnum[];
}

const getCurrentSession = async () => {
  try {
    return await Auth.currentSession();
  } catch (e) {
    return undefined;
  }
};

const hasRole = (roles: Roles) => (rolesArray: RolesArray) =>
  rolesArray.some((role) => roles[role]);

const hasEveryRole = (roles: Roles) => (rolesArray: RolesArray) =>
  rolesArray.every((role) => roles[role]);

export const useGroupValidation = () => {
  const syndicationLayout = useSyndicationLayout();
  const [loading, setLoading] = useState(true);
  const [groupValidation, setGroupValidation] = useState<GroupValidation>({});

  const populateGroupValidation = useCallback(async () => {
    const session = await getCurrentSession();
    const groups = session?.getIdToken()?.payload["cognito:groups"] || [];

    const isAdmin = includes(groups, AuthGroupEnum.Admin);
    const isDeveloper = includes(groups, AuthGroupEnum.Developer);

    const isSyndicationAdmin = !!(
      (syndicationLayout?.isSyndicate &&
        includes(
          groups,
          `syndication-${syndicationLayout.mainInfo.id}-admin-group`
        )) ||
      isAdmin
    );
    const isFundManager =
      isAdmin ||
      (!syndicationLayout.isSyndicate &&
        includes(groups, AuthGroupEnum.FundManager)) ||
      (syndicationLayout.isSyndicate &&
        includes(
          groups,
          `syndication-${syndicationLayout.mainInfo.id}-fund-manager-group`
        ));
    const isInvestor =
      isAdmin ||
      includes(groups, AuthGroupEnum.Investor) ||
      (syndicationLayout.isSyndicate &&
        includes(
          groups,
          `syndication-${syndicationLayout.mainInfo.id}-investor-group`
        ));

    const isUser = syndicationLayout.isSyndicate
      ? includes(
          groups,
          "syndication-" + syndicationLayout.mainInfo.id + "-user-group"
        )
      : includes(groups, USER_GROUP);

    const isUserOnly =
      isUser &&
      [
        !isDeveloper,
        !isAdmin,
        !isSyndicationAdmin,
        !isFundManager,
        !isInvestor,
      ].every(Boolean);

    const hasAdminLevel = isAdmin || isSyndicationAdmin;

    return {
      isAdmin,
      isFundManager,
      isInvestor,
      isSyndicationAdmin,
      groups,
      hasAdminLevel,
      isDeveloper,
      isUser,
      isUserOnly,
    };
  }, [syndicationLayout]);

  useEffect(() => {
    let isMounted = true;
    const fetchGroupValidation = async () => {
      const data = await populateGroupValidation();
      if (isMounted) {
        setGroupValidation(data);
        setLoading(false);
      }
    };

    fetchGroupValidation();

    return () => {
      isMounted = false;
    };
  }, [populateGroupValidation]);

  const {
    isAdmin,
    isFundManager,
    isInvestor,
    isSyndicationAdmin,
    isDeveloper,
    isUserOnly,
  } = groupValidation;

  const userRoles: Roles = {
    user: Boolean(isUserOnly),
    investor: Boolean(isInvestor),
    fundManager: Boolean(isFundManager),
    admin: Boolean(isAdmin),
    syndicationAdmin: Boolean(isSyndicationAdmin),
    developer: Boolean(isDeveloper),
  };

  return {
    ...groupValidation,
    loading,
    hasRole: hasRole(userRoles),
    hasEveryRole: hasEveryRole(userRoles),
  };
};
