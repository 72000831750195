import React, { FC } from "react";
import { useDealGeneralData, useDealPermissions } from "ui/hooks";
import { DealCard } from "ui/components";
import DealCardBody from "./DealCardBody/DealCardBody";
import { LINK_TABS_HEIGHT } from "ui/components/LinkTabs";

const DealCardWrapper: FC = () => {
  const { data } = useDealGeneralData();
  const { permissions } = useDealPermissions();

  return (
    <div className="sticky" style={{ top: `calc(24px + ${LINK_TABS_HEIGHT})` }}>
      {data && (
        <DealCard deal={data} size="large">
          <DealCardBody deal={data} permissions={permissions} />
        </DealCard>
      )}
    </div>
  );
};

export default DealCardWrapper;
