import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "types";

export interface InviteState {
  isModalOpen: boolean;
  confirmedInviteCode: Nullable<string>;
  originInviteCode?: string;
  inviter?: {
    username: string;
    name: string;
    familyName: string;
    job: string;
    profileImageUrl: string;
  };
  invitee?: {
    username: string;
    email?: string;
    phone?: string;
  };
}

const initialState: InviteState = {
  isModalOpen: false,
  confirmedInviteCode: null,
};

const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setConfirmedInviteCode(
      state: InviteState,
      { payload }: PayloadAction<string>
    ) {
      state.confirmedInviteCode = payload;
    },
    setOriginInviteCode(
      state: InviteState,
      { payload }: PayloadAction<string | undefined>
    ) {
      state.originInviteCode = payload;
    },
    openInviteModal(state: InviteState) {
      state.isModalOpen = true;
    },
    closeInviteModal(state: InviteState) {
      state.isModalOpen = false;
    },
    setInviterData(
      state: InviteState,
      { payload }: PayloadAction<InviteState["inviter"]>
    ) {
      state.inviter = payload;
    },
    setInviteeData(
      state: InviteState,
      { payload }: PayloadAction<InviteState["invitee"]>
    ) {
      state.invitee = payload;
    },
    resetInviteState(state: InviteState) {
      state.invitee = initialState.invitee;
      state.inviter = initialState.inviter;
      state.confirmedInviteCode = initialState.confirmedInviteCode;
      state.originInviteCode = initialState.originInviteCode;
      state.isModalOpen = initialState.isModalOpen;
    },
  },
});

export const {
  setInviteeData,
  setInviterData,
  resetInviteState,
  openInviteModal,
  closeInviteModal,
  setConfirmedInviteCode,
  setOriginInviteCode,
} = inviteSlice.actions;

export default inviteSlice.reducer;
