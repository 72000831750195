import { vestResolver } from "@hookform/resolvers/vest";
import { create, enforce, test } from "vest";
import { FormInputs } from "./DealEditPerson.form";

const schema = create<(data: FormInputs) => void>(
  ({ description, name, url }) => {
    test("name", "Name is required", () => {
      enforce(name).isNotBlank();
    });

    test("description", "Description is required", () => {
      enforce(description).isNotEmpty();
    });

    // TODO: Check if it is really necessary
    // test("url", "URL is required", () => {
    //   enforce(url).isNotEmpty();
    // });
  }
);

export default vestResolver(schema);
