import { Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { cleanAssistantMessage, getRichTextContent } from "application/utils";
import {
  AssistantAttachableField,
  AssistantThreadMessageRole,
  DealFieldInfoMap,
  DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
  FieldAssistantType,
} from "domain/assistant";
import { noop, startCase } from "lodash";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import dealAssistantService from "services/dealAssistant.service";
import useSWR from "swr";
import { useAssistantStream, useErrorHandler } from "ui/hooks";
import { MessageLoader } from "../AssistantChat/AssistantChat.components";
import { MarkdownInputViewer } from "../MarkdownInput";
import { ConfirmationModal } from "../Modal";
import {
  AssistantFloatingChat,
  IAssistantChatMessage,
} from "./AssistantFloatingChat";
import clsx from "clsx";

interface IAssistantFloatingChatContext {
  openChat: (dealId: string, fieldId: string, type: FieldAssistantType) => void;
  closeChat: () => void;
  chatPayload?: {
    dealId: string;
    fieldId: string;
    type: FieldAssistantType;
  };
}

const AssistantFloatingChatContext =
  createContext<IAssistantFloatingChatContext>({
    openChat: noop,
    closeChat: noop,
  });

export const useAssistantFloatingChatContext = () => {
  return useContext(AssistantFloatingChatContext);
};

type RemovePayloadModalType = "delete" | "replace";

interface IRemovePayloadModalRequest {
  type: RemovePayloadModalType;
  payload?: IAssistantFloatingChatContext["chatPayload"];
}

export const AssistantFloatingChatProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState<string>();
  const [isMinimized, setMinimized] = useState(false);
  const [removePayloadModalType, setRemovePayloadModalType] =
    useState<IRemovePayloadModalRequest | null>(null);
  const [chatPayload, setChatPayload] = useState<
    IAssistantFloatingChatContext["chatPayload"] | undefined
  >();
  const { pathname } = useLocation();
  const { handleError } = useErrorHandler();
  const { readStream, resetValue, streamMessages, isStreaming } =
    useAssistantStream(chatPayload?.dealId || "");

  const { data, mutate } = useSWR(
    threadId &&
      chatPayload?.dealId &&
      `assistant/thread/request/${threadId}/messages`,
    () => {
      return dealAssistantService.getThreadById(
        chatPayload?.dealId as string,
        threadId as string
      );
    },
    {
      refreshInterval: (value) => (value?.hasPendingRuns ? 5000 : 0),
    }
  );

  const openChat = useCallback(
    (dealId: string, fieldId: string, type: FieldAssistantType) => {
      setChatPayload({ dealId, fieldId, type });
    },
    [setChatPayload]
  );

  const requestNewChat = useCallback<IAssistantFloatingChatContext["openChat"]>(
    (dealId, fieldId, type) => {
      if (chatPayload && fieldId !== chatPayload.fieldId) {
        return setRemovePayloadModalType({
          type: "replace",
          payload: { dealId, fieldId, type },
        });
      }

      return openChat(dealId, fieldId, type);
    },
    [chatPayload, openChat]
  );

  const closeChat = useCallback(() => {
    setChatPayload(undefined);
    setThreadId(undefined);
    setMinimized(false);
  }, []);

  const requestCloseChat = useCallback(() => {
    return setRemovePayloadModalType({ type: "delete" });
  }, []);

  const onModalClose = useCallback(() => {
    return setRemovePayloadModalType(null);
  }, []);

  const onModalConfirm = useCallback(() => {
    if (!removePayloadModalType) return;

    const { type, payload } = removePayloadModalType;

    try {
      switch (type) {
        case "replace":
          return openChat(
            payload?.dealId as string,
            payload?.fieldId as string,
            payload?.type as FieldAssistantType
          );
        case "delete":
          return closeChat();
      }
    } finally {
      onModalClose();
    }
  }, [closeChat, onModalClose, openChat, removePayloadModalType]);

  useEffect(() => {
    if (typeof pathname === "string") {
      closeChat();
    }
  }, [closeChat, pathname]);

  const onMessageAdd = useCallback(
    async (message: string) => {
      if (!chatPayload) return;

      setLoading(true);

      try {
        if (!threadId)
          throw new FeedbackError(
            "Please, select a thread before adding messages"
          );

        const stream = await dealAssistantService.addThreadMessage(
          chatPayload.dealId,
          threadId,
          message
        );

        await readStream(stream, message);
        await mutate();
        resetValue();
      } catch (e) {
        handleError(e, "It was not possible to generate the thread.");
      } finally {
        setLoading(false);
      }
    },
    [chatPayload, handleError, mutate, readStream, resetValue, threadId]
  );

  const onInit = useCallback(async () => {
    if (!chatPayload) return;
    setLoading(true);

    try {
      const newThread = await dealAssistantService.createAndRunFieldThread(
        chatPayload.dealId,
        chatPayload.fieldId
      );

      setThreadId(newThread.threadId);
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to generate the thread.");
    } finally {
      setLoading(false);
    }
  }, [chatPayload, handleError, mutate]);

  const messages = useMemo<IAssistantChatMessage[]>(() => {
    if (!threadId || !chatPayload) return [];

    const isLastMessageFromAssistant =
      [...(data?.messages || [])]?.pop()?.role ===
      AssistantThreadMessageRole.Assistant;

    const loadingMessages: IAssistantChatMessage[] =
      (isLoading || data?.hasPendingRuns) &&
      !isStreaming &&
      !isLastMessageFromAssistant
        ? [
            {
              content: <MessageLoader />,
              threadId,
              createdAt: new Date().toISOString(),
              role: AssistantThreadMessageRole.Assistant,
              assistantId: "",
              messageId: "",
              openAiId: "",
              updatedAt: "",
              textToCopy: "",
            },
          ]
        : [];

    const validMessages = [...(data?.messages || []), ...streamMessages];

    const parsedMessages: IAssistantChatMessage[] = validMessages.map(
      (item) => {
        if (item.role === AssistantThreadMessageRole.User) {
          return item;
        }

        if (
          item.role === AssistantThreadMessageRole.Assistant &&
          item.content.includes("myfiles_browser")
        ) {
          return {
            ...item,
            content: DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
            error: true,
          };
        }

        switch (chatPayload.type) {
          case "markdown":
            return {
              ...item,
              textToCopy: cleanAssistantMessage(item.content),
              content: (
                <MarkdownInputViewer>
                  {getRichTextContent(
                    cleanAssistantMessage(item.content),
                    "markdown"
                  )}
                </MarkdownInputViewer>
              ),
            };
          default:
            return {
              ...item,
              textToCopy: cleanAssistantMessage(item.content),
              content: cleanAssistantMessage(item.content),
            };
        }
      }
    );

    return [
      ...loadingMessages,
      ...[...parsedMessages].reverse(),
      {
        content: "Create field content",
        threadId,
        createdAt: new Date().toISOString(),
        role: AssistantThreadMessageRole.User,
        assistantId: "",
        messageId: "",
        openAiId: "",
        updatedAt: "",
      },
    ];
  }, [
    data?.hasPendingRuns,
    data?.messages,
    isLoading,
    isStreaming,
    streamMessages,
    threadId,
    chatPayload,
  ]);

  return (
    <AssistantFloatingChatContext.Provider
      value={{
        openChat: requestNewChat,
        closeChat: requestCloseChat,
        chatPayload,
      }}
    >
      {children}

      {chatPayload && (
        <div
          className={clsx(
            "z-40 bg-white shadow-md fixed right-4 bottom-0 border border-solid border-gray-200 rounded-t-lg flex flex-col",
            isMinimized
              ? "cursor-pointer w-[319px]"
              : "h-[864px] max-w-[calc(100vw_-_32px)] w-[519px] max-h-[calc(100%_-_120px)]"
          )}
          onClick={isMinimized ? () => setMinimized(false) : undefined}
        >
          <AssistantFloatingChat
            title={
              DealFieldInfoMap[chatPayload?.fieldId as AssistantAttachableField]
                ?.label ?? startCase(chatPayload.fieldId)
            }
            isLoading={isLoading}
            onClose={() => requestCloseChat()}
            isInitialized={Boolean(threadId)}
            messages={messages}
            onInit={onInit}
            onMessageAdd={onMessageAdd}
            disabled={data?.hasPendingRuns}
            key={chatPayload.fieldId}
            allowMinimize
          />
        </div>
      )}

      {removePayloadModalType && (
        <ConfirmationModal
          title={
            removePayloadModalType.type === "delete"
              ? "Delete thread"
              : "Replace thread"
          }
          isOpen
          onClose={onModalClose}
          onConfirm={onModalConfirm}
          onCancel={onModalClose}
        >
          <div>
            <Typography variant="body2" component="p">
              {removePayloadModalType?.type === "delete"
                ? "Are you sure you want to delete this thread?"
                : "Are you sure you want to replace the current thread?"}
            </Typography>
            <Typography variant="body2" className="italic mt-2" component="p">
              The progress made in this thread will be lost, necessitating the
              generation of new content.
            </Typography>
          </div>
        </ConfirmationModal>
      )}
    </AssistantFloatingChatContext.Provider>
  );
};
