import { apiClient } from "./clients";
import {
  MetricData,
  MetricReportResponse,
  MetricResponse,
} from "types/metric.service";
import { MetricType } from "domain/metric";

class MetricService {
  private readonly basePath = "/Metric";

  async getMetricVisitorsChartReport(
    fundId: string,
    metricType: MetricType,
    config = {}
  ): Promise<MetricReportResponse> {
    const { data } = await apiClient.get(
      `${this.basePath}/${metricType}/${fundId}/report`,
      {
        ...config,
      }
    );

    return data;
  }

  async getMetric(
    fundId: string,
    metricType: MetricType,
    config: object = {}
  ): Promise<MetricResponse> {
    const { data } = await apiClient.get(
      `${this.basePath}/${metricType}/${fundId}`,
      {
        ...config,
        params: {
          ...(metricType === MetricType.DATA_ROOM_METRIC && {
            rootPath: "dataroom",
          }),
          // @ts-ignore
          ...config.params,
        },
      }
    );

    return data;
  }

  async sendMetric(metricData: MetricData, config = {}): Promise<void> {
    const { data } = await apiClient.post(this.basePath, metricData, {
      ...config,
    });

    return data?.response?.id;
  }
}

export default new MetricService();
