import {
  AgGridReactProps,
  AgReactUiProps,
} from "ag-grid-react/lib/shared/interfaces";
import { AgGridReact } from "ag-grid-react";
import { FC, MutableRefObject, ReactElement, forwardRef } from "react";
import clsx from "clsx";
import Loading from "../Loading/Loading";
import { EmptyContentScreen } from "../EmptyContentScreen";
import { ColDef } from "ag-grid-community";

interface WrapperProps {
  wrapperClassName?: string;
}

interface NoRowRendererProps {
  loading: boolean;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  icon?: ReactElement;
}

const NoRowRenderer: FC<NoRowRendererProps> = ({
  loading,
  title = "It was not found any data",
  subtitle = "The current resources could not be found.",
  icon,
}) => {
  if (loading) {
    return <Loading full />;
  }

  return <EmptyContentScreen title={title} subtitle={subtitle} icon={icon} />;
};

interface Props
  extends Omit<
    AgGridReactProps & AgReactUiProps & WrapperProps,
    | "noRowsOverlayComponent"
    | "noRowsOverlayComponentParams"
    | "loadingOverlayComponent"
    | "rowHeight"
    | "enableCellTextSelection"
    | "suppressCellFocus"
  > {
  ref?: MutableRefObject<AgGridReact | null>;
  noRowsOverlayComponentParams: NoRowRendererProps;
}

const defaultColumnDef: ColDef = {
  resizable: true,
  sortable: true,
  flex: 1,
  cellClass:
    "overflow-hidden whitespace-nowrap text-ellipsis whitespace-nowrap",
};

const AgGridResponsiveWrapper = forwardRef(
  ({ wrapperClassName, ...props }: Props, ref) => {
    return (
      <div className="overflow-auto">
        <div
          className={clsx(
            "w-full ag-theme-alpine m9-ag-grid-theme",
            wrapperClassName
          )}
        >
          <AgGridReact
            noRowsOverlayComponent={NoRowRenderer}
            defaultColDef={defaultColumnDef}
            loadingOverlayComponent={Loading}
            rowHeight={76}
            enableCellTextSelection={true}
            suppressCellFocus
            {...props}
            ref={ref as any}
          />
        </div>
      </div>
    );
  }
);

export default AgGridResponsiveWrapper;
