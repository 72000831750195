import { configureStore } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer, { RootState } from "./rootReducer";
import { baseApi } from "./Api/BaseApi";

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  blacklist: [
    "user",
    "notifications",
    "UI",
    "mediaPlayer",
    "api",
    "registration",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [baseApi.middleware, thunk];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);

export default store;
