import useSWR from "swr";
import UserService from "services/user.service";
import { getNameCombined, getInitials } from "application/utils";
import { UseUserProfile } from "types";

interface Params {
  id?: string;
}

export function useUserProfile({ id }: Params = {}) {
  return useSWR(
    () => (id ? `User/profile/${id}` : "User/profile/me"),
    async () => {
      const { response } = id
        ? await UserService.getUserProfileByCustomUsername(id)
        : await UserService.getUserProfile();

      if (response) {
        const fullName = getNameCombined(response.firstName, response.lastName);

        const initials = getInitials(fullName);

        return { ...response, fullName, initials } as UseUserProfile;
      }

      return;
    }
  );
}
