import { Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import CompanyCardWrapper from "../CompanyCardWrapper/CompanyCardWrapper";

export const CompanyPageLayout = () => {
  return (
    <div className="m9-container">
      <Grid container>
        <Grid xs={12} md={4} lg={3}>
          <CompanyCardWrapper />
        </Grid>
        <Grid xs={12} md={8} lg={9}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};
