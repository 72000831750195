import useSWR from "swr";
import DealService from "services/deal.service";
import { isUndefined } from "lodash";
import useDealAlias from "./useDealAlias";

export default function useDealPermissions(overwriteDealId?: string) {
  const alias = useDealAlias();
  const dealId = overwriteDealId || alias;

  const { data, error, ...rest } = useSWR(
    dealId ? `fund/${dealId}/checkpermissions` : null,
    () => DealService.getPermissions(dealId)
  );

  const loading = [data, error].every(isUndefined);

  const permissions = data?.response;
  const dealView = permissions?.view;
  const dealAccess = dealView && permissions?.fundAccess;
  const dealEdit = dealView && dealAccess && permissions?.edit;

  return {
    permissions,
    dealEdit,
    dealView,
    dealAccess,
    loading,
    error,
    ...rest,
  };
}
