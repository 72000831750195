import { isUndefined } from "lodash";
import useSWR, { SWRConfiguration } from "swr";
import { LookupUser } from "../../../types";
import lookupService from "../../../services/lookup.service";

export const useLookupDealFundManagers = (
  configuration?: SWRConfiguration<LookupUser[]>
) => {
  const swrData = useSWR<LookupUser[]>(
    `/Lookup/fundManagers`,
    () => lookupService.getFundManagers(),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configuration,
    }
  );
  const loading = [swrData.data, swrData.error].every(isUndefined);

  return {
    ...swrData,
    loading,
  };
};
