import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { FeedbackError } from "application/errors";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { defaults } from "lodash";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ControllerFieldState } from "react-hook-form";
import dealService from "services/deal.service";
import { HelperTooltip } from "ui/components";
import styles from "./styles.module.scss";

export interface HTMLEditorFieldHandle {
  reset: (value: string) => void;
}

export const HTMLEditorField = React.forwardRef(
  (
    {
      field,
      fieldState,
      label,
      placeholder,
      readOnly,
      showError,
      tooltip,
      toolbarProps = {},
    }: {
      field: { onChange: (...event: any[]) => void; value: string | undefined };
      label?: string;
      fieldState: ControllerFieldState;
      placeholder: string;
      showError?: boolean;
      readOnly?: boolean;
      tooltip?: {
        id: string;
        title: string;
        content: string;
      };
      toolbarProps?: object | undefined;
    },
    ref: React.ForwardedRef<HTMLEditorFieldHandle>
  ) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const updateEditorState = React.useCallback((value = "") => {
      const contentBlock = htmlToDraft(value);
      if (!contentBlock) return;

      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }, []);

    const onEditorStateChange = (editorState: EditorState) => {
      setEditorState(editorState);
      let html;
      if (
        editorState.getCurrentContent().hasText() &&
        editorState.getCurrentContent().getPlainText().trim().length
      ) {
        const raw = convertToRaw(editorState.getCurrentContent());
        html = draftToHtml(raw);
      } else {
        html = "";
      }

      return field.onChange(html);
    };

    useEffect(() => {
      updateEditorState(field.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadImage = React.useCallback(async function (file: File) {
      try {
        if (file) {
          const formData = new FormData();
          formData.append("File", file);

          return await dealService.uploadDealDescriptionImage(formData);
        }
      } catch (error) {
        throw new FeedbackError("It was not possible to upload the images");
      }
    }, []);

    const toolbar = React.useMemo(
      () =>
        defaults({}, toolbarProps, {
          options: [
            "inline",
            "fontSize",
            "list",
            "blockType",
            "textAlign",
            "colorPicker",
            "link",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          image: {
            previewImage: true,
            inputAccept: "image/jpeg,image/jpg,image/png,image/svg",
            uploadCallback: uploadImage,
          },
        }),
      [uploadImage, toolbarProps]
    );

    React.useImperativeHandle(ref, () => ({
      reset: (value) => {
        updateEditorState(value);
      },
    }));

    return (
      <div className="md:container md:mx-auto">
        {label && (
          <InputLabel
            disableAnimation
            shrink
            className={`relative pb-3 h-3 box-content space-x-2 overflow-visible flex items-center whitespace-normal leading-6 ${
              showError && fieldState?.error ? "text-error" : ""
            }`}
          >
            <span>{label}</span>
            {tooltip && (
              <HelperTooltip
                id={tooltip.id}
                title={tooltip.title}
                content={tooltip.content}
              />
            )}
          </InputLabel>
        )}
        <Editor
          wrapperClassName={styles.Wrapper}
          editorClassName={`${styles.Editor} ${
            showError && fieldState?.error ? "!border-[#EF466F]" : ""
          }`}
          toolbarClassName={`${styles.Toolbar} ${
            showError && fieldState?.error ? "!border-[#EF466F]" : ""
          }`}
          placeholder={placeholder}
          toolbar={toolbar}
          readOnly={readOnly}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          stripPastedStyles
        />
        {showError && fieldState?.error && (
          <Typography
            variant="caption2"
            className="font-semibold text-error pt-2"
          >
            {fieldState.error.message}
          </Typography>
        )}
      </div>
    );
  }
);
