import React, { useContext } from "react";
import DealService from "services/deal.service";
import { ParticipationTypeEnum } from "domain/deal";
import { useDealAlias } from "ui/hooks";
import { useDealParticipationRequestErrorHandler } from "ui/hooks/Deal/useDealParticipationRequestErrorHandler";
import LoadingButton from "@mui/lab/LoadingButton";
import { DealPageLayoutContext } from "ui/pages/DealPage/DealPageLayout";

interface Props {
  onSubmitSuccess: () => void;
}

const GetFundAccess = ({ onSubmitSuccess }: Props) => {
  const { handleError } = useDealParticipationRequestErrorHandler();
  const alias = useDealAlias();
  const [loading, setLoading] = React.useState(false);
  const { isFundAccessLoading, setIsFundAccessLoading } = useContext(
    DealPageLayoutContext
  );

  const handleClick = async () => {
    try {
      if (setIsFundAccessLoading) {
        setIsFundAccessLoading(true);
      } else {
        setLoading(true);
      }

      const payload = {
        participationType: ParticipationTypeEnum.FundAccess,
      };
      await DealService.sendParticipationRequest(alias, payload);
      await onSubmitSuccess();
    } catch (error) {
      handleError(error);
    } finally {
      if (setIsFundAccessLoading) {
        setIsFundAccessLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={handleClick}
      loading={isFundAccessLoading || loading}
    >
      Request Access
    </LoadingButton>
  );
};

export default GetFundAccess;
