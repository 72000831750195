import clsx from "clsx";
import React, { FC, RefObject, useMemo, useRef } from "react";
import { useCopyHTML, useDealPermissions } from "ui/hooks";
import DealSectionTitle from "../DealSectionTitle";

interface DetailsSectionProps {
  noPaddingTop?: boolean;
  title: string;
  editRoute?: string;
  isEmpty?: boolean;
  getCopyContent?: (
    innerRef: RefObject<any>
  ) => HTMLElement | Promise<HTMLElement>;
}

export const DetailSection: FC<
  DetailsSectionProps & React.ComponentPropsWithRef<"div">
> = ({
  className,
  children,
  editRoute,
  title,
  noPaddingTop = false,
  isEmpty = false,
  getCopyContent,
}) => {
  const { dealEdit } = useDealPermissions();
  const copyHtml = useCopyHTML();
  const refToCopy = useRef(null);

  const onCopy = useMemo(() => {
    if (!getCopyContent) return;

    return async () => {
      const content = await getCopyContent(refToCopy);

      copyHtml(content);
    };
  }, [copyHtml, getCopyContent]);

  if (isEmpty && !dealEdit) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        "border-0 border-b-2 border-gray-150 border-solid pb-8",
        { "pt-4": !noPaddingTop }
      )}
      ref={refToCopy}
    >
      <DealSectionTitle title={title} to={editRoute} onCopy={onCopy} />
      <div>{children}</div>
    </div>
  );
};
