import Switch from "@mui/material/Switch";
import { FeedbackError } from "application/errors";
import React from "react";
import dealAssistantService from "services/dealAssistant.service";
import { CardAction } from "ui/components";
import { useDealGeneralData, useDealId, useErrorHandler } from "ui/hooks";

export const DealManageNavAssistant = () => {
  const dealId = useDealId();
  const { data, mutate } = useDealGeneralData();
  const { handleError } = useErrorHandler();
  const isEnabled = React.useMemo(() => data?.isAssistantEnabled, [data]);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      if (!data) {
        throw new FeedbackError(
          "It was not possible to get the deal data. Please try again later."
        );
      }

      await dealAssistantService.toggleDealAssistant(
        dealId,
        !data.isAssistantEnabled
      );
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to update the deal assistant data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardAction
      label="Deal Assistant"
      beta
      description={
        isEnabled ? (
          <span>
            The assistant tab is <b>enabled</b> for the deal participants.
          </span>
        ) : (
          <span title="The data room watermark is disabled">
            The assistant tab is <b>disabled</b> for the deal participants.
          </span>
        )
      }
      onClick={handleClick}
      loading={loading}
      endAdornment={<Switch checked={isEnabled} disabled={loading} />}
    />
  );
};
