import { Auth } from "aws-amplify/lib";
import { delayAsync } from "application/utils";
import { apiClient } from "./clients";

class AuthService {
  async updateApiToken(newToken?: string) {
    if (newToken) {
      apiClient.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      return;
    }

    delete apiClient.defaults.headers.common.Authorization;
  }

  async sendEmailConfirmation() {
    await Auth.verifyCurrentUserAttribute("email");
  }

  async refreshIdToken() {
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();

    if (session) {
      const refreshToken = session.getRefreshToken();
      if (refreshToken) {
        user.refreshSession(refreshToken, (err: unknown) => {
          if (err) {
            throw new Error("Error during refresh session");
          }
        });
        await delayAsync(1000); // Delay to update tokens in local storage, otherwise if you make call right after refresh you could get obsolete tokens
      }
    } else {
      throw new Error("Could not get user session");
    }
  }

  async updateUserAttributes(attributes: Record<string, string>) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      ...attributes,
    });
  }
}

export default new AuthService();
