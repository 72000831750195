import { PixelCrop } from "react-image-crop";

export async function getCroppedImageData(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  imgSrc: string,
  crop: PixelCrop
): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = scaleX * crop.width;
    canvas.height = scaleY * crop.height;

    const mimeType = imgSrc.substring(
      imgSrc.indexOf(":") + 1,
      imgSrc.indexOf(";")
    );

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      reject(new Error("No 2d context"));
      return;
    }

    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(resolve, mimeType);
  });
}
