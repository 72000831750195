import React from "react";
import { DealQuestion } from "types/deal";
import Message from "./Message";
import { DealQuestionStatusEnum, EditQuestionResourceEnum } from "domain/deal";
import AnswerInput from "./AnswerInput";
import ActionPanel from "./ActionPanel";
import { AnswerMessage } from "./AnswerMessage";
import { useGroupValidation } from "ui/hooks";

interface QuestionProps {
  answerAble?: boolean;
  canChangePosition: boolean;
}

export const Question = ({
  answerAble,
  status,
  answer,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & DealQuestion & QuestionProps) => {
  const { hasAdminLevel } = useGroupValidation();

  return (
    <div>
      <Message
        {...props}
        ActionPanel={
          <ActionPanel
            edit={
              props.editable ? EditQuestionResourceEnum.Question : undefined
            }
            deleteAction={
              props.editable ? EditQuestionResourceEnum.Question : undefined
            }
            pin={hasAdminLevel}
            canChangePosition={hasAdminLevel && props.canChangePosition}
            questionId={props.id}
            text={props.text}
          />
        }
      />
      {status === DealQuestionStatusEnum.Answered && answer ? (
        <div className="mt-7">
          <AnswerMessage
            {...answer}
            ActionPanel={
              <ActionPanel
                edit={
                  answer.editable ? EditQuestionResourceEnum.Answer : undefined
                }
                deleteAction={
                  answer.editable ? EditQuestionResourceEnum.Answer : undefined
                }
                questionId={props.id}
                text={answer.text}
              />
            }
          />
        </div>
      ) : answerAble ? (
        <AnswerInput className="pt-5 pl-5 space-y-5" questionId={props.id} />
      ) : null}
    </div>
  );
};

export default Question;
