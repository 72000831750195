import { apiClient } from "./clients";

class EmailSubscriptionService {
  private readonly basePath = "/EmailSubscription";

  async subscribeToNewsletter(email: string): Promise<void> {
    return await apiClient.post(`${this.basePath}/newsletter`, {
      email,
    });
  }

  async subscribeToAIWaitList(email: string): Promise<void> {
    return await apiClient.post(`${this.basePath}/ai/wait-list`, {
      email,
    });
  }
}

export default new EmailSubscriptionService();
