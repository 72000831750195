import { generatePath } from "react-router-dom";
import {
  dealDetails,
  dealManage,
  dealEdit,
  dealRolePermissions,
  dealUserPermissions,
  dealDataroom,
  dealReports,
  editDealLeadInvestor,
  editDealSponsor,
  editDealAdvisor,
  editDealHistoricalPerformance,
  editDealKeyBiographies,
  editDealKeyHighlights,
  editDealSummary,
  editDealPodcast,
  editDealCampaign,
  dealCampaigns,
  dealCampaignExclusionLists,
  dealAssistant,
} from "ui/Router/paths";
import useDealAlias from "./useDealAlias";

export function useDealPaths() {
  const alias = useDealAlias() as string;
  const options = { alias };

  const dealPath = generatePath("/" + dealDetails, options);
  const dealAssistantPath = generatePath("/" + dealAssistant, options);
  const dealManagePath = generatePath("/" + dealManage, options);
  const rolePermissionsPath = generatePath("/" + dealRolePermissions, options);
  const userPermissionsPath = generatePath("/" + dealUserPermissions, options);
  const userCampaignExclusionList = generatePath(
    "/" + dealCampaignExclusionLists,
    options
  );

  const dataroomPath = generatePath("/" + dealDataroom, options);
  const reportsPath = generatePath("/" + dealReports, options);
  const dealCampaignsPath = generatePath("/" + dealCampaigns, options);

  const editDealPath = generatePath("/" + dealEdit, options);
  const editDealLeadInvestorPath = generatePath(
    "/" + editDealLeadInvestor,
    options
  );
  const editDealSponsorPath = generatePath("/" + editDealSponsor, options);
  const editDealPodcastPath = generatePath("/" + editDealPodcast, options);
  const editDealCampaignPath = generatePath("/" + editDealCampaign, options);
  const editDealAdvisorPath = generatePath("/" + editDealAdvisor, options);
  const editDealHistoricalPerformancePath = generatePath(
    "/" + editDealHistoricalPerformance,
    options
  );
  const editDealKeyBiographiesPath = generatePath(
    "/" + editDealKeyBiographies,
    {
      alias,
    }
  );
  const editDealKeyHighlightsPath = generatePath(
    "/" + editDealKeyHighlights,
    options
  );
  const editDealSummaryPath = generatePath("/" + editDealSummary, options);

  return {
    dealPath,
    dealManagePath,
    rolePermissionsPath,
    userPermissionsPath,
    dataroomPath,
    reportsPath,
    dealCampaignsPath,
    editDealPath,
    editDealLeadInvestorPath,
    editDealSponsorPath,
    editDealAdvisorPath,
    editDealHistoricalPerformancePath,
    editDealKeyBiographiesPath,
    editDealKeyHighlightsPath,
    editDealSummaryPath,
    editDealPodcastPath,
    editDealCampaignPath,
    userCampaignExclusionList,
    dealAssistantPath,
  };
}
