import { isUndefined } from "lodash";
import dealService from "services/deal.service";
import useSWR, { SWRConfiguration } from "swr";
import { IMediaItem } from "types/media.service";
import useDealAlias from "./useDealAlias";

export const useDealMediaItem = (
  configuration?: SWRConfiguration<IMediaItem | null>
) => {
  const alias = useDealAlias();
  const { data, error, mutate } = useSWR<IMediaItem | null>(
    `/fund/${alias}/media/item`,
    () => dealService.getDealMediaItem(alias),
    configuration
  );
  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};
