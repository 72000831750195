import { isUndefined } from "lodash";
import mediaService from "services/media.service";
import useSWR from "swr";

export const useAllMedia = () => {
  const { data, error, mutate } = useSWR(`/Media`, () =>
    mediaService.getAllMedia()
  );

  const loading = [data, error].every(isUndefined);

  return {
    data,
    error,
    mutate,
    loading,
  };
};
