import {
  DealCardAdvancedPayload,
  DealCardPayload,
  DealGeneralData,
  DealPerson,
  DealRegistrationSteps,
  GetDealPersonDataResponse,
} from "types/deal";

const defaultCompleteRegistrationSteps: DealRegistrationSteps = {
  historicalPerformance: false,
  keyBiographies: false,
  keyHighlights: false,
  leadInvestor: false,
  sponsor: false,
  advisor: false,
  summary: false,
};

export const getPersonData = (data: GetDealPersonDataResponse): DealPerson => ({
  id: data.id,
  fundId: data.fundId,
  imageUrl: data.imageUrl,
  organizationId: data.organizationId,
  formData: {
    name: data.name,
    url: data.url,
    description: data.description,
    otherDeals: data.otherDeals,
    deals: data.deals || [],
  },
});

export const getDealGeneralData = (
  data: DealCardPayload | DealCardAdvancedPayload
): DealGeneralData => {
  const sector = data.sector || [];
  const dealType = data.dealType || [];
  const equityType = data.equityType || [];
  const geographicalRegion = data.geographicalRegion || [];
  const assetType = data.assetType || "";

  const categories = {
    sector,
    geographicalRegion,
    dealType,
    equityType,
    assetType,
  };

  let fundId = "";
  if ("id" in data) {
    fundId = data.id;
  } else if ("fundId" in data) {
    fundId = (data as any).fundId;
  }

  const longDescription = "longDescription" in data ? data.longDescription : "";
  const publicDescription = data?.publicDescription || "";

  const result: DealGeneralData = {
    ...data,
    assetType,
    assistantGenerationStatus: data.assistantGenerationStatus,
    availablePart: data.availablePart || 0,
    initialAvailablePart: data.initialAvailablePart || 0,
    availablePercent: data.availablePercent || 0,
    categories,
    dealType,
    equityType,
    endDate: data.endDate,
    startDate: data.startDate,
    fundId,
    isPDFDownloadEnabled: data.isPDFDownloadEnabled,
    fundOwner: data.fundOwner,
    fundSize: data.fundSize || 0,
    imageUrl: data.imageUrl || "",
    isPublished: data.isPublished,
    longDescription,
    minContribution: data.minContribution || 0,
    name: data.name || "",
    publicDescription,
    sector,
    shortDescription: data.shortDescription || "",
    alias: data.alias || "",
    actualCommitedAmount: data.actualCommitedAmount,
    completedRegistrationSteps:
      data.completedRegistrationSteps || defaultCompleteRegistrationSteps,
  };

  return result;
};
