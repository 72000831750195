import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";

export interface PageHeaderProps {
  title?: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <Stack className="text-center max-w-[385px] mb-5" spacing={2}>
      {title && (
        <Typography
          variant="h4"
          className="font-medium font-serif text-primary-600"
        >
          {title}
        </Typography>
      )}
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
    </Stack>
  );
};
