import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import React, { FC, MouseEvent, useCallback, useState } from "react";
import ModalComponent from "ui/pages/CommonLayout/Modals/ModalComponent";

interface ConfirmationModalProps {
  onConfirm: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => Promise<void> | void;
  title: string;
  children: string | React.ReactElement[] | React.ReactElement;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  disabled?: boolean;
  onCancel?: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => Promise<void> | void;
  onClose: () => void;
  isOpen: boolean;
  closeAfterSubmit?: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  children,
  onClose,
  onConfirm,
  title,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm",
  closeAfterSubmit = true,
  disabled,
  ...props
}) => {
  const [isLoading, setLoading] = useState(false);
  const { onCancel = onClose } = props;

  const handleConfirm = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      setLoading(true);

      try {
        await onConfirm(e);
      } finally {
        setLoading(false);

        if (closeAfterSubmit) onClose();
      }
    },
    [closeAfterSubmit, onClose, onConfirm]
  );

  const handleCancel = useCallback(
    async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      setLoading(true);

      try {
        await onCancel(e);
      } finally {
        setLoading(false);

        if (closeAfterSubmit) onClose();
      }
    },
    [closeAfterSubmit, onCancel, onClose]
  );

  return (
    <ModalComponent smallTitle title={title} open={isOpen} onClose={onClose}>
      <div className="h-full flex flex-col">
        <Typography variant="body1" className="text-gray-500">
          {children}
        </Typography>
        <div className="pt-10 mt-auto flex flex-col space-y-2">
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={(e) => handleConfirm(e)}
          >
            {confirmButtonLabel}
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            color="primary"
            onClick={(e) => handleCancel(e)}
          >
            {cancelButtonLabel}
          </LoadingButton>
        </div>
      </div>
    </ModalComponent>
  );
};
