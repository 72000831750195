import React, { FC } from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { UserAvatar } from "ui/components";
import { BiographerItemData } from "types/deal";
import { getNameInitials } from "application/utils";

interface DealBiographerCardProps
  extends Partial<BiographerItemData>,
    React.ComponentPropsWithoutRef<"div"> {
  index?: number;
  editItem?: (index: number) => void;
}

const DealBiographerCard: FC<DealBiographerCardProps> = ({
  className,
  fullName,
  job,
  description,
  imageUrl,
  editItem,
  index,
}) => (
  <div
    className={clsx(
      className,
      "p-6 flex flex-col shadow-depth-2 border border-solid border-[#F4F5F6] rounded-[12px] w-[300px] min-w-[250px] bg-white"
    )}
  >
    <div className="flex flex-row pb-2">
      <UserAvatar className="flex-start" src={imageUrl}>
        {getNameInitials(fullName)}
      </UserAvatar>
    </div>
    <div className="leading-none">
      <Typography variant="body2" className="font-bold">
        {fullName}
      </Typography>
      {job && (
        <Typography
          component="div"
          variant="caption2"
          className="font-semibold"
        >
          {job}
        </Typography>
      )}
      {description && (
        <Typography
          component="div"
          className="block py-3 font-medium text-gray-400 text-[0.75rem] leading-5"
        >
          {description}
        </Typography>
      )}
    </div>
    <div className="grow flex items-end">
      {editItem && index && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            editItem(index);
          }}
        >
          Edit
        </Button>
      )}
    </div>
  </div>
);

export default DealBiographerCard;
