import { vestResolver } from "@hookform/resolvers/vest";
import { create, enforce, test } from "vest";

const schema = create((data = {}) => {
  test("inviteCode", "Invite Code is required", () => {
    enforce(data.inviteCode).isNotEmpty();
  });
});

export default vestResolver(schema);
