import React from "react";
import Button from "@mui/material/Button";
import { Modal, HTMLEditorField, HTMLEditorFieldHandle } from "ui/components";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DealQuestionID } from "types/deal";
import { useDealId, useErrorHandler, useDealQuestions } from "ui/hooks";
import DealService from "services/deal.service";
import { EditQuestionResourceEnum } from "domain/deal";

interface FormValues {
  text: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  questionId: DealQuestionID;
  type: EditQuestionResourceEnum;
}

export const EditModal = ({
  text = "",
  type,
  questionId,
  isOpen,
  onClose,
}: Props) => {
  const dealId = useDealId() as string;
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({ defaultValues: { text } });
  const { handleError } = useErrorHandler();
  const { mutate } = useDealQuestions();
  const fieldRef = React.useRef<HTMLEditorFieldHandle>(null);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setLoading(true);
      if (type === EditQuestionResourceEnum.Question) {
        await DealService.putDealQuestion(dealId, questionId, {
          anonymous: false,
          text: data.text,
        });
      } else if (type === EditQuestionResourceEnum.Answer) {
        await DealService.putDealQuestionAnswer(dealId, questionId, {
          text: data.text,
        });
      }

      await mutate();
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    reset();
    fieldRef.current?.reset(text);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} title="Edit text">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 sm:w-[340px] md:w-[440px]"
        >
          <Controller
            render={(props) => (
              <HTMLEditorField
                ref={fieldRef}
                {...props}
                placeholder="Enter text"
                toolbarProps={{ options: ["image"] }}
                showError
              />
            )}
            name="text"
            control={control}
          />
          <div className="space-x-6">
            <Button
              disabled={loading}
              type="submit"
              color="primary"
              variant="contained"
            >
              Save
            </Button>
            <Button startIcon={<HighlightOffIcon />} onClick={onReset}>
              Reset
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditModal;
