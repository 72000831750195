import campaignService from "services/campaign.service";
import useSWR, { SWRConfiguration } from "swr";
import { ICampaignContactList } from "types/campaign.service";

export const useContactLists = (options?: {
  onError: (err: Error) => void;
  options: SWRConfiguration<ICampaignContactList[]>;
}) => {
  return useSWR<ICampaignContactList[]>(
    "/Campaign/contact-list",
    () => campaignService.getContactList(),
    options
  );
};
