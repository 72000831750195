import { ColDef, ColGroupDef, GridSizeChangedEvent } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { noop } from "lodash";
import { FC, ReactElement, useRef } from "react";
import AgGridResponsiveWrapper from "ui/components/AgGridResponsiveWrapper/AgGridResponsiveWrapper";

interface NoRowsRendererProps {
  loading: boolean;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  icon?: ReactElement;
}

export interface SelectionTableProps
  extends Omit<AgGridReactProps, "onSelectionChanged"> {
  rowData?: any[] | null;
  columnDefs: (ColDef | ColGroupDef)[] | null;
  pagination?: boolean;
  loading: boolean;
  emptyContent?: Omit<NoRowsRendererProps, "loading">;
  onSelectionChange?: (values: unknown[]) => void;
  wrapperClassName?: string;
}

const gridOptions = {
  rowHeight: 76,
  rowStyle: { background: "transparent", cursor: "pointer" },
  suppressCellFocus: true,
  onGridSizeChanged: (event: GridSizeChangedEvent) =>
    event.api.sizeColumnsToFit(),
  onRowDoubleClicked: noop,
};

export const SelectionTable: FC<SelectionTableProps> = ({
  columnDefs,
  rowData,
  pagination = true,
  loading,
  emptyContent = {},
  onSelectionChange = noop,
  wrapperClassName,
}) => {
  const gridRef = useRef<AgGridReact | null>(null);

  const handleSelectionChanged = () => {
    const apiSelectedRows =
      gridRef?.current?.api?.getSelectedRows() as unknown[];

    onSelectionChange(apiSelectedRows || []);
  };

  return (
    <AgGridResponsiveWrapper
      ref={gridRef}
      rowData={rowData}
      columnDefs={columnDefs}
      pagination={pagination}
      noRowsOverlayComponentParams={{
        loading,
        title: "Nothing to show",
        subtitle: "There are no data for your search",
        ...emptyContent,
      }}
      paginationPageSize={10}
      rowSelection="multiple"
      {...gridOptions}
      onSelectionChanged={handleSelectionChanged}
      wrapperClassName={wrapperClassName || "h-[880px]"}
    />
  );
};
