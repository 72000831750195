import React, { FC, ReactElement } from "react";
import clsx from "clsx";
import { Tooltip, Typography } from "@mui/material";

interface PageTitleProps extends React.ComponentPropsWithoutRef<"div"> {
  subtitle?: string | ReactElement;
  removePadding?: boolean;
  childrenClassName?: string;
  tooltip?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({
  children,
  subtitle,
  className,
  removePadding,
  childrenClassName,
  tooltip,
}) => (
  <div className={clsx({ "pb-9": !removePadding }, className)}>
    {tooltip ? (
      <Tooltip placement="bottom-start" title={<>{children}</>}>
        <Typography
          component="h1"
          variant="h2"
          className={clsx("font-serif", childrenClassName)}
        >
          {children}
        </Typography>
      </Tooltip>
    ) : (
      <Typography
        component="h1"
        variant="h2"
        className={clsx("font-serif", childrenClassName)}
      >
        {children}
      </Typography>
    )}

    {subtitle && (
      <Typography variant="caption" className="inline-block mt-4">
        {subtitle}
      </Typography>
    )}
  </div>
);

export { PageTitle };
