import { isUndefined } from "lodash";
import adminService from "services/admin.service";
import useSWR, { SWRConfiguration } from "swr";
import { ISyndicationPartnerUserGroupsResponse } from "types/admin.service";

export const useUserGroups = (
  userId: string,
  config?: SWRConfiguration<ISyndicationPartnerUserGroupsResponse[]>
) => {
  const { data, error, mutate } = useSWR<
    ISyndicationPartnerUserGroupsResponse[]
  >(
    `/Admin/users/${userId}/groups`,
    () => adminService.getUserGroupsBySyndication(userId),
    config
  );
  const loading = [data, error].every(isUndefined);

  return { data, error, mutate, loading };
};
