import { Typography } from "@mui/material";
import { FC } from "react";

interface CardTableSectionProps {
  title: string;
  children?: React.ReactNode;
}

export const CardTableSection: FC<CardTableSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div className="flex flex-col">
      <Typography
        variant="hairline2"
        className="text-gray-300 block pb-1 uppercase"
      >
        {title}
      </Typography>
      <div className="flex justify-center flex-col">{children}</div>
    </div>
  );
};
