import { matchRoutes, RouteMatch, useLocation } from "react-router-dom";
import * as paths from "ui/Router/paths";

const matchPaths = Object.keys(paths).map((key: string) => ({
  path: (paths as Record<string, string>)[key],
}));

export const useCurrentPath = () => {
  const location = useLocation();
  const [routeMatch] = matchRoutes(matchPaths, location) as RouteMatch[];

  return routeMatch?.route?.path;
};
