import {
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { clamp } from "lodash";
import { FC, useMemo } from "react";

export enum StepperProgressType {
  EndOfLabel,
  MiddleOfLabel,
}

interface HorizontalStepperProps {
  currentStep: number;
  steps: string[];
  progressType?: StepperProgressType;
}

const linearProgressClasses = { root: "bg-gray-200", bar: "rounded-r-full" };
const stepLabelSX = {
  "& > .MuiStepLabel-labelContainer > .MuiStepLabel-alternativeLabel": {
    marginTop: "8px",
  },
};

export const HorizontalStepper: FC<HorizontalStepperProps> = ({
  currentStep,
  steps,
  progressType = StepperProgressType.MiddleOfLabel,
}) => {
  const endOfLabelProgress = useMemo(() => {
    return (100 / steps.length) * (currentStep + 1);
  }, [currentStep, steps.length]);

  const middleOfLabelProgress = useMemo(() => {
    const unit = 100 / steps.length;
    return unit * (currentStep + 1) - unit / 2;
  }, [currentStep, steps.length]);

  const progress = useMemo(() => {
    switch (progressType) {
      case StepperProgressType.EndOfLabel:
        return endOfLabelProgress;
      default:
        return middleOfLabelProgress;
    }
  }, [endOfLabelProgress, middleOfLabelProgress, progressType]);

  return (
    <div className="w-full">
      <LinearProgress
        sx={{
          height: 6,
        }}
        variant="determinate"
        value={clamp(progress, 0, 100)}
        classes={linearProgressClasses}
      />
      <Stepper alternativeLabel activeStep={currentStep} connector={null}>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel StepIconComponent={() => null} sx={stepLabelSX}>
                <Typography
                  variant="hairline2"
                  className={clsx(
                    "uppercase",
                    index <= currentStep ? "text-primary-400" : "text-gray-400"
                  )}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
