import { FormatType } from "domain/common";
import { FC } from "react";
import { useFormattedValue, UseFormattedValueOptions } from "ui/hooks";

interface FormattedValueProps {
  value: any;
  formatType?: FormatType;
  options?: UseFormattedValueOptions;
}

export const FormattedValue: FC<FormattedValueProps> = ({
  value,
  formatType = FormatType.Text,
  options,
}) => {
  const format = useFormattedValue();

  return format(value, formatType, options);
};
