import React from "react";
import Dialog from "@mui/material/Dialog";
import ParticipationDialogBody from "./ParticipationDialogBody/ParticipationDialogBody";
import ParticipationSentBody from "./ParticipationSentBody/ParticipationSentBody";
import DataRoomRequestSentBody from "./DataRoomRequestSentBody/DataRoomRequestSentBody";
import AccessRequestSentBody from "./AccessRequestSentBody/AccessRequestSentBody";

export enum DialogTypes {
  ParticipationRequestOpen,
  ParticipationRequestSent,
  DataRoomAccessRequestSent,
  AccessRequestSent,
}

export interface DialogProps {
  open: boolean;
  type: DialogTypes;
}

interface Props {
  onClose: () => void;
}

interface DialogBodyMapperProps {
  type: DialogTypes;
  onClose: () => void;
}

const DealDialog = ({ type, ...props }: DialogProps & Props) => {
  return (
    <Dialog {...props} classes={{ paper: "p-8 max-w-[488px]" }}>
      <DialogBodyMapper type={type} onClose={props.onClose} />
    </Dialog>
  );
};

const DialogBodyMapper = ({ type, onClose }: DialogBodyMapperProps) => {
  switch (type) {
    case DialogTypes.AccessRequestSent: {
      return <AccessRequestSentBody onClose={onClose} />;
    }
    case DialogTypes.DataRoomAccessRequestSent: {
      return <DataRoomRequestSentBody onClose={onClose} />;
    }
    case DialogTypes.ParticipationRequestSent: {
      return <ParticipationSentBody onClose={onClose} />;
    }
    case DialogTypes.ParticipationRequestOpen:
    default: {
      return <ParticipationDialogBody onClose={onClose} />;
    }
  }
};

export default DealDialog;
