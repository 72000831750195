import React, { useCallback, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as IconArrowUpRoundedSquareSVG } from "assets/svg/icon-arrow-up-rounded-square.svg";
import { ReactComponent as IconArrowDownRoundedSquareSVG } from "assets/svg/icon-arrow-down-rounded-square.svg";
import EditModal from "./EditModal";
import { useDealId, useErrorHandler, useDealQuestions } from "ui/hooks";
import DealService from "services/deal.service";
import {
  DealQuestionPriorityEnum,
  EditQuestionResourceEnum,
} from "domain/deal";
import { MenuButton, MenuButtonItem } from "ui/components";
import {
  BorderColor,
  HighlightOff,
  PushPinOutlined,
} from "@mui/icons-material";
import { FeedbackError } from "application/errors";
import { isEmpty } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";

interface ActionPanelProps {
  edit?: EditQuestionResourceEnum;
  pin?: boolean;
  canChangePosition?: boolean;
  deleteAction?: EditQuestionResourceEnum;
  questionId: string;
  text: string;
}

const deleteQuestionOrAnswer = async (
  dealId: string,
  questionId: string,
  type: EditQuestionResourceEnum
) => {
  switch (type) {
    case EditQuestionResourceEnum.Question:
      return DealService.deleteDealQuestion(dealId, questionId);
    case EditQuestionResourceEnum.Answer:
      return DealService.deleteDealQuestionAnswer(dealId, questionId);
    default:
      throw new FeedbackError("Invalid question action");
  }
};

export const ActionPanel = ({
  questionId,
  edit,
  pin,
  canChangePosition,
  deleteAction,
  text,
}: ActionPanelProps) => {
  const menuBreakpoint = useMediaQuery(json2mq({ maxWidth: 460 }));
  const dealId = useDealId() as string;
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPinLoading, setPinLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const { handleError } = useErrorHandler();
  const { mutate } = useDealQuestions();
  const changePriority = useCallback(
    async (priority: DealQuestionPriorityEnum) => {
      try {
        setLoading(true);
        await DealService.postDealQuestionPriority(
          dealId,
          questionId,
          priority
        );
        mutate();
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [dealId, handleError, mutate, questionId]
  );

  const isAnythingLoading = isPinLoading || isDeleteLoading || loading;

  const increasePriority = async () => {
    changePriority(DealQuestionPriorityEnum.Increase);
  };

  const decreasePriority = async () => {
    changePriority(DealQuestionPriorityEnum.Decrease);
  };

  const handlePin = async () => {
    try {
      setPinLoading(true);
      await DealService.putDealQuestionPin(dealId, questionId);
      mutate();
    } catch (error) {
      handleError(error);
    } finally {
      setPinLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      if (!deleteAction) {
        throw new FeedbackError(
          "It was not possible to find the delete action."
        );
      }

      await deleteQuestionOrAnswer(dealId, questionId, deleteAction);
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to delete the question");
    } finally {
      setDeleteLoading(false);
    }
  };

  const menuItems = [
    edit && {
      label: "Edit",
      icon: <BorderColor />,
      onClick: () => setEditModalOpen(true),
      disabled: isAnythingLoading,
    },
    pin && {
      label: "Pin",
      icon: <PushPinOutlined />,
      onClick: handlePin,
      loading: isPinLoading,
      disabled: isAnythingLoading,
    },
    deleteAction && {
      label: "Delete",
      icon: <HighlightOff />,
      onClick: handleDelete,
      loading: isDeleteLoading,
      disabled: isAnythingLoading,
    },
    menuBreakpoint &&
      canChangePosition && {
        label: "Move down",
        icon: <IconArrowDownRoundedSquareSVG height={18} width={18} />,
        onClick: decreasePriority,
        loading: loading,
        disabled: isAnythingLoading,
      },
    menuBreakpoint &&
      canChangePosition && {
        label: "Move up",
        icon: <IconArrowUpRoundedSquareSVG height={18} width={18} />,
        onClick: increasePriority,
        loading: loading,
        disabled: isAnythingLoading,
      },
  ].filter(Boolean) as MenuButtonItem[];

  return (
    <div className="space-x-2 flex">
      {edit && (
        <EditModal
          isOpen={isEditModalOpen}
          questionId={questionId}
          onClose={() => setEditModalOpen(false)}
          text={text}
          type={edit}
        />
      )}
      {isEmpty(menuItems) ? null : (
        <MenuButton
          className="text-gray-300 h-6 w-6 p-0"
          boldItems
          size="small"
          items={menuItems}
        />
      )}
      {!menuBreakpoint && canChangePosition && (
        <>
          <IconButton
            className="h-6 w-6 p-0"
            disabled={loading}
            onClick={decreasePriority}
            size="small"
          >
            <IconArrowDownRoundedSquareSVG />
          </IconButton>
          <IconButton
            className="h-6 w-6 p-0"
            disabled={loading}
            onClick={increasePriority}
            size="small"
          >
            <IconArrowUpRoundedSquareSVG />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default ActionPanel;
