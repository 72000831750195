import {
  LookupDeal,
  LookupMedia,
  LookupOrganization,
  LookupUser,
  OnboardingOptions,
} from "types";
import { apiClient } from "./clients";

class LookupService {
  private readonly basePath = "/Lookup";

  async getLookupDeals(): Promise<GenericResponse<LookupDeal[]>> {
    const { data } = await apiClient.get(this.basePath + "/deals");

    return data;
  }

  async getFundManagers(config = {}): Promise<LookupUser[]> {
    const { data } = await apiClient.get(this.basePath + "/fundManagers", {
      ...config,
    });

    return data.response;
  }

  async getFundDataOptions(
    config = {}
  ): Promise<GenericResponse<OnboardingOptions>> {
    const { data } = await apiClient.get(this.basePath + "/funddata", {
      ...config,
    });

    return data;
  }

  async getDealPropertiesOptions(
    config = {}
  ): Promise<GenericResponse<OnboardingOptions>> {
    const { data } = await apiClient.get(this.basePath + "/dealProperties", {
      ...config,
    });

    return data;
  }

  async getFundSectors(config = {}): Promise<GenericResponse<Option[]>> {
    const { data } = await apiClient.get(this.basePath + "/sector", {
      ...config,
    });

    return data;
  }

  async getUsers(fundId = "", config = {}): Promise<LookupUser[]> {
    const params = {
      fundId: fundId,
    };
    const { data } = await apiClient.get(this.basePath + `/users`, {
      ...config,
      params,
    });

    return data.response;
  }

  async getMediaList(): Promise<GenericResponse<LookupMedia[]>> {
    const { data } = await apiClient.get(this.basePath + "/media");

    return data;
  }

  async getOrganizations(): Promise<LookupOrganization[]> {
    const { data } = await apiClient.get(this.basePath + "/organizations");

    return data.response;
  }
}

export default new LookupService();
