import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Mark } from "@mui/base/SliderUnstyled";
import {
  useController,
  UseControllerProps,
  FieldValues,
} from "react-hook-form";
import FormSliderInput, {
  Props as FormSliderInputProps,
} from "./FormSliderInput";

interface Props<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<FormSliderInputProps, "name" | "defaultValue"> {
  showError?: boolean;
  label: string;
  marks?: boolean | Mark[];
  valueLabelDisplay?: "on" | "auto" | "off";
  valueLabelFormat?: (value: number) => string;
}

const FormSliderInputController = <T extends FieldValues>({
  name,
  control,
  showError,
  label,
  ...props
}: Props<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div>
      <FormSliderInput
        {...props}
        {...field}
        error={showError && Boolean(fieldState.error)}
        label={label}
      />
      {showError && fieldState?.error && (
        <FormHelperText error>{fieldState.error.message}</FormHelperText>
      )}
    </div>
  );
};

export default FormSliderInputController;
