import { useDealId, useDealPermissions } from "ui/hooks";
import useSWR from "swr";
import { DealQuestion } from "types/deal";
import DealService from "services/deal.service";

export const useDealQuestions = () => {
  const dealId = useDealId() as string;
  const { dealAccess: isAllowed } = useDealPermissions();
  return useSWR<DealQuestion[]>(
    () => isAllowed && `fund/${dealId}/questions-and-answers`,
    async () => {
      const { response } = await DealService.getDealQuestionsAndAnswers(dealId);
      return response;
    }
  );
};

export default useDealQuestions;
