import { IRegistrationSteps } from "types";
import { RegistrationStepEnum } from "./RegistrationForm.enums";
import * as paths from "ui/Router/paths";

export interface BasicInformationSharedData {
  email: string;
  password: string;
  username: string;
  phoneNumber: string;
  countryCode: string;
  bypass?: boolean;
}

export const RegistrationDealsProjects = [
  RegistrationStepEnum.Invite,
  RegistrationStepEnum.BasicInformation,
  RegistrationStepEnum.PhoneConfirmation,
  RegistrationStepEnum.EmailConfirmation,
  RegistrationStepEnum.QualifyAsInvestor,
  RegistrationStepEnum.Position,
  RegistrationStepEnum.Accreditation,
  RegistrationStepEnum.Interests,
  RegistrationStepEnum.NDA,
  RegistrationStepEnum.Welcome,
  RegistrationStepEnum.Guide,
];

export const RegistrationProjectsOnly = [
  RegistrationStepEnum.Invite,
  RegistrationStepEnum.BasicInformation,
  RegistrationStepEnum.PhoneConfirmation,
  RegistrationStepEnum.EmailConfirmation,
  RegistrationStepEnum.Welcome,
  RegistrationStepEnum.Guide,
];

export const RegistrationDealsOnly = [
  RegistrationStepEnum.Invite,
  RegistrationStepEnum.BasicInformation,
  RegistrationStepEnum.PhoneConfirmation,
  RegistrationStepEnum.EmailConfirmation,
  RegistrationStepEnum.Position,
  RegistrationStepEnum.Accreditation,
  RegistrationStepEnum.Interests,
  RegistrationStepEnum.NDA,
  RegistrationStepEnum.Welcome,
  RegistrationStepEnum.Guide,
];

export const RegistrationFormOrderSyndication = [
  RegistrationStepEnum.Invite,
  RegistrationStepEnum.BasicInformation,
  RegistrationStepEnum.PhoneConfirmation,
  RegistrationStepEnum.EmailConfirmation,
  RegistrationStepEnum.Position,
  RegistrationStepEnum.Accreditation,
  RegistrationStepEnum.Interests,
  RegistrationStepEnum.NDA,
  RegistrationStepEnum.Welcome,
  RegistrationStepEnum.Guide,
];

export const RegistrationProgressMap: Record<
  keyof IRegistrationSteps,
  RegistrationStepEnum
> = {
  accreditation: RegistrationStepEnum.Accreditation,
  follow: RegistrationStepEnum.Follow,
  interests: RegistrationStepEnum.Interests,
  position: RegistrationStepEnum.Position,
  signatureDocument: RegistrationStepEnum.NDA,
  qualifyAsInvestor: RegistrationStepEnum.QualifyAsInvestor,
};

export const RegistrationStepPaths = {
  [RegistrationStepEnum.Invite]: paths.signUpInvite,
  [RegistrationStepEnum.BasicInformation]: paths.signUpPath,
  [RegistrationStepEnum.PhoneConfirmation]: paths.signUpPhoneConfirmation,
  [RegistrationStepEnum.EmailConfirmation]: paths.signUpEmailConfirmation,
  [RegistrationStepEnum.Interests]: paths.signUpInterests,
  [RegistrationStepEnum.Accreditation]: paths.signUpAccreditation,
  [RegistrationStepEnum.Position]: paths.signUpPosition,
  [RegistrationStepEnum.NDA]: paths.signUpNda,
  [RegistrationStepEnum.Follow]: paths.signUpFollow,
  [RegistrationStepEnum.Welcome]: paths.signUpWelcome,
  [RegistrationStepEnum.Guide]: paths.firstSteps,
  [RegistrationStepEnum.QualifyAsInvestor]: paths.qualifyAsInvestor,
};
