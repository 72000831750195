import { getNameCombined, getNameInitials } from "application/utils";
import {
  FormInputController,
  FormSelectController,
  Modal,
} from "ui/components";
import { Avatar, Button, Chip, MenuItem } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useErrorHandler } from "../../hooks";
import NotificationsService from "services/notifications.service";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

interface IMessageUser {
  id: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
}

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUsersIds: string[];
  usersToSelect: IMessageUser[];
}

interface FormInputs {
  recipients: string[];
  message: string;
}

const defaultValues: FormInputs = {
  recipients: [],
  message: "",
};

export const MessageModal: FC<MessageModalProps> = ({
  isOpen,
  selectedUsersIds,
  onClose,
  usersToSelect,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleError } = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, setValue, handleSubmit } = useForm<FormInputs>({
    defaultValues,
  });

  useEffect(() => {
    reset({
      message: defaultValues.message,
      recipients: selectedUsersIds,
    });
  }, [selectedUsersIds, reset]);

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const selectOptions = useMemo(() => {
    if (!usersToSelect) return [];

    return usersToSelect.map((item) => ({
      value: item.id,
      label: getNameCombined(item?.firstName, item?.lastName),
    }));
  }, [usersToSelect]);

  const onSubmit: SubmitHandler<FormInputs> = async ({
    recipients,
    message,
  }) => {
    setSubmitting(true);

    try {
      await NotificationsService.sendEmailMessageNotification(
        message,
        recipients
      );

      enqueueSnackbar("Messages sent successfully", {
        title: "Sent!",
        variant: "success",
      });
    } catch (e) {
      handleError(e, "It was not possible send messages");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Message"
      open={isOpen}
      onClose={handleClose}
      bodyProps={{ className: "xs:w-[340px] md:w-[488px]" }}
    >
      <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <FormSelectController
          name="recipients"
          label="To"
          multiple
          control={control}
          displayEmpty
          renderValue={(selected) => (
            <div className="h-full min-h-[24px] -my-2 flex flex-wrap items-center">
              {(selected as string[]).map((value) => {
                const selectedUser = usersToSelect.find(
                  (item) => item.id === value
                );

                const onDelete = () => {
                  setValue(
                    "recipients",
                    (selected as string[]).filter((item) => item !== value)
                  );
                };

                const selectedUserName = getNameCombined(
                  selectedUser?.firstName,
                  selectedUser?.lastName
                );

                return (
                  <Chip
                    avatar={
                      <Avatar src={selectedUser?.imageUrl}>
                        {getNameInitials(selectedUserName)}
                      </Avatar>
                    }
                    className="my-1 mr-1"
                    key={value}
                    label={selectedUserName}
                    variant="outlined"
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onClick={onDelete}
                    onDelete={onDelete}
                  />
                );
              })}
            </div>
          )}
          showError
        >
          {selectOptions.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FormSelectController>
        <FormInputController
          label="Message"
          name="message"
          multiline
          rows={4}
          control={control}
          showError
        />
        <div className="space-y-2">
          <LoadingButton
            loading={isSubmitting}
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            Send now
          </LoadingButton>
          <Button
            disabled={isSubmitting}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};
