import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import {
  AutocompleteController,
  Checkbox,
  DragAndDropField,
  DragAndDropFieldItem,
  FormInputController,
  FormInputWrapper,
  TabPanel,
} from "../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Tab, useTheme } from "@mui/material";
import { useErrorHandler, useGroupValidation } from "../../hooks";
import { useForm } from "react-hook-form";
import Tabs from "@mui/material/Tabs";
import { ReactComponent as IconDownloadSVG } from "assets/svg/icon-download.svg";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import campaignService from "../../../services/campaign.service";
import ModalComponent from "../CommonLayout/Modals/ModalComponent";
import { useCampaignContactList } from "ui/hooks/Deal/useDealCommunication";
import { vestResolver } from "@hookform/resolvers/vest";
import { getSchema } from "./CampaignCreateListFormValidator";
import FormControlLabel from "@mui/material/FormControlLabel";
import { noop } from "lodash";

interface Props {
  addContacts?: boolean;
  contactListId?: string;
  isOpen: boolean;
  handleClose: () => void;
  isExclusionList: boolean;
  onSuccess?: () => void;
}

interface FormInputs {
  name?: string;
  isGlobal: boolean;
  emails: string[];
  files: File[];
}

export const defaultValues: FormInputs = {
  isGlobal: false,
  emails: [],
  files: [],
};

const CampaignCreateListModal = ({
  addContacts,
  contactListId,
  isOpen,
  isExclusionList,
  handleClose,
  onSuccess = noop,
}: Props) => {
  const { mutate } = useCampaignContactList();
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customColors } = useTheme();
  const { handleError } = useErrorHandler();
  const { isAdmin } = useGroupValidation();
  const [uploadProgress] = useState<any>();
  const [isLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const validationSchema = useMemo(() => {
    return getSchema(Boolean(addContacts));
  }, [addContacts]);

  const { control, handleSubmit, watch, reset, setValue, register } =
    useForm<FormInputs>({
      defaultValues,
      resolver: vestResolver(validationSchema),
    });

  const watchFiles = watch("files");
  const watchIsGlobal = watch("isGlobal");

  const [tabsValue, setTabsValue] = useState(0);

  const onClose = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  const filesChanged = (e: SyntheticEvent, files: FileList) => {
    setValue("files", [...files]);
  };

  const handleRemoveFile = (file: File) => {
    const updatedFiles = watchFiles.filter(
      (currentFile) =>
        currentFile.name !== file.name &&
        currentFile.lastModified !== file.lastModified
    );
    setValue("files", updatedFiles);
  };

  const onSubmit = async ({ name, emails, isGlobal }: FormInputs) => {
    setSubmitting(true);
    try {
      const listId = contactListId
        ? contactListId
        : await campaignService.addContactList({
            name: name as string,
            isGlobal: !isAdmin || isGlobal,
            isExclusionList: isExclusionList,
          });

      const formData = new FormData();
      const contactListFile = watchFiles?.[0];

      if (contactListFile) {
        formData.append("file", contactListFile, contactListFile.name);
      }

      if (emails) {
        formData.append("emails", emails.join(","));
      }

      await campaignService.updateContactList(listId, formData);
      await mutate();

      onSuccess();

      enqueueSnackbar(
        (isExclusionList ? `The exclusion list` : `The contact list`) +
          ` has been ${addContacts ? "created" : "updated"}.”`,
        {
          title: "Success!",
          variant: "success",
        }
      );
      onClose();
    } catch (e) {
      handleError(
        e,
        `It was not possible to ${
          addContacts ? "updated" : "created"
        } contact list`
      );
      setHasError(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ModalComponent
      title={
        addContacts
          ? "Add contacts"
          : "Create new " + (isExclusionList ? "exclusion " : "") + "list"
      }
      open={isOpen}
      onClose={() => onClose()}
      smallTitle
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="overflow-auto h-full space-y-4"
      >
        {!addContacts && (
          <div>
            <FormInputController
              {...register("name")}
              control={control}
              name="name"
              label="List name"
              placeholder="Enter list name"
              showError
            />
          </div>
        )}
        <div>
          <div className={clsx("flex", "justify-center")}>
            <Tabs
              className="flex justify-center mb-6 w-full"
              sx={{
                borderBottom: `1px solid ${customColors.gray?.[150]}`,
                height: "58px",
                "& .MuiTabs-indicator": {
                  height: 4,
                  borderRadius: 10,
                },
              }}
              variant="scrollable"
              allowScrollButtonsMobile
              value={tabsValue}
              onChange={handleChange}
            >
              <Tab
                label="Import contacts"
                className="font-bold capitalize text-[16px] w-[50%]"
              />
              <Tab
                label={isExclusionList ? "Add a recipient" : "Add a subscriber"}
                className="font-bold capitalize text-[16px] w-[50%]"
              />
            </Tabs>
          </div>

          <TabPanel value={tabsValue} index={0}>
            <DragAndDropField
              isLoading={isLoading}
              onFilesChanged={filesChanged}
              uploadProgress={uploadProgress}
              hasError={hasError}
              accept="text/csv"
            />
          </TabPanel>

          <TabPanel value={tabsValue} index={1}>
            <FormInputWrapper helperText="Press ENTER to insert each email address.">
              <AutocompleteController
                name="emails"
                control={control}
                options={[]}
                defaultValue={[]}
                multiple
                freeSolo
                getOptionLabel={undefined}
                isOptionEqualToValue={undefined}
                showError
                placeholder="Enter contact emails"
                open={false}
              />
            </FormInputWrapper>
          </TabPanel>
        </div>
        <div>
          {watchFiles &&
            watchFiles.map((file, index) => (
              <div key={index}>
                <DragAndDropFieldItem
                  name={file.name}
                  handleRemoveFile={() => handleRemoveFile(file)}
                />
              </div>
            ))}
        </div>

        <div className="flex items-center">
          <div className="mr-4">
            <IconDownloadSVG />
          </div>
          <Typography variant="caption" className="font-bold text-gray-500">
            <a
              href="data:application/octet-stream,email%2Cfirst_name%2Clast_name%2Caddress_line_1%2Caddress_line_2%2Ccity%2Cstate_province_region%2Cpostal_code%2Ccountry%0Aexample%40example.com%2CJohn%2CDoe%2C123%20Neverland%20Lane%2CSuite%2042%2CDenver%2CCO%2C80202%2CUSA%0A"
              download="contacts-upload-example.csv"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>{" "}
            the CSV template
          </Typography>
        </div>

        <div className="flex flex-col">
          {isAdmin && (
            <div>
              <FormControlLabel
                className="m-0 items-start"
                control={
                  <Checkbox
                    checked={!!watchIsGlobal}
                    onChange={() => setValue("isGlobal", !watchIsGlobal)}
                    disableRipple
                  />
                }
                label={
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ lineHeight: "180%" }}
                  >
                    <span className="text-primary-400 font-bold">
                      {isExclusionList ? `Exclusion list` : `Contact list`} is
                      global
                    </span>
                    <br />
                    <span className="text-gray-400">
                      (Otherwise, this list is only available to you)
                    </span>
                  </Typography>
                }
              />
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-2">
          <LoadingButton
            loading={isLoading || isSubmitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {addContacts
              ? "Add contacts"
              : isExclusionList
              ? "Save new exclusion list"
              : "Save new contact list"}
          </LoadingButton>
          <Button
            disabled={isLoading || isSubmitting}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default CampaignCreateListModal;
